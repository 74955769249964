import React, { useEffect, useState } from "react";
import { Modal, Upload, Row, Button, Form, message, Typography } from "antd";
import pdfLogo from "../../../images/pdf-logo.svg";
import deleteIcon from "../../../images/delete icon.svg";
import "./uploadFiles.css";
import { CloseOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import uploadIcon from "../../../images/upload icon.svg";
import Api from "services/Api";
import { useSelector, useDispatch } from "react-redux";
import { produceWithPatches } from "immer";
import { houseActions } from "../houses/store";
import Notification from "services/Notification";
const UploadPdfDocs = (props) => {
  console.log("junoUploadPdfDocs", props?.uploaderId);
  const { user } = useSelector((state) => state.auth);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const { videoModal, groupDetails } = useSelector((state) => state.house);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   props?.refresh();
  //   props?.refreshed();
  // }, []);


  useEffect(() => {
    console.log("resss", videoModal);
    if (videoModal === true) {
      props.setVideoModal(true);
    }
  }, [videoModal]);
  const [jdFile, setJdFile] = useState([]);
  const [fileList, setFiles] = useState([]);

  const handleChange = ({ fileList }) => {
    setFiles(fileList);
    const selectedFiles = fileList.map(file => file.originFileObj);
    setJdFile(selectedFiles);
    console.log("jjjfiless", fileList)
  };

  const pdfbeforeUpload = (file) => {
    console.log("ppp", file)
    const isPdf = file.type === "application/pdf";
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isPdf) {
      message.error("You can only upload PDF files");
      return isPdf || Upload.LIST_IGNORE;
    } else if (!isLt10M) {
      message.error("pdf must smaller than 10MB!");
      return isPdf || Upload.LIST_IGNORE;
    } else {
      getBase64(file, (url) => {
        setLoading(false);
        setPdfUrl(url);
        setJdFile([file]);
      });
    }
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const [form] = Form.useForm();

  const showUploadList = {
    showPreviewIcon: false,
  };

  console.log("userupload", props?.uploaderId)
  let data = {};
  const handleUploadpdf = (values) => {
    console.log("jjjuploading", values)
    let files = [];
    console.log("sssfile", jdFile)
    jdFile?.forEach((val, index) => {
      files.push(val);
      console.log("ffff", val)
    });
    data = { ...data, files: files };
    console.log("aankh moond", videoModal, groupDetails);
    if (videoModal === true) {
      Api.post(
        `/ibelong-user/uploadUserFile?eventId=${props?.details?.id}&groupId=${groupDetails?.id}`
      ).upload(data, (response, percent) => {

        props.setVideoModal(false);
        setJdFile([]);
        setPdfUrl("");
        // setFiles([])
        dispatch(houseActions.openVideoModal(false));
        props.refreshed();
      });
      return;
    }

    else if (user?.role === 'SuperAdmin') {
      Api.post(`/ibelong-user/uploadUserFileBySuperAdmin?eventId=${props?.details?.id}&userId=${props?.uploaderId}`).upload(data,
        (response, percent) => {
          console.log("junouser", user?.role)
          props.setVideoModal(false);
          dispatch(houseActions.openVideoModal(false));
          // setFiles([])
          setJdFile([]);
          setPdfUrl("");
          props.refresh();
        }
      );
    } else if (user?.id === props?.uploaderId) {
      Api.post(`/ibelong-user/uploadUserFile?eventId=${props?.details?.id}`).upload(data,
        (response, percent) => {
          console.log("junouser", user?.role)
          props.setVideoModal(false);
          dispatch(houseActions.openVideoModal(false));
          // setFiles([])
          setJdFile([]);
          setPdfUrl("");
          props.refresh();
        }
      );
    }



  };
  const getImageSizeKb = (value) => {
    return Math.round(value / 1024);
  };


  return (
    <Modal
      open={props.videoModal}
      onCancel={() => {
        props?.setVideoModal(false);
        setJdFile([]);
        setPdfUrl("");
        props?.setAddMedia();
        dispatch(houseActions.openVideoModal(false));
        if (props?.Tab === "Groups") {
          props?.refreshed();
          console.log("jjjuserId", user?.id, "props", props);
        }
        else if (props.refreshTst == undefined) {
          return null
        }
        else {

          props?.refresh();
        }
      }}
      footer={false}
      destroyOnClose={true}
    >
      <div
        style={{
          fontFamily: "poppins",
          fontSize: "20px",
          fontWeight: "bold",
          padding: "0 0 20px 0",
        }}
      >
        Upload files
      </div>
      <Form form={form} onFinish={handleUploadpdf}>
        {console.log(props?.details?.eventType, "tab", props?.Tab, 'junoUIDind1', user?.id, 'uploaderId', props?.uploaderId, 'user?.privilege', user?.privilege, 'props?.groupLeaderUpload', props?.groupLeaderUpload, 'props?.groupRole', props?.groupRole)}
        {(props?.Tab === "Individual" || props?.details?.eventType === "Individual") ?
          (user?.id === props?.uploaderId || user?.role === 'SuperAdmin') &&
          (
            < Form.Item name="files">
              {console.log('junoUIDind', user?.id, 'uploaderId', props?.uploaderId, 'user?.privilege', user?.privilege, 'props?.groupLeaderUpload', props?.groupLeaderUpload, 'props?.groupRole', props?.groupRole)}
              <Upload.Dragger name="file" id="abcd" beforeUpload={pdfbeforeUpload} accept=".pdf" style={{ backgroundColor: "#6FC78F1A", margin: "0 0 20px 0" }} onRemove={() => { setJdFile([]); setPdfUrl(""); }} multiple={true} maxCount={5} onChange={handleChange} listType="picture-card" showUploadList={showUploadList} > <img src={uploadIcon} /> <p style={{ fontFamily: "poppins" }}> Drag to this area or Click to Upload </p>
              </Upload.Dragger>
            </Form.Item>
          ) :
          props?.Tab === "Groups" || props?.details?.eventType === "Group" ?
            (user?.id === props?.uploaderId ||
              user?.privilege === "EventCoordinators" ||
              user?.role === "SuperAdmin" ||
              (props?.groupLeaderUpload === true && props?.event?.createdBy?.id == user?.id) ||
              (props?.groupRole === 'Group Leader' && props?.event?.createdBy?.id == user?.id)) && (
              < Form.Item name="files">
                {console.log("groups")}
                <Upload.Dragger name="file" id="abcd" beforeUpload={pdfbeforeUpload} accept=".pdf" style={{ backgroundColor: "#6FC78F1A", margin: "0 0 20px 0" }} onRemove={() => { setJdFile([]); setPdfUrl(""); }} multiple={true} maxCount={5} onChange={handleChange} listType="picture-card" showUploadList={showUploadList} > <img src={uploadIcon} /> <p style={{ fontFamily: "poppins" }}>     Drag to this area or Click to Upload </p> </Upload.Dragger>
              </Form.Item>
            )
            : null}
        <Form.Item>
          <div style={{ display: "flex", flexWrap: "wrap", width: "500px" }}>
            {console.log("userId", user?.id, "uploaderId", props?.id)}
            {console.log("sssJdfile", props?.media)}
            {props?.media?.map((val, id) => {
              console.log("jjjjjuu", val)
              let IndPdfName;
              let grpPdfName;
              if (val?.postLink !== undefined) {
                IndPdfName = val?.postLink?.split("/")[val?.postLink?.split("/")?.length - 1];
              } else {
                grpPdfName = val?.postMedia[0]?.[0]?.split("/")[val?.postMedia[0]?.[0]?.split("/")?.length - 1];
              }
              return (<div className="uploadedImageContainer" key={"image" + id} id={"image" + id} style={{ width: "110px", height: "110px", margin: "0 10px 20px 0", }} > <div className="image">
                <Typography.Text style={{ textOverflow: "ellipsis", overflow: "hidden", paddingLeft: "8px", }} ellipsis={{ tooltip: true }} >
                  {IndPdfName}  {grpPdfName}
                </Typography.Text>
                {console.log("filesjjj", val)}

                <img src={pdfLogo} style={{ width: "110px", height: "110px" }}></img>
                {(props?.details?.eventType === "Individual" || props?.Tab === "Individual") ? (
                  user?.id === props?.uploaderId || user?.role === 'SuperAdmin') && (
                    <div className="icon" onClick={() => {
                      Api.delete("/ibelong-events/deletePost").params({ postId: val?.postId !== undefined ? val?.postId : val?.id, }).send((res) => {
                        console.log("resss1", res?.show?.type)
                        if (res?.show?.type === 'error') {
                          console.error("cannot delete")
                        }
                        else {
                          let deletePdf = document.getElementById(`image${id}`);
                          deletePdf.style.display = "none";
                          val?.postLink !== undefined ? props?.refresh() : props?.refreshed();

                        }
                      });
                    }} >
                      <img className="icon-img" src={deleteIcon}></img>
                      <p>Remove file</p>
                    </div>) :
                  props?.Tab === "Groups" || props?.details?.eventType === "Group" ?
                    (user?.id === props?.uploaderId ||
                      user?.privilege === "EventCoordinators" ||
                      user?.role === "SuperAdmin" ||
                      (props?.groupLeaderUpload === true && props?.event?.createdBy?.id == user?.id) ||
                      (props?.groupRole === 'Group Leader' && props?.event?.createdBy?.id == user?.id)) && (
                      // (user?.id === props?.uploaderId || user?.privilege === "EventCoordinators" || (props?.groupLeaderUpload === true || props?.groupRole === 'Group Leader')) && (

                      //  (user?.id === props?.uploaderId || user?.privilege === "EventCoordinators" || props?.groupLeaderUpload === true) && (
                      <div className="icon" onClick={() => {
                        Api.delete("/ibelong-events/deletePost").params({ postId: val?.postId !== undefined ? val?.postId : val?.id, }).send((res) => {
                          console.log("resss2", res)
                          if (res?.show?.type === 'error') {
                            console.log("error", res?.show?.type)
                          }
                          else {
                            let deletePdf = document.getElementById(`image${id}`);
                            deletePdf.style.display = "none"; val?.postLink !== undefined ? props?.refresh() : props?.refreshed();

                          }
                        })
                      }} >
                        <img className="icon-img" src={deleteIcon}></img>
                        <p>Remove file</p>
                      </div>) : null}
              </div>
              </div>
              );
            })}

          </div>
        </Form.Item>
        {console.log("junerole", props)}

        {props?.Tab === "Groups" || props?.details?.eventType === "Group" ?
          (user?.id === props?.uploaderId || user?.role === "SuperAdmin" ||
            user?.privilege === "EventCoordinators" ||
            (props?.groupLeaderUpload === true && props?.event?.createdBy?.id == user?.id) ||
            (props?.groupRole === 'Group Leader' && props?.event?.createdBy?.id == user?.id)) &&
          // (user?.id === props?.uploaderId ||
          //   user?.privilege === "EventCoordinators" ||
          //   (props?.groupLeaderUpload === true || props?.groupRole === 'Group Leader')) &&
          (
            <Form.Item>
              {console.log('role', props?.groupRole, "juniagroupsTab", props?.Tab, 'USERID', user?.id, 'UPLOADERID', props?.uploaderId, 'PRIVILEGE', user?.privilege, 'GROUPLEADERUPLOAD', props?.groupLeaderUpload)}
              <Row justify="center" style={{ marginTop: "10px" }}> <Button type="primary" size="large" htmlType="submit" disabled={jdFile.length > 0 ? false : true} > Submit </Button>
              </Row>

            </Form.Item>
          )
          : (props?.details?.eventType === "Individual" || props?.Tab === "Individual") ? (
            user?.id === props?.uploaderId || user?.role === 'SuperAdmin') && (
              <Form.Item>
                {/* {console.log('jjjjjrole', user?.role, "juniagroupsTab", props?.Tab, 'USERID', user?.id, 'UPLOADERID', props?.uploaderId, 'PRIVILEGE', user?.privilege)} */}
                <Row justify="center" style={{ marginTop: "10px" }}> <Button type="primary" size="large" htmlType="submit" disabled={jdFile.length > 0 ? false : true} > Submit </Button>
                </Row>
                {/* } */}
              </Form.Item>
            ) : null}
      </Form>
    </Modal >
  );
};
export default UploadPdfDocs;