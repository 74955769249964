import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import "./SearchCompo.css";

const SearchCompo = (props) => {
  return (

    <div style={{ display: "flex", gap: "10px", backgroundColor: "white", borderRadius: "5px", maxWidth: props?.maxWidth ? props?.maxWidth : "100%", width: '100%', }}>
      <Input className='SearchCompo_inputSearch' style={{ width: '100%' }} id='searchUser' onChange={(e) => { 
        // const currValue = e.target.value.trim(); 
        const currValue = e.target.value; 
        props.setSearch(currValue); 
        }} value={props.value} placeholder="Search" />
    </div>
  )
}
export default SearchCompo;