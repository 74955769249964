export default {

  input: {
    padding: 1
      },


suggestions: {
  list: {
    backgroundColor: "#F5F3F3",

    border: "1px solid rgba(0,0,0,0.15)",

    fontSize: 16,

    top: "28em",

    position: "fixed",

    height: "18vh",

    overflowY: "auto",
  },

  item: {
    padding: "5px 15px",

    borderBottom: "1px solid rgba(0,0,0,0.15)",

    "&focused": {
      backgroundColor: "#cee4e5",
    },
  },
},
};