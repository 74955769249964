import React from "react";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import { useSelector, useDispatch } from "react-redux";
import { overViewActions } from "./Pages/OverView/store";
import './YearDropdown.css';

function LocationDropdown({ locationArr }) {
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.overView);

  return (
    <Select
      style={{ width: 120 }}
      dropdownClassName="select-dropdown"
      onChange={(val) => {
        dispatch(overViewActions.updateLocation(val?.toLowerCase()));
      }}
      placeholder={"Select location"}
      defaultValue={locationArr[0]}
      defaultActiveFirstOption={true}
    >
      {locationArr.map((val, index) => {
        return (
          <Option value={val} key={Math.random()}>
            {val}
          </Option>
        );
      })}
    </Select>
  );
}
export default LocationDropdown;