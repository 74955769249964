import classes from "../../Pages/users.module.css";
import "./myProfile.css";
import React, { useState, useRef } from 'react';
import { Modal, Space } from "antd";
import 'antd/dist/antd.css';
import { Table, Button, Select, Spin, Row } from 'antd';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import UserProfileDetails from '../userProfileDetails/userProfileDetails';
import { useLocation } from 'react-router-dom';
import Api from 'services/Api';
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "antd";
import { authActions } from "../store";
import PdfLogo from "../../../images/pdf-logo.svg"
import UploadPdfDocs from "../events/uploadpdfDocs";
import UploadbyUserImg from "../events/uploadbyuserimg";
import VideoModal from "../events/videomodal";
import UploadAnyFile from "../events/UploadAnyFile";
import ReactPlayer from "react-player";
import { DownloadOutlined } from "@ant-design/icons";
import YearDropdown from "component/YearDropdown";
import "./myProfile.css"
import { useWindowDimensions } from "../../windowDimention/windowDimension";
import { EventDetailsMob } from "../userProfile";
import PlayVideo from "../events/playVideo";
import pdfLogo from '../../../images/pdf-logo.svg'
const { Paragraph, } = Typography;
const MyProfile = () => {
  const { width } = useWindowDimensions();
  const [details, setDetails] = useState("")
  const [event, setEvent] = useState([]);
  const [checked, setChecked] = useState(false);
  const [recordmedia, setRecord] = useState(false)
  const { user, year } = useSelector(state => state.auth)
  const [ellipsis, setEllipsis] = useState(true);
  const [videoModal, setVideoModal] = useState(false)
  const [audience, setAudience] = useState()
  const Option = Select
  const [playVideomedia, setPlay] = useState("");
  const [filterEvent, setFilterEvent] = useState([]);
  console.log("filterEvent", filterEvent)
  const [uploadDetails, setUploadDetails] = useState(null)
  const [uploadPostmedia, setUploadPostmedia] = useState([])
  const dispatch = useDispatch()
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  useEffect(() => {
    if (!checked) {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  }, [checked]);

  const handleVideoPlay = () => {
    setIsPlaying(true);
  };

  const handleVideoPause = () => {
    setIsPlaying(false);
  };

  console.log("sssPlayPuse", isPlaying)

  const handleClose = () => {
    setIsPlaying(false);
    setChecked(false);
    setRecord(false);
    getApi();
  }
  console.log("ddd", uploadDetails)
  const columns = [

    {
      title: 'Event Name',
      dataIndex: 'eventName',
      key: 'event',
      render: (text, record) => {
        return (
          <NavLink to={{ pathname: "/event-details", state: { data: record } }}>
            <p className={classes.colItem}>{record?.event?.title}</p></NavLink>)
      }

    },

    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => {
        return (
          <p className={classes.colItem}>{record?.event?.startDate}</p>
        )
      }
    },
    {
      title: 'Events Description',
      dataIndex: 'description',
      key: 'description',
      width: 400,
      render: (text, record) => {
        // const [ellipsis, setEllipsis] = useState(true);

        return (<NavLink to={{ pathname: "/event-details", state: { data: record } }}>

          <Paragraph className="Profile-event-description" ellipsis={ellipsis ? { rows: 2, expandable: true, symbol: ' ', fontSize: 16, maxWidth: '200px' } : false}>
            {record?.event?.description}
          </Paragraph>
        </NavLink>
        )
      }
    },

    {
      title: 'Stage',
      key: 'stage',
      dataIndex: 'stage',
      render: (text, record) => {
        return (
          <p className={classes.colItem}>{text}</p>
        )
      }
    },
    {
      title: 'Files',
      dataIndex: 'files',
      key: 'files',

      render: (text, record) => {
        console.log("recordjj", record)
        if (record?.event?.fileType === null || record?.event?.fileType === "none") {
          return (<p>N/A</p>)
        }
        let disabled = false
        if (record?.performanceType !== "Individual"
        ) {
          disabled = true
        }
        console.log("sss", user)
        if ((record?.stage === "Shortlisted" && record?.postMedia?.length === 1)) {
          return (
            <Button disabled={disabled && user?.role !== "SuperAdmin" ? disabled : (!record?.event?.nominationStatus)} className={classes.colItem} onClick={() => {
              setVideoModal(true);
              setUploadDetails(record.event)
            }}>
              Upload{" "}{record?.event?.fileType}
            </Button>
          )
        }

        if (record?.postMedia?.length !== 0 && record?.postMedia[0] !== null && record?.event?.fileType === "all") {
          return (
            <div>
              <a
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  setVideoModal(true);
                  setUploadDetails(record?.event)
                  setUploadPostmedia(record?.event?.posts)

                  // store.dispatch(houseActions.openVideoModal(true));
                  // setAddMedia(record?.posts);
                  // store.dispatch(houseActions?.groupDetails(record));
                  // setUploaderId(record?.createdBy?.id);
                  // setGroupDetails(record);
                  console.log("sssrec", record?.postMedia)
                }}
              >
                Upload additional files
              </a>

            </div>
          );
        }

        // if (record?.postMedia?.[0] && record?.event?.fileType === "application") {
        //   return (
        //     <Row>
        //       {record?.postMedia.map(element => {
        //         return (
        //           <div>
        //             <img onClick={() => window.open(element)} src={PdfLogo} style={{ height: "30px", width: "40px", cursor: 'pointer' }} />
        //           </div>
        //         )
        //       })}
        //     </Row>)
        // }
        // else if (record?.postMedia?.[0] && record?.event?.fileType === "all") {
        //   { console.log("fileee", record?.postMedia?.[0]) }
        //   const postLink = record?.postMedia?.[0]
        //   const fileExtension = postLink?.split(".").pop().toLowerCase();
        //   const isImage = ["jpg", "jpeg", "gif", "bmp", "png"].includes(fileExtension);
        //   const isPDF = ["pdf"].includes(fileExtension);
        //   const isVideo = ["mp4", "avi", "mov", "wmv"].includes(fileExtension);

        //   return (
        //     <Row>

        //       {record?.postMedia.map(element => {
        //         return (
        //           <>
        //             {isImage && <img onClick={() => window.open(element)} src={postLink} alt='file' style={{ cursor: 'pointer', width: "50px", height: "50px", marginRight: '5px' }} />}
        //             {isPDF && <img onClick={() => window.open(element)} src={pdfLogo} alt="PDF" style={{ cursor: 'pointer', width: "50px", height: "50px", marginRight: '5px' }} />}
        //             {isVideo && (
        //               <video style={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => {
        //                 setChecked(true)
        //                 setRecord(element)
        //                 setIsPlaying(true)
        //               }} width="50" height="50" controls>
        //                 <source onClick={() => window.open(element)} src={postLink} type="video/mp4" />
        //                 Your browser does not support the video tag.
        //               </video>
        //             )}
        //           </>
        //           // < img onClick={() => window.open(element)} src={element} style={{ height: "30px", width: "40px", marginLeft: "2px", cursor: "pointer" }} />
        //         )
        //       })}
        //     </Row>)
        // }
        // else if (record?.postMedia?.[0] && record?.event?.fileType === "image") {
        //   return (
        //     <Row>
        //       {record?.postMedia.map(element => {
        //         return (
        //           <img onClick={() => window.open(element)} src={element} style={{ height: "30px", width: "40px", marginLeft: "2px", cursor: "pointer" }} />
        //         )
        //       })}
        //     </Row>)
        // }
        // else if (record?.postMedia?.[0] && record?.event?.fileType === "video") {
        //   return (
        //     <>
        //       {record?.postMedia.map(element => {
        //         return (
        //           <Space style={{ marginRight: '8px' }}>
        //             <Button style={{ width: '100%', }} onClick={() => {
        //               setChecked(true)
        //               setRecord(element)
        //               setIsPlaying(true)
        //             }}>
        //               Play
        //             </Button>
        //             <Button style={{ width: '100%', height: '100%', cursor: 'pointer' }} onClick={() => window.open(element)}><DownloadOutlined />
        //             </Button>
        //           </Space>)
        //       })}
        //     </>)
        // }
        console.log("jjjrecord", record?.event?.nominationStatus)
        return (
          <Button disabled={disabled && user?.role !== "SuperAdmin" ? disabled : (!record?.event?.nominationStatus)} className={classes.colItem} onClick={() => {
            setVideoModal(true);
            setUploadDetails(record?.event)
          }}
          >
            Upload{" "}{record?.event?.fileType}
          </Button>
        );
      }
    },
  ];

  const getApi = () => {
    Api.get(`/ibelong-user/userMasterView?userId=${user.id}`).params({ queryYear: year }).send((res => {
      setEvent(res?.userParicipatedEvents?.items)
      setDetails(res?.user)
      dispatch(authActions.updateUserImage({ userImage: res.user?.profileImage }))
      const eventfiltered = res?.userParicipatedEvents?.items.filter(element => element.stage !== "NotInterested")
      setFilterEvent(eventfiltered)
    }))
  }
  useEffect(() => {
    Api.get(`/ibelong-user/userMasterView?userId=${user.id}`).params({ queryYear: year }).send((res => {
      console.log("reeee", res)
      setEvent(res?.userParicipatedEvents?.items)
      setDetails(res?.user)
      dispatch(authActions.updateUserImage({
        userImage: res?.user?.profileImage
      }))
      const eventfiltered = res?.userParicipatedEvents?.items.filter(element => element.stage !== "NotInterested")
      setFilterEvent(eventfiltered)
    }))

  }, [year])
  console.log("ssssuploadDetails", uploadDetails)
  return (<>
    {width > 700 && <div>
      {details === "" ? (<Row justify='center' style={{ marginTop: "100px" }}><Spin size='large' style={{ alignSelf: "center", marginTop: "30vh" }} /></Row>) : (
        <div className={classes.dashboard} >
          <div className={classes['profile-header']} style={{ display: 'flex', justifyContent: 'space-between', margin: "0.5rem 0 1.3rem 0" }}>
            <h1 style={{ fontFamily: "poppins" }}>My Details</h1>
            <YearDropdown></YearDropdown>
          </div>
          <UserProfileDetails detailed={details} record={event} refresh={getApi.bind(this)} />
          <main className={classes.main}>
            <div className={classes.tableHeader}>
              <h1 className={classes.participatedHeading}>Participated Events</h1>
            </div>
            <Table columns={columns} dataSource={filterEvent} rowClassName={classes.tableStyle} style={{ fontFamily: "poppins", fontSize: "16px", }} />
          </main>
          {uploadDetails?.fileType === "application" ?
            <>
              <UploadPdfDocs setAddMedia={() => { setVideoModal(false) }} videoModal={videoModal} setVideoModal={setVideoModal}
                details={uploadDetails} refresh={getApi.bind(this)} uploaderId={details?.id} />
            </> :
            uploadDetails?.fileType === "image" ?
              <>
                <UploadbyUserImg setAddMedia={() => { setVideoModal(false) }} videoModal={videoModal} setVideoModal={setVideoModal}
                  details={uploadDetails} refresh={getApi.bind(this)} uploaderId={details?.id} />
              </> :
              uploadDetails?.fileType === "all" ?
                <>
                  <UploadAnyFile setAddMedia={() => { setVideoModal(false) }} videoModal={videoModal} setVideoModal={setVideoModal} setUploadPostmedia={setUploadPostmedia}
                    details={uploadDetails} uploadPostmedia={uploadPostmedia} refresh={getApi.bind(this)} uploaderId={details?.id} setUploadDetails={setUploadDetails} />
                </> :
                uploadDetails?.fileType === "video" ?
                  <VideoModal setAddMedia={() => { setVideoModal(false) }} videoModal={videoModal} setVideoModal={setVideoModal}
                    details={uploadDetails} media={uploadDetails} refresh={getApi.bind(this)} uploaderId={details?.id} /> : ""}

          {/* <Modal open={checked} onCancel={handleClose} footer={false} closable={true} >
            <ReactPlayer
              style={{ margin: "0px 0px ", }}
              height={"300px"}
              width={"100%"}
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              onContextMenu={e => e.preventDefault()}
              url={recordmedia}
              controls={true}
            />
          </Modal> */}



          <Modal open={checked} onCancel={handleClose} footer={false} closable={true} >
            <video src={recordmedia} controls
              ref={videoRef}
              style={{ margin: "0px 0px ", }}
              height={"300px"}
              width={"100%"}
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            // onContextMenu={e => e.preventDefault()}
            // url={recordmedia}
            // controls={true}
            />
          </Modal>

          {/* <Modal
            open={checked}
            onCancel={handleClose}
            footer={null}
            closable={false}
          >
            <ReactPlayer
              style={{ margin: "0px 0px" }}
              height={"300px"}
              width={"100%"}
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              onContextMenu={e => e.preventDefault()}
              url={recordmedia}
              controls={true}
              playing={isPlaying}
              onPlay={handleVideoPlay}
              onPause={handleVideoPause}
            />
            <Button style={{ marginTop: "1rem" }} onClick={handleClose}>Cancel</Button>
          </Modal> */}
        </div>)}
    </div>}
    {width < 700 && (
      <div>
        {details === "" ? (
          <Row justify='center' style={{ width: '100vw', height: '100vh', }}><Spin size='large' style={{ display: "flex", justifyContent: "center", alignItems: 'center' }} /></Row>

        ) : (
          <div className={classes.userProfileMob}>
            <div className={classes['profile-header']} style={{ display: 'flex', justifyContent: 'space-between', margin: "0.5rem 0 0.5rem 0" }}>
              <h1 style={{ fontFamily: "poppins" }}>My Details</h1>
              <YearDropdown></YearDropdown>
            </div>

            <UserProfileDetails record={filterEvent} detailed={details} />
            <h3
              style={{
                margin: "1.5rem 0 0.5rem 0",
                fontSize: "1rem",
                fontWeight: "700",
                fontFamily: "poppins",
              }}
            >
              Participated Events
            </h3>
            <div className={classes.eventDetailsMob}>
              {filterEvent?.map((item, index) => {
                return (
                  <EventDetailsMob
                    item={item}
                    key={index}
                    checked={checked}
                    setChecked={setChecked}
                    setPlay={setPlay}
                  />
                );
              })}
              <PlayVideo details={playVideomedia} checked={checked} />
            </div>
          </div>
        )}
      </div>
    )}
  </>
  )
};
export default MyProfile;