import classes from "./SideBar.module.css";
import eventLogo from "../../images/events.png";
import userLogo from "../../images/users.png";
import overviewLogo from "../../images/overview.png";
import BrigoshaLogo from "../../images/brigoshaLogo.svg";
import UserIcon10 from "../../images/UserIcon10.svg";
import houses from "../../images/houses.png";
import { NavLink } from "react-router-dom";
import toggleimage from "../../images/toggle.png";
import toggledown from "../../images/toggledown.png";
import HouseNav from "./houseNav";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import chatSquareIcon from "../../images/chatIcon.svg";
import { Form, Modal, Upload, Button, message, Row, Col } from "antd";
import { Footer } from "antd/lib/layout/layout";
import WinnerModal from "component/Pages/OverView/WinnerModal";
import { useRef } from "react";
import menu from "../../images/menu.svg";
import logo from '../../images/iBelongLogo.svg';
import Analyticslogo from "../../images/Group 130.svg";
import x from '../../images/x.svg';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";




function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}

const Sidebar = () => {
  const [page, setPage] = useState(0);
  const { height, width } = useWindowDimensions();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();
  const userPage = location.state?.userPage || 0;

  useEffect(() => {
    const path = window.location.pathname;
    
    console.log(path,window.location,"CHECKING");
    console.log(page,"QWERTY");
    
    
    switch (path) {
      case "/":
        setPage(0);
        break;
      case "/vayu":
        setPage(1);
        break;
      case "/prithvi":
        setPage(2);
        break;
      case "/jal":
        setPage(3);
        break;
      case "/agni":
        setPage(4);
        break;
      case "/akash":
        setPage(5);
        break;
      case "/users":
        setPage(6);
        break;
      case "/userProfile":
        setPage(6);
        break;
      case "/events":
        
          setPage(7);
      
        break;
      case "/event-details":
        setPage(7);
        break;
      case "/myProfile":
        setPage(8);
        break;
      case "/chat":
        setPage(9);
        break;
      case "/analytics":
        setPage(10);
     
        break;
      default:
          setPage(null); // Handle unknown paths

    }
  }, [window.location.pathname]);

  // const updatePage = () => {
  //   const path = window.location.pathname;
  //   console.log(page,path,'popstate');
  //   switch (path) {
  //     case "/":
  //       console.log(page);
  //       setPage(0);
  //       break;
  //     case "/vayu":
  //       setPage(1);
  //       break;
  //     case "/prithvi":
  //       setPage(2);
  //       break;
  //     case "/jal":
  //       setPage(3);
  //       break;
  //     case "/agni":
  //       setPage(4);
  //       break;
  //     case "/akash":
  //       setPage(5);
  //       break;
  //     case "/users":
  //     case "/userProfile":
  //       setPage(6);
  //       break;
  //     case "/events":
  //     case "/event-details":
  //       setPage(7);
  //       break;
  //     case "/myProfile":
  //       setPage(8);
  //       break;
  //     case "/chat":
  //       setPage(9);
  //       break;
  //     case "/analytics":
  //       setPage(10);
  //       break;
  //     default:
  //       setPage(null); // Handle unknown paths
  //   }
  // };

  // useEffect(() => {
  //   // Initial page load
  //   updatePage();

  //   // Listen for URL changes
  //   window.addEventListener('popstate', updatePage);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('popstate', updatePage);
  //   };
  // }, [window.location.pathname]);

  console.log("location--",window.location.pathname)

  const { user } = useSelector((state) => state.auth);
  const role = user?.role;
  const privilege = user?.privilege;
  const house = user?.house;

  const sideBarHandler = (key) => {
    setPage(key);
    if (!houseClick) {
      console.log("closed")
      setSidebarOpen(false);
    }
  };
  const images = { toggleimage, toggledown };
  const [img, setImg] = useState(false);
  const imgChangeHandler = () => {
    if (!img) {
      setImg(true);
    } else {
      setImg(false);
    }
  };
  const [houseClick, setHouseClick] = useState(false);
  const sideBarStyle = {
    color: "#000000",
    fontFamily: "poppins",
    fontSize: "14px",
    fontWeight: "500",
  };


  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`${classes.sidebar} ${!isSidebarOpen && width < 700 ? classes.closed : classes.opened}`}>
      <div className={classes.element}>
        {width < 700 && (
          <div style={{ height: "27px", width: 'fit-content', marginTop: '10px', display: 'flex', alignItems: 'center', gap: '6px', marginBottom: '5px', position: 'absolute', }} className={classes.sidebarIcon}>
            {isSidebarOpen ? (
              <img src={x} onClick={toggleSidebar} styleevents={{ marginLeft: '88vw', height: "20px", width: '20px', marginTop: '3px', }} />
            ) : (
              <img src={menu} alt="overview" style={{ height: "100%", width: '20px', cursor: 'pointer' }} onClick={toggleSidebar} />
            )}
          </div>
        )}

        {(width > 700 || isSidebarOpen) && (
          <main className={classes.main}>
            {width < 700 && <div style={{ marginBottom: '25px', padding: '10px' }}>
              <img style={{ width: '30vw', height: '30vh', marginTop: '5px', paddingLeft: '11px' }} src={logo} alt="ibelong logo"></img>
            </div>}
            {width > 700 && <nav style={{ padding: '3px' }}>
              <ul>
                <NavLink to="/">
                  <li
                    id="Overview"
                    onClick={() => {
                      sideBarHandler(0);
                    }}
                    style={{
                      backgroundColor: page === 0 ? "#E8F4FF" : "",
                      paddingLeft: "16px",
                    }}
                  >
                    <div >
                      <img
                        src={overviewLogo}
                        alt="overview"
                        style={{ height: "18px", width: "18px", }}
                      ></img>
                      <div to="/">
                        <span style={sideBarStyle}>Overview</span>
                      </div>
                    </div>
                  </li>
                </NavLink>
                {(user?.role === 'SuperAdmin' || user?.role === 'Captain' || user?.role === 'ViceCaptain')?
                // <NavLink to="/analytics">
                //   <li
                //     id="Analytics"
                //     onClick={() => {
                //       sideBarHandler(10)
                //     }}
                //     style={{
                //       backgroundColor: page === 10 ? "#E8F4FF" : "",
                //       paddingLeft: "16px",
                //     }}
                //   >
                //     <div>
                //       <img
                //         src={Analyticslogo}
                //         alt="Analytics"
                //         style={{ height: "18px", width: "18px" }}
                //       ></img>
                //       <div to="/analytics">
                //         <span  style={sideBarStyle}>Analytics</span>
                //       </div>
                //     </div>
                //   </li>
                // </NavLink>
                <NavLink 
  to="/analytics" 
  onClick={() => sideBarHandler(10)} 
  style={{ 
    textDecoration: 'none', // Optional: Removes the underline from the link
    display: 'block' 
  }}
>
  <li
    id="Analytics"
    style={{
      backgroundColor: page === 10 ? "#E8F4FF" : "",
      paddingLeft: "16px",
      listStyleType: 'none' // Optional: Removes the bullet from the list item
    }}
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={Analyticslogo}
        alt="Analytics"
        style={{ height: "18px", width: "18px", marginRight: '8px' }}
      />
      <span style={sideBarStyle}>Analytics</span>
    </div>
  </li>
</NavLink>
                : null}
                <li
                  className="navitem"
                  style={{ display: "flex", flexDirection: "column", }}
                >
                  {(role === "SuperAdmin" || privilege === "Admin") && (
                    <div
                      className={classes.houses}
                      onClick={() => {
                        setHouseClick((next) => !next);
                        imgChangeHandler();
                      }}
                    >
                      <img
                        src={houses}
                        alt="houses"
                        style={{ height: "18px", width: "18px" }}
                      />
                      <span style={sideBarStyle}>Houses</span>
                      <span>
                        <img
                          src={!img ? toggledown : toggleimage}
                          alt="toggle"
                          className={classes.togg}
                        />
                      </span>
                    </div>
                  )}
                  {!houseClick && (
                    <HouseNav
                      house={house}
                      role={role}
                      privilege={privilege}
                      sideBarHandler={sideBarHandler}
                      page={page}
                    />
                  )}
                </li>

                <NavLink to="/users">
                  <li
                    id="Users"
                    onClick={() => {
                      sideBarHandler(6);
                    }}
                    style={{
                      backgroundColor: (page === 6 ||userPage === 6)? "#E8F4FF" : "",
                      paddingLeft: "16px",
                    }}
                  >
                    {role === "SuperAdmin" && (
                      <div style={{ marginTop: "0" }}>
                        <img
                          src={userLogo}
                          alt="users"
                          style={{ height: "18px", width: "18px" }}
                        ></img>
                        <div style={sideBarStyle}>Users</div>
                      </div>
                    )}
                  </li>
                </NavLink>

                <NavLink to="/events">
                  <li
                    id="Events"
                    onClick={() => {
                      sideBarHandler(7);
                    }}
                    style={{
                      backgroundColor: page === 7 ? "#E8F4FF" : "",
                      paddingLeft: "16px",
                    }}
                  >
                    <div>
                      <img
                        src={eventLogo}
                        alt="events"
                        style={{ height: "18px", width: "18px" }}
                      ></img>
                      <div to="/events" style={sideBarStyle}>
                        Events
                      </div>
                    </div>
                  </li>
                </NavLink>

                <NavLink to="/myProfile">
                  <li
                    id="Profile"
                    onClick={() => {
                      sideBarHandler(8);
                    }}
                    style={{
                      backgroundColor: page === 8 ? "#E8F4FF" : "",
                      paddingLeft: "16px",
                    }}
                  >
                    <div>
                      <img
                        src={UserIcon10}
                        alt="events"
                        style={{ height: "18px", width: "18px" }}
                      ></img>
                      <div to="/myProfile" style={sideBarStyle}>
                        Profile
                      </div>
                    </div>
                  </li>
                </NavLink>
                <NavLink to="/chat">
                  <li
                    onClick={() => {
                      sideBarHandler(9);
                    }}
                    style={{
                      backgroundColor: page === 9 ? "#E8F4FF" : "",
                      paddingLeft: "16px",
                    }}
                  >
                    <div>
                      <img
                        src={chatSquareIcon}
                        alt="events"
                        style={{ height: "18px", width: "18px" }}
                      ></img>
                      <div to="/chat" style={sideBarStyle}>
                        Chats
                      </div>
                    </div>
                  </li>
                </NavLink>
              </ul>

            </nav>}

            {width < 700 &&
              // <div className={`${isSidebarOpen ? 'open' : 'closed'} ${!houseClick && !isSidebarOpen ? 'smooth-closing-sidebar' : ''}`}>
              <div className={classes.mobmenu}>
                <nav >
                  <ul>
                    <NavLink to="/">
                      <li id="Overview" onClick={() => { sideBarHandler(0); }} style={{ backgroundColor: page === 0 ? "#E8F4FF" : "", padding: '2px', width: '92vw' }} >
                        <div >
                          <img src={overviewLogo} alt="overview" style={{ height: "18px", width: "18px", }}></img>
                          <div to="/"> <span style={sideBarStyle}>Overview</span>
                          </div>
                        </div>
                      </li>
                    </NavLink>
                    {(user?.role === 'SuperAdmin' || user?.role === 'Captain' || user?.role === 'ViceCaptain')?
                    <NavLink to="/analytics">
                      <li id="Analytics" onClick={() => { sideBarHandler(10); }}
                        style={{ backgroundColor: page === 10 ? "#E8F4FF" : "", padding: '2px', width: '92vw' }}  >
                        <div>
                          <img src={Analyticslogo} alt="Analytics" style={{ height: "18px", width: "18px" }} />
                          <div to="/analytics" style={sideBarStyle}> Analytics </div>
                        </div>
                      </li>
                    </NavLink>
                    : null}
                    <li className="navitem" onClick={() => { setSidebarOpen(false); }} style={{ display: "flex", flexDirection: "column", padding: '2px', marginLeft: role === "SuperAdmin" ? '0px' : '-17px', width: '92vw' }}  >
                      {(role === "SuperAdmin" || privilege === "Admin") && (
                        <div style={{}} className={classes.houses} onClick={() => { setHouseClick((next) => !next); imgChangeHandler(); }}  >
                          <img src={houses} alt="houses" style={{ height: "18px", width: "18px" }} />
                          <span style={sideBarStyle}>Houses</span>
                        </div>
                      )}
                      <HouseNav house={house} role={role} privilege={privilege} sideBarHandler={sideBarHandler} page={page} />
                    </li>
                    <NavLink to="/users">
                      <li id="Users" onClick={() => { sideBarHandler(6); }} style={{ backgroundColor: page === 6 ? "#E8F4FF" : "", padding: role === "SuperAdmin" ? '5px' : '0px', width: '92vw' }}  >
                        {role === "SuperAdmin" && (
                          <div style={{ marginTop: "0" }}>
                            <img src={userLogo} alt="users" style={{ height: "18px", width: "18px" }} />
                            <div style={sideBarStyle}>Users</div>
                          </div>
                        )}
                      </li>
                    </NavLink>

                    <NavLink to="/events">
                      <li id="Events" onClick={() => { sideBarHandler(7); }}
                        style={{ backgroundColor: page === 7 ? "#E8F4FF" : "", padding: '2px', width: '92vw' }}  >
                        <div>
                          <img src={eventLogo} alt="events" style={{ height: "18px", width: "18px" }} />
                          <div to="/events" style={sideBarStyle}> Events </div>
                        </div>
                      </li>
                    </NavLink>

                    <NavLink to="/myProfile">
                      <li id="Profile" onClick={() => { sideBarHandler(8); }}
                        style={{ backgroundColor: page === 8 ? "#E8F4FF" : "", padding: '2px', width: '92vw' }}  >
                        <div>
                          <img src={UserIcon10} alt="events" style={{ height: "18px", width: "18px" }} />
                          <div to="/myProfile" style={sideBarStyle}> Profile</div>
                        </div>
                      </li>
                    </NavLink>
                    <NavLink to="/chat">
                      <li onClick={() => { sideBarHandler(9); }}
                        style={{ backgroundColor: page === 9 ? "#E8F4FF" : "", padding: '2px', width: '92vw' }} >
                        <div>
                          <img src={chatSquareIcon} alt="events" style={{ height: "18px", width: "18px" }} />
                          <div to="/chat" style={sideBarStyle}> Chats  </div>
                        </div>
                      </li>
                    </NavLink>
                  </ul>

                </nav>
                <div className={classes.brigosha} >
                  <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <img src={BrigoshaLogo} alt="Brigosha" />
                    <p>powered by</p>
                    <span>Brigosha Technologies</span>
                  </div>
                </div>
              </div>
              // </div>
            }
          </main>

        )}
      </div>
      {
        width > 800 ? <div
          className={classes.brigosha}
        >
          <img
            src={BrigoshaLogo}
            alt="Brigosha"
          />
          <p>powered by</p>
          <span>Brigosha Technologies</span>
        </div> : null
      }


      {/* {
        isSidebarOpen ? <div
          className={classes.brigosha}
        >
          <img
            src={BrigoshaLogo}
            alt="Brigosha"
          />
          <p>powered by</p>
          <span>Brigosha Technologies</span>
        </div> : null
      } */}

      {/* )} */}

    </div >
  );
};
export default Sidebar;