import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import { React, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../component/Pages/store";
import './YearDropdown.css';


function YearDropdown() {
  const dispatch = useDispatch();
  useEffect(() => {

    // document.getElementById("dropdown").
    // document.getElementById("dropdown").classList.add
  }, [])
  let today = moment().format("YYYY");
  var yearArr = [];
  const { year } = useSelector(state => state.auth)
  for (let i = parseInt(today); i >= 2022; i--) {
    yearArr.push(i);
  }
  return (
    <Select
      defaultValue={year ? year : "Select year"}
      onChange={(val) => {
        dispatch(authActions.updateYear(val));
      }}
      style={{ width: 120 }}
      dropdownClassName="select-dropdown"
    >
      {yearArr?.map((val,index) => (
        <Option key={index} value={val}>
          {val}
        </Option>
      ))}
    </Select>
  )

}
export default YearDropdown;