import classes from "./housePost.module.css";
import nominatedVideo from "../../../images/overviewModalVideoImage.jpg";
import redHeartIcon from "../../../images/redhearticon.svg";
import heartIcon from "../../../images/heart icon.svg";
import uploadLogo from "../../../images/uploadIcon2.jpg";
import { useEffect, useState } from "react";
import { time } from "./timeUtility";
import moment from "moment";
import { Button, message, Modal, Radio, Upload } from "antd";
import React from "react";
import "antd/dist/antd.css";
import Api from "services/Api";
import profileIcon from "../../../images/profileIcon.jpg"
import { useSelector } from "react-redux";
import { overViewActions } from "../OverView/store";
import { useDispatch } from "react-redux";
import { houseActions } from "./store";
import HouseModal from "./houseModal";


var keynum, lines = 1;

function limitLines(obj, e) {
  if (window.event) {
    keynum = e.keyCode;
  } else if (e.which) {
    keynum = e.which;
  }

  if (keynum === 13) {
    if (lines === obj.rows) {
      return false;
    } else {
      lines++;
    }
  }
}

const HouseModalPost = (props) => {
  const { likes } = useSelector(state => state.overView)
  const [image, setImage] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  // const [comment, setComment] = useState("");
  // const [imageUrl, setImageUrl] = useState("");
  const [postUlInfo, setPostUrl] = useState([]);
  const [likeCount, setLikeCount] = useState(null);
  const [isLiked, setIsLiked] = useState(null);
  const [postLikes, setPostLikes] = useState({});
  const { userImage, user } = useSelector(state => state.auth);
  const { toggle } = useSelector(state => state.house);
  //  console.log("propss", props);
  // const resetForm = () => {
  //   setComment('');
  //   props?.setImageUrl(null);
  //   setImage(null)
  //   console.log("image", props?.imageUrl)
  // };

  useEffect(() => {
    setPostLikes({
      id: props?.data?.id,
      likes: props?.data?.postLikesCount,
      liked: props?.data?.like,
    })
  }, [JSON.stringify(props)])
  const dispatch = useDispatch()

  useEffect(() => {
    Api.get(`/ibelong-houses/postData/${props?.data?.id}`).send(
      (res) => {
        setPostUrl(res?.postData?.comments);
        setLikeCount(res?.postData?.postLikesCount);
        setIsLiked(res?.likeStatus)
      }
    );
  }, [props?.data, likes]);

  const submitFormHandler = (event) => {
    setBtnLoader(true);
    event.preventDefault();
    const postData = {
      postId: props?.data?.id,
      file: image,
      comment: props?.comment,
    };
    Api.post("/ibelong-houses/houseComment").upload(postData, (response, percentCompleted) => {
      console.log("jjcommentone", postData)
      setBtnLoader(false);
      setImage("");
      props?.setImageUrl("");
      props?.setComment("");
      Api.get(`/ibelong-houses/postData/${props?.data?.id}`).send(
        (res) => {
          setPostUrl(res?.postData?.comments);
          setLikeCount(res?.postData?.postLikesCount);

        }
      );
    }
    );
    // Api.post("/posts/comment").upload(

    //     Api.get(`/houses/getEventData?eventId=${props?.data?.eventId}`).send(
    //       (res) => {
    //         console.log("response", res);
    //         setPostUrl(res?.eventPostComments);
    //       }))

  };
  setTimeout(() => { console.log("post data", postUlInfo) })


  const updateLikesHandler = () => {
    setPostLikes((postLikes) => {
      if (postLikes.liked) {
        return {
          ...postLikes,
          likes: postLikes.likes - 1,
          liked: !postLikes.liked,
        };
      } else {
        return {
          ...postLikes,
          likes: postLikes.likes + 1,
          liked: !postLikes.liked,
        };
      }
    });

  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const imgbeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 3072 / 3072 < 10;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    else if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    else {
      getBase64(file, (url) => {
        props?.setImageUrl(url);
        setImage(file);
      });
    }
  };
  return (
    <>
      <div className={`${classes.container} ${classes.scroll}`} >
        {postUlInfo?.map((info, key) => {
          return (
            <div key={key} >
              {info?.commentMedia === null ? (
                <div className={classes.profile1}>
                  <div className={classes.profile} >
                    <div>
                      <img src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} alt="profileImage" />
                    </div>
                    <div className={classes.employee} style={{ minWidth: '18vw' }}>
                      <h2>{info?.user?.name}</h2>
                      <p> {moment(info?.createdAt).format('lll')}</p>
                    </div>
                  </div>
                  <span>{info?.comment}</span>
                </div>
              ) : (
                <div className={classes.imagePart} >
                  <img src={info?.commentMedia} alt={nominatedVideo} />
                  <div className={classes.profile2} >
                    <div className={classes.imageProf}>
                      <div>
                        <img src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} alt="profileImage" />
                      </div>
                      <div className={classes.employee} >
                        <h2>{info?.user?.name}</h2>
                        <p>{moment(info?.createdAt).format('lll')}</p>
                      </div>
                    </div>
                    <span>{info?.comment}</span>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className={classes.likeDiv}>
        <img style={{ cursor: 'pointer' }}
          src={postLikes.liked ? redHeartIcon : heartIcon}
          alt="redHeartIcon"
          onClick={() => {
            updateLikesHandler()
            dispatch(overViewActions.likesList(!likes))
            return new Promise((resolve, reject) => {
              console.log("likeeeethis is posteventID", postLikes);
              Api.post("/ibelong-posts/like").params({ postId: postLikes?.id })
                .send((response, error) => {
                  dispatch(houseActions.updaterenderPost(!toggle));
                  resolve();
                  if (response) { }
                });
            });
          }
          }
        />
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={{ marginRigh: '10px' }}>Liked by</div>{postLikes.liked && <div style={{ marginLeft: '5px' }}>you and </div>} <div style={{ marginLeft: '5px' }}>{isLiked ? (likeCount - 1) : (likeCount)} others</div>
        </div>
      </div>
      {user.role === "SuperAdmin" ? "" : <div>
        <form className={classes.formControl} >
          <textarea
            style={{ maxWidth: "70%", marginRight: "1rem", color: "#000000" }}
            value={props?.comment}
            placeholder="Add comment "
            onChange={(event) => {
              props?.setComment(event.target.value);
            }}
            onkeydown={(event) => { limitLines(this, event) }}
          />
          <div
            style={{ hieght: "30px", width: "50px" }}
            className={classes.scroll}
          >
            <Upload
              name="avatar"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={imgbeforeUpload}
            >
              {props?.imageUrl ? (
                <img src={props?.imageUrl}
                  alt=""
                  style={{ width: "50px", height: "50px", cursor: 'pointer' }}
                />
              ) : (
                <img
                  style={{ marginTop: "5px", borderRadius: "50%", cursor: 'pointer' }}
                  src={uploadLogo}
                  alt=""
                />
              )
              }
            </Upload>
          </div>
          <Button
            disabled={props?.comment === ""}
            type="text"
            loading={btnLoader}
            onClick={submitFormHandler}
          >
            Post
          </Button>
          {/* <Button style={{ fontSize: '14px', border: 'none', color: '#FF6969', }} onClick={resetForm} >Cancel</Button> */}
        </form>
      </div>}
    </>
  );
};
export default HouseModalPost;

// {time(info?.createdAt).mon} {time(info?.createdAt).day} at {time(info.createdAt).time} PM