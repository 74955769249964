import classes from './Chats.module.css';
import React from 'react';
import { Form, Select, Button, Col, Row } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import emailIcon from "../../../images/emailIcon.png";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { chatActions } from "./chatStore";
import Api from "services/Api";
import profileIcon from "../../../images/profileIcon.jpg";
import socketIO from "socket.io-client";
import "./ChatConversation.css";
import { useState, useEffect } from 'react';
import axios from 'axios';
import credentials from '../../../../src/credentials';
import x from '../../../images/x.svg';
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}
const FileShareModal = ({ isOpen, onClose, selectedMedia }) => {
    const { allChatToggle, allUser, grpChatPerson, singleChatPerson } = useSelector((state) => state.chat);
    const { token } = useSelector((state => state.auth))
    const { user, } = useSelector((state) => state.auth);
    const socket = socketIO(credentials?.socketUrl);
    const [idVal, setIdVal] = useState([])
    const { height, width } = useWindowDimensions();

    const dispatch = useDispatch();
    const { Option } = Select;

    let options = [];
    allUser.forEach((ele) => {
        if (!singleChatPerson.some(p => p.userId == ele.userId)) {
            const option = {
                value: ele?.userId + ',' + -2 + ',' + ele?.userName,
                label: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={ele.profileImage || profileIcon} alt="profile" style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                        <span>{ele.userName}</span>
                    </div>
                ),
            };
            options.push(option);
            // console.log('logic', `User ${ele.userId} doesn't have a corresponding chat ID in singlechatPerson.${user.id}`);
        }
    });

    singleChatPerson.forEach((person) => {
        const option = {
            value: person?.userId + ',' + person?.chatId + ',' + person?.userName,
            label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={person.userProfileImage || profileIcon} alt="profile" style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                    <span>{person.userName}</span>
                </div>
            ),
        };
        options.push(option);
    });
    grpChatPerson.forEach((person) => {
        const option = {
            value: -1 + ',' + person?.chatId + ',' + person?.chatName,
            label: (
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={person.groupChatIcon || profileIcon} alt="profile" style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                        <span>{person.chatName}</span>
                    </div>
                </>
            ),
        };
        options.push(option);
    });
    const [filteredOptions, setFilteredOptions] = useState(options);
    const sendShareMessageHandler = async (val) => {
        let promises = []
        let users = []
        val?.receiver?.forEach((ele) => {
            // console.log("receivers", val?.receiver, ele)
            const ids = ele.match(/[^,]+/g);
            ids[0] = parseInt(ids[0]);
            ids[1] = parseInt(ids[1]);
            if (ids[1] === -2) {
                promises.push(
                    axios.post(`${credentials?.backendUrl}/api/chat/createChat`, { participants: [user?.id, ids[0]] }, { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            users.push(res?.data?.chatId)
                            if (res?.data?.chatId) {
                                ids[1] = res?.data?.chatId;
                                dispatch(chatActions.updateAllChatToggle(!allChatToggle));
                                shareFile(ids, val);
                            } else {
                                // console.log("Failed to create chat", ids);
                            }
                        }))
            } else {
                shareFile(ids, val);
            }
        })
        Promise.all(promises).then(() => {
            // console.log("pppp", users, promises)
        })
    };


    const shareFile = (ids, val) => {
        let data = {
            to: ids[0] == -1 ? undefined : ids[0],
            from: user?.id,
            message: val?.message,
            chatId: ids[1],
            chatType: ids[0] == -1 ? "Group" : "nonGroup",
            media: selectedMedia,
        };
        let data1 = {
            to: ids[0] == -1 ? undefined : ids[0],
            from: user?.id,
            message: val?.message,
            chatId: ids[1],
            chatType: ids[0] == -1 ? "Group" : "nonGroup",
            createdAt: moment().format(),
            fromName: user?.name,
            media: selectedMedia,
        };
        if (selectedMedia.length > 0) {
            let files = [];
            if (selectedMedia.length > 0) {
            }
            socket.emit("sendMessage", data, (res) => {
                if (res === true) {
                    dispatch(
                        chatActions.updateChatData({ chatData: data1, type: "SEND" })
                    );
                    document.getElementById("message").reset();
                }
            });
        }
    }


    return (
        <div className={`modal ${isOpen ? 'is-active' : ''}`}>
            {width > 700 && <Modal bodyStyle={{ width: '100%', height: 520, }} open={isOpen} onCancel={onClose} closable={true} header={null} footer={null} id={Math.random(100)} key={Math.random(100)} >
                <div>
                    <Form onSubmit={onClose} className="messageform" id="message" onFinish={(val) => { sendShareMessageHandler(val); onClose(); }} >
                        <Form.Item>
                            <Col style={{ fontFamily: 'sans-serif', fontWeight: "bold", paddingBottom: '4vh' }}>
                                Share this File
                            </Col>
                        </Form.Item>
                        <Form.Item name="receiver" rules={[{ required: true, message: 'Please select atleast one member or group' }]}>
                            <Select
                                mode="multiple"
                                showSearch
                                allowClear
                                listHeight={100}
                                optionFilterProp="children"
                                placeholder="search for groups or people "
                                filterOption={(input, option) => option.value.toString().split(',')[2]?.toLowerCase().includes(input.toLowerCase())
                                }
                                style={{ width: "100%", boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)", }}
                            >
                                {filteredOptions.map((option) => (
                                    <Select.Option key={option.value} value={option.value} >
                                        {option.label}
                                        {console.log("jj", option.label)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <div style={{ marginTop: "3vh", boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)" }}>
                            <Form.Item name="message">
                                <TextArea
                                    placeholder="Add a message if you'd like to"
                                    rows={2}
                                ></TextArea>
                            </Form.Item>
                        </div>

                        <div className={classes.ShareDiv} >
                            <Form.Item>
                                {/* <Button className={classes.ShareBtn}
                                    onSubmit={onClose}
                                    htmlType="submit">
                                    SHARE
                                </Button> */}
                                <Button
                                    className={classes.ShareBtn}
                                    // disabled={isShareDisabled}
                                    htmlType="submit"
                                >
                                    SHARE
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Modal>}

            {width < 700 && isOpen && (
                <div
                    style={{
                        width: '90%',
                        height: 'fit-content',
                        backgroundColor: 'white',
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        boxShadow: 'rgba(22, 23, 23, 0.77) 1px 25px 50px 189px',
                        // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                        zIndex: 1000,
                        padding: '20px',
                    }}
                >
                    <Form
                        onSubmit={onClose}
                        className="messageform"
                        id="message"
                        onFinish={(val) => {
                            sendShareMessageHandler(val);
                            onClose();
                        }}
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ fontFamily: 'sans-serif', fontWeight: 'bold', paddingBottom: '4vh' }}>
                                Share this File
                            </div>
                            <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={onClose} />

                        </div>

                        <Form.Item
                            name="receiver"
                            rules={[{ required: true, message: 'Please select at least one member or group' }]}
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                allowClear
                                listHeight={100}
                                optionFilterProp="children"
                                placeholder="search for groups or people "
                                filterOption={(input, option) => option.value.toString().split(',')[2]?.toLowerCase().includes(input.toLowerCase())
                                }
                                style={{ width: "100%", boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)", }}
                            >
                                {filteredOptions.map((option) => (
                                    <Select.Option key={option.value} value={option.value} >
                                        {option.label}
                                        {console.log("jj", option.label)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <div style={{ marginTop: '3vh', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' }}>
                            <Form.Item name="message">
                                <TextArea placeholder="Add a message if you'd like to" rows={2}></TextArea>
                            </Form.Item>
                        </div>

                        <div className={classes.ShareDiv}>
                            <Form.Item>
                                <Button className={classes.ShareBtn} htmlType="submit">
                                    SHARE
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            )}


        </div>
    );
};

export default FileShareModal;
