import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Modal, Button, Typography, Card } from "antd";
import windlogo from "../../../images/vayuLogo.svg";
import earthlogo from "../../../images/prithviLogo.svg";
import firelogo from "../../../images/agniLogo.svg";
import spacelogo from "../../../images/akashLogo.svg";
import waterlogo from "../../../images/jalLogo.svg";
import Lottie from "react-lottie";
import animationData from "../../../lotties/data";
import Api from "services/Api";
import "./WinnerModal.css";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";
import { useWindowDimensions } from "../../windowDimention/windowDimension";
import { display } from "@mui/system";
import x from '../../../images/x.svg'
console.log("winnernewmodal11")

const MultipleWinnerModal = ({ winnerHouseArray, powerHouseArray, year, setmultiplewinnerModalOpen, }) => {

    console.log("winnernewmodal", winnerHouseArray)
    const handleCloseWinnerModal = () => {
        setmultiplewinnerModalOpen(false);
    };

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', position: 'relative' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontStyle: 'italic', height: 'fit-content', color: "white", fontSize: "45px", fontWeight: "bolder", width: "100%", display: 'flex', justifyContent: 'center', }}>WINNERS {year}</div>
                <img className="closeModal" src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={handleCloseWinnerModal} />
            </div>
            <div style={{ width: '100%', display: 'flex', height: '97%', justifyContent: 'space-between' }}>
                <div style={{ width: '48%', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', width: '100%', fontSize: "22px", fontStyle: "italic", color: "white", fontWeight: "bolder", justifyContent: 'center', height: 'fit-content', padding: '5px', }}> CHAMPIONS {year}</div>
                    <div style={{ backgroundColor: "transparent", width: '100%', justifyContent: 'center', display: 'flex', gap: '15px' }}>
                        {winnerHouseArray.map((house, index) => (
                            <div className="Winner2" key={index} style={{
                                backgroundColor:
                                    (typeof house === 'string' && house.toLowerCase() === 'agni') ? "#ECB189"
                                        : (typeof house === 'string' && house.toLowerCase() === 'jal')
                                            ? "#A3D6F0"
                                            : (typeof house === 'string' && house.toLowerCase() === 'akash')
                                                ? "#AA8FB5"
                                                : (typeof house === 'string' && house.toLowerCase() === 'vayu')
                                                    ? "#F8EECB"
                                                    : (typeof house === 'string' && house.toLowerCase() === 'prithvi')
                                                        ? "#CAE0D2"
                                                        : "",
                                border:
                                    (typeof house === 'string' && house.toLowerCase() === 'agni')
                                        ? "5px solid #CC5200"
                                        : (typeof house === 'string' && house.toLowerCase() === 'jal')
                                            ? "5px solid #348FBD"
                                            : (typeof house === 'string' && house.toLowerCase() === 'akash')
                                                ? "5px solid #5D3382"
                                                : (typeof house === 'string' && house.toLowerCase() === 'vayu')
                                                    ? "5px solid #F0BC00"
                                                    : (typeof house === 'string' && house.toLowerCase() === 'prithvi')
                                                        ? "5px solid #447A57"
                                                        : "",
                                height: "200px", width: "200px",
                            }}>
                                <img
                                    src={
                                        (typeof house === 'string' && house.toLowerCase() === 'agni')
                                            ? firelogo
                                            : (typeof house === 'string' && house.toLowerCase() === 'jal')
                                                ? waterlogo
                                                : (typeof house === 'string' && house.toLowerCase() === 'akash')
                                                    ? spacelogo
                                                    : (typeof house === 'string' && house.toLowerCase() === 'vayu')
                                                        ? windlogo
                                                        : (typeof house === 'string' && house.toLowerCase() === 'prithvi')
                                                            ? earthlogo
                                                            : ""
                                    }
                                    style={{
                                        width: "100%",
                                        height: "auto",
                                    }}
                                >{console.log("logo", house)}</img>

                                <Row
                                    style={{
                                        fontStyle: "italic",
                                        fontWeight: "bolder",
                                        fontSize: "25px",
                                        marginTop: "30px",
                                        width: "100%",
                                        justifyContent: "center",
                                        color:
                                            (typeof house === 'string' && house.toLowerCase() === 'agni')
                                                ? "#CC5200"
                                                : (typeof house === 'string' && house.toLowerCase() === 'jal')
                                                    ? "#348FBD"
                                                    : (typeof house === 'string' && house.toLowerCase() === 'akash')
                                                        ? "#5D3382"
                                                        : (typeof house === 'string' && house.toLowerCase() === 'vayu')
                                                            ? "#F0BC00"
                                                            : (typeof house === 'string' && house.toLowerCase() === 'prithvi')
                                                                ? "#447A57"
                                                                : "",
                                    }}
                                >
                                    {typeof house === 'string' && house.toUpperCase()}
                                </Row>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ width: '48%', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', fontStyle: "italic", color: "white", fontWeight: "bolder", fontSize: "22px", height: 'fit-content', padding: '5px', }}> POWER HOUSE {year}</div>
                    <div style={{ backgroundColor: "transparent", width: '100%', justifyContent: 'center', display: 'flex', justifyContent: 'center', gap: '15px' }}>
                        {powerHouseArray.map((house, index) => (
                            <div className="Power" key={index} style={{
                                backgroundColor:
                                    (typeof house === 'string' && house.toLowerCase() === 'agni') ? "#ECB189"
                                        : (typeof house === 'string' && house.toLowerCase() === 'jal') ? "#A3D6F0"
                                            : (typeof house === 'string' && house.toLowerCase() === 'akash') ? "#AA8FB5"
                                                : (typeof house === 'string' && house.toLowerCase() === 'vayu') ? "#F8EECB"
                                                    : (typeof house === 'string' && house.toLowerCase() === 'prithvi') ? "#CAE0D2"
                                                        : "",
                                border:
                                    (typeof house === 'string' && house.toLowerCase() === 'agni') ? "5px solid #CC5200"
                                        : (typeof house === 'string' && house.toLowerCase() === 'jal') ? "5px solid #348FBD"
                                            : (typeof house === 'string' && house.toLowerCase() === 'akash') ? "5px solid #5D3382"
                                                : (typeof house === 'string' && house.toLowerCase() === 'vayu') ? "5px solid #F0BC00"
                                                    : (typeof house === 'string' && house.toLowerCase() === 'prithvi') ? "5px solid #447A57"
                                                        : "",
                                height: "200px", width: "200px",
                            }}>
                                <img
                                    src={
                                        (typeof house === 'string' && house.toLowerCase() === 'agni') ? firelogo
                                            : (typeof house === 'string' && house.toLowerCase() === 'jal') ? waterlogo
                                                : (typeof house === 'string' && house.toLowerCase() === 'akash') ? spacelogo
                                                    : (typeof house === 'string' && house.toLowerCase() === 'vayu') ? windlogo
                                                        : (typeof house === 'string' && house.toLowerCase() === 'prithvi') ? earthlogo
                                                            : ""
                                    }
                                    style={{
                                        width: "100%",
                                        height: "auto",
                                    }}
                                />
                                <Row
                                    style={{
                                        fontStyle: "italic",
                                        fontWeight: "bolder",
                                        fontSize: "25px",
                                        marginTop: "30px",
                                        width: "100%",
                                        justifyContent: "center",
                                        color:
                                            (typeof house === 'string' && house.toLowerCase() === 'agni') ? "#CC5200"
                                                : (typeof house === 'string' && house.toLowerCase() === 'jal') ? "#348FBD"
                                                    : (typeof house === 'string' && house.toLowerCase() === 'akash') ? "#5D3382"
                                                        : (typeof house === 'string' && house.toLowerCase() === 'vayu') ? "#F0BC00"
                                                            : (typeof house === 'string' && house.toLowerCase() === 'prithvi') ? "#447A57"
                                                                : "",
                                    }}
                                >
                                    {typeof house === 'string' && house.toUpperCase()}
                                </Row>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div >
    )
};

export default MultipleWinnerModal;




