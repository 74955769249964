import classes from './overviewModal.module.css';
import pdfLogo from "../../../images/pdf-logo.svg"
import { useEffect, useState } from "react";
import Anahita from "../../../images/Anahita.jpg";
import hearticon from "../../../images/heart icon.svg";
import redhearticon from "../../../images/redhearticon.svg";
import nominatedVideo from '../../../images/overviewModalVideoImage.jpg';
import ReactPlayer from 'react-player'
import { useDispatch, useSelector } from 'react-redux';
import Api from "services/Api";
import { overViewActions } from "./store";
import { PropertySafetyFilled } from '@ant-design/icons';
import "./nominatedFiles.css"
import profileIcon from "../../../images/profileIcon.jpg"
import { Button, Row, Spin } from 'antd';
import ordinal from 'ordinal';
const months = [" ", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
const ModalNominatedFiles = (props) => {
  const { width } = useWindowDimensions();
  const [videoData, setVideoData] = useState([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const { videos, NominationLoader, likes } = useSelector(state => state.overView)

  useEffect(() => {
    function init() {
      let temp = [];
      for (let i = 0; i < videos?.length; i++) {
        let innertemp = {
          postMedia: videos[i].postMedia,
          id: videos[i].id,
          isLiked: videos[i].liked,
          postLikesCount: videos[i].postLikesCount,
          title: videos[i].title,
          description: videos[i].description,
          privacy: videos[i].Public,
          postType: videos[i].Event,
          postSubType: videos[i].Organiser,
          postLikesCount: videos[i].postLikesCount,
          postCommentCount: videos[i].postCommentCount,
          fileName: videos[i].fileName,
          status: videos[i].status,
          videoLink: videos[i].videoLink,
          createdAt: videos[i].createdAt,
          updatedAt: videos[i].updatedAt,
          postLikes: videos[i].postLikes,
          createdBy: videos[i].createdBy,

        }
        temp.push(innertemp)
      }
      setVideoData(temp);
    }
    init()
  }, [])

  const [reload, setReload] = useState(false);
  const updateLike = (index) => {

    let tmp = videoData;
    if (tmp[index].isLiked) {
      tmp[index].isLiked = false;
      tmp[index].postLikesCount = parseInt(tmp[index].postLikesCount) > 0 ? parseInt(tmp[index].postLikesCount) - 1 : 0
    } else {
      tmp[index].isLiked = true;
      tmp[index].postLikesCount = parseInt(tmp[index].postLikesCount) + 1
    }
    setVideoData(tmp);
    setReload(!reload)
  }
  useEffect(() => {
  }, [reload]);
  return (
    <>
      {NominationLoader ?
        <>
          {width > 700 && <Row justify='center' style={{ margin: "20vh 0 0 10vw" }}><Spin size='large' style={{ alignSelf: "center", height: '60vh' }} /></Row>}
          {width < 700 && <Row justify='center' style={{ width: '100vw', height: '100%', }}><Spin size='large' style={{ display: "flex", justifyContent: "center", alignItems: 'center' }} /></Row>}
        </>
        :
        <> {videoData.map((vid, index) => {
          let fileType;
          if(vid.postMedia[0]?.includes('.mp4')){
            fileType='video'
          }
          else if(vid.postMedia[0]?.includes('.jpg')||vid.postMedia[0]?.includes('.png')||vid.postMedia[0]?.includes('.svg')){
          fileType='image'
          }
          else if(vid.postMedia[0]?.includes('.pdf')){
            fileType='application'
          }
          return (
            <div className={classes.imageControl} key={vid.id} style={{marginBottom:"0.5rem"}} >
              <div style={{ cursor: 'pointer', width: '10rem', height: '50%', }}>
                {fileType === "video" ?
                  <>
                    <ReactPlayer
                      className="custom-player"
                      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                      onContextMenu={e => e.preventDefault()}
                      url={vid.postMedia[0]}
                      controls={true}
                    />
                    {/* </div> */}
                  </> : fileType === "image" ? <>
                    <div style={{ textAlign: "center" }}>
                      <img
                        onClick={() => window.open(vid.postMedia[0])}
                        src={vid.postMedia[0]}

                        // className="pdf-logo"
                        style={{ height: "100px", width: "100px", borderRadius: '4px' }}
                      />

                    </div></> : fileType === "application" ? <>
                      <div style={{ textAlign: "center" }}>
                        <img
                          onClick={() => window.open(vid.postMedia[0])}
                          src={pdfLogo}

                          // className="pdf-logo"
                          style={{ height: "80px", width: "80px", }}
                        />

                      </div>
                    </> : ""}
              </div>
              <div className={classes.heartIcon}>
                <img style={{ cursor: 'pointer' }}
                  src={vid.isLiked ? redhearticon : hearticon}
                  onClick={() => {
                    // updateLikesHandler(vid.id);
                    dispatch(overViewActions.likesList(!likes))
                    updateLike(index)
                    return new Promise((resolve, reject) => {
                      Api.post("/ibelong-posts/like").params({ postId: vid.id })
                        .send((response, error) => {
                          // if (callBack) {
                          //   callBack();
                          // }
                          resolve();
                          if (response) {
                            // dispatch(response());
                            // overViewActions.videoList(videoData)

                          }

                        });
                    });
                  }}
                  width="24px"
                  height="24px"
                  alt="hearticon"
                ></img>
                <div style={{ marginBottom: '20px', marginLeft: '6px', fontStretch: 'ultra-condensed' }}>
                  <span style={{ fontStretch: 'ultra-condensed' }}> Liked by </span>{vid.isLiked && <span style={{ fontStretch: 'ultra-condensed' }}> you and</span>}  <span style={{ fontStretch: 'ultra-condensed' }}>{vid.isLiked ? (vid.postLikesCount - 1) : vid.postLikesCount} others</span>
                </div>
              </div>

              <div className={classes.profileControl}>
                <img
                  src={vid.createdBy.profileImage !== null ? vid.createdBy.profileImage : profileIcon}
                  alt="profileImag"
                  style={{ width: "40px", height: "40px", borderRadius: '50%' }}
                />

                <div className={classes.profileDiv}>
                  <span style={{ fontWeight: '500', fontStretch: 'ultra-condensed' }}>Posted by :<h2> {vid.createdBy.name}</h2></span>
                  <h3 style={{ fontStretch: 'ultra-condensed' }}>{ordinal(parseInt(vid.createdAt.slice(8, 10))) + " " + months[parseInt(vid.createdAt.slice(5, 7))]}</h3>
                </div>

              </div>

            </div>
          );
        })}

        </>}


    </>

  )
}

export default ModalNominatedFiles;