import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Modal, Button, Typography, Card } from "antd";
import windlogo from "../../../images/vayuLogo.svg";
import earthlogo from "../../../images/prithviLogo.svg";
import firelogo from "../../../images/agniLogo.svg";
import spacelogo from "../../../images/akashLogo.svg";
import waterlogo from "../../../images/jalLogo.svg";
import Lottie from "react-lottie";
import animationData from "../../../lotties/data";
import Api from "services/Api";
import "./WinnerModal.css";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";
import { useWindowDimensions } from "../../windowDimention/windowDimension";

const WinnerModal = ({
  winnerHouse,
  powerHouse,
  year,
  setwinnerModalOpen,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleCloseWinnerModal = () => {
    setwinnerModalOpen(false);
  };
  return winnerHouse === powerHouse ? (
    <Row className="winnerModalMainRow" >

      <div className="winnerModalYearWinner" >
        WINNER {year}
      </div>
      <Col
        className="Champ"
        style={{
          height: "450px",
          width: "100%",
          justifyContent: "center",
          backgroundColor: winnerHouse?.toLowerCase() === "agni"
            ? "#ECB189"
            : winnerHouse?.toLowerCase() === "jal"
              ? "#A3D6F0"
              : winnerHouse?.toLowerCase() === "akash"
                ? "#AA8FB5"
                : winnerHouse?.toLowerCase() === "vayu"
                  ? "#F8EECB"
                  : winnerHouse?.toLowerCase() === "prithvi"
                    ? "#CAE0D2"
                    : "",

          border:
            winnerHouse?.toLowerCase() === "agni"
              ? "5px solid #CC5200"
              : winnerHouse?.toLowerCase() === "jal"
                ? "5px solid #348FBD"
                : winnerHouse?.toLowerCase() === "akash"
                  ? "5px solid #5D3382"
                  : winnerHouse?.toLowerCase() === "vayu"
                    ? "5px solid #F0BC00"
                    : winnerHouse?.toLowerCase() === "prithvi"
                      ? "5px solid #447A57"
                      : "",
          marginTop: "15px",
        }}
      >
        <Lottie
          options={defaultOptions}
          style={{
            width: "100%",
            height: "100%",
            zIndex: "2",
            position: "absolute",
          }}
        />

        <Row
          style={{
            color:
              winnerHouse?.toLowerCase() === "agni"
                ? "#CC5200"
                : winnerHouse?.toLowerCase() === "jal"
                  ? "#348FBD"
                  : winnerHouse?.toLowerCase() === "akash"
                    ? "#5D3382"
                    : winnerHouse?.toLowerCase() === "vayu"
                      ? "#F0BC00"
                      : winnerHouse?.toLowerCase() === "prithvi"
                        ? "#447A57"
                        : "",
            fontStyle: "italic",
            fontWeight: "bolder",
            fontSize: "1.2rem",
            width: "100%",
            alignItems: "center",
            textAlign: "center",

          }}
        >
          CHAMPIONS AND POWERHOUSE OF BRIGOSHA
        </Row>

        <Row
          style={{
            height: "250px",
            width: "250px",
            width: "100%",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
            }}
            src={
              winnerHouse?.toLowerCase() === "agni"
                ? firelogo
                : winnerHouse?.toLowerCase() === "jal"
                  ? waterlogo
                  : winnerHouse?.toLowerCase() === "akash"
                    ? spacelogo
                    : winnerHouse?.toLowerCase() === "vayu"
                      ? windlogo
                      : winnerHouse?.toLowerCase() === "prithvi"
                        ? earthlogo
                        : ""
            }
          ></img>
        </Row>

        <Row
          style={{
            color: "white",
            fontStyle: "italic",
            fontWeight: "bolder",
            fontSize: "1rem",
            width: "100%",
            justifyContent: "center",
            marginTop: "15px",
            textAlign: "center",

          }}
        >
          CONGRATULATIONS TEAM {winnerHouse?.toUpperCase()}
        </Row>
      </Col>
    </Row>
  ) : (
    <Row
      style={{
        fontStyle: "italic",
        height: "100% ",
        // width: "700px",
        margin: "auto",
        justifyContent: "center",
      }}
    >
      <Row className='winnerLabel'
        style={{
          color: "red",
          fontSize: "45px",
          fontWeight: "bolder",
          width: "100%",
          height: "fit-content",
          justifyContent: "center",
          fontSize: "1.5rem",
          display: "flex",
          alignItems: "center",
          paddingLeft: "10rem",
        }}
      >
        {/* 2 2WINNER {year} */}
        <Col span={19} style={{ textAlign: "center" }}
        >WINNER {year}
        </Col>

      </Row>
      <Row className='colWise'
        style={{
          margin: "auto",
          columnGap: "50px",
          marginTop: "70px",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Col style={{}} >
          {console.log("winner", winnerHouse)}
          <Col
            className="Winner2"
            style={{
              backgroundColor: winnerHouse?.toLowerCase() === "agni"
                ? "#ECB189"
                : winnerHouse?.toLowerCase() === "jal"
                  ? "#A3D6F0"
                  : winnerHouse?.toLowerCase() === "akash"
                    ? "#AA8FB5"
                    : winnerHouse?.toLowerCase() === "vayu"
                      ? "#F8EECB"
                      : winnerHouse?.toLowerCase() === "prithvi"
                        ? "#CAE0D2"
                        : "",
              border:
                winnerHouse?.toLowerCase() === "agni"
                  ? " 5px solid #CC5200"
                  : winnerHouse?.toLowerCase() === "jal"
                    ? " 5px solid #348FBD"
                    : winnerHouse?.toLowerCase() === "akash"
                      ? " 5px solid #5D3382"
                      : winnerHouse?.toLowerCase() === "vayu"
                        ? " 5px solid #F0BC00"
                        : winnerHouse?.toLowerCase() === "prithvi"
                          ? "5px solid #447A57"
                          : "",
              height: "300px",
              width: "300px",
            }}
          >
            <Lottie
              options={defaultOptions}
              style={{
                width: "100%",
                height: "100%",
                zIndex: "2",
                position: "absolute",
              }}
            />

            <Row
              className="mobileImg"
              style={{
                height: "250px",
                width: "250px",
                marginLeft: "25px",
                marginTop: "20px",
              }}
            >
              <img
                src={
                  winnerHouse?.toLowerCase() === "agni"
                    ? firelogo
                    : winnerHouse?.toLowerCase() === "jal"
                      ? waterlogo
                      : winnerHouse?.toLowerCase() === "akash"
                        ? spacelogo
                        : winnerHouse?.toLowerCase() === "vayu"
                          ? windlogo
                          : winnerHouse?.toLowerCase() === "prithvi"
                            ? earthlogo
                            : ""
                }
                style={{
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Row>

            <Row
              style={{
                fontStyle: "italic",
                fontWeight: "bolder",
                fontSize: "25px",
                marginTop: "-135px",
                width: "100%",
                justifyContent: "center",
                color: "white",

              }}
            >
              {winnerHouse} CHAMPIONS
              <Row style={{ width: "100%", justifyContent: "center" }}>
                {year}
              </Row>
            </Row>
          </Col>
          <Row
            style={{

              fontStyle: "italic",
              fontWeight: "bolder",
              fontSize: "25px",
              marginTop: "30px",
              width: "100%",
              justifyContent: "center",
              color:
                winnerHouse?.toLowerCase() === "agni"
                  ? "#CC5200"
                  : winnerHouse?.toLowerCase() === "jal"
                    ? "#348FBD"
                    : winnerHouse?.toLowerCase() === "akash"
                      ? "#5D3382"
                      : winnerHouse?.toLowerCase() === "vayu"
                        ? "#F0BC00"
                        : winnerHouse?.toLowerCase() === "prithvi"
                          ? "#447A57"
                          : "",
            }}
          >
            {winnerHouse?.toUpperCase()}
          </Row>
        </Col>
        <Col
          style={{
            backgroundColor: "transparent",
          }}
        >

          <Col
            className="Power"
            style={{
              backgroundColor: powerHouse?.toLowerCase() === "agni"
                ? "#ECB189"
                : powerHouse?.toLowerCase() === "jal"
                  ? "#A3D6F0"
                  : powerHouse?.toLowerCase() === "akash"
                    ? "#AA8FB5"
                    : powerHouse?.toLowerCase() === "vayu"
                      ? "#F8EECB"
                      : powerHouse?.toLowerCase() === "prithvi"
                        ? "#CAE0D2"
                        : "",
              border:
                powerHouse?.toLowerCase() === "agni"
                  ? "5px solid #CC5200"
                  : powerHouse?.toLowerCase() === "jal"
                    ? "5px solid #348FBD"
                    : powerHouse?.toLowerCase() === "akash"
                      ? "5px solid #5D3382"
                      : powerHouse?.toLowerCase() === "vayu"
                        ? "5px solid #F0BC00"
                        : powerHouse?.toLowerCase() === "prithvi"
                          ? "5px solid #447A57"
                          : "",

              height: "300px",
              width: "300px",
            }}
          >
            <Row
              className="mobileImg"
              style={{
                height: "250px",
                width: "250px",
                marginLeft: "25px",
                marginTop: "20px",
              }}
            >
              <img
                src={
                  powerHouse?.toLowerCase() === "agni"
                    ? firelogo
                    : powerHouse?.toLowerCase() === "jal"
                      ? waterlogo
                      : powerHouse?.toLowerCase() === "akash"
                        ? spacelogo
                        : powerHouse?.toLowerCase() === "vayu"
                          ? windlogo
                          : powerHouse?.toLowerCase() === "prithvi"
                            ? earthlogo
                            : ""
                }
                style={{
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Row>
            <Row
              style={{
                fontStyle: "italic",
                fontWeight: "bolder",
                fontSize: "25px",
                width: "100%",
                justifyContent: "center",
                marginTop: "-135px",
                color: "white",

              }}
            >
              POWERHOUSE
              <Row style={{ width: "100%", justifyContent: "center" }}>
                {year}
              </Row>
            </Row>
          </Col>
          <Row
            style={{
              fontStyle: "italic",
              fontWeight: "bolder",
              fontSize: "25px",
              marginTop: "30px",
              width: "100%",
              justifyContent: "center",
              color:
                powerHouse?.toLowerCase() === "agni"
                  ? "#CC5200"
                  : powerHouse?.toLowerCase() === "jal"
                    ? "#348FBD"
                    : powerHouse?.toLowerCase() === "akash"
                      ? "#5D3382"
                      : powerHouse?.toLowerCase() === "vayu"
                        ? "#F0BC00"
                        : powerHouse?.toLowerCase() === "prithvi"
                          ? "#447A57"
                          : "",
            }}
          >
            {powerHouse?.toUpperCase()}
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default WinnerModal;
