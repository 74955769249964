import { Form, message, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "services/Api";
import { chatActions } from "./chatStore";

function ChatCreateConversation({ conversationModal, setConversationModal }) {
    const { allUser, singleChatPerson, allChatToggle } = useSelector(
        (state) => state.chat
    );
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);
    const [addVal, setAddVal] = useState();
    const getChatPerson = (val) => {
        Api.post("/ibelong-chat/createChat")
            .params({ participants: [val?.userId, user?.id] })
            .send((res) => {
                if (res !== undefined) {
                    dispatch(chatActions.updateAllChatToggle(!allChatToggle));
                    setConversationModal(false);
                }
            });
    };

    useEffect(() => {
        setSearchLoader(true);
        Api.get("/ibelong-chat/houseUsers")
            .params({ search: search })
            .send(async (res) => {
                await dispatch(chatActions.updateAllUser(res));
                setSearchLoader(false);
            });
    }, [search]);

    const addHandler = () => {
        if (addVal === undefined) {
            setConversationModal(false);
        }
        if (!singleChatPerson.find((ele) => ele.userId === addVal)) {
            allUser?.forEach((val) => {
                if (addVal === val?.userId) {
                    getChatPerson(val);
                }
            });
        } else {
            message.error("This Chat already exists");
        }
    };

    return (
        <div>
            <Modal
                open={conversationModal}
                footer={false}
                onCancel={() => {
                    setConversationModal(false);
                }}
            >
                <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                    <div className="creategrp-heading">Start Conversation</div>

                    <div style={{ color: "#a5a5a5" }}>
                        Here you can have one on one conversation with your teammates
                    </div>

                    <Form onFinish={addHandler}>
                        <Form.Item>
                            <Select
                                onBlur={() => {
                                    setSearch("");
                                }}
                                onChange={(val) => {
                                    setAddVal(val);
                                }}
                                showSearch
                                allowClear
                                filterOption={false}
                                placeholder="please select"
                                style={{ width: "100%" }}
                                loading={searchLoader}
                                onSearch={(value) => {
                                    setSearch(value);
                                }}
                                onSelect={(value) => {
                                    setAddVal(value);
                                }}
                            >
                                {allUser?.map((val, id) => {
                                    if (val?.userId !== user?.id) {
                                        return (
                                            <Select.Option value={val?.userId} key={id}>
                                                <div>{val?.userName}</div>
                                            </Select.Option>
                                        );
                                    }
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
                            <button type="submit" className="creategrpBtn">
                                Create
                            </button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}

export default ChatCreateConversation;
