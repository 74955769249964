import "./scrollcard.css";
import React, { useState, useContext } from "react";
import ScrollMenu from "react-horizontal-scroll-menu";
import { Card, Row, Col, Typography } from "antd";
import captainImg from "../../../../images/profileIcon.jpg";
import { BorderRightOutlined, HeartOutlined } from "@ant-design/icons";
import Modal from "antd";
import HouseModal from "../houseModal";
import { useSelector } from "react-redux";
import Api from "services/Api";
import { useDispatch } from "react-redux";
import hearticon from "../../../../images/heart icon.svg";
import red from "../../../../images/redhearticon.svg";
import userImage from "../../../../images/profileIcon.jpg";
import { authActions } from "../../store";
import { useEffect } from "react";
import { houseActions } from "../store";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
const months = [
  " ",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const { Meta } = Card;
function Scrollcard(props) {
  const { width } = useWindowDimensions();
  const { user } = useSelector((state) => state.auth);
  const [objVal, setObjVal] = useState({});
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [onliked, setOnLiked] = useState([]);
  const [isIconClicked, setIconClicked] = useState(false);
  const { post, toggle } = useSelector((state) => state.house);
  const { year } = useSelector((state) => state.auth);
  const [isCardDetail, setIsCardDetail] = useState([]);
  // const heartEmpty = require("../../../../images/heart icon.svg");
  // const heartFull = require("../../../../images/redhearticon.svg");
  // const images = {hearticon, red}
  // const [img, setImg] = useState(false)
  // const imgChangeHandler = () =>{
  //   if(!img){
  //     setImg(true)
  //   }else{
  //     setImg(false)
  //   }
  // }
  // const getApi = () => {
  //   Api.get(`/houses/${props?.data?.id}`).params({queryYear:year}).send((response) => {
  //     let value = [];
  //     let list1 = [];
  //     if (response === undefined) {
  //       console.log("UNDEFIND");
  //     }
  //     if (response !== undefined) {
  //       console.log("response", response);
  //       dispatch(authActions.updateHouses(response.houses));
  //       list1 = response?.whatsHappen?.map((element) => {
  //         value.push(element);
  //         return element;
  //       });
  //       setOnLiked(list1);
  //       setIconClicked(false);
  //     }
  //   });
  // };
  // useEffect(() => {
  //   if (!props?.data?.id) {
  //     return;
  //   }
  //   console.log("cccccccccccc")
  //   console.log(`/houses/${props?.data?.id}`)
  //   Api.get(`/houses/${props?.data?.id}`).params({ search: '', queryYear:year }).send((response) => {
  //     if (response === undefined) {
  //       console.log("API is not Gonna Hit");
  //     }
  //     if (response !== undefined) {
  //       console.log("YE RESPONSE DEKHO", response);
  //       dispatch(
  //         houseActions.updateCarddetails({ cardDetails: response.housePosts })
  //       );
  //       dispatch(authActions.updatePost(!post));
  //     }
  //   });
  // }, [props?.data?.id, post])
  const [media, setMedia] = useState("");
  let widthed;


  const manageLike = (obj, text) => {
    // setObjVal(obj);
    // setMedia(text);
    // setOpen(true);
    Api.post("/ibelong-posts/like")
      .params({ postId: obj?.id })
      .send((res) => {
        dispatch(houseActions.updaterenderPost(!toggle));
      });
  }
  if (user?.privilege === "Admin" && props.captains !== true) {
    widthed = "80vw";
  } else if (props.captains === true) {
    widthed = "45vw";
  } else {
    widthed = "80vw";
  }
  const MenuItem = ({ text, obj }) => {

    console.log("jjjobj", obj)
    const date =
      obj.date.slice(8, 10) + "th  " + months[parseInt(obj.date.slice(5, 7))];
    const createrImage = obj.createdBy.profileImage;
    let cardContent;
    let cardStyle = { color: "#000000", width: "237px", height: "147px" };

    if (!text && obj.description) {
      cardContent = (
        <div
          className="card-content"
          onClick={() => {
            setObjVal(obj);
            setMedia(text);
            setOpen(true);
          }}
        >
          <Typography.Text
            className="card-description"
            ellipsis={{ tooltip: true }}
          >
            {obj.description}
          </Typography.Text>
        </div>
      );
    } else if (text) {
      cardContent = (
        <div className="card-image-container" onClick={() => {
          setObjVal(obj);
          console.log("jj11", objVal)
          setMedia(text);
          setOpen(true);
        }}>

          <img
            className="card-image"
            alt="example"
            src={text}
          // onClick={() => setOpen(true)}
          />
        </div>
      );
    }

    return (
      <>
        {cardContent && (
          <div style={{ position: "relative", }}>
            <Card hoverable className="cardItem" cover={cardContent}  >
              <div className="card-profile-container">
                <div className="card-profile-img-container" >
                  <img className=" card-profile-img" alt="" src={createrImage ? createrImage : userImage} />
                </div>
                <div className="card-profile-name-container">
                  <Typography.Text className="card-profile-name" ellipsis={{ tooltip: true }} >
                    <Meta title={obj.createdBy.name} onClick={() => {
                      // setOpen(true);
                      setObjVal(obj);
                    }}
                    />
                  </Typography.Text>
                  <p className="card-profile-date">{date}</p>
                </div>

              </div>
            </Card>
            <img style={{ position: "absolute", bottom: "30px", right: "15px", cursor: 'pointer' }}
              src={obj?.like ? red : hearticon}
              onClick={() => { manageLike(obj, text) }}
            ></img>
          </div>
        )}
      </>
    );
  };
  // All items component
  // Important! add unique key
  const Menu = (list, selected) =>
    list?.map((el) => {
      let value = {
        createdBy: el.createdBy,
        date: el.createdAt,
        description: el.description,
        id: el.id,
        like: el.like,
        postLikesCount: el.postLikesCount,
      };
      return <MenuItem obj={value} text={el.postMedia[0]} key={el.id} />;
    });
  const Arrow = ({ text, className }) => {
    return <div className={className}>{text}</div>;
  };
  const ArrowLeft = Arrow({ text: "<   ", className: "arrow-prev" });
  const ArrowRight = Arrow({ text: "   >", className: "arrow-next" });
  const { cardDetails } = useSelector((state) => state.house.cardDetails);
  // console.log("CARDDETAILS:", cardDetails);
  const [selected, setSelected] = useState();
  const menuItems = Menu(cardDetails, selected);
  const menu = menuItems;
  const onSelect = (key) => {
    setSelected(key);
  };
  { console.log('jjjobjjjreturnone', objVal) }
  return (
    <>
      {console.log('jjjobjjjreturn', objVal)}
      {width > 700 && <Row className="Scroll-container">
        <div style={{
          maxWidth: widthed,
          paddingBottom: "15px",
          margin: "0 auto",
        }}
        >
          <ScrollMenu
            data={menu}
            arrowLeft={ArrowLeft}
            arrowRight={ArrowRight}
            selected={selected}
            onSelect={onSelect}
          />
        </div>
        {console.log("jjobjval", objVal)}
        <HouseModal objVal={objVal} setOpen={setOpen} open={open} media={media} />
      </Row>}
      {width < 700 && <Row className="Scroll-container">
        <div style={{
          maxWidth: '95vw',
          paddingBottom: "15px",
          margin: "0 auto",
        }}
        >
          <ScrollMenu
            data={menu}
            arrowLeft={ArrowLeft}
            arrowRight={ArrowRight}
            selected={selected}
            onSelect={onSelect}
          />
        </div>
        <HouseModal objVal={objVal} setOpen={setOpen} open={open} media={media} />
      </Row>}
    </>


  );
}
export default Scrollcard;
