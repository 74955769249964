import classes from "./style.module.css";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "antd/dist/antd.css";
import { Button, Dropdown, Input, Popover, Select, Table } from "antd";
import { useEffect } from "react";
import userImage from "./userImage.png";
import Api from "services/Api";
import {
  DownloadOutlined,
  EyeOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { houseActions } from "./store";
import profileIcon from "../../../images/profileIcon.jpg";
import SearchCompo from "component/searchCompo";
import { overViewActions } from "../OverView/store";
import TableDataMob from "./tableDataMob";
import { useWindowDimensions } from "../../windowDimention/windowDimension";

// import { Option } from "antd/lib/mentions";

// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   return {
//     width,
//     height,
//   };
// }

// export function useWindowDimensions() {
//   const [windowDimensions, setWindowDimensions] = useState(
//     getWindowDimensions()
//   );
//   useEffect(() => {
//     function handleResize() {
//       setWindowDimensions(getWindowDimensions());
//     }
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);
//   return windowDimensions;
// }

const Tablecomp = (props) => {
  const { height, width } = useWindowDimensions();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const Option = Select.Option;
  // console.log(props.data);
  const dispatch = useDispatch();
  const { toggle } = useSelector((state) => state.house);
  const { location } = useSelector((state) => state.overView);
  const { year } = useSelector((state) => state.auth);
  const [showLocation, setShowLocation] = useState(false);
  const locationOpt = ["all", "bangalore", "coimbatore"];
  const filteredData = data.filter((item) =>
    item?.name?.toLowerCase().includes(search?.toLowerCase())
  );


  const columns = [
    {
      title: "Associate",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const profileImage = record?.user?.profileImage;

        return (
          <NavLink
            to={{ pathname: "/userProfile", state: { data: record?.user } }}
            className={classes.userProfile}
          >
            <div className={classes.evenCol}>
              <div className={classes.col}>
                <img
                  src={profileImage !== null ? profileImage : profileIcon}
                  alt="userimage"
                />
              </div>
              <div className={classes.col}>
                <h2
                  style={{
                    color: "#000000",
                    fontSize: 16,
                    fontFamily: "poppins",
                    fontWeight: 400,
                    minWidth: "12vw",
                  }}
                >
                  {record?.user?.name}
                </h2>
                <p>{record?.user?.uid}</p>
              </div>
            </div>
          </NavLink>
        );
      },
    },
    {
      title: "Contact Details",
      dataIndex: "email",
      key: "email",
      render: (text, record) => {
        return (
          <>
            <p className={classes.colItem}>{record?.user?.email}</p>
            <p>{record?.user?.phone}</p>
          </>
        );
      },
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "dept",
      render: (text, record) => {
        return (
          <>
            <p className={classes.colItem}>{text}</p>
            <p>{record?.user?.department ? record?.user?.department : "NA"}</p>
          </>
        );
      },
    },
    {
      title: "Events Participated",
      dataIndex: "eventno",
      key: "eventno",
      render: (text, record) => {
        return (
          <p className={classes.colItem}>{record?.eventParticipateCount}</p>
        );
      },
      // sorter: (record1, record2) => {
      //   return record1.eventno > record2.eventno;
      // },
    },
    {
      title: "location",
      key: "location",
      dataIndex: "location",
      render: (text, record) => {
        return <p className={classes.colItem}>{record?.user?.location}</p>;
      },
    },
  ];

  const handleDownloadClick = () => {
    // Make a request to your server to get the filtered data
    Api.get(`/ibelong-houses/${props?.data?.id}/filteredData`)
      .params({
        queryYear: year,
        location: location,
      })
      .send((response) => {
        console.log("response for download", response);
        if (typeof response != "undefined") {
          // Trigger a file download for the filtered data
          const blob = new Blob([response], { type: 'application/octet-stream' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'filtered_data.csv'; // You can set the filename here
          a.click();
          window.URL.revokeObjectURL(url);
        }
      });
  };



  const [tableLoader, setTableLoader] = useState(true);
  useEffect(() => {
    dispatch(overViewActions.updateLocation("all"));
  }, []);
  useEffect(() => {
    if (!props?.data?.id) {
      return;
    }
    Api.get(`/ibelong-houses/${props?.data?.id}`)
      .params({ search: search, queryYear: year, location: location })
      .send((response) => {
        setTableLoader(false);
        let value = [];
        let list = [];
        let houseCount = [];
        if (response === undefined) console.log("err ");

        if (response !== undefined) {
          let isMounted = true;
          // console.log("response in Table:",response);
          list = response?.house?.map((element) => {
            return element;
          });
          dispatch(
            houseActions.updateCarddetails({
              cardDetails: response.housePosts,
              membersParticipated: response.membersParticipated,
              totalWinners: response.totalWinners,
            })
          );
          dispatch(
            houseActions.updateMembersParticipated(response.membersParticipated)
          );
          dispatch(houseActions.updateTotalWinners(response.totalWinners));
          dispatch(houseActions.updateHousePoint(response.housePoints));
          setData(list);
        }
      });
  }, [props?.data?.id, toggle, search, year, location]);
  return (
    <div
      className={classes.dashboard}
      style={{ backgroundColor: width < 900 ? "#ffffff" : "#f3f6fa" }}
    >
      {width > 900 && (
        <main className={classes.main}>
          <div className={classes.tableHeader}>
            <div style={{}}>
              <SearchCompo setSearch={setSearch} maxWidth="100%" />

              {/* <Input
                className='SearchCompo_inputSearch'
                style={{ width: '100%' }}
                id='searchUser'
                onChange={(e) => {
                  const currValue = e.target.value.trim();
                  setSearch(currValue);
                }}
                value={search}
                placeholder="Search"
              /> */}
            </div>
            <div>
              <Button
                size="large"
                style={{
                  background: "none",
                  fontFamily: "poppins",
                  fontWeight: "bold",
                  fontSize: "16px",
                  border: "0",
                }}
                onClick={(e) => {
                  Api.get(`/ibelong-houses/${props?.data?.id}`)
                    .params({
                      download: true,
                      queryYear: year,
                      location: location,
                      search: search,
                      //  filteredData: filteredData,
                    })
                    // console.log("filteredData", filteredData)
                    .send((response) => {
                      console.log("response for download", response);
                      if (typeof response != "undefined") {
                        window.open(response, "_blank")?.focus();
                      }
                    });
                }}
              >
                Download User Data
                <DownloadOutlined style={{ marginLeft: "0.5rem" }} />
              </Button>
            </div>
          </div>

          <Table
            loading={tableLoader}
            columns={columns}
            dataSource={data}
            rowClassName={classes.tableStyle}
            style={{ fontFamily: "poppins", fontWeight: "16px" }}
            pagination={{
              total: data?.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} members`,
            }}
          />
        </main>
      )}
      {width < 900 && <>
        <div style={{ backgroundColor: "#ffffff", padding: "1rem" }}>
          <div style={{ border: "2px solid rgb(245, 243, 243)" }}>
            <SearchCompo setSearch={setSearch} maxWidth="360px" />
          </div>
        </div>
        <TableDataMob data={data} />

      </>
      }
    </div>
  );
};
export default Tablecomp;
