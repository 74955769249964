import classes from "./captainComment.module.css";
import profileIcon from "../../../../images/profileIcon.jpg";
import uploadIcon from "../../../../images/uploadLogo.png";
import { Button, Upload, message, Modal, Radio, Form, Space, Input } from "antd";
import { useState, useEffect } from "react";
import Api from "services/Api";
import { useDispatch, useSelector } from "react-redux";
import { houseActions } from "../store";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
const CaptainComment = () => {
  const { width } = useWindowDimensions();
  const { toggle } = useSelector((state) => state.house);
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState("");
  const [comment, setComment] = useState("");
  const { userImage, user } = useSelector((state) => state.auth);
  const [postModal, setPostModal] = useState(false);
  const [audience, setAudience] = useState("")
  const [cmntData, setCmntData] = useState({});
  //const {post}=useSelector(state=>state.house);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const imgbeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 3072 / 3072 < 10;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    } else if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    } else {
      getBase64(file, (url) => {
        setImageUrl(url);
        setImage(file);
      });
    }
  };

  const postApi = () => {

  }

  const sendPostHandler = () => {
    setPostModal(false);
    let data = { image: cmntData.image, description: cmntData.comment, privacy: audience }
    Api.post("/ibelong-posts/housePost").upload(data, (percent, res) => {
      //090909090
      // dispatch(
      //   houseActions.updateCarddetails({ cardDetails:res })
      // );
      console.log("Result after Update:", res);
      setComment("");
      setImage("");
      setImageUrl("");
      dispatch(houseActions.updaterenderPost(!toggle));
      //  dispatch(houseActions.updatePost(!post))
    }
    );


  }


  const submitFormHandler = (event) => {
    setPostModal(true);

    event.preventDefault();
    const data = { eventId: "1", comment: comment, file: image };
    if (comment === "") {
      return;
    }

    console.log("cmnt", { image: image, comment: comment, privacy: audience })
    setCmntData({ image: image, comment: comment })
  };


  let role = " Hey Vice Captain,  what's on your mind?";
  if (user?.role === "Captain")
    role = "Hey Captain,  what's on your mind?";



  return (

    <>
      {width > 700 &&
        <div className={classes.comment}>
          <img src={userImage ? userImage : profileIcon} alt="" style={{ borderRadius: "50%", marginLeft: "1rem", width: "50px", height: "50px" }} />
          <div className={classes.commentDiv}>
            <input className={classes.inputSearch}
              type="text"
              placeholder={role}
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              required
            />
            {/* <textarea className={classes.inputSearch}
              type="text"
              placeholder={role}
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              required
              rows={3}
            /> */}
            <form className={classes.upload} onSubmit={submitFormHandler}>
              <Upload
                name="avatar"
                listType="picture"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={imgbeforeUpload}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt=""
                    style={{
                      width: "55px",
                      height: "55px",
                      borderRadius: "1px",
                      marginRight: "1rem",
                      cursor: 'pointer'
                    }}
                  />
                ) : (
                  <img
                    src={uploadIcon}
                    alt=""
                    style={{ width: "20px", height: "20px", display: "flex", marginTop: "0.4rem", marginRight: "0", cursor: 'pointer' }}

                  // style={{ width: "15px", height: "15px", border: "0", borderRadius: "1px", cursor: 'pointer' }}
                  />

                )}
              </Upload>
              <div className={classes.postButton}>
                <button disabled={comment === ""} type="submit" style={{ marginRight: "0.5rem", marginLeft: '0rem', cursor: 'pointer' }}>
                  Post
                </button>
                <Modal open={postModal} onCancel={() => { setPostModal(false) }} footer={false}>
                  <div style={{ font: 'normal normal bold 20px/25px poppins', margin: '10px 5px 16px 0px', }}>Choose members who can view the post</div>
                  {/* <Form onFinish={sendPostHandler}>
                    <Form.Item rules={[{ required: true, message: "Please choose audience", },]}>
                      <Radio.Group onChange={(e) => { console.log("aud", e.target.value); setAudience(e.target.value) }}> <Space direction="vertical" style={{ font: 'normal normal 400 16px/20px poppins', display: 'flex', gap: "16px" }}>
                        <Radio value="Public" >All members  </Radio>
                        <Radio value="Private">Event participants</Radio>
                      </Space></Radio.Group>
                    </Form.Item>
                    <Form.Item className={classes.buttonDiv}>
                      <Button onClick={() => { setPostModal(false); setComment(""); setImageUrl("") }}>Discard</Button>
                      <Button className={classes.sendButton} htmlType="submit"> Send</Button>
                    </Form.Item>
                  </Form> */}
                  <Form onFinish={sendPostHandler}>
                    <Form.Item
                      name="audience"
                      rules={[{ required: true, message: 'Please choose audience' }]}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          console.log('aud', e.target.value);
                          setAudience(e.target.value);
                        }}
                      >
                        <Space direction="vertical" style={{ font: 'normal normal 400 16px/20px poppins', display: 'flex', gap: '16px' }}>
                          <Radio value="Public">All members</Radio>
                          <Radio value="Private">Event participants</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item className={classes.buttonDiv}>
                      <Button onClick={() => { setPostModal(false); setComment(''); setImageUrl('') }}>Discard</Button>
                      <Button className={classes.sendButton} htmlType="submit" disabled={!audience}>
                        Send
                      </Button>
                    </Form.Item>
                  </Form>
                </Modal>
              </div>
            </form>
          </div>
        </div>}

      {
        width < 700 &&
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '15vh' }}>
          <div className={classes.comment}>
            <div style={{ width: '40px', display: 'flex', marginRight: '5px' }}>
              <img src={userImage ? userImage : profileIcon} alt="" style={{ borderRadius: "50%", height: "40px", width: '40px' }} />
            </div>
            <div className={classes.commentDiv}>
              <input className={classes.inputSearch}
                type="text"
                placeholder={role}
                value={comment}
                onChange={(event) => setComment(event.target.value)}
                required
              />
              <form style={{ marginLeft: window.innerWidth < 395 ? '53vw' : '', }} className={classes.upload} onSubmit={submitFormHandler}>
                <Upload
                  name="avatar"
                  listType="picture"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={imgbeforeUpload}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt=""
                      style={{
                        width: "40px",
                        height: "30px",
                        borderRadius: "1px",
                        marginRight: "1rem",
                        border: "0",
                      }}
                    />
                  ) : (
                    <div style={{ width: "20px", height: "20px", display: "flex", marginTop: "0.4rem", }}>
                      <img
                        src={uploadIcon}
                        alt=""
                        style={{ width: "15px", height: "15px", border: "0", borderRadius: "1px" }}
                      />
                    </div>
                  )}
                </Upload>
                <div className={classes.postButton}>
                  <button disabled={comment === ""} type="submit" style={{ marginRight: "0.5rem", marginLeft: '0rem' }}>
                    Post
                  </button>
                  <Modal open={postModal} onCancel={() => { setPostModal(false) }} footer={false}>
                    <div style={{ font: 'normal normal bold 18px/23px poppins', margin: '10px 5px 16px 0px', }}>Choose members who can view the post</div>
                    <Form onFinish={sendPostHandler}>
                      <Form.Item rules={[{ required: true, message: "Please choose audience", },]}>
                        <Radio.Group onChange={(e) => { console.log("aud", e.target.value); setAudience(e.target.value) }}> <Space direction="vertical" style={{ font: 'normal normal 400 16px/20px poppins', display: 'flex', gap: "16px" }}>
                          <Radio value="Public" >All members  </Radio>
                          <Radio value="Private">Event participants</Radio>
                        </Space></Radio.Group>
                      </Form.Item>
                      <Form.Item className={classes.buttonDiv}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '18vw', width: '40vw' }}>
                          <Button onClick={() => { setPostModal(false); setComment(""); setImageUrl("") }}>Discard</Button>
                          <Button className={classes.sendButton} htmlType="submit">Send</Button>
                        </div>
                      </Form.Item>

                    </Form>
                  </Modal>
                </div>
              </form>
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default CaptainComment;
