// import { Carousel } from "antd";
import React from "react";
import { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SliderImage.css'
import Slider from "react-slick";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
function SliderImage(props) {
  const { height, width } = useWindowDimensions();

  console.log("slideee")
  return (
    <div id="wrap" >
      <Slider dots={true} swipe={true} slidesToShow={1} slidesToScroll={1} autoplay={true} autoplaySpeed={3000} infinite={true} arrows={false} pauseOnHover={true} pauseOnFocus={true} variableWidth={true}>
        {props?.media?.map((val, id) => {
          let fileType = val;
          let fileTypes = ['jpeg', 'png', 'jpg', 'gif']
          const isImage = fileTypes.includes(fileType.toLowerCase().split(".").pop());
          if (isImage) {
            return (
              <div className="ImageContainer" key={id}>
                <img className="WhtsImg" src={val} onClick={() => { props?.onWhatshappenClick(props?.id) }} />
              </div>
            )
          } else {
            return (
              <div key={id}>
                <video height={147} width={200} src={val} controls></video>
              </div>
            )
          }
        })}
      </Slider>
    </div>

  )
}


export default SliderImage;