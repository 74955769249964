import React from "react";
import { Button, Modal ,Row,Col} from "antd";
import ReactPlayer from "react-player";
import PdfLogo from "../../../images/pdf-logo.svg"
const AssociateFiles = (props) =>{
return(
    <Modal open={props.candsMedia} onCancel={()=>props.setCandMedia(false)} footer={false} closable={true}>
        {props?.candidateUpload?.fileType==="application" ? <Row>{props?.candidateUpload?.record?.media.map((element) =>{
            return(
               
                <img
                  onClick={() => window.open(element)}
                  src={PdfLogo}
                  
                  // className="pdf-logo"
                  style={{height:"200px",width:"200px",marginLeft:"20px"}}
                />
             
             
            )
        })}</Row>:props?.candidateUpload?.fileType==="image"?<Row>{props?.candidateUpload?.record?.media.map((element) =>{
            return(
                <div >
                <img
                  onClick={() => window.open(element)}
                  src={element}
                  
                  // className="pdf-logo"
                  style={{height:"200px",width:"200px",marginLeft:"20px"}}
                />
             
              </div>
            )
        })}</Row>:props?.candidateUpload?.fileType==="video"?<Row>{props?.candidateUpload?.record?.media.map((element) =>{
            return(<Col span={12}>
                <Row style={{ marginTop: 10 }} >
                 <ReactPlayer
            
            height={"300px"}
            width={"200px"}
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            // Disable right click
            onContextMenu={e => e.preventDefault()}
            url={element}
            controls={true}
          // light={true}
          />
             
              </Row>
              < Row style={{marginTop:"20px"}}><Button onClick={() =>{
                window.open(element)
              }}>Download</Button></Row>
            </Col>)
        })}</Row>:"" }
    </Modal>
)
}
export default AssociateFiles