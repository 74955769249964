import ModalCompo from 'design/ModalCompo';
import React from 'react';
import classes from './users.module.css';
import ButtonCompo from 'design/ButonCompo';
import upArrow from '../../images/UpArrow.svg';
import downArrow from '../../images/DownArrow.svg';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Api from 'services/Api';
import windlogo from "../../images/vayuLogo.svg";
import earthlogo from "../../images/prithviLogo.svg";
import firelogo from "../../images/agniLogo.svg";
import spacelogo from "../../images/akashLogo.svg";
import waterlogo from "../../images/jalLogo.svg";
import { authActions } from './store';
import { Row, Spin } from 'antd';
export default function AssignModal({modal,setModal,refresh}) {
    const {houseMembers}=useSelector(state=>state.auth)
    const dispatch=useDispatch();
    const [selectedHouse,setSelectedHouse]=useState();
    const [suggestedHouse,setSuggestedHouse]=useState();
    const [loader,setLoader]=useState(false)
    useEffect(()=>{
        setLoader(true)
      Api.get('/ibelong-houses/locationWiseMemberData').send((res)=>{
       dispatch(authActions.updateHouseMembers(res))
       Api.get('/ibelong-user/predictHouse').send((data)=>{
        res?.forEach(element => {

           if(element?.houseId===data?.houseId) {
            setSuggestedHouse(element);
            setLoader(false)
           }

        });
        
    })
      }) 
    },[])
   const AssignHouseHandler=(house,user)=>{

    Api.post('/ibelong-user/assignHouseToUser').params({houseId:house,userId:user}).send((res)=>{
        console.log('assigned',res)
        if(res?.show?.type==='success'){
            setModal();
            refresh()
        }
    })
   }
    const [page,setPage]=useState(false);
const houseWisedata=(house)=>{
    let color,logo,hcolor,border;
    if (house === "AGNI") {
        color = "#ECCCB6";
        logo = firelogo;
        hcolor = "#CC5200";
        border = "3px solid #CC5200"
      } else if (house === "JAL") {
        color = "#D3E6F0";
        logo = waterlogo;
        hcolor = "#348FBD";
        border = "3px solid #348FBD"
      } else if (house === "VAYU") {
        color = "#F8F4E4";
        logo = windlogo;
        hcolor = "#F0BC00";
        border = "3px solid #F0BC00"
      } else if (house === "AKASH") {
        color = "#C8B8CF";
        logo = spacelogo;
        hcolor = "#5D3382";
        border = "3px solid #5D3382"
      } else if (house === "PRITHVI") {
        color = "#D5E0D9";
        logo = earthlogo;
        hcolor = "#447A57";
        border = "3px solid #447A57"
      }
      return {color,logo,hcolor,border}
}
let cdata=houseWisedata(suggestedHouse?.house) 
  return (
    <ModalCompo className={classes.AssignModal} open={modal!==undefined} onCancel={()=>{setModal()}} closableRight={true}>
     <div style={{width:'100%', display:'flex',flexDirection:'column',gap:'24px',maxHeight:'65vh',overflowY:'scroll'}}>
        <div style={{font:"normal normal bold 24px/35px Poppins",color:'#161616',marginTop:'-40px'}}>Assign a House</div>
        {loader?<div style={{display:'flex',justifyContent:'center', height: "30vh" }} >
          <Spin size="large" style={{ alignSelf: "center", }} />
        </div>:
        <div  style={{width:'100%',display:'flex',flexDirection:'column',gap:'24px'}}>
        <div  style={{border:'1px solid #cecece',display:'flex',flexDirection:'column',gap:12,padding:'16px'}}>
            <div onClick={()=>{setPage(!page);setSelectedHouse()}} style={{display:'flex',justifyContent:'space-between',cursor:'pointer'}}>
            <div style={{font:"normal normal bold 18px/27px Poppins",color:'#161616'}}>Suggested House</div>
            <img src={page?downArrow:upArrow}   style={{width:'20px',height:'20px',cursor:'pointer'}}/>
            </div>
        {page===false?<>
        <div style={{font:"italic normal normal 18px/29px Poppins"}}>The associate will be assigned to {suggestedHouse?.houseName}</div>
            <div style={{background:'#D5E0D9',padding:'10px',borderRadius:'5px'}}>
                <div style={{color:cdata?.hcolor,font:"normal normal 600 20px/30px Poppins"}}>{suggestedHouse?.house}</div>
                <div>
                    <span className={classes.lightHeader}>Team: </span>
                    <span className={classes.darkHeader}> {suggestedHouse?.memberStats?.totalMembers}</span>
                </div>
                <div>
                    <span className={classes.lightHeader}>Bangalore Team:</span>
                    <span className={classes.darkHeader}> {suggestedHouse?.memberStats?.bangaloreMembers}</span>
                </div>
                <div>
                    <span className={classes.lightHeader}>Coimbatore Team:</span>
                    <span className={classes.darkHeader}> {suggestedHouse?.memberStats?.coimbatoreMembers}</span>
                </div>
                <div></div>
                <div></div>
            </div>
            <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                <ButtonCompo title={`ASSIGN HOUSE ${suggestedHouse?.houseName}`} style={{backgroundColor:'#6FC78F', color:'#fff'}} onClick={()=>{AssignHouseHandler(suggestedHouse?.houseId,modal?.id)}} />
            </div>
        </>:''}  
        </div>

        <div style={{border:'1px solid #cecece',display:'flex',flexDirection:'column',gap:12,padding:'16px'}}>
                <div onClick={()=>{setPage(!page);setSelectedHouse()}} style={{display:'flex',justifyContent:'space-between',cursor:'pointer'}}>
                <div style={{font:"normal normal bold 18px/27px Poppins",color:'#161616'}}>Assign House Manually</div>
                <img src={page?upArrow:downArrow}  style={{width:'20px',height:'20px',}}/>
                </div>
            {page===true?<>  
            <div style={{font:"italic normal normal 18px/27px Poppins"}}>Select a house you want to assign the user to</div>
            <div>
            <div className={classes.ManualHouseConatiner}>
                {houseMembers?.map((item,id)=>{
                    let data=houseWisedata(item?.houseName) 
                    let d=item?.memberStats;
                    return(
                        <div key={id} className={(selectedHouse!==undefined && item?.houseId!==selectedHouse?.id)?classes.disableHouse:''} style={{background:data?.color,padding:'10px',borderRadius:'5px',cursor:'pointer'}} onClick={()=>{setSelectedHouse({id:item?.houseId,name:item?.houseName})}}>
                        <div style={{display:'flex',justifyContent:'space-between'}}> 
                                <div style={{color:data?.hcolor,font:"normal normal 600 20px/30px Poppins"}}>{item?.houseName}</div>
                                <img src={data?.logo} style={{width:'40px',height:'40px'}} />
                            </div>
                            
                            <div>
                                <span className={classes.lightHeader}>Team: </span>
                                <span className={classes.darkHeader}> {d?.totalMembers}</span>
                            </div>
                            <div>
                                <span className={classes.lightHeader}>Bangalore Team: </span>
                                <span className={classes.darkHeader}> {d?.bangaloreMembers}</span>
                            </div>
                            <div>
                                <span className={classes.lightHeader}>Coimbatore Team: </span>
                                <span className={classes.darkHeader}> {d?.coimbatoreMembers}</span>
                            </div>
                        </div> 
                    
                    )
                })}
                </div> 
            </div>
            </>:''}
            <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                    {selectedHouse!==undefined && 
                <div style={{display:'flex',gap:'10px',alignItems:'center'}}>
                        <div style={{font:"normal normal 600 18px/27px Poppins"}}>Note:<span style={{font:"italic normal 600 18px/27px Poppins"}}>The associate will be assigned to House {selectedHouse?.name}</span>.</div>
                    <ButtonCompo title={`ASSIGN HOUSE ${selectedHouse?.name}`} style={{backgroundColor:'#6FC78F', color:'#fff'}} onClick={()=>{AssignHouseHandler(selectedHouse?.id,modal?.id)}} />
                    </div>
                    }
                </div>
        </div>
        </div>}
    </div>
    </ModalCompo>
  )
}
