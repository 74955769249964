import React from 'react';
import '../modules/UnassignedMembers.css';
import UnassignedMembersimg from "../../../../images/10251364 1.svg"; 
import Ellipse from "../../../../images/Ellipse 2445.svg"; 
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
//import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';


const UnassignedMembers = ({userCountsByStatus,currentWeekUsers}) => {


  // Sample data
  const history = useHistory();
  const unassignedCount = userCountsByStatus?.Draft;
  const userCount = Object.entries(currentWeekUsers??{}).length;

  const handleNavigate = ()=>{
    history.push('/users',{userPage: 6});
  }


  return (
    <div className="UnassignedContainer">
      <div className='Unassigned-header'>
          <h2>Unassigned Member Tally</h2>
          <p>Total count members who have not yet been assigned to a house</p>
      </div>
      <div  className='UnassignedContent1'>
        <div style={{display: 'flex', flexDirection: 'column',}}>
            <div className="count" >
              <img src={Ellipse}/>
              {userCountsByStatus?.Draft}
            </div>
            <div style={{marginTop: '0px'}}>
              <p style={{margin: '0px' , fontFamily:"Roboto", fontSize: '14px'}}>Total Unassigned members</p>
              {userCount ? <p style={{fontFamily:"Roboto", fontSize: '14px'}}>+{userCount} this week</p> : ''}
            </div>
        </div>
        <img src={UnassignedMembersimg}/>
      </div>
      <hr style={{ borderTop: '1px dashed #E8E8E8'}}/>
      <div className='UnassignedBottom'>
        <div className='UnassignedBottomContent'>
          <h3>Start Assigning Members</h3>
          <p>Unassigned members are awaiting placement in a house. Hurry!</p>
        </div>
        <Button type="primary" style={{borderRadius: '80px', width:'124px', height: '36px' }} onClick={handleNavigate}>Go to Users</Button>
        {/* <NavLink
      to="/users"
      className={({ isActive }) => `navlink-button ${isActive ? 'active' : ''}`}
    >
      <li style={{
                      backgroundColor: "#E8F4FF",
                      paddingLeft: "16px",
                    }}>
      Go to Users
      </li>
    </NavLink> */}
      </div>
    </div>
  );
};

export default UnassignedMembers;
