
// import { Row, Col, Button, message, Modal, Radio, Upload } from "antd";
// import HouseModalDescription from './houseModalDescription';
// import HouseModalPost from "./housepost";
// import { useWindowDimensions } from "../../windowDimention/windowDimension";
// // import classes from './houseModal.module.css';
// import "antd/dist/antd.css";
// import Api from "services/Api";
// import profileIcon from "../../../images/profileIcon.jpg"
// import { useSelector } from "react-redux";
// import { overViewActions } from "../OverView/store";
// import { useDispatch } from "react-redux";
// import { houseActions } from "./store";
// import classes from "./housePost.module.css";
// import nominatedVideo from "../../../images/overviewModalVideoImage.jpg";
// import redHeartIcon from "../../../images/redhearticon.svg";
// import heartIcon from "../../../images/heart icon.svg";
// import uploadLogo from "../../../images/uploadIcon2.jpg";
// import { useEffect, useState } from "react";
// import { time } from "./timeUtility";
// import moment from "moment";
// var keynum, lines = 1;

// function limitLines(obj, e) {
//   if (window.event) {
//     keynum = e.keyCode;
//   } else if (e.which) {
//     keynum = e.which;
//   }

//   if (keynum === 13) {
//     if (lines === obj.rows) {
//       return false;
//     } else {
//       lines++;
//     }
//   }
// }
// const HouseModal = (props) => {
//   console.log("jjjpropone", props)
//   const { likes } = useSelector(state => state.overView)
//   const [image, setImage] = useState("");
//   const [btnLoader, setBtnLoader] = useState(false);
//   const [comment, setComment] = useState("");
//   const [imageUrl, setImageUrl] = useState("");
//   const [postUlInfo, setPostUrl] = useState([]);
//   const [likeCount, setLikeCount] = useState(null);
//   const [isLiked, setIsLiked] = useState(null);
//   const [postLikes, setPostLikes] = useState({});
//   const { userImage, user } = useSelector(state => state.auth);
//   const { toggle } = useSelector(state => state.house);
//   const { width } = useWindowDimensions();
//   const media = props?.media;
//   const resetForm = () => {
//     setComment('');
//     setImageUrl(null);
//     setImage(null)
//     console.log("image", imageUrl)
//   };

//   useEffect(() => {
//     setPostLikes({
//       id: props?.data?.id,
//       likes: props?.data?.postLikesCount,
//       liked: props?.data?.like,
//     })
//   }, [JSON.stringify(props)])
//   const dispatch = useDispatch()
//   console.log("jjjprops", props?.objVal)
//   useEffect(() => {
//     Api.get(`/houses/postData/${props?.data?.id}`).send(
//       (res) => {
//         console.log("jjjrespost", res)
//         setPostUrl(res?.postData?.comments);
//         setLikeCount(res?.postData?.postLikesCount);
//         setIsLiked(res?.likeStatus)
//       }
//     );
//   }, [props?.data, likes]);

//   const submitFormHandler = (event) => {
//     setBtnLoader(true);
//     event.preventDefault();
//     const postData = {
//       postId: props?.objVal?.id,
//       file: image,
//       comment: comment,
//     };
//     console.log("jjpropscomment", postData)
//     Api.post(`/houses/houseComment?postId=${postData?.postId}&comment=${postData?.comment}`).upload(postData, (response, percentCompleted) => {
//       console.log("jjcommentwo", postData)
//       setBtnLoader(false);
//       setImage("");
//       setImageUrl("");
//       setComment("");
//       Api.get(`/houses/postData/${props?.objVal?.id}`).send(
//         (res) => {
//           console.log("hhh", res)
//           setPostUrl(res?.postData?.comments);
//           setLikeCount(res?.postData?.postLikesCount);

//         }
//       );
//     }
//     );

//   };


//   // const submitFormHandler = (event) => {
//   //   setBtnLoader(true);
//   //   event.preventDefault();

//   //   const postData = {
//   //     postId: props?.objVal?.id,
//   //     file: image,
//   //     comment: comment,
//   //   };

//   //   Api.post("/houses/houseComment")
//   //     .send(postData) // Send the postData object in the request body
//   //     .upload(image) // Upload the image (if needed)
//   //     .progress((percentCompleted) => {
//   //       console.log(`Upload progress: ${percentCompleted}%`);
//   //     })
//   //     .then((response) => {
//   //       setBtnLoader(false);
//   //       setImage("");
//   //       setImageUrl("");
//   //       setComment("");
//   //       const updatedData = response.data;

//   //     })
//   //     .catch((error) => {
//   //       console.error("Error:", error);
//   //     });
//   // };

//   setTimeout(() => { console.log("post data", postUlInfo) })


//   const updateLikesHandler = () => {

//     setPostLikes((postLikes) => {
//       if (postLikes.liked) {
//         return {
//           ...postLikes,
//           likes: postLikes.likes - 1,
//           liked: !postLikes.liked,
//         };
//       } else {
//         return {
//           ...postLikes,
//           likes: postLikes.likes + 1,
//           liked: !postLikes.liked,
//         };
//       }
//     });

//   };

//   const getBase64 = (img, callback) => {
//     const reader = new FileReader();
//     reader.addEventListener("load", () => callback(reader.result));
//     reader.readAsDataURL(img);
//   };

//   const imgbeforeUpload = (file) => {
//     const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//     const isLt2M = file.size / 3072 / 3072 < 10;
//     if (!isJpgOrPng) {
//       message.error("You can only upload JPG/PNG file!");
//     }
//     else if (!isLt2M) {
//       message.error("Image must smaller than 5MB!");
//     }
//     else {
//       getBase64(file, (url) => {
//         setImageUrl(url);
//         setImage(file);
//       });
//     }
//   };
//   return (
//     <>
//       {width > 700 && <Modal centered width="960px" open={props.open} onOk={() => props.setOpen(false)} onCancel={() => { props.setOpen(false); setImage(""); setImageUrl(""); setComment("") }} closable={true} header={null} footer={null} >
//         <div className={classes.houseModal}>
//           <div>
//             <HouseModalDescription data={props} media={media} />
//           </div>
//           <div >
//             <>
//               <div className={`${classes.container} ${classes.scroll}`} >
//                 {postUlInfo?.map((info, key) => {
//                   return (
//                     <div key={key} >
//                       {info?.commentMedia === null ? (
//                         <div className={classes.profile1}>
//                           <div className={classes.profile} >
//                             <div>
//                               <img src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} alt="profileImage" />
//                             </div>
//                             <div className={classes.employee} style={{ minWidth: '18vw' }}>
//                               <h2>{info?.user?.name}</h2>
//                               <p> {moment(info?.createdAt).format('lll')}</p>
//                             </div>
//                           </div>
//                           <span>{info?.comment}</span>
//                         </div>
//                       ) : (
//                         <div className={classes.imagePart} >
//                           <img src={info?.commentMedia} alt={nominatedVideo} />
//                           <div className={classes.profile2} >
//                             <div className={classes.imageProf}>
//                               <div>
//                                 <img src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} alt="profileImage" />
//                               </div>
//                               <div className={classes.employee} >
//                                 <h2>{info?.user?.name}</h2>
//                                 <p>{moment(info?.createdAt).format('lll')}</p>
//                               </div>
//                             </div>
//                             <span>{info?.comment}</span>
//                           </div>
//                         </div>
//                       )}
//                     </div>
//                   );
//                 })}
//               </div>

//               <div className={classes.likeDiv}>
//                 <img
//                   src={postLikes.liked ? redHeartIcon : heartIcon}
//                   alt="redHeartIcon"
//                   onClick={() => {
//                     updateLikesHandler()
//                     dispatch(overViewActions.likesList(!likes))
//                     return new Promise((resolve, reject) => {
//                       console.log("this is posteventID", postLikes);
//                       Api.post("/posts/like").params({ postId: postLikes?.id })
//                         .send((response, error) => {
//                           dispatch(houseActions.updaterenderPost(!toggle));
//                           resolve();
//                           if (response) { }
//                         });
//                     });

//                   }
//                   }
//                 />
//                 <div style={{ display: 'flex', marginBottom: '20px' }}>
//                   <div style={{ marginRigh: '10px' }}>Liked by</div>{postLikes.liked && <div style={{ marginLeft: '5px' }}>you and </div>} <div style={{ marginLeft: '5px' }}>{isLiked ? (likeCount - 1) : (likeCount)} others</div>
//                 </div>
//               </div>
//               {user.role === "SuperAdmin" ? "" : <div >
//                 <form className={classes.formControl}  >
//                   <textarea
//                     style={{ maxWidth: "70%", marginRight: "1rem", color: "#000000" }}
//                     value={comment}
//                     placeholder="Add comment "
//                     onChange={(event) => {
//                       setComment(event.target.value);
//                     }}
//                     onkeydown={(event) => { limitLines(this, event) }}
//                   />
//                   <div
//                     style={{ hieght: "30px", width: "50px" }}
//                     className={classes.scroll}
//                   >
//                     <Upload
//                       name="avatar"
//                       className="avatar-uploader"
//                       showUploadList={false}
//                       beforeUpload={imgbeforeUpload}
//                     >
//                       {imageUrl ? (
//                         <img src={imageUrl}
//                           alt=""
//                           style={{ width: "50px", height: "50px", cursor: 'pointer', marginRight: '4px' }}
//                         />
//                       ) : (
//                         <img
//                           style={{ marginTop: "5px", borderRadius: "50%", cursor: 'pointer', marginRight: '4px' }}
//                           src={uploadLogo}
//                           alt=""
//                         />
//                       )
//                       }
//                     </Upload>
//                   </div>
//                   <Button
//                     disabled={comment === ""}
//                     type="text"
//                     loading={btnLoader}
//                     onClick={submitFormHandler}
//                   >
//                     Post
//                   </Button>
//                   {/* <Button style={{ marginLeft: '5px', fontSize: '14px', border: 'none', color: '#FF6969', }} onClick={resetForm} >Cancel</Button> */}
//                 </form>
//               </div>}
//             </>
//           </div>
//         </div>
//       </Modal>}

//       {width < 700 && <Modal centered width="960px" open={props.open} onOk={() => props.setOpen(false)} onCancel={() => { props.setOpen(false); setImage(""); setImageUrl(""); setComment("") }} closable={true} header={null} footer={null} >
//         <div className={classes.houseModal}>
//           <div style={{ width: '100%', display: 'flex' }}>
//             <HouseModalDescription data={props} media={media} />
//           </div>
//           <div >
//             <>
//               <div className={`${classes.container} ${classes.scroll}`} >
//                 {postUlInfo?.map((info, key) => {
//                   return (
//                     <div key={key} >
//                       {info?.commentMedia === null ? (
//                         <div className={classes.profile1}>
//                           <div className={classes.profile} >
//                             <div>
//                               <img src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} alt="profileImage" />
//                             </div>
//                             <div className={classes.employee} style={{ minWidth: '18vw' }}>
//                               <h2>{info?.user?.name}</h2>
//                               <p> {moment(info?.createdAt).format('lll')}</p>
//                             </div>
//                           </div>
//                           <span>{info?.comment}</span>
//                         </div>
//                       ) : (
//                         <div className={classes.imagePart} >
//                           <img src={info?.commentMedia} alt={nominatedVideo} />
//                           <div className={classes.profile2} >
//                             <div className={classes.imageProf}>
//                               <div>
//                                 <img src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} alt="profileImage" />
//                               </div>
//                               <div className={classes.employee} >
//                                 <h2>{info?.user?.name}</h2>
//                                 <p>{moment(info?.createdAt).format('lll')}</p>
//                               </div>
//                             </div>
//                             <span>{info?.comment}</span>
//                           </div>
//                         </div>
//                       )}
//                     </div>
//                   );
//                 })}
//               </div>

//               <div className={classes.likeDiv}>
//                 <img
//                   src={postLikes.liked ? redHeartIcon : heartIcon}
//                   alt="redHeartIcon"
//                   onClick={() => {
//                     updateLikesHandler()
//                     dispatch(overViewActions.likesList(!likes))


//                     return new Promise((resolve, reject) => {
//                       console.log("this is posteventID", postLikes);
//                       Api.post("/posts/like").params({ postId: postLikes?.id })
//                         .send((response, error) => {
//                           dispatch(houseActions.updaterenderPost(!toggle));
//                           resolve();
//                           if (response) { }
//                         });
//                     });

//                   }
//                   }
//                 />
//                 <div style={{ display: 'flex', marginBottom: '20px' }}>
//                   <div style={{ marginRigh: '10px' }}>Liked by</div>{postLikes.liked && <div style={{ marginLeft: '5px' }}>you and </div>} <div style={{ marginLeft: '5px' }}>{isLiked ? (likeCount - 1) : (likeCount)} others</div>
//                 </div>
//               </div>
//               {user.role === "SuperAdmin" ? "" : <div>
//                 <form className={classes.formControl} >
//                   <textarea
//                     style={{ maxWidth: "70%", marginRight: "1rem", color: "#000000" }}
//                     value={comment}
//                     placeholder="Add comment "
//                     onChange={(event) => {
//                       setComment(event.target.value);
//                     }}
//                     onkeydown={(event) => { limitLines(this, event) }}
//                   />
//                   <div
//                     style={{ hieght: "30px", width: "50px" }}
//                     className={classes.scroll}
//                   >
//                     <Upload
//                       name="avatar"
//                       className="avatar-uploader"
//                       showUploadList={false}
//                       beforeUpload={imgbeforeUpload}
//                     >
//                       {imageUrl ? (
//                         <img src={imageUrl}
//                           alt=""
//                           style={{ width: "50px", height: "50px", cursor: 'pointer' }}
//                         />
//                       ) : (
//                         <img
//                           style={{ marginTop: "5px", borderRadius: "50%", cursor: 'pointer' }}
//                           src={uploadLogo}
//                           alt=""
//                         />
//                       )
//                       }
//                     </Upload>
//                   </div>
//                   <Button
//                     disabled={comment === ""}
//                     type="text"
//                     loading={btnLoader}
//                     onClick={submitFormHandler}
//                   >
//                     Post
//                   </Button>
//                   {/* <Button style={{ fontSize: '14px', border: 'none', color: '#FF6969', }} onClick={resetForm} >Cancel</Button> */}
//                 </form>
//               </div>}
//             </>
//           </div>
//         </div>
//       </Modal>}
//     </>


//   );
// };

// export default HouseModal;


import { Row, Col, Modal } from "antd";
import HouseModalDescription from './houseModalDescription';
import HouseModalPost from "./housepost";
import { useWindowDimensions } from "../../windowDimention/windowDimension";
import classes from './houseModal.module.css'
import { useState } from "react";

const HouseModal = (props) => {
  const { width } = useWindowDimensions();
  const [imageUrl, setImageUrl] = useState("");
  const [comment, setComment] = useState("");
  const media = props?.media;
  return (
    <Modal bodyStyle={{ height: '80vh', overflowY: 'scroll' }} centered width="960px" open={props.open} onOk={() => props.setOpen(false)}
      onCancel={() => { props.setOpen(false); setImageUrl(""); setComment("") }} closable={true} header={null} footer={null} >
      <div className={classes.houseModal}>
        <div>
          <HouseModalDescription data={props} media={media} />
        </div>
        <div>
          <HouseModalPost data={props.objVal} setImageUrl={setImageUrl} imageUrl={imageUrl} comment={comment} setComment={setComment} />
        </div>
      </div>
    </Modal>
  );
};

export default HouseModal;