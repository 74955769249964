import React , {useEffect, useState} from "react";
import TabsMenu from './components/TabsMenu';
import "./analytics.css";
import UnassignedMembers from "./components/UnassignedMembers";
import TeamMemberAllocation from "./components/TeamMemberAllocation";
import ParticipationInsights from "./components/ParticipationInsights";
import EventOverview from "./components/EventOverview";
import LocationAnalysis from "./components/LocationAnalysis";
import DashboardComponent from "./components/DashboardComponent"
import Location from "./components/Location";
import GenderDistribution from "./components/GenderDistribution";
import Api from "services/Api";
import { useSelector } from "react-redux";
 


function Analytics(){
  const { user } = useSelector((state) => state.auth);

    const [activeTab, setActiveTab] = useState(user?.role==='SuperAdmin'?'all':(user?.role==='Captain'||user?.role==='ViceCaptain')?user?.house.toLowerCase():'');

  
    const [renderedComponent, setRenderedComponent] = useState(null);
    const [data,setData] = useState()

    useEffect(()=>{
      Api.get(`/ibelong-dashboard/admin`).send((response,error)=>{
         if(response){
          setData(response)
          
         }
         else{
          console.log("NO RESPONSE",error)
         }
      })
    },[window.location.pathname]);

    console.log(window.location.pathname, "Q");

    //const {userCountsByStatus, currentWeekUsers} = data?? {};

    const handleTabChange = (key) =>{
        setActiveTab(key);
        console.log(key)
    }
    const jalColors = [
      '#B8E2FF',
      '#75C8FF', 
      '#30ADFF', 
      '#008AE6', 
      '#005C99', 
    ];
    const prithviColors = [
      '#C4F3D3',
      '#88E7A8', 
      '#4ADA7B', 
      '#25B655', 
      '#197B3A', 
    ];
    const agniColors =[
      '#FFD7BD',
      '#FFAF7A', 
      '#FF8A3B', 
      '#EB5E00', 
      '#9E3F00',
    ];
    const vayuColors =[
      '#FAF4C2',
      '#F5EA84', 
      '#F0DF44', 
      '#D4C111', 
      '#8E810B',
    ];
    const akashColors =[
      '#D9BBF6',
      '#B377EE', 
      '#8C33E5', 
      '#6918B9', 
      '#45107A',
    ];
    const colors = ['#4ADA7B',  '#FF8A3B', '#F0DF44','#30ADFF', '#8C33E5'];

    useEffect(()=>{
      
        if (['prithvi', 'jal', 'akash', 'agni', 'vayu'].includes(activeTab)) {
            switch(activeTab){
              case 'prithvi':
                if(user?.role==='SuperAdmin' || (((user?.role==='Captain')  || (user?.role === 'ViceCaptain'))&& user?.house==='PRITHVI'))
                  setRenderedComponent(<DashboardComponent colors={prithviColors} data_id={2}/>);
                  break;
              case 'jal':
                if(user?.role==='SuperAdmin' || (((user?.role==='Captain')  || (user?.role === 'ViceCaptain'))&& user?.house==='JAL'))
                  setRenderedComponent(<DashboardComponent colors={jalColors} data_id={4}/>);
                  break;
              case 'akash':
                if(user?.role==='SuperAdmin' || (((user?.role==='Captain')  || (user?.role === 'ViceCaptain'))&& user?.house==='AKASH'))
                  setRenderedComponent(<DashboardComponent colors={akashColors} data_id={6}/>);
                  break;
              case 'agni':
                if(user?.role==='SuperAdmin' || (((user?.role==='Captain')  || (user?.role === 'ViceCaptain'))&& user?.house==='AGNI'))
                  setRenderedComponent(<DashboardComponent colors={agniColors} data_id={5}/>);
                  break;
              case 'vayu':
                if(user?.role==='SuperAdmin' || (((user?.role==='Captain')  || (user?.role === 'ViceCaptain'))&& user?.house==='VAYU'))
                  setRenderedComponent(<DashboardComponent colors={vayuColors} data_id={1}/>);
                  break;
              // default:
              //   setRenderedComponent(<DashboardComponent colors={jalColors}/>);
            }
          } else {
            if(user?.role==='SuperAdmin'){
            setRenderedComponent(
              <>
                <div className="dashboard-contents">
                  <UnassignedMembers 
                  userCountsByStatus={data?.userCountsByStatus} 
                  currentWeekUsers={data?.currentWeekUsers}
                  />
                  <TeamMemberAllocation 
                  allHouses={data?.allHouses}
                  userCountByHouse={data?.userCountByHouse}
                  userCountsByStatus={data?.userCountsByStatus}
                  />
                </div>
                <div className="dashboard-contents">
                  <GenderDistribution 
                  allHouses={data?.allHouses} 
                  colors={colors}
                  userCountByHouse={data?.userCountByHouse}
                  />
                  <Location
                  allHouses={data?.allHouses} 
                  colors={colors}
                  userCountByHouse={data?.userCountByHouse}
                  />
                </div>
                <div className="dashboard-contents">
                  <EventOverview 
                  totalEvents={data?.eventOverView?.totalEvents}
                  completedEvents={data?.eventOverView?.completedEvents}
                  upcomingEvents={data?.eventOverView?.upcomingEvents}
                  eventsHappeningThisWeek={data?.eventOverView?.eventsHappeningThisWeek}
                  eventsHeppenedThisWeek={data?.eventOverView?.eventsHeppenedThisWeek}
                  eventOverView={data?.eventOverView}
                  />
                  <ParticipationInsights 
                  allHouses={data?.allHouses} 
                  overAllParticipationPercentage={data?.overAllParticipationPercentage} 
                  colors={colors}
                  userCountByHouse={data?.userCountByHouse}
                  />
                </div>
              </>
            );
          }
          }
        
    },[activeTab,data]);
    return (
    <div className='Analytics-container'>
                <div className='dashboard-header'>
                    <h1>Analytics</h1>
                    <p>Dive into your data for actionable insights </p>
                </div>
                <div className="main-content">
                <TabsMenu onTabChange={handleTabChange} tabName={activeTab} />
                </div>  
                {renderedComponent}   
    </div>);
};
export default Analytics;