import React from 'react';
//import './EventOverview.css';
import EventCard from './EventCard';
import { Button, Progress } from 'antd';
import '../modules/EventOverview.css'
import Ellipse from '../../../../images/Ellipse 2445.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const EventOverview = ({totalEvents, completedEvents, upcomingEvents, eventsHappeningThisWeek, eventsHeppenedThisWeek, eventOverView}) => {
  // Sample data
  const moment = require('moment');
  const eventCompletion = Math.round(((Number(completedEvents)/Number(totalEvents)) * 100));
  const history = useHistory();

  const handleNavigate = ()=>{
    history.push('/events')
  }
  let date1 = new Date(eventOverView?.recentEvents[0]?.actualDate);
  let dayOfWeek1 = date1.getDay();
  let date2 = new Date(eventOverView?.recentEvents[1]?.actualDate);
  let dayOfWeek2 = date2.getDay();

  let days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
  let dayName1 = days[dayOfWeek1];
  let dayName2 = days[dayOfWeek2];




  return (
    <div className="EventOverviewContainer">
      <div className='EventOverviewHeader'>
        <h2>Event Overview</h2>
        <p>A broad look at completed and upcoming events</p>
      </div>
    <div style={{ width: '512px', height: '322px',marginTop:'24px'}}>
          
          <div style={{display:"flex", justifyContent: 'space-between', height:'25px'}}>
            <p style={{marginBottom: '0px', fontFamily: 'Roboto'}}>Event Completion</p>
            <p style={{marginBottom: '0px', fontFamily: 'Roboto'}}>{eventCompletion}%</p>
          </div>
          <Progress
          percent={eventCompletion}
          showInfo={false}
          strokeColor='#008AE6'
          trailColor="#e0e0e0"
          className="custom-progress"
          />
          <hr style={{marginBottom: '12px', borderTop: '1px dashed #E8E8E8', marginTop: '12px'}}/>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${eventCompletion}%` }}></div>
        </div>
        <div className="events-summary" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div className="upcoming-events">
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
              <img src={Ellipse} />
              <h3 style={{fontSize: '28px', marginBottom: '0px', height: '45px', fontFamily: 'Roboto'}}>{upcomingEvents}</h3>
            </div>
            <p style={{marginBottom: '0px', fontFamily:'Roboto'}}>Upcoming events</p>
            <p style={{fontFamily: 'Roboto', color:'#616161'}}>{eventsHappeningThisWeek} events happening this week</p>
          </div>
          
          <div className="completed-events">
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
              <img src={Ellipse} />
              <h3 style={{fontSize: '28px', marginBottom: '0px', height: '45px', fontFamily: 'Roboto'}}>{completedEvents}</h3>
            </div>
            <p style={{marginBottom: '0px', fontFamily: 'Roboto'}}>Completed events</p>
            <p style={{fontFamily: 'Roboto', color:'#616161'}}>{eventsHeppenedThisWeek} events happened this week</p>
          </div>
        </div>
        <hr style={{marginBottom: '24px', borderTop: '1px dashed #E8E8E8'}}/>
        <div style={{ display: 'flex', gap:'8px', flexDirection: 'column' , alignItems: 'center'}}>
          
        <EventCard
          date={eventOverView?.recentEvents[0]?.actualDate.slice(-2)}
          day={dayName1?.slice(0,3)}
          eventName={eventOverView?.recentEvents[0]?.title}
          time={moment(eventOverView?.recentEvents[0]?.startDate).format('DD/MM/YY')+' to '+moment(eventOverView?.recentEvents[0]?.endDate).format('DD/MM/YY')}
          publisher={eventOverView?.recentEvents[0]?.eventType}
        />
        <EventCard
         date={eventOverView?.recentEvents[1]?.actualDate.slice(-2)}
         day={dayName2?.slice(0,3)}
         eventName={eventOverView?.recentEvents[1]?.title}
         time={moment(eventOverView?.recentEvents[1]?.startDate).format('DD/MM/YY')+' to '+ moment(eventOverView?.recentEvents[1]?.endDate).format('DD/MM/YY')}
         publisher={eventOverView?.recentEvents[1]?.eventType}
        />
        <Button type="primary" style={{borderRadius: '100px', width:'130px', height: '36px' }} onClick={handleNavigate}>View All Events</Button>
        </div>
    </div>
    </div>
  );
};

export default EventOverview;
