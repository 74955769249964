import classes from "./Main.module.css";
import Sidebar from "../SideBar/SideBar";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Users from "../Pages/users";
import EventDetails from "component/Pages/events/eventdetails";
import Events from "../Pages/events/Events";
import UserProfile from "../Pages/userProfile";
import Akash from "component/Pages/houses/akash";
import Agni from "component/Pages/houses/agni";
import Vayu from "component/Pages/houses/vayu";
import Jal from "component/Pages/houses/jal";
import Overview from "component/Pages/OverView/Overview";
import Prithvi from "component/Pages/houses/prithvi";
import { Layout } from "antd";
import MyProfile from "component/Pages/myProfile/myProfile";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Chat from "component/Pages/chats/Chat";
import NotFound from "component/SideBar/NotFound";
import Analytics from "component/Pages/Analytics/Analytics";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}

const Main = () => {
  const { height, width } = useWindowDimensions();
  const { user } = useSelector((state) => state.auth);
  { console.log("rrr", window.location.href.split('/')[4]) }
  let location = window.location.href.split('/')[3]
  let locationfour = window.location.href.split('/')[4]

  return (
    <Router>
      <Switch>
        {/* {(location === '' || location.includes('?hash') || location === 'users' || location === 'event-details' || location === 'events' || location === 'myProfile' || location === 'userProfile' || location === 'akash' || location === 'agni' || location === 'vayu' || location === 'jal' || location === 'pithvi' || location === 'chat') &&
          (locationfour === undefined)
          ? */}
        <div className={classes.main} style={{ flexWrap: width > 1000 ? "nowrap" : "wrap" }} >

          {width < 700 && (
            <>
              <div className={classes.SidebardivMob}>
                <Sidebar />
              </div>
              <div>
                {user?.role === "SuperAdmin" && (<Route exact path="/users"> <Users /> </Route>)}

                <Route exact path="/event-details">
                  <EventDetails />
                </Route>
                <Route exact path="/">
                  <Overview></Overview>
                </Route>
                <Route exact path="/analytics">
                  <Analytics></Analytics>
                </Route>
                <Route exact path="/events">
                  <Events />
                </Route>
                <Route exact path="/myProfile">
                  <MyProfile />
                </Route>

                <Route exact path="/userProfile">
                  <UserProfile />
                </Route>
                {(user?.role === "SuperAdmin" ||
                  user?.privilege == "Admin" ||
                  user?.house === "AKASH") && (
                    <Route exact path="/akash">
                      <Akash />
                    </Route>
                  )}
                {(user?.role === "SuperAdmin" ||
                  user?.privilege == "Admin" ||
                  user?.house === "AGNI") && (
                    <Route exact path="/agni">
                      <Agni />
                    </Route>
                  )}
                {(user?.role === "SuperAdmin" ||
                  user?.privilege == "Admin" ||
                  user?.house === "VAYU") && (
                    <Route exact path="/vayu/">
                      <Vayu />
                    </Route>
                  )}
                {(user?.role === "SuperAdmin" ||
                  user?.privilege == "Admin" ||
                  user?.house === "PRITHVI") && (
                    <Route exact path="/prithvi">
                      <Prithvi />
                    </Route>
                  )}
                {(user?.role === "SuperAdmin" ||
                  user?.privilege == "Admin" ||
                  user?.house === "JAL") && (
                    <Route exact path="/jal">
                      <Jal />
                    </Route>
                  )}

                <Route exact path="/chat">
                  <Chat></Chat>{" "}
                </Route>
              </div>
            </>)}

          {width > 700 && (
            <>
              {(location === '' || location.includes('?hash') || location === 'users' 
              || location === 'event-details' || location === 'events' || location === 'myProfile' 
              || location === 'userProfile' || location === 'akash' || location === 'agni' 
              || location === 'vayu' || location === 'jal' || location === 'prithvi'
               || location === 'chat' ||location === 'analytics') &&
                (locationfour === undefined)
                ?
                <>
                  <div className={classes.Sidebardiv}>
                    <Sidebar />
                  </div>
                  <div style={{ height: "88vh", overflowY: "auto", }} >
                    {user?.role === "SuperAdmin" && (
                      <Route exact path="/users"> <Users /> </Route>)}
                    <Route path="/event-details">
                      <EventDetails />
                    </Route>
                    <Route exact path="/">
                      <Overview></Overview>
                    </Route>
                    <Route exact path="/analytics">
                      <Analytics></Analytics>
                    </Route>
                    <Route path="/events">
                      <Events />
                    </Route>
                    <Route exact path="/myProfile">
                      <MyProfile />
                    </Route>

                    <Route exact path="/userProfile">
                      <UserProfile />
                    </Route>
                    {(user?.role === "SuperAdmin" ||
                      user?.privilege == "Admin" ||
                      user?.house === "AKASH") && (
                        <Route exact path="/akash">
                          <Akash />
                        </Route>
                      )}
                    {(user?.role === "SuperAdmin" ||
                      user?.privilege == "Admin" ||
                      user?.house === "AGNI") && (
                        <Route exact path="/agni">
                          <Agni />
                        </Route>
                      )}
                    {(user?.role === "SuperAdmin" ||
                      user?.privilege == "Admin" ||
                      user?.house === "VAYU") && (
                        <Route exact path="/vayu/">
                          <Vayu />
                        </Route>
                      )}
                    {(user?.role === "SuperAdmin" ||
                      user?.privilege == "Admin" ||
                      user?.house === "PRITHVI") && (
                        <Route exact path="/prithvi">
                          <Prithvi />
                        </Route>
                      )}
                    {(user?.role === "SuperAdmin" ||
                      user?.privilege == "Admin" ||
                      user?.house === "JAL") && (
                        <Route exact path="/jal">
                          <Jal />
                        </Route>
                      )}

                    <Route exact path="/chat">
                      <Chat></Chat>
                    </Route>

                    {/* <Route path="/*">
                <NotFound />
              </Route> */}
                  </div>
                </> :
                <Route path='*'>
                  <NotFound />
                </Route>}
            </>
          )}
        </div>
        :



      </Switch>
    </Router>
  );
};
export default Main;
