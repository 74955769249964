import React from 'react';
//import './ParticipationInsights.css';
import ProgressBar from './ProgressBar';
import { Flex } from 'antd';
import {Progress} from 'antd';
import HouseName from './HouseNames';
import '../modules/ParticipationInsights.css'
import Ellipse from "../../../../images/Ellipse 2445.svg"


const ParticipationInsights = ({allHouses, overAllParticipationPercentage,colors, userCountByHouse}) => {
  // Sample data


  return (
    <div className="ParticipationInsightsContainer">
      <div className='ParticipationHeader'>
        <h2>Participation Insights</h2>
        <p>Participation of house members in activities, events, or programs</p>
      </div>
      <div className="overall-participation">
        <div style={{display: 'flex', flexDirection: 'row', gap: '4px' , alignItems: 'center'}}>
        <img src={Ellipse} style={{width: '14px', height: '14px'}}/>
        <h3>{Number(overAllParticipationPercentage).toFixed(0)}%</h3>
        </div>
        <p style={{fontFamily: 'Roboto'}}>Overall participation rate</p>
      </div>
    
      {allHouses?.map((item, index) => (
          <div style={{display: 'flex', flexDirection: 'row', width: '512px', height: '24px', marginTop: '28px', alignItems: 'center'}}>
            <HouseName key={index} label={item.name} color={colors[index]}/>
            <ProgressBar
              numerator={(Number(userCountByHouse?.[item.name]?.count)*Number(userCountByHouse?.[item.name]?.particpationPercentage)/100).toFixed(0)}
              denominator={Number(userCountByHouse?.[item.name]?.count)} 
              percentage={Number(userCountByHouse?.[item.name]?.particpationPercentage).toFixed(0)}
              color={colors[index]}/>
          </div>
      ))}
      
      {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '512px', height: '24px'}}>
      <HouseName label="Prithvi" color="#4ADA7B"/>
      <ProgressBar numerator="564" denominator="938" percentage="60" color="#4ADA7B"/>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', width: '512px', height: '24px', marginTop: '32px', alignItems: 'center'}}>
      <HouseName label="Agni" color="#FF8A3B"/>
      <ProgressBar numerator="886" denominator="1032" percentage="82" color="#FF8A3B"/>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', width: '512px', height: '24px', marginTop: '32px'}}>
      <HouseName label="Vayu" color="#F0DF44"/>
      <ProgressBar numerator="743" denominator="845" percentage="100" color="#F0DF44"/>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', width: '512px', height: '24px', marginTop: '32px'}}>
      <HouseName label="Jal" color="#30ADFF"/>
      <ProgressBar numerator="604" denominator="892" percentage="71" color="#30ADFF"/>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', width: '512px', height: '24px', marginTop: '32px'}}>
      <HouseName label="Akash" color="#8C33E5"/>
      <ProgressBar numerator="886" denominator="986" percentage="90" color="#8C33E5"/>
      </div> */}
    </div>
  );
};

export default ParticipationInsights;
