import React, { useState, useEffect } from "react";
import { Modal, Upload, message, Button, Progress, Row, Form } from "antd";
import Api from "services/Api";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
// import {SyncOutlined} from '@ant-design/icons';
import { houseActions } from "../houses/store";
import Notification from "services/Notification";
import PlayVideo from "./playVideo";
import uploadIcon from "../../../images/upload icon.svg";
import deleteIcon from "../../../images/delete icon.svg";
import "./uploadVideoFiles.css";

const Option = Select;

const blockSize = 1048576 * 20;
let currentBlockNumber = null;
let fileId = null;
let totalBlocksCount = null;
let blockStart = null;
let blockEnd = null;
const uuidv4 = (value, id) => {
  return value + Math.random();
};
export const uploadCompleted2 = (object, callback) => (dispatch, getState) => {
  // const params = {
  //   fileName: fileId,
  // }
  console.log("object", object);
  return Api.post(`/ibelong-posts/UploadComplete`, object).send((response) => {
    // console.log("up2 res", response);
    if (response != undefined) {
      callback(response);
    }
  });
};

const VideoModal = (props) => {
  console.log("junoVideoModal", props)
  const { user } = useSelector((state) => state.auth);
  const [fileKey, setFileKey] = useState(true);
  const [progressdone, setprogressDone] = useState("");
  const [done, setDone] = useState(false);
  const [playVideomedia, setPlay] = useState("");
  const [checked, setChecked] = useState(false);
  const [privacy, setPrivacy] = useState("");
  const { videoModal, groupDetails } = useSelector((state) => state.house);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("resss", videoModal);
    if (videoModal === true) {
      props.setVideoModal(true);
    }
  }, [videoModal]);

  const uploadBlock2 = (params, callback) => {
    // const blockUploadUrl = `/admin-library/UploadChunk?fileName=${params.fileName}&orderId=${params.orderId}`;
    const blockUploadUrl = `/ibelong-posts/uploadChunk`;
    // let file=params.file;
    // let data = {
    //   fileName: params.fileName,
    //   orderId:

    // }
    // return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      Api.post(blockUploadUrl).upload(params, (percent, response) => {
        if (percent === -1) {
          // callback()
          // console.log("percent -1");
        }
        if (response !== undefined) {
          // console.log("percent", percent);
          if (percent === 100) {
            callback(response);
            resolve();
          }
        }
      });
    });

    // Api.post(blockUploadUrl).upload(params, (percent, response) => {
    //     setProgress(percent)
    //     setprogressDone(percent)
    //   if (percent === -1) {
    //     // callback()
    //     // console.log("percent -1");
    //   }
    //   if (response !== undefined) {
    //     // console.log("percent", percent);
    //     if (percent === 100) {
    //       console.log("percebt",percent)
    //       callback(response);

    //     }
    //   }
    // });

    // };
  };
  const MergeChunk = (fileId, callback, succesCallback) => {
    let params = {};
    if (videoModal === true) {
      params = {
        fileName: fileId,
        eventId: props?.details?.id,
        status: privacy,
        group: groupDetails?.id,
      };
    } else {
      params = {
        fileName: fileId,
        eventId: props?.details?.id,
        status: privacy,
      };
    }
    // const { fileKey } = getState().digitalLibrary;
    console.log("object", params);
    Api.post(`/ibelong-posts/MergeChunks`)
      .params(params)
      .send((response) => {
        // console.log("up2 res", response);
        // if (callback) {
        //   callback()
        // }

        // if(videoModal && groupDetails){
        //   console.log("refreshed is called",videoModal,groupDetails)
        //   props.refreshed()
        // }
        // else{
        //   console.log("refresh is called",videoModal,groupDetails)
        //   props.refresh()

        // }
        setDone(false);
        dispatch(houseActions?.openVideoModal(false));

        setVideoDetails("");
        //console.log("vvvvvvvvv clearing",videoDetails)

        setprogressDone(0);
        window.location.reload();
        props.setVideoModal(false);
        if (response != undefined) {
          // dispatch(uploadContent({ ...response, type: object.type }))
          // callback(response)
          if (response?.fileKey) {
            uploadCompleted2();

            // if (succesCallback) {
            //   succesCallback()
            // }
          } else {
            if (callback) {
              callback();
              // }
            }
          }
        }
      });
  };
  const [form] = Form.useForm();

  const [progress, setProgress] = useState("");
  const [videoDetails, setVideoDetails] = useState("");
  const uploadBlock = async (currentBlock, callback, successCallback) => {
    console.log("block0");
    try {
      // console.log("block1");
      var params = {
        file: currentBlock,
        orderId: currentBlockNumber,
        fileName: fileId,
      };
      console.log("Paramns", params);
      await uploadBlock2(params, async (response) => {
        if (response != undefined) {
          // console.log("response 52", response)
          // console.log("resData", response);
          if (response?.data?.progress?.message === "uploaded") {
            blockStart = blockEnd;
            blockEnd += blockSize;

            console.log("block uploaded", currentBlockNumber, totalBlocksCount);
            if (currentBlockNumber === totalBlocksCount) {
              console.log("mergeChunk");
              var percent = Math.round(
                (currentBlockNumber / totalBlocksCount) * 100
              );
              setprogressDone(percent);
              MergeChunk(fileId, callback, successCallback);
            } else {
              var percent = Math.round(
                (currentBlockNumber / totalBlocksCount) * 100
              );
              setprogressDone(percent);
            }
          }
        }
      });
      // const response = await axios.post(
      //     blockUploadUrl,formData, {headers: { 'Content-Type': 'multipart/form-data' }}
      // );
    } catch (error) {
      console.log("error in block upload", error);
    }
  };
  const uploadFunction = async (callback, successCallback) => {
    if (videoDetails.type.slice(0, 5) !== "video") {
      Notification.error("Error","Please Upload Video");
      return;
    } else if (videoDetails.length === 0) {
      Notification.error("Error","Please Upload video");
      return;
    }
    // console.log("filesize", selectedFile.size / 1024 / 1024);
    totalBlocksCount =
      videoDetails?.size % blockSize === 0
        ? videoDetails?.size / blockSize
        : Math.floor(videoDetails?.size / blockSize) + 1;
    // var completeFile = new Blob([selectedFile]);
    fileId =
      uuidv4(user?.name, props?.details?.id) +
      "." +
      videoDetails.name.split(".").pop();
    blockStart = 0;
    blockEnd = blockSize;
    // console.log("completefile", completeFile);
    for (
      currentBlockNumber = 1;
      currentBlockNumber <= totalBlocksCount;
      currentBlockNumber += 1
    ) {
      var block = videoDetails.slice(blockStart, blockEnd);
      // console.log("block ", currentBlockNumber, "   ", block);
      await uploadBlock(block, callback, successCallback);
    }
  };
  function onChange(e) {
    let value = e.fileList[0]?.originFileObj;
    setVideoDetails(value);
  }

  return (
    <Modal
      id="modal"
      open={props?.videoModal}
      onCancel={() => {
        props?.setVideoModal(false);
        dispatch(houseActions.openVideoModal(false));
        props?.setAddMedia();
        form.resetFields();
        setVideoDetails("");

        if (props?.Tab === "Groups") {
          props?.refreshed();
        } else {
          props?.refresh();
        }
      }}
      footer={false}
      destroyOnClose={true}
    >
      <div
        style={{
          fontFamily: "poppins",
          fontSize: "20px",
          fontWeight: "bold",
          padding: "0 0 20px 0",
        }}
      >
        Upload files
      </div>
      <Form id="form" form={form}>
        {console.log("juuuuuserId", user?.id, "props", props, 'USER', user)}
        {(props?.details?.eventType === "Individual" || props?.Tab === "Individual")
          ? (user?.id === props?.uploaderId || user?.role === 'SuperAdmin') && (
            <Form.Item name="files">
              <Upload.Dragger
                onChange={onChange}
                onRemove={() => setVideoDetails("")}
                maxCount={1}
                action={{
                  status: "done",
                }}
              >
                <img src={uploadIcon} />
                <p style={{ fontFamily: "poppins" }}>
                  Drag to this area or Click to Upload
                </p>
              </Upload.Dragger>
            </Form.Item>
          )
          : props?.Tab === "Groups" ?
            // (user?.id === props?.uploaderId || user?.privilege === "EventCoordinators" || props?.groupLeaderUpload === true) && (
            (user?.id === props?.uploaderId ||
              user?.privilege === "EventCoordinators" ||
              user?.role === 'SuperAdmin' ||
              (props?.groupLeaderUpload === true && props?.event?.createdBy?.id == user?.id) ||
              (props?.groupRole === 'Group Leader' && props?.event?.createdBy?.id == user?.id)) && (
              // (user?.id === props?.uploaderId || user?.privilege === "EventCoordinators" || (props?.groupLeaderUpload === true || props?.groupRole === 'Group Leader')) && (

              <Form.Item name="files">
                <Upload.Dragger
                  onChange={onChange}
                  onRemove={() => setVideoDetails("")}
                  maxCount={1}
                  action={{
                    status: "done",
                  }}
                >
                  <img src={uploadIcon} />
                  <p style={{ fontFamily: "poppins" }}>
                    Drag to this area or Click to Upload
                  </p>
                </Upload.Dragger>
              </Form.Item>
            ) : null}
        <Form.Item>
          <div style={{ display: "flex", flexWrap: "wrap", width: "109%" }}>
            {props?.media?.map((val, id) => {
              return (
                <div
                  className="uploadedImageContainer"
                  key={id}
                  id={"image" + id}
                  style={{
                    width: "200px",
                    height: "180px",
                    margin: "0 40px 20px 10px",
                  }}
                >
                  <div className="image">
                    <div
                      onClick={() => {
                        setPlay(
                          val?.postLink !== undefined
                            ? val?.postLink
                            : val?.postMedia[0]?.[0]
                        );
                      }}
                    >
                      <video
                        src={
                          val?.postLink !== undefined
                            ? val?.postLink
                            : val?.postMedia[0]?.[0]
                        }
                        style={{ width: "200px", height: "180px" }}
                        onClick={() => {
                          setChecked(!checked);
                        }}
                      ></video>
                    </div>
                    {(props?.details?.eventType === "Individual" || props?.Tab === "Individual")
                      ? (user?.id === props?.uploaderId || user?.role === 'SuperAdmin') && (
                        <div
                          className="icon"
                          onClick={() => {
                            {
                              Api.delete("/ibelong-events/deletePost").params({ postId: val?.postId !== undefined ? val?.postId : val?.id, })
                                .send((res) => {
                                  if (res?.show?.type === 'error') {
                                    console.log("ddderror", res?.show?.type)
                                  } else {
                                    console.log("ddd", res)
                                    let deleteImage = document.getElementById(`image${id}`);
                                    deleteImage.style.display = "none";
                                    val?.postLink !== undefined ? props?.refresh() : props?.refreshed();
                                  }
                                  console.log("ddd222", res)
                                });
                            }
                          }}
                        >
                          <img className="icon-img" src={deleteIcon}></img>
                          <p>Remove file</p>
                        </div>
                      )
                      : props?.Tab === "Groups" ?
                        //  (user?.id === props?.uploaderId || user?.privilege === "EventCoordinators" || props?.groupLeaderUpload === true) && (
                        (user?.id === props?.uploaderId ||
                          user?.privilege === "EventCoordinators" ||
                          user?.role === 'SuperAdmin' ||
                          (props?.groupLeaderUpload === true && props?.event?.createdBy?.id == user?.id) ||
                          (props?.groupRole === 'Group Leader' && props?.event?.createdBy?.id == user?.id)) && (
                          // (user?.id === props?.uploaderId || user?.privilege === "EventCoordinators" || (props?.groupLeaderUpload === true || props?.groupRole === 'Group Leader')) && (

                          <div
                            className="icon"
                            onClick={() => {
                              {
                                Api.delete("/ibelong-events/deletePost").params({ postId: val?.postId !== undefined ? val?.postId : val?.id, })
                                  .send((res) => {
                                    if (res?.show?.type === 'error') {
                                      console.log("error", res?.show?.type)
                                    } else {
                                      console.log("ddd11")
                                      let deleteImage = document.getElementById(`image${id}`);
                                      deleteImage.style.display = "none";
                                      val?.postLink !== undefined ? props?.refresh() : props?.refreshed();
                                    }

                                  });
                              }
                            }}
                          >
                            <img className="icon-img" src={deleteIcon}></img>
                            <p>Remove file</p>
                          </div>
                        ) : null}
                    {""}
                  </div>
                </div>
              );
            })}
          </div>
        </Form.Item>
        {(props?.details?.eventType === "Individual" || props?.Tab === "Individual")
          ? (user?.id === props?.uploaderId || user?.role === 'SuperAdmin') && (
            <Form.Item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  setDone(true);
                  uploadFunction();
                }}
                htmlType="submit"
                disabled={!videoDetails}
              >
                Submit
              </Button>
            </Form.Item>
          )
          : props?.Tab === "Groups" ?
            //  (user?.id === props?.uploaderId || user?.privilege === "EventCoordinators" || props?.groupLeaderUpload === true) && (
            (user?.id === props?.uploaderId ||
              user?.privilege === "EventCoordinators" ||
              user?.role === 'SuperAdmin' ||
              (props?.groupLeaderUpload === true && props?.event?.createdBy?.id == user?.id) ||
              (props?.groupRole === 'Group Leader' && props?.event?.createdBy?.id == user?.id)) && (
              // (user?.id === props?.uploaderId || user?.privilege === "EventCoordinators" || (props?.groupLeaderUpload === true || props?.groupRole === 'Group Leader')) && (
              <Form.Item style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    setDone(true);
                    uploadFunction();
                  }}
                  htmlType="submit"
                  disabled={!videoDetails}
                >
                  Submit
                </Button>
              </Form.Item>
            ) : null}
      </Form>

      <PlayVideo details={playVideomedia} checked={checked} setPlay={setPlay} />
    </Modal>
  );
};
export default VideoModal;
