import React from 'react';
import { Tabs } from 'antd';
import '../modules/TabsMenu.css';
import houses from "../../../../images/houses.png";
import akashHouse from "../../../../images/akashLogo.svg";
import prithviHouse from "../../../../images/prithviLogo.svg";
import jalHouse from "../../../../images/jalLogo.svg";
import vayuHouse from "../../../../images/vayuLogo.svg";
import agniHouse from "../../../../images/agniLogo.svg"; 
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;

const TabsMenu = ({ onTabChange, tabName }) => {
  const { user } = useSelector((state) => state.auth);

  console.log(tabName,"TABMENU");

  if(user?.role==='SuperAdmin'){

  return (
    
    <Tabs defaultActiveKey="all" onChange={onTabChange} className="tabs-menu">
      <TabPane
        tab={
          <span className="tab-span" style={tabName === 'all' ? {}: { filter: 'grayScale(1)', color:'#919191' } }>
            <img src={houses} className='tabImage' />
            All
          </span>
        }
        key="all"
      />
      <TabPane
        tab={
          <span className="tab-span" style={tabName === 'prithvi' ? {color:"#4ADA7B"}: { filter: 'grayScale(1)' } }>
            <img src={prithviHouse} className='tabImage' />
            Prithvi
          </span>
        }
        key="prithvi"
      />
      <TabPane
        tab={
          <span className="tab-span" style={tabName === 'agni' ? {color:"#FF8A3B"}: { filter: 'grayScale(1)' } }>
            <img src={agniHouse} className='tabImage'/>
            Agni
          </span>
        }
        key="agni"
      />
      <TabPane
        tab={
          <span className="tab-span" style={tabName === 'vayu' ? {color: "#F0DF44"}: { filter: 'grayScale(1)' } }>
            <img src={vayuHouse} className='tabImage'/>
            Vayu
          </span>
        }
        key="vayu"
      />
      <TabPane
        tab={
          <span className="tab-span" style={tabName === 'jal' ? {color: "#30ADFF"}: { filter: 'grayScale(1)' } }>
            <img src={jalHouse} className='tabImage'/>
            Jal
          </span>
        }
        key="jal"
      />
      <TabPane
        tab={
          <span className="tab-span" style={tabName === 'akash' ? {color: "#8C33E5"}: { filter: 'grayScale(1)' } }>
            <img src={akashHouse} className='tabImage'/>
            Akash
          </span>
        }
        key="akash"
      />
    </Tabs>
     
  );
}
else if((user?.role==='Captain' || user?.role==='ViceCaptain') && user?.house === 'PRITHVI'){
  return (
    
    <Tabs defaultActiveKey="all" onChange={onTabChange} className="tabs-menu">
      <TabPane
        tab={
          <span className="tab-span">
            <img src={prithviHouse} className='tabImage'/>
            Prithvi
          </span>
        }
        key="prithvi"
      />
  
   
    </Tabs>
     
  );
}
else if((user?.role==='Captain' || user?.role==='ViceCaptain') && user?.house === 'AGNI'){
  return (
    
    <Tabs defaultActiveKey="all" onChange={onTabChange} className="tabs-menu">
     
      <TabPane
        tab={
          <span className="tab-span">
            <img src={agniHouse} className='tabImage'/>
            Agni
          </span>
        }
        key="agni"
      />
     
   
    </Tabs>
     
  );
}
else if((user?.role==='Captain' || user?.role==='ViceCaptain') && user?.house === 'VAYU'){
  return (
    
    <Tabs defaultActiveKey="all" onChange={onTabChange} className="tabs-menu">
     
      <TabPane
        tab={
          <span className="tab-span">
            <img src={vayuHouse} className='tabImage'/>
            Vayu
          </span>
        }
        key="vayu"
      />
     
   
    </Tabs>
     
  );
}
else if((user?.role==='Captain' || user?.role==='ViceCaptain') && user?.house === 'JAL'){
  return (
    
    <Tabs defaultActiveKey="all" onChange={onTabChange} className="tabs-menu">
     
      <TabPane
        tab={
          <span className="tab-span">
            <img src={jalHouse} className='tabImage'/>
            Jal
          </span>
        }
        key="jal"
      />
    </Tabs>
     
  );
}
else if((user?.role==='Captain' || user?.role==='ViceCaptain') && user?.house === 'AKASH'){
  return (
    
    <Tabs defaultActiveKey="all" onChange={onTabChange} className="tabs-menu">
     
      <TabPane
        tab={
          <span className="tab-span">
            <img src={akashHouse} className='tabImage'/>
            Akash
          </span>
        }
        key="akash"
      />
     
   
    </Tabs>
     
  );
}
};

export default TabsMenu;
