import classes from "./houseModalDescription.module.css";
import { Typography } from "antd";
import globeLogo from '../../../images/globe.svg';
import profileIcon from "../../../images/profileIcon.jpg";
const months = [" ", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

const ModalDescription = (props) => {
  const { Paragraph } = Typography;
  const createrImage = props.data.objVal.createdBy.profileImage;
  const date = props?.data?.objVal?.date.slice(8, 10) + " th  " + months[parseInt(props.data?.objVal.date.slice(5, 7))];
  const media = props?.media;
  return (
    <div className={classes.container} style={{ height: '100%', width: window.innerWidth < 700 ? '87vw' : '', justifyContent: 'space-between' }}>
      {media &&
        <div className={classes.modalDesImg}>
          <img src={media} alt="modaldesImage"></img>
        </div>
      }
      <div>
        <Paragraph
          className={classes.descriptionInfo}
          ellipsis={{ rows: 10, tooltip: true }}
        >
          {props?.data?.objVal?.description}

        </Paragraph>
      </div>


      <div className={classes.footer}>
        <img
          src={createrImage !== null ? createrImage : profileIcon}
          alt="createrProfile"
          style={{ width: "40px", height: '40px', borderRadius: "50%" }}
        />
        <div>
          <h2>Posted by {props?.data?.objVal?.createdBy?.name}</h2>
          <div className={classes.logo}>
            {date}
            <img src={globeLogo} alt="globeLogo" />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ModalDescription;