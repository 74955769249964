import ModalCompo from 'design/ModalCompo'
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Api from 'services/Api'
import { authActions } from './store'
import windlogo from "../../images/vayuLogo.svg";
import earthlogo from "../../images/prithviLogo.svg";
import firelogo from "../../images/agniLogo.svg";
import spacelogo from "../../images/akashLogo.svg";
import waterlogo from "../../images/jalLogo.svg";
import classes from './users.module.css';
import { useState } from 'react'
import { Spin } from 'antd'
export default function ViewMemberModal({modal,setModal}) {
    const {houseMembers}=useSelector(state=>state.auth)
    const dispatch=useDispatch();
    const [loader,setLoader]=useState(false)
    useEffect(()=>{
      setLoader(true)
      Api.get('/ibelong-houses/locationWiseMemberData').send((res)=>{
       dispatch(authActions.updateHouseMembers(res))
       setLoader(false)
      }) 
    },[])
  return ( 
    <ModalCompo className={classes.ViewMemberModal} open={modal} onCancel={()=>{setModal()}} closableRight={true}>
      <div style={{display:'flex',flexDirection:'column',gap:'16px',maxHeight:'65vh',overflowY:'scroll'}}> 
        <div style={{font:"normal normal bold 24px/36px Poppins",color:'#161616',marginTop:'-40px'}}>House Members Count</div>
        {(loader && houseMembers?.length===0)?<div style={{display:'flex',justifyContent:'center', height: "30vh",width:'50vw' }} >
          <Spin size="large" style={{ alignSelf: "center", }} />
        </div>:<div className={classes.HouseConatiner}>
            {houseMembers?.map((item,id)=>{
                let color,logo,hcolor,border
                if (item?.houseName === "AGNI") {
                        color = "#ECCCB6";
                        logo = firelogo;
                        hcolor = "#CC5200";
                        border = "3px solid #CC5200"
                      } else if (item?.houseName === "JAL") {
                        color = "#D3E6F0";
                        logo = waterlogo;
                        hcolor = "#348FBD";
                        border = "3px solid #348FBD"
                      } else if (item?.houseName === "VAYU") {
                        color = "#F8F4E4";
                        logo = windlogo;
                        hcolor = "#F0BC00";
                        border = "3px solid #F0BC00"
                      } else if (item?.houseName === "AKASH") {
                        color = "#C8B8CF";
                        logo = spacelogo;
                        hcolor = "#5D3382";
                        border = "3px solid #5D3382"
                      } else if (item?.houseName === "PRITHVI") {
                        color = "#D5E0D9";
                        logo = earthlogo;
                        hcolor = "#447A57";
                        border = "3px solid #447A57"
                      }
                let d=item?.memberStats;
                return(
                    <div key={id} style={{background:color,padding:'10px',borderRadius:'5px'}}>
                       <div style={{display:'flex',justifyContent:'space-between'}}> 
                            <div style={{color:hcolor,font:"normal normal 600 20px/30px Poppins"}}>{item?.houseName}</div>
                            <img src={logo} style={{width:'40px',height:'40px'}} />
                        </div>
                        
                        <div>
                            <span className={classes.lightHeader}>Team: </span>
                            <span className={classes.darkHeader}> {d?.totalMembers}</span>
                        </div>
                        <div>
                            <span className={classes.lightHeader}>Bangalore Team: </span>
                            <span className={classes.darkHeader}> {d?.bangaloreMembers}</span>
                        </div>
                        <div>
                            <span className={classes.lightHeader}>Coimbatore Team: </span>
                            <span className={classes.darkHeader}> {d?.coimbatoreMembers}</span>
                        </div>
                      </div> 
                  
                )
            })}
        </div> }
    </div>   
    </ModalCompo>
  )
}
{/* <Col className="houses-grid" span={4} style={{ backgroundColor: color, border: val.name === winnerHouse && border, }}>
                            {console.log("jjhouse", val?.name)}
                            <img className="houses-logo"
                              src={logo}
                            ></img>
                            <div
                              className="houses-info"
                              style={{
                                paddingRight: val?.name === winnerHouse ? "0" : "15px",

                              }}
                            >
                              <h1 className="info-heading"
                                style={{
                                  color: hcolor,

                                }}
                              >
                                {val?.name}
                              </h1>
                              <div className="captain-info">
                                <div>
                                  <div className="captain-name">
                                    Captain:{" "}
                                    <Typography.Text className="captain-name"
                                      ellipsis={{ tooltip: true }}>
                                      {val?.captain}
                                    </Typography.Text>
                                  </div>
                                  <div className="team-size">
                                    Team: <b>{val?.team}</b>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  marginTop: "45px",
                                  width: "100%",
                                  marginLeft: "-10px",
                                  textAlign: "center",
                                }}
                              >
                              </div>
                            </div>
                           
                          </Col> */}