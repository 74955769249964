import SliderImage from "component/SliderImage";
import classes from './modalDescription.module.css';
import globeLogo from '../../../images/globe.svg';
import { Typography } from "antd";
import profileIcon from "../../../images/profileIcon.jpg"
import { useState, useEffect } from 'react';
import { time } from '../houses/timeUtility';
import moment from 'moment';
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
const ModalDescription = (props) => {
  const { height, width } = useWindowDimensions();
  const createrImage = props?.data?.createrImage;
  const { Paragraph } = Typography;
  const [ellipsis, setEllipsis] = useState(true);
  const getDate = (str) => {
    const date = new Date(str);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return (date.getUTCDate() + " " + months[date.getUTCMonth()] + " " + date.getFullYear() + " at " + `${parseInt(date?.getUTCHours()).toString().padStart(2, "0")}` + ":" + `${parseInt(date?.getUTCMinutes()).toString().padStart(2, "0")}`)
  }
  const date = getDate(props?.data?.createdAt);
  return (
    <div className={classes.container} style={{padding:"0.5rem",border:"0.1px solid #e3e3e3" ,overflowY:"scroll",height:"100%",backgroundColor:"#f3f6fa"}}>
      {width > 700 && <>{props?.data?.media[0] && <img src={props?.data?.media[0]} width='366px' height='240px'></img>}</>}
      {width < 700 && <>{props?.data?.media[0] && <img src={props?.data?.media[0]} style={{ width: '97%' }} height='240px'></img>}</>}
      {width > 700 &&
        <>
          <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "365px", fontFamily: "poppins", fontWeight: "600", fontSize: "20px", marginTop: "0.5rem", minHeight: "4.5vh" }}>{props?.data?.title}
          </Typography.Text>

          {/* <Paragraph className="scroll" style={{ border: '2px solid red', lineHeight: "1.5rem", height: "auto", width: "100%", overflowY: 'scroll', textAlign: "justify", fontSize: '15px', paddingRight: '10px', }}
            ellipsis={ellipsis ? { rows: 6, expandable: true, symbol: 'more', } : false}>{props?.data?.description}
          </Paragraph> */}
          <div style={{height:'fit-content',}}>
          <Paragraph className="scroll" style={{ lineHeight: "1.2rem", height: "100%", width: "100%", overflowY: 'scroll', textAlign: "justify", fontSize: '15px', marginTop: '5px' }}
            ellipsis={ellipsis ? { rows: 5, expandable: true, symbol: 'more', } : false}>
            {props?.data?.description}
          </Paragraph>
          </div>
        </>
      }


      {width < 700 &&
        <div style={{ marginLeft: '5px', width: '100%', display: 'flex', justifyContent: 'flex-start', height: 'fit-content', marginTop: "0.5rem", flexDirection: 'column', height: '100%' }}>
          <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "100%", fontFamily: "poppins", fontWeight: "600", fontSize: "20px", }}>{props?.data?.title}
          </Typography.Text>
          <Paragraph className="scroll" style={{ lineHeight: "1.5rem", height: "auto", width: "100%", overflowY: 'scroll', textAlign: "justify", fontSize: '15px', paddingRight: '10px', }}
            ellipsis={ellipsis ? { rows: 6, expandable: true, symbol: 'more', } : false}>{props?.data?.description}
          </Paragraph>
        </div>
      }

      <div className={classes.footer} style={{ }}>
        <img src={createrImage !== null ? createrImage : profileIcon} alt="ProfileIcon" style={{ width: "40px", height: '40px', borderRadius: "50%",}} />
        <div>
          <h2>Posted by {props.data.createdBy}</h2>
          <div className={classes.logo}>
            <span>   {moment(props?.data?.createdAt).format('lll')}</span>
            {/* <img src={globeLogo} alt="globeLogo" style={{ width: "20px", height: '20px', }} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalDescription;

