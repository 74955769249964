import classes from './events.module.css';
import { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Upload, Form, Input, DatePicker, Row, Select, Space, } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import Api from 'services/Api';
import moment from 'moment';
import EventCaptain from './eventCaptian';
import { authActions } from '../store';
import pdfLogo from "../../../images/pdf-logo.svg"
import blackGrid from "../../../images/blackGrid.svg"
import greenGrid from "../../../images/greenGrid.svg";
import blackList from "../../../images/blackList.svg";
import greenList from "../../../images/greenList.svg";
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Notification from 'services/Notification';
import SearchCompo from 'component/searchCompo';
import YearDropdown from 'component/YearDropdown';
import LocationDropdown from 'component/LocationDropdown';
import { useWindowDimensions } from "../../windowDimention/windowDimension";
const { Option } = Select;
const Events = () => {
  const { user } = useSelector(state => state.auth);
  const [form] = Form.useForm();
  const { addevent, events } = useSelector(state => state.auth)
  const [eventValChanged, setEventValChanged] = useState(false)
  const [search, setSearch] = useState("")
  const { sendmodal } = useSelector(state => state.auth)
  const [modalOpen, setModalOpen] = useState(sendmodal);
  const dispatch = useDispatch()
  const [nominationdates, setNominationDate] = useState("")
  const [startDate, setStartdate] = useState("")
  const [endDate, setEnddate] = useState("")
  const [NominationStartDate, setNominationEnddate] = useState("")
  const [buttonLoader, setBtnLoader] = useState(false);
  const { year } = useSelector(state => state.auth);
  const [winner1st, setWinner1st] = useState("0");
  const [winner2nd, setWinner2nd] = useState("0");
  const [winner3rd, setWinner3rd] = useState("0");
  // const [totalPoint, setTotalPoint]=useState()
  const [imageUrl, setImageUrl] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [listType, setListType] = useState('list');
  const locationArr = ["Both", "Bangalore", "Coimbatore"];
  const [eventIdRes, setEventIdRes] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");

  const { width } = useWindowDimensions();
  const uploadButton = (
    <div style={{ color: "#6FC78F", cursor: 'pointer', }}>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      Click to Upload
    </div>
  );

  const imgbeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return isJpgOrPng || Upload.LIST_IGNORE
    }
    else if (!isLt10M) {
      message.error("Image must smaller than 10MB!");
      return isJpgOrPng || Upload.LIST_IGNORE
    }
    else if (imageUrl.length >= 5) {
      message.error("Number of files uploaded should not be greater than 5!");
    }
    else {
      getBase64(file, (url) => {
        setLoading(false);
        setImageUrl(prev => [...prev, url]);
        setImgFile(file)
      });
    }
  }
  const changeListTpe = () => {
    setListType("grid");

  }
  const changeGridTpe = () => {
    setListType('list')
  }

  const pdfbeforeUpload = (file) => {
    const isPdf = file.type === "application/pdf";
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isPdf) {
      message.error("You can only upload PDF files")
      return isPdf || Upload.LIST_IGNORE
    }
    else if (!isLt10M) {
      message.error("File must smaller than 10MB!");
      return isLt10M || Upload.LIST_IGNORE
    }
    else {
      getBase64(file, (url) => {
        setLoading(false);
        setPdfUrl(url);
        console.log("jjpdf", pdfUrl)
        setUploadedFileName(file?.name);
      });
    }
  }
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const addEventHandler = (values) => {
    if (values?.files?.fileList?.length === 0) {
      Notification.error("Error","please upload Event Image")
      return;
    }
    if (values?.files?.fileList?.length > 5) {
      Notification.error("Error","please upload only 5 image")
      return;
    }
    if (values?.guidelinesFile?.fileList?.length === 0) {
      message.error("Error","please upload guideline file")
      return;
    }
    if (parseInt(values?.secondWinnerPoints) >= parseInt(values?.firstWinnerPoints)) {
      Notification.error("Error","Points for 1st winner should be greater than 2nd winner");
      return;
    }
    if (parseInt(values?.thirdWinnerPoints) >= parseInt(values?.secondWinnerPoints)) {
      Notification.error("Error","Points for 2nd winner should be greater than 3rd winner");
      return;
    }
    if (Date.parse(NominationStartDate) > Date.parse(nominationdates)) {
      Notification.error("Error","Event Nomination Start Date should be earlier than End Date")
      return;
    }
    if (Date.parse(startDate) > Date.parse(endDate)) {
      Notification.error("Error","Event Start Date should be earlier than End Date")
      return;
    }
    if (Date.parse(NominationStartDate) > Date.parse(startDate)) {
      Notification.error("Error","Nomination Start Date should be earlier than Event Start Date")
      return;
    }
    if (Date.parse(nominationdates) > Date.parse(endDate)) {
      Notification.error("Error","Nomination End Date should be earlier than Event End Date")
      return;
    }
    if (Date.parse(nominationdates) > Date.parse(startDate)) {
      Notification.error("Error","Nomination End Date should be earlier than Event Start Date")
      return;
    }



    let files = []
    //  console.log('llllll',values,"jj", values.Imgfile.fileList)
    values?.files?.fileList.forEach((val, index) => {
      files?.push(val?.originFileObj)
    })
    //  console.log('kkkkkkk', files)


    // console.log('event added',values)
    setEventValChanged(false)
    const finalVal = {
      ...values, 'actualDate': values.actualDate.format('YYYY/MM/DD'),
      'nominationDate': values.nominationDate.format('YYYY/MM/DD'),
      'startDate': values.startDate.format('YYYY/MM/DD'),

      'endDate': values.endDate.format('YYYY/MM/DD'),
      'guidelinesFile': values.guidelinesFile.fileList[0].originFileObj,
      files: files
    };

    Api.post("/ibelong-events/create").upload(finalVal, (percentCompleted, response) => {

      setModalOpen(false);
      if (percentCompleted === 100 && response !== undefined) {
        setEventValChanged(true);
        setEventIdRes(response?.eventId)
        form.resetFields()
        setImgFile("")
        setImageUrl("")
        setPdfUrl("")
        setBtnLoader(false)
        dispatch(authActions.addEvent(finalVal))
      }
    });
    document.getElementById("addEventForm").reset();
    setWinner1st("0");
    setWinner2nd("0");
    setWinner3rd("0");

  }


  return (
    <div className={classes.container}>
      <div className={classes['events-header']} >
        <h1>Events</h1>
        {(user?.role === "SuperAdmin" || user?.privilege === "Admin" || user?.privilege === "EventOrganisers") ? <button onClick={() => setModalOpen(true)}>ADD EVENTS</button> : ""}
        <Modal centered open={modalOpen} onCancel={() => setModalOpen(false)} footer={null} closable={width > 700 ? false : true} maskClosable={true} width={width > 700 ? '700px' : "100%"} margin="0">
          <div style={{ display: "flex" }}>
            <Form layout='vertical' className={classes.modalDiv} onFinish={addEventHandler} form={form} id="addEventForm">
              <h1>Add Event</h1>
              <div className={classes.scrollForm + " scroll"}>
                <Form.Item label="Image" name="files" className={classes.headerDiv} rules={[{ required: true, message: "Please Upload Event Image", },]} style={{ width: '100%' }}>
                  <Upload className={classes.modalSection} showUploadList={true} beforeUpload={imgbeforeUpload} onRemove={() => setImageUrl("")} maxCount={5} accept=".png, .jpg, .jpeg">
                    <div style={{ fontFamily: 'poppins', width: width > 700 ? '600px' : "100%", display: 'flex', justifyContent: 'center', alignItems: "center", gap: '5px' }}>Upload <UploadOutlined /></div>
                  </Upload>
                </Form.Item>
                <Form.Item label="Name" name="title" className={classes.nameDiv} rules={[{ required: true, message: "Please enter Event Name", },]}>
                  <Input type="text " placeholder='Enter name of the event' />
                </Form.Item>
                <Form.Item label="Event location" name="location" rules={[{ required: true, message: "Please enter Event location", },]}>
                  <select style={{ width: '100%', border: '0.2px solid #d9d9d9', height: '30px', outline: 'none', fontFamily: 'poppins', borderRadius: '0' }}>
                    <option value="" disabled selected hidden>Select event location</option>
                    <option value="bangalore">bangalore</option>
                    <option value="coimbatore">coimbatore</option>
                    <option value="both">Both</option>
                  </select>
                </Form.Item>
                <Row className={classes.dateDiv}>
                  <Form.Item label="Nomination Start Date" name="actualDate" className={classes.firstDateDiv} rules={[{ required: true, message: "Please enter Nomination Start Date", },]} style={{ position: 'relative' }}>
                    <DatePicker style={{ width: '100%' }} onChange={(date, dateString) => { setNominationEnddate(dateString) }} disabledDate={(current) => { return current && current < moment().startOf('day') }} placeholder='dd-mm-yyyy' format={"YYYY-MM-DD"} placement="bottomRight" getPopupContainer={trigger => trigger.parentElement}>
                    </DatePicker>
                  </Form.Item>
                  <Form.Item label="Nomination Final Date" name="nominationDate" className={classes.firstDateDiv} rules={[{ required: true, message: "Please enter Nomination End Date", },]}>
                    <DatePicker style={{ width: '100%' }} disabledDate={(current) => { return current && current < moment(NominationStartDate, 'YYYY-MM-DD'); }} onChange={(date, dateString) => { { setNominationDate(dateString) } }} placeholder='dd-mm-yyyy' format={"YYYY-MM-DD"} placement="bottomRight" getPopupContainer={trigger => trigger.parentElement}>
                    </DatePicker>
                  </Form.Item>
                </Row>
                <Row className={classes.dateDiv}>
                  <Form.Item label="Event Start Date" name="startDate" rules={[{ required: true, message: "Please enter Event Start Date", },]}>
                    <DatePicker style={{ width: '100%' }} onChange={(date, dateString) => { setStartdate(dateString) }} disabledDate={(current) => { return current && current < moment(nominationdates, "YYYY-MM-DD") }} placeholder='dd-mm-yyyy' format={"YYYY-MM-DD"} className={classes.firstDateDiv} placement="bottomRight" getPopupContainer={trigger => trigger.parentElement}>
                    </DatePicker>
                  </Form.Item>
                  <Form.Item label="Event End Date" name="endDate" rules={[{ required: true, message: "Please enter Event End Date", },]}>
                    <DatePicker style={{ width: '100%' }} onChange={(date, dateString) => { setEnddate(dateString) }} disabledDate={(current) => { return current && current < moment(startDate, "YYYY-MM-DD") }} placeholder='dd-mm-yyyy' format={"YYYY-MM-DD"} className={classes.firstDateDiv} placement="bottomRight" getPopupContainer={trigger => trigger.parentElement}>
                    </DatePicker>
                  </Form.Item>
                </Row>
                <Form.Item label="Event Type" name="eventType" rules={[{ required: true, message: "Please enter Event type", },]}>
                  <select style={{ width: '100%', border: '0.2px solid #d9d9d9', height: '30px', outline: 'none', fontFamily: 'poppins', borderRadius: '0' }}>
                    <option value="" disabled selected hidden>Select event type</option>
                    <option value="Individual">Individual</option>
                    <option value="Group">Group</option>
                    <option value="Both">Both</option>
                  </select>
                </Form.Item>
                <Form.Item label="File Type" name="fileType" rules={[{ required: true, message: "Please enter File Type", },]}>
                  <select style={{ width: '100%', border: '0.2px solid #d9d9d9', height: '30px', outline: 'none', fontFamily: 'poppins' }}>
                    <option value="" disabled selected hidden>Select file type</option>
                    {/* <option value="image">Image</option>
                    <option value="application">Pdf</option>
                    <option value="video">Video</option> */}
                    <option value="all">All</option>
                    <option value="none">None</option>
                  </select>

                </Form.Item>

                <Form.Item label=" Description" name="description" className={classes.nameDiv} rules={[{ required: true, message: "Please enter Description", },]}>
                  <TextArea type="textareat" placeholder="type here" rows={3} />
                </Form.Item>

                <Row className={classes.dateDiv}>
                  <Form.Item label="Points for 1st winner" name="firstWinnerPoints" rules={[{ required: true, message: "Please enter points for 1st winner", },]}>
                    <Input type='number' min={0} onChange={(value) => { setWinner1st(value.target.value) }}></Input>
                  </Form.Item>

                  <Form.Item label="Points for 2nd winner" name="secondWinnerPoints" rules={[{ required: true, message: "Please enter points for 2nd winner", },]}>
                    <Input type="number" min={0} onChange={(value) => setWinner2nd(value.target.value)}></Input>
                  </Form.Item>
                </Row>

                <Row className={classes.dateDiv}>
                  <Form.Item label="Points for 3rd winner" name="thirdWinnerPoints" rules={[{ required: true, message: "Please enter points for 3rd winner", },]}>
                    <Input type='number' min={0} onChange={(value) => { setWinner3rd(value.target.value); }}></Input>
                  </Form.Item>

                  <Form.Item >
                    <b>Total points allocated-   </b> {parseInt(winner1st) + parseInt(winner2nd) + parseInt(winner3rd)}
                  </Form.Item>
                </Row>
                {width > 700 &&
                  <Form.Item label="Upload Guidelines" name="guidelinesFile" required rules={[{ required: true, message: "Please upload guidelines for event", },]}>
                    <Upload name="file" className={classes.guidlineUpload} showUploadList={true} beforeUpload={pdfbeforeUpload} style={{ backgroundColor: "#6FC78F", }} onRemove={() => setPdfUrl("")} maxCount={1} accept=".pdf ">
                      {pdfUrl ? (<img src={pdfLogo} alt="Error" style={{ width: "100%", height: "30px", borderRadius: "5%", }} />) : (uploadButton)}
                    </Upload>
                  </Form.Item>}
                {width < 700 &&
                  <Form.Item label="Upload Guidelines" name="guidelinesFile" required rules={[{ required: true, message: "Please upload guidelines for event", },]}>
                    <Upload name="file" className={classes.guidlineUpload} showUploadList={true} beforeUpload={pdfbeforeUpload} style={{ backgroundColor: "#6FC78F", }} onRemove={() => setPdfUrl("")} maxCount={1} accept=".pdf ">
                      {pdfUrl ? (<img src={pdfLogo} alt="Error" style={{ width: "100%", height: "30px", borderRadius: "5%", }} />) : (uploadButton)}
                    </Upload>
                  </Form.Item>}
                {/* {width < 700 && (
                  <Form.Item
                    label="Upload Guidelines"
                    name="guidelinesFile"
                    required
                    rules={[{ required: true, message: "Please enter guideline for event" }]}
                  >
                    <div className={classes.guidlineUpload}>
                      <Upload
                        name="file"
                        showUploadList={true}
                        beforeUpload={pdfbeforeUpload}
                        style={{ backgroundColor: "#6FC78F" }}
                        onRemove={() => setPdfUrl("")}
                        maxCount={5}
                        accept=".pdf"
                      >
                        {pdfUrl ? (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <img
                              src={pdfLogo}
                              alt="Error"
                              style={{
                                width: "100%",
                                height: "30px",
                                borderRadius: "5%",
                                marginRight: "5px",
                              }}
                            />
                            <span style={{ width: "5px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                              {uploadedFileName}
                            </span>
                          </div>
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </div>
                  </Form.Item>
                )} */}

              </div>
              <Form.Item className={classes.buttonDiv}>
                <Button onClick={() => { setModalOpen(false); document.getElementById("addEventForm").reset(); setWinner1st("0"); setWinner2nd("0"); setWinner3rd("0"); }}>DISCARD</Button>
                <Button loading={buttonLoader} htmlType='submit' className={classes.addButton} >ADD</Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
      {width > 700 && <Modal centered open={sendmodal} footer={null} closable={false} maskClosable={true} width="600px" >
        <div className={""}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", }}>
            {width > 700 && <span style={{ fontFamily: "poppins", fontSize: "22px", fontWeight: "600", color: "#6fc78f", marginBottom: "1.5rem", }}>
              Send the link of the Event to all the Associates.
            </span>}
            {width < 700 && <span style={{ fontFamily: "poppins", fontSize: "18px", fontWeight: "600", color: "#6fc78f", marginBottom: "1.5rem", }}>
              Send the link of the Event to all the Associates.
            </span>}
          </div>
          <div className={classes.buttonDiv}>
            <Button
              className={classes.addButton}
              onClick={() => {
                let data = []
                events.map((element) => {
                  if (element.title === addevent.title) {
                    data.push(element)
                  }
                })
                //  console.log(data)
                // setSendModalOpen(true);
                Api.post("/ibelong-events/sendMail")
                  .params({ sendNow: true, eventId: data[0]?.eventId, date: "" })
                  .send(res => {
                    // Notification.success(res?.show?.type,res?.show?.message)
                    dispatch(authActions.emailModal(false))
                  })
                //  dispatch(authActions.emailModal(false))
                // setModalOpen(false);
              }}
            >SEND NOW</Button>

            <Space direction="vertical">
              <DatePicker
                key={Math.random()}
                allowClear={false}
                disabledDate={(current) => { return current && current < moment().startOf('day') }}
                onChange={(date, dateString) => {
                  let data = []
                  events.map((element) => {
                    if (element.title === addevent.title) {
                      data.push(element)
                    }
                  })
                  // setSendModalOpen(true);
                  Api.post("/ibelong-events/sendMail")
                    .params({ sendNow: false, eventId: data[0]?.eventId, date: dateString })
                    .send(res => {
                      //  Notification.success(res?.show?.message,res?.show?.type)
                      dispatch(authActions.emailModal(false))
                    })
                }}
                placeholder="SEND LATER"
                suffixIcon={<div></div>}
                className={classes.sendNow}
              />
            </Space>
          </div>
        </div>
      </Modal>}

      {width <= 700 && sendmodal && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "20px",
            textAlign: "center",
            zIndex: '1',
            width: '98%',
          }}
        >
          <div style={{ fontFamily: "poppins", fontSize: "18px", fontWeight: "600", color: "#6fc78f", marginBottom: "1.5rem" }}>
            Send the link of the Event to all the Associates.
          </div>
          <div className={classes.buttonDiv}>
            <Button
              className={classes.addButton}
              onClick={() => {
                let data = [];
                events.map((element) => {
                  if (element.title === addevent.title) {
                    data.push(element);
                  }
                });

                Api.post("/ibelong-events/sendMail")
                  .params({ sendNow: true, eventId: data[0]?.eventId, date: "" })
                  .send((res) => {
                    dispatch(authActions.emailModal(false));
                  });
              }}
            >
              SEND NOW
            </Button>
            <Space direction="vertical">
              <DatePicker
                // key={Math.random()}
                allowClear={false}
                disabledDate={(current) => {
                  return current && current < moment().startOf("day");
                }}
                onChange={(date, dateString) => {
                  let data = [];
                  events.map((element) => {
                    if (element.title === addevent.title) {
                      data.push(element);
                    }
                  });

                  Api.post("/ibelong-events/sendMail")
                    .params({ sendNow: false, eventId: data[0]?.eventId, date: dateString })
                    .send((res) => {
                      dispatch(authActions.emailModal(false));
                    });
                }}
                placeholder="SEND LATER"
                suffixIcon={<div></div>}
                className={classes.sendNow}
              />
            </Space>
          </div>
        </div>
      )}


      <div className={classes.searchCompo}>
        {console.log("priii", user)}
        <SearchCompo setSearch={setSearch} maxWidth="100%" />
        {width > 700 && <div style={{ marginLeft: user?.privilege === "Admin" || user?.privilege === "EventOrganisers" || user?.role === 'SuperAdmin' ? '30vw' : '40vw', display: "flex", flexDirection: "row", }} >
          {
            listType === 'list' ? <img src={greenGrid} alt='' style={{cursor:"pointer"}} onClick={changeGridTpe} /> : <img src={blackGrid} alt="" style={{cursor:"pointer"}} onClick={changeGridTpe} />
          }
          {
            listType === 'grid' ? <img src={greenList} alt="alt" style={{ marginLeft: '1rem',cursor:"pointer" }} onClick={changeListTpe} /> : <img src={blackList} alt="alt" style={{ marginLeft: '1rem',cursor:"pointer" }} onClick={changeListTpe} />
          }
        </div>}
        {width > 700 && <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', }}>
          <div><YearDropdown /></div>
          <div>
            {(user?.role === "SuperAdmin" || user?.privilege === "Admin" || user?.privilege === "EventOrganisers") ? <LocationDropdown locationArr={locationArr} /> : ""}
          </div>
        </div>}
        {width < 700 && <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'flex-start', width: '95vw' }}>
          <div><YearDropdown /></div>
          <div>
            {(user?.role === "SuperAdmin" || user?.privilege === "Admin" || user?.privilege === "EventOrganisers") ? <LocationDropdown locationArr={locationArr} /> : ""}
          </div>
        </div>}
      </div>
      <div>
        <EventCaptain viewType={listType} eventAdded={eventValChanged} setEventValChanged={setEventValChanged} search={search} />
      </div>
    </div>
  )
}
export default Events;