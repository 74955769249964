// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import AgeGroupChart from './Dashboardcomponents/AgeGroupChart';
import LocationChart from './Dashboardcomponents/LocationChart';
import DepartmentChart from './Dashboardcomponents/DepartmentChart';
import ParticipationChart from './Dashboardcomponents/ParticipationRate';
import Gender from './Dashboardcomponents/Gender'
import '../modules/DashboardComponent.css'
import ParticipationRate from './Dashboardcomponents/ParticipationRate';
import Api from "services/Api";
import GenderAgeGroupChart from './Dashboardcomponents/GenderAgeGroup';
import Participation from './Dashboardcomponents/Participation';



    

const Dashboard = ({colors, data_id}) => {

  const [data,setData] = useState();
  const [colorFlag, setColorFlag] = useState(false);

  useEffect(()=>{
    Api.get(`/ibelong-dashboard/house-analytics?houseId=${data_id}`).send((response,error)=>{
       if(response){
        setData(response)
       }
       else{
        console.log("NO RESPONSE",error)
       }
    })
  },[data_id]);
  const genderData = [
    { name: 'Male', value: Math.floor(data?.maleCount) },
    { name: 'Female', value: Math.floor(data?.femaleCount) }
  ];
  const totalGenderCount = genderData?.reduce((sum, item) => sum + item.value, 0);



  const ageData = Object.entries(data?.ageGroups ?? {})
  .filter(([key]) => key !== 'not in range/not defined') // Filter out unwanted key
  .map(([key, value]) => ({
    name: key,
    Male: value['malePercentage'],
    Female: value['femalePercentage'],
    maleCount: value['maleCount'],
    femaleCount: value['femaleCount']
  }));

  // const locationData = [
  //   { name: 'Bengaluru', value: 400 },
  //   { name: 'Ahmedabad', value: 300 },
  //   { name: 'Kolkata', value: 238 }
  // ];
  // const transformData = (locationMap, locationData) => {
  //   return Object.keys(locationMap).map(key => {
  //     const location = locationData.find(loc => loc.name.toLowerCase() === key.toLowerCase());
  //     return {
  //       name: key,
  //       value: location ? location.value : 0  // Default to 0 if no match found
  //     };
  //   });
  // };
 
  const locationData = Object.entries(data?.locationMap??{})?.map(([key, value]) => ({
      name: key,
      value: value
    }));

  const totalLocationCount = locationData?.reduce((sum, item) => sum + item.value, 0);



  // const departmentData = [
  //   { name: 'Finance', value: 24 },
  //   { name: 'Marketing', value: 104 },
  //   { name: 'Operations management', value: 256 },
  //   { name: 'Human resources', value: 345 },
  //   { name: 'Information technology', value: 206 }
  // ];
  const departmentData = Object.entries(data?.departmentCounts??{})?.map(([key, value],index) => ({
    name: key,
    value: value,
    index: index
  }));
  const totalDepartmentCount = departmentData?.reduce((sum, item) => sum + item.value, 0);


  console.log(departmentData,"qwert")
  // const participationData = [
  //   { name: 'Chess Connect', value: 44 },
  //   { name: 'Let’s Play Cricket', value: 80 },
  //   { name: 'Super Bowl', value: 72 }
  // ];
  const participationData = Object.entries(data?.participation ?? {})
  .filter(([key, value]) => key === 'overallMalePercentage' || key === 'overallFemalePercentage')
  .map(([key, value]) => ({
    name: key,
    value: Math.round(Number(value))
  }));

  const extraParticipationData = Object.entries(data?.participation ?? {})
  .filter(([key, value]) => key === 'overallMaleParticipants' || key === 'overallFemaleParticipants')
  .map(([key, value]) => ({
    name: key,
    value: value
  }));

  console.log(participationData,"CHECK")
  


  
  // console.log(participationData,"ABC");

    
  //   ({

  //   // Male: value['overallMaleParticipation'],
  //   // Female: value['overallMaleParticipation'],
  //   // remainingTotal: Number(data?.totalActiveMembers)-value['overallMaleParticipation']-value['overallFemaleParticipation'],
  
    
  // }));
  // participationData?.push({name: 'Remaining', value: (Math.round((data?.totalActiveMembers-(data?.participation?.overallMaleParticipants)-(data?.participation?.overallFemaleParticipants))/data?.totalActiveMembers * 100))})

  return (
    <div className="dashboard">
      
       <div className='chart-column'>
        <Gender data={genderData} colors={colors} totalActiveMembers={data?.totalActiveMembers} totalGenderCount={totalGenderCount}/>
        {/*<GenderChart data={genderData} />
        <AgeGroupChart data={ageData} colors={colors} />*/}
        <GenderAgeGroupChart data={ageData} colors={colors}/>
        <LocationChart data={locationData} colors={colors} totalLocationCount={totalLocationCount} />
        </div>
        <div className='chart-column'>
        <DepartmentChart data={departmentData} colors={colors} totalDepartmentCount={totalDepartmentCount}/>
        {/*<ParticipationRate data={participationData} colors={colors}/>*/}
        <Participation data={participationData} colors={colors} eventdata={data?.participation?.eventMap} total={data?.totalActiveMembers} extraParticipationData={extraParticipationData}/>
        </div>
      
    </div>
  );
};

export default Dashboard;
