import React from "react";

const HouseName = ({label,color})=>{
    return (
        <div style={styles.container}>
            <div style={styles.labelContainer}>
                <div style={{ ...styles.square, backgroundColor: color }}></div>
                <div style={styles.label}>{label}</div>
            </div>
        </div>
    );
};

const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      /*padding: '10px',
      backgroundColor: '#FFF',*/
      borderRadius: '4px',
      /*boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',*/
      width: '30%',
     
      
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
    square: {
      width: '12px',
      height: '12px',
      marginRight: '10px',
      borderRadius: '2px',
    },
    label: {
      fontSize: '15px',
      fontWeight: 'normal',
      color: '#424242',
      fontFamily: 'Roboto'
    },
   
    
  };

export default HouseName