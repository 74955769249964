import classes from './Chats.module.css';
import "./ChatConversation.css";
import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import { Modal } from 'antd';
import UserProfile from "../../../images/userProfile.png";
import profileIcon from "../../../images/profileIcon.jpg";
import SingleMessage from "./SingleMessage";
import sendIcon from "../../../images/sendIcon.svg";
import removeIcon from "../../../images/removeIcon.svg";
import emailIcon from "../../../images/emailIcon.png";
import fileText from "../../../images/fileText.svg";
import moreVertical from "../../../images/moreVertical.svg";
import moreHorizontal from "../../../images/moreHorizontal.svg";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import mentionsInputStyle from "./mentionsInputStyle";
import mentionStyle from "./mentionStyle";
import { Mention, MentionsInput } from "react-mentions";
import darkSendIcon from "../../../images/darkSendIcon.svg";
// import emojiIcon from "../../../images/emojiIcon.svg";
import {
  Form,
  Input,
  Row,
  Col,
  Popover,
  Upload,
  Image,
  Button,
  message,
  Popconfirm,
  Dropdown,
  Space,
  Tabs,
  Typography,
  Select,
  notification,
  Spin,
} from "antd";
import Api from "services/Api";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { chatActions } from "./chatStore";
import {
  AudioOutlined,
  DownOutlined,
  MoreOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import SearchCompo from "component/searchCompo";
import downloadIcon from "../../../images/downloadIcon.svg";
import TextArea from "antd/lib/input/TextArea";
// import { Option } from "antd/lib/mentions";
import Notification from "services/Notification";
import { useRef } from "react";
import InputEmoji from "react-input-emoji";
import uploadImage from "../../../images/uploadImage.svg";
import smileEmojiIcon from "../../../images/smile icon.svg";
import { Message } from "@mui/icons-material";
import { height } from "@mui/system";
import fileIcon from "../../../images/fileIcon.svg";
import downloadChatIcon from "../../../images/downloadChatIcon.svg";
import shareIcon from "../../../images/shareIcon.svg";
import FileShareModal from "./FileShareModal";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
// import { PropaneSharp } from "@mui/icons-material";
const { Search } = Input;
const confirm = Modal.confirm;
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
function GrpChatConversation({ socket, grpChatLoader, userClick }) {
  const { user, userImage, chatToken } = useSelector((state) => state.auth);
  const { chatData, openChatWindow, allChatToggle, allUser, grpChatPerson, singleChatPerson, grpSelected, chatWindow } = useSelector(
    (state) => state.chat
  );
  const [editModal, setEditModal] = useState(false);
  const dispatch = useDispatch();
  const content = (
    <div>

      <div className="memberAdmin-btn"> Make Admin</div>
      <div className="memberRemove-btn">Remove as member</div>
    </div>
  );
  const { width } = useWindowDimensions();

  const [diableUpload, setDisableUpload] = useState(false);
  const [grpModal, setGrpModal] = useState(false);
  const [aboutClicked, setAboutClicke] = useState(false);
  const [membersClicked, setMembersClicke] = useState(false);
  const [modalAddPeopleOpen, SetmodalAddPeopleOpen] = useState(false);
  const [modalAboutEditOpen, setModalAboutEditOpen] = useState(false);
  const [modalAboutShareOpen, setmodalAboutShareOpen] = useState(false);
  const [OpenShareModal, SetOpenShareModal] = useState(false);
  const [AddAdminModal, setAddAdminModal] = useState(false);
  const [grpCreate, setGrpCreate] = useState({});
  const [editGrpData, setEditGrpData] = useState({});
  const [filterAddPeopleValues, SetFilterAddPeopleValues] = useState([]);
  const [leaveGroupCnfirmModal, SetLeaveGroupCnfirmModal] = useState(false);
  const [selectedFileter, setSelectedFileter] = useState("Members");
  const [filterVal, setFilterVal] = useState("");
  const [showFilterVal, setShowFilterVal] = useState([]);
  const [adminFilterVal, SetAdminFilterVal] = useState("");
  const [adminFilterValData, SetAdminFilterValData] = useState([]);
  const [getMsgToggle, setMsgToggle] = useState(false);
  const [passEditValues, setPassEditValues] = useState({});
  const currDate = useRef("");
  const msgId = useRef("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [uploadImgVisible, setUploadImgVisible] = useState(true);
  // const handleCancel = () => setPreviewOpen(false);
  const [update, setUpdate] = useState(false);
  const [SendchatData, setSendChatData] = useState([]);
  const [fileListVisible, setFileListVisible] = useState(false);
  const [fileList, setFileList] = useState(new Map());
  const [fileLists, setFileLists] = useState([]);
  const [search, setSearch] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [mediaChat, setmediaChat] = useState([]);
  const [filteredMedia, setFilteredMedia] = useState([])
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [FileShareModalOpen, setFileShareModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [visible, setVisible] = useState(false);
  const [msg, setMsg] = useState();
  const [value, setValue] = useState("");
  const [allMentionedUsers, setAllMentionedUsers] = useState([]);
  const [filteredMentions, setFilteredMentions] = useState([]);
  const [sendMentionUser, setSendMentionUser] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileLists(newFileList);
  };

  const { Option } = Select;
  const myOnEmojiClick = (emojiData: EmojiClickData, event: MouseEvent) => {
    setValue((prevState) => prevState + emojiData.emoji);
    setVisible(false);
    document.getElementById('onFoucsInput').focus();
  };

  const emojiVisibleHandler = () => {
    setVisible(!visible)
  }

  const myTst = (e) => {
    setValue(e.target.value);
    document.getElementById('onFoucsInput').focus();
    setVisible(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  }

  const handleCancel = () => setPreviewOpen(false);

  const handleShareIconClick = (mediavalue) => {
    setFileShareModalOpen(true);

    setSelectedMedia(mediavalue);
  };

  // useEffect(() => {
  //   setSearchLoader(true);
  //   setTimeout(() => {
  //     Api.get("/chat/getMedia")
  //       .params({
  //         search: search,
  //         chatId: openChatWindow?.chatId,
  //         userId: user?.id,
  //       })
  //       .send(async (res) => {
  //         await dispatch(chatActions.updateChatData(res));
  //         setmediaChat(res);
  //         if (Array.isArray(res)) {
  //           const mediaFiles = res.map(item => item.media);
  //           setFilteredMedia(mediaFiles);

  //         } else {
  //           console.log('mediaChat is not an array');
  //         }
  //         setSearchLoader(false);
  //       });
  //   }, 2000);


  // }, [search, openChatWindow]);
  const getMediaFunc = (() => {
    console.log("mediaaa")
    Api.get("/chat/getMedia")
      .params({ search: search, chatId: openChatWindow?.chatId, userId: user?.id })
      .send(async (res) => {
        await dispatch(chatActions.updateChatData(res));
        console.log("mediaaa111Response", res);
        const uniqueResponses = new Set();
        const filteredMediaFiles = [];

        res.forEach((response) => {
          if (!uniqueResponses.has(response.id)) {
            uniqueResponses.add(response.id);
            console.log("111Responseafterfilter", response);
            filteredMediaFiles.push(response.media);
          }
        });

        setFilteredMedia(filteredMediaFiles);
        setSearchLoader(false);
      });
  })

  useEffect(() => {
    setSearchLoader(true);
    setTimeout(() => {
      getMediaFunc();
      // Api.get("/chat/getMedia")
      //   .params({ search: search, chatId: openChatWindow?.chatId, userId: user?.id })
      //   .send(async (res) => {
      //     await dispatch(chatActions.updateChatData(res));
      //     console.log("111Response", res);
      //     const uniqueResponses = new Set();
      //     const filteredMediaFiles = [];

      //     res.forEach((response) => {
      //       if (!uniqueResponses.has(response.id)) {
      //         uniqueResponses.add(response.id);
      //         console.log("111Responseafterfilter", response);
      //         filteredMediaFiles.push(response.media);
      //       }
      //     });

      //     setFilteredMedia(filteredMediaFiles);
      //     setSearchLoader(false);
      //   });
    }, 2000);
  }, [search, openChatWindow]);


  const handleChange = (info) => {
    console.log("Handlechangejjjj", info)
    const { status, response, fileList: newFileList } = info;
    const userId = openChatWindow?.chatId;
    console.log("jjj000", fileList)
    if (!fileList) {
      let map = new Map();
      map?.set(userId, newFileList)
      // console.log("jjj222", fileList, "jjj333", newFileList, "jjj444", userId);
      setUpdate(!update)
      setFileList(map);
    } else {
      fileList?.get(userId)?.map(ele => {
        console.log("jjj111", ele);

      })
      fileList?.set(userId, newFileList)
      // console.log("jjj222", fileList, "jjj333", newFileList, "jjj444", userId);
      setUpdate(!update)
      setFileList(fileList);
    }
    console.log("jjj222", fileList, "jjj333", newFileList, "jjj444", userId);

  };

  const handleRemoveChat = (file) => {
    const userId = openChatWindow?.chatId;
    const index = fileList?.get(userId)?.indexOf(file);
    const list = fileList?.get(userId);
    list.splice(index, 1);
    setUpdate(!update);
    fileList?.set(userId, list);
    setFileList(fileList);
  };

  useEffect(() => {
    const compareName = (obj1, obj2) => {
      return obj1.userName === obj2.userName && obj1.userId === obj2.userId;
    };
    const arr = allUser?.filter((b) => {
      let indexFound = openChatWindow?.participants?.findIndex((a) =>
        compareName(a, b)
      );
      return indexFound == -1;
    });
    SetFilterAddPeopleValues(arr);
    openChatWindow?.participants.map((ele) => {
      let obj = {};
      if (ele != undefined) {
        obj["id"] = ele.userId;
        obj["display"] = ele.userName;
      }
      setAllMentionedUsers((oldArray) => [...oldArray, obj]);
    });
  }, [openChatWindow]);
  // const handleAddPeople = (val) => {
  //   console.log('jjj', val)
  //   let response = localStorage.getItem("auth");
  //   let response1 = openChatWindow?.participants.filter(
  //     (ele) => ele.userName === JSON.parse(response).auth.user.name
  //   );
  //   let object = {
  //     chatId: openChatWindow?.chatId,
  //     newParticipants: val?.grpMember.join(),
  //   };
  //   if (object.newParticipants[0] == JSON.parse(response).auth.user.id) {
  //     message.error("Sorry..! you can Leave Group you can't remove yourself ");
  //   } else if (response1[0].userPrivilege === "ADMIN") {
  //     Api.patch("/chat/updateGroupChat")
  //       .params(object)
  //       .send((res) => {
  //         dispatch(chatActions.updateAllChatToggle(!allChatToggle));
  //         SetmodalAddPeopleOpen(false);
  //       });
  //   } else {
  //     message.error("Sorry..! you are not ADMIN");
  //   }
  // };

  const handleAddPeople = (val) => {
    let response = localStorage.getItem("auth");
    let response1 = openChatWindow?.participants.filter(
      (ele) => ele.userName === JSON.parse(response).auth.user.name
    );
    let object = {
      chatId: openChatWindow?.chatId,
      newParticipants: val?.grpMember.join(),
    };
    if (object.newParticipants[0] == JSON.parse(response).auth.user.id) {
      message.error("Sorry..! you can Leave Group you can't remove yourself ");
    } else if (response1[0].userPrivilege === "ADMIN") {
      Api.patch("/ibelong-chat/updateGroupChat")
        .params(object)
        .send((res) => {
          dispatch(chatActions.updateAllChatToggle(!allChatToggle));
          SetmodalAddPeopleOpen(false);
        });
    } else {
      message.error("Sorry..! you are not ADMIN");
    }
  };

  const handleRemove = (items) => {
    let response = localStorage.getItem("auth");
    let response1 = openChatWindow?.participants.filter(
      (ele) => ele.userName === JSON.parse(response).auth.user.name
    );
    let data = {
      action: "dismiss",
      chatId: openChatWindow?.chatId,
      userId: [items?.userId],
    };
    if (data.userId[0] == JSON.parse(response).auth.user.id) {
      message.error("Sorry..! you can Leave Group you can't remove yourself ");
    } else if (response1[0].userPrivilege === "ADMIN") {
      Api.patch("/ibelong-chat/editPrivilege")
        .params(data)
        .send((res) => {
          dispatch(chatActions.updateAllChatToggle(!allChatToggle));
          setModalAboutEditOpen(false);
        });
    } else {
      message.error("Sorry..! you are not ADMIN");
    }
  };

  const CnfmText = "Are you sure to Leave Group";

  const confirm = () => {
    Api.delete("/ibelong-chat/leaveGroup")
      .params({ chatId: openChatWindow?.chatId })
      .send((per, res) => {
        if (per === undefined) {
          Notification.error(" Cannot Delete Try After Sometime..!");
          return;
        } else {
          dispatch(chatActions.updateAllChatToggle(!allChatToggle));
          setGrpModal(false);
          dispatch(chatActions.updateChatWindow(false));
        }
      });
    SetLeaveGroupCnfirmModal(false);
  };

  const onCancelLeaveGroupModal = () => {
    SetLeaveGroupCnfirmModal(false);
  };

  const [filesClicked, setFilesClicke] = useState(false);
  const clearChatHandler = () => {
    Api.patch("/ibelong-chat/chatActions")
      .params({
        chatId: openChatWindow?.chatId,
        mute: false,
        archive: false,
        clearChat: true,
      })
      .send((res) => {
        dispatch(
          chatActions.updateChatData({
            chatId: openChatWindow?.chatId,
            type: "DELETE",
          })
        );
        dispatch(chatActions.updateAllChatToggle(!allChatToggle));
        setMsgToggle(!getMsgToggle);
      });
  };
  const MuteFunc = () => {
    Api.patch("/ibelong-chat/chatActions")
      .params({
        chatId: openChatWindow?.chatId,
        mute: true,
        archive: false,
        clearChat: false,
      })
      .send((res) => {
        dispatch(chatActions.updateAllChatToggle(!allChatToggle));
      });
  };

  const HideFunc = () => {
    // console.log("Hide")
  };

  const itemspop = [
    {
      key: "1",
      label: (
        <a onClick={MuteFunc}>
          {openChatWindow?.notifications ? "Mute" : "Unmute"}
        </a>
      ),
    },
    {
      key: "2",
      label: <a onClick={clearChatHandler}>Clear Chat</a>,
    },
  ];
  const items1 = [
    {
      key: "1",
      label: <a>Mute</a>,
    },
    {
      key: "2",
      label: <a>Hide</a>,
    },
  ];
  const [sendBlock, setSendBlock] = useState(0);

  useEffect(() => {
  }, [value]);

  const shareHandler = () => {
    SetOpenShareModal(true);
  };
  const downloadHandler = () => {
  };
  const handleOpenAddPeople = () => {
    SetmodalAddPeopleOpen(true);
  };
  const handleOpenAboutEditModal = () => {
    setModalAboutEditOpen(true);
  };
  const [form] = Form.useForm();

  const handleDiscard = () => {
    document.getElementById("tst").reset();
    form.resetFields("");
    setTimeout(() => {
      setEditModal(false);
    }, 2000);
  };
  const addAdminHandler = (value) => {
    let response = localStorage.getItem("auth");
    let response1 = openChatWindow?.participants.filter(
      (ele) => ele.userName === JSON.parse(response).auth.user.name
    );
    if (response1[0].userPrivilege === "ADMIN" && value?.addAdmin?.length > 0) {
      let data = {
        action: "make",
        chatId: openChatWindow?.chatId,
        userId: value?.addAdmin,
      };
      Api.patch("/ibelong-chat/editPrivilege")
        .params(data)
        .send((res) => {
          if (res !== undefined) {


            setAddAdminModal(false);

            // setModalAboutEditOpen(false);
            // setGrpModal(false);
            // dispatch(chatActions.updateChatWindow(false));
            dispatch(chatActions.updateAllChatToggle(!allChatToggle));
          }
        });
    } else {
      if (value?.addAdmin?.length === 0 || value?.addAdmin === undefined) {
        setAddAdminModal(false);
      } else {
        message.error("Sorry..! you are not ADMIN");
      }
    }
  };
  const handleSaveEditGroup = (val) => {
    let data = {};
    if (
      val?.grpName !== undefined &&
      val?.grpName !== openChatWindow?.chatName
    ) {
      data = { ...data, chatName: val?.grpName };
    }
    if (val?.grpName?.trim()?.length === 0) {
      Notification.error("please give some group Name", "error");
      return;
    }
    if (
      val?.description !== undefined &&
      val?.description !== openChatWindow?.chatDescription
    ) {
      data = { ...data, chatDescription: val?.description };
    }
    if (
      val?.grpImage !== undefined &&
      val?.grpImage !== openChatWindow?.groupChatIcon
    ) {
      data = {
        ...data,
        groupChatIcon: val?.grpImage?.fileList[0]?.originFileObj,
      };
    }
    if (Object.keys(data)?.length !== 0) {
      data = { ...data, chatId: openChatWindow?.chatId };
    }
    Api.patch("/ibelong-chat/updateGroupChat").uploadAndPatch(
      data,
      (percentCompleted, response) => {
        setEditModal(false);
        dispatch(chatActions.updateAllChatToggle(!allChatToggle));
      }
    );
  };
  // const handleSaveEditGroup = (val) => {
  //   console.log("juneedit", val)
  //   let data = {};
  //   if (
  //     val?.grpName !== undefined &&
  //     val?.grpName !== openChatWindow?.chatName
  //   ) {
  //     data = { ...data, chatName: val?.grpName };
  //   }
  //   if (val?.grpName?.trim()?.length === 0) {
  //     Notification.error("please give some group Name", "error");
  //     return;
  //   }
  //   if (
  //     val?.description !== undefined &&
  //     val?.description !== openChatWindow?.chatDescription
  //   ) {
  //     data = { ...data, chatDescription: val?.description };
  //   }
  //   if (
  //     val?.grpImage !== undefined &&
  //     val?.grpImage !== openChatWindow?.groupChatIcon
  //   ) {
  //     data = {
  //       ...data,
  //       groupChatIcon: val?.grpImage?.fileList[0]?.originFileObj,
  //     };
  //   }
  //   if (Object.keys(data)?.length !== 0) {
  //     data = { ...data, chatId: openChatWindow?.chatId };
  //   }
  //   Api.patch("/chat/updateGroupChat").uploadAndPatch(
  //     data,
  //     (percentCompleted, response) => {
  //       console.log("juneres", response)
  //       setEditModal(false);
  //       dispatch(chatActions.updateAllChatToggle(!allChatToggle));
  //     }
  //   );
  // };
  const ShareModal = () => {
    console.log("sharemodal");
    SetOpenShareModal(true);
  };
  const selectHandleChange = (value) => {
    setSelectedFileter(value);
  };
  const AddAdmin = () => {
    setAddAdminModal(true);
  };
  const participantMakeAdmin = (ele) => {
    let res = localStorage.getItem("auth");
    const data = {
      action: "make",
      chatId: openChatWindow?.chatId,
      userId: [ele?.userId],
    };
    let res1 = openChatWindow?.participants.filter(
      (ele) => ele.userName === JSON.parse(res).auth.user.name
    );
    if (data.userId[0] == JSON.parse(res).auth.user.id) {
      message.error(" sorry.. you can't proceed this action ");
    } else if (res1[0].userPrivilege === "ADMIN") {
      Api.patch("/ibelong-chat/editPrivilege")
        .params(data)
        .send((res) => {
          dispatch(chatActions.updateAllChatToggle(!allChatToggle));
        });
    } else {
      message.error("Sorry..! you are not ADMIN");
    }
  };
  const ParcticipantsRemoveMember = (ele) => {
    let res = localStorage.getItem("auth");
    let data = {
      chatId: openChatWindow?.chatId,
      removedParticipants: ele?.userId.toString(),
    };
    let res1 = openChatWindow?.participants.filter(
      (ele) => ele.userName === JSON.parse(res).auth.user.name
    );
    if (data.removedParticipants[0] == JSON.parse(res).auth.user.id) {
      message.error("Sorry..! you can leave the group, can't remove yourself");
    } else if (res1[0].userPrivilege === "ADMIN") {
      Api.patch("/ibelong-chat/updateGroupChat")
        .params(data)
        .send((res) => {
          dispatch(chatActions.updateAllChatToggle(!allChatToggle));
          if (ele?.userId === user?.id) {
            dispatch(chatActions.updateChatWindow(false));
          }
        });
    } else {
      message.error("Sorry..! you are not ADMIN");
    }
  };
  function markup(id, display) {
    return `{display}`;
  }

  const handleAboutEdit = (openChatWindow) => {
    setPassEditValues(openChatWindow);
    setEditModal(true);
  };
  const handleMemberWhoCanChange = (e) => {
    SetAdminFilterVal(e.target.value);
    let results = openChatWindow?.participants.filter((items) => {
      return Object.values(items)
        .join("")
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    SetAdminFilterValData(results);
  };

  const membersSelectedFilter = (e) => {
    setFilterVal(e.target.value);
    if (selectedFileter === "Everyone") {
      let res = allUser.filter((ele) => {
        return Object.values(ele)
          .join("")
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });
      setShowFilterVal(res);
    } else {
      if (selectedFileter === "Members") {
        let result = openChatWindow?.participants.filter((item) => {
          return Object.values(item)
            .join("")
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        });
        setShowFilterVal(result);
      }
    }
  };


  const filterMedia = (e) => {
    if (Array.isArray(mediaChat)) {
      const mediaFiles = mediaChat.map(item => item.media);
      const filteredMedia = mediaFiles.filter(media => {
        const fileName = media[0].split('/').pop();
        return fileName.toLowerCase().includes(e.target.value.toLowerCase());
      });
      setFilteredMedia(filteredMedia);
    } else {
      console.log('mediaChat is not an array');
    }
  };

  const imgbeforeUploading = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return isJpgOrPng || Upload.LIST_IGNORE;
    }
  };

  const { Paragraph, Text } = Typography;

  const items = [
    {
      key: "1",
      label: `About`,
      children: (
        <div>
          < div className={classes.itemmainDiv} >

            <div className={classes.ChatDesc} >

              <div className={classes.DesDiv} >
                Description
              </div>
              <div className="aboutDescriptionStyle">
                {openChatWindow?.chatDescription}
              </div>
            </div>
            <div className={classes.EditModalDiv}>
              <div className={classes.EditDiv} >

                <div style={{ fontWeight: "bold" }}>Managed by</div>
                <div>

                  <Button onClick={handleOpenAboutEditModal}>Edit</Button>
                </div>
              </div>
              <div>

                {openChatWindow?.participants.map((val, index) => {
                  if (val?.userPrivilege === "ADMIN")
                    return <div key={index}>{val?.userName}</div>;
                })}
              </div>
            </div>
            <div className={classes.PopConfirmdiv} style={{ color: "red", padding: "4px 10px", marginTop: "10px" }}  >
              <Popconfirm
                placement="topLeft"
                title={CnfmText}
                onConfirm={confirm}
                okText="Yes"
                cancelText="No"
              >

                <Button
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    padding: "4px 0px",
                    width: "32vw",
                    position: "fixed",
                  }}
                ></Button>
              </Popconfirm>
            </div>
            <Space
              direction="vertical"
              style={{
                width: "100%",
              }}
            >

              <Button
                block
                onClick={() => SetLeaveGroupCnfirmModal(true)}
                style={{
                  fontWeight: "bold",
                  color: "red",
                  display: "flex",
                  justifyContent: "flex-start",
                  opacity: "60%",
                }}
              >
                Leave Group
              </Button>
            </Space>
          </div >
        </div >
      ),
    },
    {
      key: "2",
      label: `Members`,
      children: (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Search
                placeholder="search "
                onChange={membersSelectedFilter}
                allowClear
              />
            </div>

          </div >
          <div style={{ marginLeft: "10px", cursor: "pointer", marginTop: "20px" }} >
            <UserAddOutlined style={{ color: "blue" }} />
            <Typography.Text
              ellipsis={{ tooltip: true }}
              style={{
                font: "normal normal bold 12px/21px Poppins",
                maxWidth: "150px",
                color: "blue",
              }}
              onClick={handleOpenAddPeople}
            >
              Add people
            </Typography.Text>
          </div>
          <div className="addPeopleMemberScrollBar">
            {selectedFileter === "Everyone" ? (
              <div >
                {filterVal?.length >= 1 ? (
                  <>
                  {
                  showFilterVal?.length > 0 ?
                  <div >
                    {showFilterVal?.map((ele) => {
                      return (
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={3} style={{ padding: "5px", marginLeft: "20px", }} >
                            <div>
                              <img src={ele?.profileImage ? ele?.profileImage : profileIcon} alt="image" style={{ width: "40px", height: "42px" }} />
                            </div>
                          </Col>
                          <Col className="gutter-row" span={13} style={{ padding: "5px" }} >
                            <div>
                              <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 12px/21px Poppins", maxWidth: "150px", }}>
                                {ele?.userName}
                              </Typography.Text>
                            </div> </Col> <Col className="gutter-row" span={6}>
                            <div>
                              <Button>{ele?.admin}</Button>
                              <Popover placement="bottomRight" content={content} trigger="click"     >
                                <MoreOutlined />
                              </Popover>
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                  :
                  <div style={{display:"flex",flexDirection:"column",alignItems:"center",textAlign:"center",margin:"3rem"}}>
                    No data
                  </div>
                  }
                  </>
                ) : (
                  <>
                  {
                  allUser?.length > 0 ?
                  <div>
                    {allUser?.map((ele) => {
                      return (
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={3} style={{ padding: "5px", marginLeft: "20px" }} >
                            <div>
                              <img src={ele?.profileImage ? ele?.profileImage : profileIcon} alt="image" style={{ width: "40px", height: "42px" }} />
                            </div>
                          </Col>
                          <Col className="gutter-row" span={13} style={{ padding: "5px" }} >
                            <div>
                              <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 12px/21px Poppins", maxWidth: "150px", }}>
                                {ele?.userName}
                              </Typography.Text>
                            </div>
                          </Col>
                          <Col className="gutter-row" span={6}>
                            <div style={{ border: "1px solid", backgroundColor: "blue", }}   >
                              <Button>{ele?.admin}</Button>
                            </div> </Col>
                        </Row>
                      );
                    })}
                  </div>
                  :
                  <div style={{display:"flex",flexDirection:"column",alignItems:"center",textAlign:"center",margin:"3rem"}}>
                    No data
                  </div>
                  }
                  </>
                )}
              </div>
            ) : (
              <div>
                {filterVal?.length >= 1 ? (
                  <>
                  {
                    showFilterVal?.length > 0 ?
                    <div>
                      {showFilterVal?.map((ele) => {
                        return (
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                            <Col className="gutter-row" span={3} style={{ padding: "5px", marginLeft: "20px", }} >
                              <img src={ele.userProfileImage ? ele?.userProfileImage : profileIcon} alt="image" style={{ width: "40px", height: "40px", }} />
                            </Col>
                            <Col className="gutter-row" span={13} style={{ display: 'flex', alignItems: 'center', }} >
                              <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 12px/21px Poppins", maxWidth: "150px", }}  >
                                {ele?.userName}
                              </Typography.Text>
                            </Col>
                            <Col className="gutter-row" span={6} style={{ display: 'flex', alignItems: 'center', }}>
                              <div className='PopCustom'>
                                <div style={{ display: "flex", justifyContent: "center", height: '100%', display: 'flex', alignItems: 'center' }}   >
                                  <div style={{ color: "black", border: "1px solid #ebe9e9 ", backgroundColor: "#ebe9e9", minWidth: '5vw', justifyContent: 'center', display: 'flex' }}  >
                                    {ele?.userPrivilege}
                                  </div>
                                  <Popover placement="bottomRight" content={() => (<div>
                                    <div onClick={() => participantMakeAdmin(ele)} className="memberAdmin-btn"  > Make Admin</div>
                                    <div onClick={() => ParcticipantsRemoveMember(ele)} className="memberRemove-btn">  Remove as member </div>
                                  </div>)} trigger="click">
                                    <MoreOutlined style={{ color: "black" }} onClick={() => console.log("hii")} />
                                  </Popover>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                    :
                    <div style={{display:"flex",flexDirection:"column",alignItems:"center",textAlign:"center",margin:"3rem"}}>
                      No data
                    </div>
                  }
                  </>
                ) : (
                  <>
                  {
                  openChatWindow?.participants?.length > 0 ?
                  <div>
                    {openChatWindow?.participants?.map((ele) => {
                      return (
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={3} style={{ padding: "5px", marginLeft: "20px", }} >
                            <img src={ele?.userProfileImage ? ele?.userProfileImage : profileIcon} alt="image" style={{ width: "40px", height: "40px" }} />
                          </Col>
                          <Col className="gutter-row" span={14} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 12px/21px Poppins", maxWidth: "150px", paddingLeft: "1rem", }}     >
                              {ele?.userName}
                            </Typography.Text>
                          </Col>
                          <Col className="gutter-row" span={5} style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ color: "black", border: "1px solid #ebe9e9 ", backgroundColor: "#ebe9e9", minWidth: '5vw', justifyContent: 'center', display: 'flex' }}  >
                              {ele?.userPrivilege}
                            </div>
                            <div>
                              <Popover placement="bottomRight" content={() => (<div>
                                <div onClick={() => participantMakeAdmin(ele)} className="memberAdmin-btn"  >
                                  Make Admin
                                </div>
                                <div onClick={() => ParcticipantsRemoveMember(ele)} className="memberRemove-btn" >
                                  Remove as member
                                </div>
                              </div>)} trigger="click" >
                                <MoreOutlined style={{ color: "black" }} onClick={() => console.log("hii")} />
                              </Popover>
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                  :
                  <div style={{display:"flex",flexDirection:"column",alignItems:"center",textAlign:"center",margin:"3rem"}}>
                    No data
                  </div>
                  }
                  </>
                )}
              </div>
            )}
          </div>
        </div >
      ),
    },
    {
      key: "3",
      label: `Files`,
      children: (
        <div>
          <div>
            <div>

              {/* <Search
                setSearch={setSearch}
                placeholder="search "
                onChange={filterMedia}
                allowClear
                style={{ width: '100%' }}
              /> */}
            </div>

            <Row className={classes.SharedFileList} >
              {filteredMedia?.map((media, key) => (
                <Row className={classes.filteredMedia}>
                  {media?.map((mediavalue, index) => {
                    const fileName = mediavalue?.split('/').pop();
                    const downloadUrl = mediavalue;
                    const handleDownload = (fileUrl, filename) => {
                      fetch(fileUrl, {
                        headers: {
                          'Content-Type': 'application/octet-stream'
                        },
                        method: 'GET',
                        mode: 'cors'
                      }).then(response => response.blob())
                        .then(blob => {
                          const url = window.URL.createObjectURL(blob); const link = document.createElement('a'); link.href = url; link.setAttribute('download', fileUrl?.split('/').pop()); document.body.appendChild(link); link.click(); document.body.removeChild(link); window.URL.revokeObjectURL(url);
                        })
                        .catch(error => console.log(error));
                      window.open(fileUrl, "_blank")?.focus();
                    };
                    const fileType = mediavalue?.split(".")?.pop()?.toLowerCase();
                    const isImage = ["jpg", "jpeg", "gif", "bmp", "png"].includes(fileType);
                    return (
                      <Col className={classes.Files}
                      >
                        <Col className={classes.filePreview}
                        > {isImage ? (<img src={mediavalue} alt={null} width="100%" height="100%" />) : (<Col className={classes.fileText}   >     <img src={fileText} alt={null} width="100%" height="100%" />   </Col>)}
                        </Col>
                        <Col className={classes.fileNameFit}

                        > <Typography.Text ellipsis={{ tooltip: true }}> {fileName}</Typography.Text>
                        </Col>

                        <Col className={classes.shareIconcol} > <img style={{ marginRight: "0.5rem", width: '100%', height: '100%' }} src={shareIcon} onClick={() => handleShareIconClick(key + '-' + index)} /> {FileShareModalOpen && <FileShareModal isOpen={key + '-' + index == selectedMedia ? FileShareModalOpen : false} onClose={() => setFileShareModalOpen(false)} selectedMedia={mediavalue} socket={socket} />}
                        </Col>
                        <Col className={classes.downloadIconcol}
                        ><img style={{ width: '100%', height: '100%' }} src={downloadIcon} onClick={() => handleDownload(mediavalue, fileName)}></img></Col>
                      </Col>
                    );
                  })}
                </Row>
              ))}
            </Row>

          </div>
        </div >

      ),
    },
  ];
  useEffect(() => {
    Api.get("/ibelong-chat/conversation")
      .params({ chatId: openChatWindow?.chatId, page: 1, limit: 50 })
      .send((res) => {
        if (res?.messages?.length > 0) {
          dispatch(
            chatActions.updateChatData({ chatData: res?.messages, type: "API" })
          );
          dispatch(
            chatActions.updateUnseenCount({
              unseenCount: res?.unseen,
              chatId: openChatWindow?.chatId,
            })
          );
        }
      });
    if (userClick) { setValue("") }
  }, [openChatWindow, passEditValues, getMsgToggle]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendMessageHandler();
    }
  };

  const sendMessageHandler = (val) => {
    console.log("vvvvsend", val)
    let matches
    if (value?.length >= 1 && value?.includes("(") && value?.includes(")")) {
      matches = value?.match(/\((.*?)\)/g)?.map(match => match?.slice(1, -1));
    }
    let data = {
      from: user?.id,
      // message: val?.message,
      message: value,
      chatId: openChatWindow?.chatId,
      chatType: "Group",
      to: openChatWindow?.userId,
    };
    let data1 = {
      from: user?.id,
      message: value,
      chatId: openChatWindow?.chatId,
      chatType: "nonGroup",
      to: openChatWindow?.userId,
      createdAt: moment().format(),
      fromName: user?.name,
    };

    if (matches !== undefined) {
      data = { ...data, tags: matches }
    }

    if (fileList?.get(openChatWindow?.chatId)?.length > 0) {
      console.log("p o s t A P I")
      let file = [];
      fileList?.get(openChatWindow?.chatId)?.forEach((ele) => {
        file.push(ele?.originFileObj);
      });
      data = { ...data, files: file };
      Api.post("/ibelong-chat/mediaMessage").upload(data,
        (percentCompleted, response) => {
          document.getElementById("clearForm").reset();
          setFileList((el) => {
            console.log("inside set functiom", el)
          })
          // delete fileList[openChatWindow?.chatId];

          if (response !== undefined && percentCompleted === 100) {
            // setSendChatData(response?.data?.messageResponseObject)
            // dispatch(chatActions.updateChatData({
            //   chatData: response?.data?.messageResponseObject
            //   , type: "RECEIVE"
            // }
            // ));
            // document.getElementById("message").reset();
            // setUpdate(!update)
            // setFileListVisible(false)
            // setFileList(new Map());
            document.getElementById("clearForm").reset();
            setFileList(new Map())
            // delete fileList[openChatWindow?.chatId];
          }
        });
      setValue("");
      getMediaFunc();
    }
    else if (fileList?.get(openChatWindow?.chatId)?.length === 0 || fileList?.get(openChatWindow?.chatId) === undefined) {
      console.log("s o c k e t A P I")
      if (value?.trim()?.length === 0) {
        return;
      }
      socket.emit("sendMessage", data, (res) => {
        if (res === true) {
          // document.getElementById("message").reset();
        }
      });
      setValue("");
      getMediaFunc();
    }
    console.log("socket code run till here");
  };


  const scrollref = useRef();
  useEffect(() => {
    scrollref.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatData]);
  return (
    <>

      {grpChatLoader ? (
        <Row justify="center" style={{ marginTop: "10vh" }}>
          <Spin size="large" style={{ alignSelf: "center", height: "60vh" }} />
        </Row>
      ) : (
        <>

          <div className={classes.GrpChatBox}>
            <div className={classes.singleChatheader} >
              <div className={classes.ProfImg}  >
                <img src={openChatWindow?.groupChatIcon ? openChatWindow?.groupChatIcon : profileIcon} alt="No Image" style={{ width: "40px", height: "40px", borderRadius: "5px", }} />
              </div>
              <div className={classes.ProfImgMobile} > <img src={openChatWindow?.groupChatIcon ? openChatWindow?.groupChatIcon : profileIcon} alt="No Image" style={{ width: "40px", height: "40px", borderRadius: "50%", cursor: "pointer", }} /> </div>
              <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 12px/22px Poppins", maxWidth: "15vw", cursor: 'pointer' }} onClick={() => { setGrpModal(true); }} >
                {openChatWindow?.chatName}
              </Typography.Text>
              <Space>
                <Dropdown
                  menu={{
                    items: itemspop,
                  }}
                  placement="bottomRight"
                  profileImage
                >
                  {window.innerWidth > 700 ? (
                    <div className={classes.more}>
                      <img src={moreHorizontal} style={{ width: '100%', height: '100%', cursor: 'pointer' }} alt="More Horizontal" />
                    </div>
                  ) : (
                    <div className={classes.more}>
                      <img src={moreVertical} style={{ width: '100%', height: '100%', cursor: 'pointer' }} alt="More Vertical" />
                    </div>
                  )}
                </Dropdown>
              </Space>
            </div>
            <Col className="MessageArea scroll">
              {chatData[openChatWindow?.chatId]?.map((val, key) => {
                let time = moment(val?.createdAt).format("hh:mma");
                let profileImage = userImage;
                openChatWindow?.participants?.forEach((ele) => {
                  if (
                    ele.userId === parseInt(val.from) &&
                    ele.userProfileImage
                  ) {
                    profileImage = ele.userProfileImage;
                  }
                });
                const dateToday = moment(val?.createdAt).format("MMMM D");
                let flag = false;
                if (key == 0) {
                  currDate.current = moment(val?.createdAt).format("MMMM D");
                  flag = true;
                } else if (currDate.current != dateToday) {
                  currDate.current = dateToday;
                  flag = true;
                }
                {
                  /* console.log("dateDay ProfileImage", user?.id, ":", val?.from, ":", profileImage); */
                }
                if (key === 0) {

                  msgId.current = val?.id;
                  return (
                    <div key={key} ref={scrollref}>

                      {flag && (
                        <div style={{ justifyContent: "center", display: "flex" }}
                        >
                          <div style={{ borderBottom: "0.2px solid #E3E3E3", height: "0", width: "40%", marginTop: "16px", }} ></div> <div style={{ marginTop: "5px", border: "1px solid #E3E3E3", textAlign: "center", width: "fit-content", borderRadius: "3px", display: "flex", font: "normal normal bold 12px/22px Poppins", paddingRight: "5px", paddingLeft: "5px", }} >
                            {currDate.current} </div> <div style={{ borderBottom: "0.2px solid #E3E3E3", height: "0", width: "40%", marginTop: "16px", }} ></div>
                        </div>
                      )}
                      <SingleMessage
                        text={val?.message}
                        userName={val?.fromName}
                        time={time}
                        profileImage={profileImage}
                        SendchatData={val}
                      />
                    </div>
                  );
                } else if (msgId.current !== val?.id) {
                  {
                  }
                  msgId.current = val?.id;
                  return (
                    <div key={key} ref={scrollref}>

                      {flag && (
                        <div className={classes.Datediv}>
                          <div className={classes.dateBorder}></div>
                          <div className={classes.DateBox} >{currDate.current} </div>
                          <div className={classes.dateBorder}></div>
                        </div>

                      )}
                      <SingleMessage
                        text={val?.message}
                        userName={val?.fromName}
                        time={time}
                        profileImage={profileImage}
                        SendchatData={val}
                      />
                    </div>
                  );
                }
              })}
            </Col>
            <Row
              className="SendFile"
              style={{
                height: "auto",
                borderTop: "1px solid #B6B6B6",
                display: fileList?.get(openChatWindow?.chatId) == undefined || fileList?.get(openChatWindow?.chatId)?.length == 0 ? "none" : "block",
                width: "100%",
                padding: "7px",
              }}
            >
              <Row className={classes.SendFileText}>
                Send the attached files
              </Row>
              <Row className={classes.uploadFilesRow} >
                {fileList?.get(openChatWindow?.chatId)?.map((file) => (
                  <Row className={classes.SingleFile} key={file.uid}>
                    <Col className={classes.uploadedImg} >
                      {file.type.startsWith("image/") ? (
                        <img src={URL.createObjectURL(file.originFileObj)} alt={file.name} style={{ width: "100%", height: "100%" }}
                        />
                      ) : (
                        <img src={fileText} alt={file.name} style={{ width: "100%", height: "100%" }}
                        />
                      )}
                    </Col>
                    <Col className={classes.FileNameCol} >{file.name}
                    </Col>
                    <Col className={classes.RemoveIcon}>
                      <img
                        style={{ objectFit: "cover", width: "100%", height: "auto" }}
                        onClick={() => handleRemoveChat(file)}
                        src={removeIcon}
                      ></img>
                    </Col>
                  </Row>
                ))}
              </Row>
            </Row>
            <Row className={classes.ChatMesage}>
              <Form className={classes.messageform} id="clearForm" onFinish={(val) => { sendMessageHandler(val); }} onKeyDown={handleKeyDown} >
                <Col style={{ width: window.innerWidth > 700 ? "83%" : '79%' }}>
                  <Form.Item name="message" style={{ height: "auto" }} className="input-form-item">
                    <div>
                      {visible ? (<div className="Emojipick">
                        <Picker className="emojiStyle" onEmojiClick={(emojiObject) => myOnEmojiClick(emojiObject)} /> </div>) : null}
                      <div className="mentionInputContainer" ><MentionsInput placeholder={"Type a message.."} style={mentionsInputStyle} value={value} onChange={myTst} id="onFoucsInput" >
                        <Mention style={mentionStyle} data={allMentionedUsers} trigger="@" /></MentionsInput>
                      </div>
                    </div>
                  </Form.Item>
                </Col>
                <Col className={classes.messageBox}>
                  <div className={classes.UploadCol}>
                    <Form.Item name="File">
                      <Upload
                        fileList={fileList?.get(openChatWindow?.chatId)}
                        className="uploadImageList"
                        listType="picture"
                        showUploadList={false}
                        onChange={handleChange}
                        onRemove={handleRemove}
                        maxCount={5}
                        beforeUpload={(file) => {
                          const isLt20MB = file.size / 1024 / 1024 < 20; if (!isLt20MB) { message.error("File must be smaller than 20MB!"); return Upload.LIST_IGNORE; } const combinedList = [...(fileList?.get(openChatWindow?.chatId) ?? []), file,]; if (combinedList?.length > 5) { message.error("You can only upload up to 5 files!"); return Upload.LIST_IGNORE; } return isLt20MB;
                        }}
                      >
                        <img style={{ cursor: "pointer", border: "none", }} src={fileIcon} ></img>
                      </Upload>
                    </Form.Item>
                  </div>
                  <div style={{ width: '30%' }}>
                    <Form.Item className="input-form-item">
                      <Button className={classes.BtnEmo}
                      >
                        <img style={{ cursor: "pointer", border: "none", backgroundColor: "#F2F2F2", }} src={smileEmojiIcon} onClick={emojiVisibleHandler} ></img>
                      </Button>
                    </Form.Item>
                  </div>
                  <div style={{ width: '30%', height: '60%' }}>
                    <Form.Item >
                      <Button
                        htmlType="submit"
                        style={{
                          cursor: "pointer", backgroundColor: "#F2F2F2", border: "none", padding: "0px",

                        }}
                      >
                        <img src={value?.trim()?.length > 0 || fileList?.get(openChatWindow?.chatId)?.length > 0 ? darkSendIcon : sendIcon}></img>
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Form>
            </Row>
            <Modal bodyStyle={{ height: '76vh' }} open={grpModal} onCancel={() => { setGrpModal(false); }} footer={false} closable={false}>
              <div className={classes.FileShareModaldiv}>
                <div className={classes.FileModalheader}>
                  <div className={classes.ModalProfImgdiv}>
                    <Image src={openChatWindow?.groupChatIcon ? openChatWindow?.groupChatIcon : profileIcon} alt="image"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    />
                    <div>
                      <Typography.Text
                        ellipsis={{ tooltip: true }}
                        style={{
                          font: "normal normal bold 12px/21px Poppins", maxWidth: "200px",
                        }}
                      >
                        {openChatWindow?.chatName}
                      </Typography.Text>
                    </div>
                  </div>
                  <div className="editOption" onClick={() => handleAboutEdit(openChatWindow)} >
                    Edit
                  </div>
                  <div onClick={() => { setGrpModal(false); }} style={{ cursor: "pointer" }} >
                    <CloseOutlined />
                  </div>
                </div>
                <div>
                  <Modal
                    bodyStyle={{ height: 300, marginTop: "100px" }}
                    open={modalAddPeopleOpen}
                    onCancel={() => SetmodalAddPeopleOpen(false)}
                    closable={true}
                    header={null}
                    footer={null}
                  >

                    <div>
                      <div>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 18px/25px Poppins", maxWidth: "300px", }}> Add People to {openChatWindow?.chatName}
                        </Typography.Text >
                      </div >
                      <div style={{ marginTop: "30px", height: "30vh" }}>
                        {/* <Form layout="vertical" onFinish={handleAddPeople}>
                          <Form.Item style={{ marginTop: "20px" }} name="grpMember"  >
                            <Select mode="multiple" showSearch allowClear optionFilterProp="children" placeholder="please select" style={{ width: "32vw" }} listHeight={150}>
                              {filterAddPeopleValues?.map((val, id) => {
                                console.log('valll', val)
                                return (
                                  <Option value={val?.userId} key={id}> {val?.userName} </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item style={{ display: "flex", justifyContent: "flex-end", marginTop: "130px", }}   >
                            <Button className={classes.GrpAddPeopleBtn} htmlType="submit" >   ADD   </Button>
                          </Form.Item >
                        </Form > */}
                        <Form layout="vertical" onFinish={handleAddPeople}>
                          <Form.Item style={{ marginTop: "20px" }} name="grpMember" >
                            <Select
                              mode="multiple"
                              showSearch
                              allowClear
                              optionFilterProp="children"
                              placeholder="please select"
                              style={{ width: "100%" }}
                            >
                              {filterAddPeopleValues?.map((val, id) => {
                                return (
                                  <Select.Option value={val?.userId} key={id}>
                                    {val?.userName}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "130px",
                            }}
                          >
                            <Button className={classes.GrpAddPeopleBtn}
                              htmlType="submit"

                            >
                              ADD
                            </Button>
                          </Form.Item >
                        </Form >
                      </div >
                    </div >
                  </Modal >
                  {width > 700 && <Modal bodyStyle={{ height: '67vh', }} open={modalAboutEditOpen} onCancel={() => setModalAboutEditOpen(false)} closable={true} header={null} footer={null}>
                    <div>
                      <div>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 18px/25px Poppins", maxWidth: "300px", }}>  Members who can manage </Typography.Text >
                      </div>
                      <div>
                        <div style={{ display: "flex", flexDirection: "column", padding: "10px", height: "10vh", }}> <Search placeholder="Search Admin" onChange={handleMemberWhoCanChange} allowClear />
                        </div >
                        <div style={{ marginLeft: "10px", cursor: "pointer" }} onClick={AddAdmin}
                        > <UserAddOutlined style={{ color: "blue" }} />{" "} <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 12px/21px Poppins", maxWidth: "150px", color: "blue", }} >   Add Admin </Typography.Text>
                        </div >

                        {adminFilterVal?.length >= 1 ? (<div>   <div className="addAdminScrollBar"> {adminFilterValData?.map((items, id) => {
                          if (items?.userPrivilege === "ADMIN") {
                            return (<Row className="aboutEdit" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                              < Col className="gutter-row" span={3} style={{ padding: "5px", marginLeft: "20px", }} >
                                <div>
                                  <img src={items?.userProfileImage ? items?.userProfileImage : profileIcon} alt="image" style={{ width: "40px", height: "42px", }} />
                                </div>
                              </Col>
                              <Col span={14}>
                                <div>
                                  <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 12px/21px Poppins", maxWidth: "150px", marginTop: "13px", paddingLeft: "1rem" }} >
                                    {items.userName}
                                  </Typography.Text>
                                </div>
                              </Col>
                              <Col span={5}>
                                {items?.userId !== user?.id ? (<div className="remove-btn" onClick={() => { handleRemove(items); }}   >
                                  <Typography.Text ellipsis={{ tooltip: true }} style={{ cursor: "pointer", color: "red", marginTop: "13px", }} > Remove </Typography.Text >
                                </div >) : ("")}
                              </Col >
                            </Row >);
                          }
                        })}   </div > </div >
                        ) : (<div>
                          <div className="addAdminScrollBar">
                            {openChatWindow?.participants?.map((items, id) => {
                              if (items?.userPrivilege === "ADMIN") {
                                return (<Row className="aboutEdit" gutter={{ xs: 8, sm: 16, md: 24, lg: 32, }} >
                                  <Col className="gutter-row" span={3} style={{ padding: "5px", marginLeft: "20px", }}>
                                    <div>
                                      <img src={items?.userProfileImage ? items?.userProfileImage : profileIcon} alt="image" style={{ width: "40px", height: "42px", }} />
                                    </div>
                                  </Col >
                                  <Col span={14}>
                                    <div>
                                      <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 12px/21px Poppins", maxWidth: "150px", marginTop: "13px", paddingLeft: "1rem", }}  > {items?.userName}  </Typography.Text>
                                    </div>
                                  </Col>
                                  <Col span={5}>
                                    {items?.userId !== user?.id ? (<div className="remove-btn" onClick={() => { handleRemove(items); }} >   <Typography.Text ellipsis={{ tooltip: true }} style={{ cursor: "pointer", color: "red", marginTop: "13px", }}   >     Remove   </Typography.Text > </div >) : ("")}
                                  </Col >
                                </Row >);
                              }
                            })}
                          </div >
                        </div>
                        )}
                      </div >
                    </div >
                  </Modal >}
                  {width < 700 && <Modal bodyStyle={{ height: '76vh', }} open={modalAboutEditOpen} onCancel={() => setModalAboutEditOpen(false)} closable={true} header={null} footer={null}>
                    <div>
                      <div>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 16px/23px Poppins", maxWidth: "300px", }}>  Members who can manage </Typography.Text >
                      </div>
                      <div>
                        <div style={{ display: "flex", flexDirection: "column", padding: "10px", height: "10vh", }}> <Search placeholder="Search Admin" onChange={handleMemberWhoCanChange} allowClear />
                        </div >
                        <div style={{ marginLeft: "10px", cursor: "pointer" }} onClick={AddAdmin}> <UserAddOutlined style={{ color: "blue" }} />{" "} <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 12px/21px Poppins", maxWidth: "150px", color: "blue", }} >   Add Admin </Typography.Text>
                        </div >

                        {adminFilterVal?.length >= 1 ? (<div>   <div className="addAdminScrollBar">     {adminFilterValData?.map((items, id) => {
                          if (items?.userPrivilege === "ADMIN") {
                            return (<Row className="aboutEdit" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}           >
                              < Col className="gutter-row" span={3} style={{ padding: "5px", marginLeft: "20px", }}             >
                                <div>
                                  <img src={items?.userProfileImage ? items?.userProfileImage : profileIcon} alt="image" style={{ width: "40px", height: "42px", }} />
                                </div>
                              </Col >
                              <Col span={14}>
                                <div>
                                  <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 12px/21px Poppins", maxWidth: "150px", marginTop: "13px", paddingLeft: "1rem" }}                 >                   {items.userName}                 </Typography.Text>               </div>             </Col>             <Col span={5}>               {items?.userId !== user?.id ? (<div className="remove-btn" onClick={() => { handleRemove(items); }}                   > <Typography.Text ellipsis={{ tooltip: true }} style={{ cursor: "pointer", color: "red", marginTop: "13px", }} >   Remove </Typography.Text >                   </div >) : ("")}             </Col >           </Row >);
                          }
                        })}   </div > </div >
                        ) : (<div>
                          <div className="addAdminScrollBar">
                            {openChatWindow?.participants?.map((items, id) => {
                              if (items?.userPrivilege === "ADMIN") {
                                return (<Row className="aboutEdit" gutter={{ xs: 8, sm: 16, md: 24, lg: 32, }} >
                                  <Col className="gutter-row" span={3} style={{ padding: "5px", marginLeft: "20px", }}>
                                    <div>
                                      <img src={items?.userProfileImage ? items?.userProfileImage : profileIcon} alt="image" style={{ width: "40px", height: "42px", }} />
                                    </div>
                                  </Col >
                                  <Col span={14}>
                                    <div>
                                      <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 12px/21px Poppins", maxWidth: "150px", marginTop: "13px", paddingLeft: "1rem", }}  > {items?.userName}  </Typography.Text>
                                    </div>
                                  </Col>
                                  <Col span={5}>
                                    {items?.userId !== user?.id ? (<div className="remove-btn" onClick={() => { handleRemove(items); }} >   <Typography.Text ellipsis={{ tooltip: true }} style={{ cursor: "pointer", color: "red", marginTop: "13px", }}   >     Remove   </Typography.Text > </div >) : ("")}
                                  </Col >
                                </Row >);
                              }
                            })}
                          </div >
                        </div>
                        )}
                      </div >
                    </div >
                  </Modal >}

                  <Modal
                    bodyStyle={{ height: "350px", marginTop: "100px" }}
                    open={AddAdminModal}
                    onCancel={() => setAddAdminModal(false)}
                    closable={true}
                    header={null}
                    footer={null}
                  >
                    <Typography.Text
                      ellipsis={{ tooltip: true }}
                      style={{
                        font: "normal normal bold 18px/25px Poppins",
                        maxWidth: "300px",
                      }}
                    >

                      Add Admin to {openChatWindow?.chatName}
                    </Typography.Text >
                    <Form onFinish={addAdminHandler}>

                      <Form.Item
                        name="addAdmin"
                        style={{
                          display: "flex", flexDirection: "column", padding: "8px", height: "auto", width: "100%", border: "1px solid #d3d3d3",
                        }}
                      >
                        <Select mode="multiple" showSearch allowClear optionFilterProp="children" placeholder="Search by Name or Email" // style={{ width: "32vw" }} listHeight={120}
                        > {openChatWindow?.participants?.map((val, id) => { if (val?.userPrivilege !== "ADMIN") { return (<Option value={val?.userId} key={id}>           {val?.userName}         </Option >); } })}
                        </Select >
                      </Form.Item >
                      <Form.Item>
                        <Button className={classes.GrpAddAdminBtn}
                          htmlType="submit"
                        > ADD
                        </Button>
                      </Form.Item >
                    </Form >
                  </Modal >
                </div >
                <div>
                  <Tabs defaultActiveKey="1" items={items} />
                </div >
              </div >
            </Modal >
            {width > 700 &&
              // <Modal
              //   open={editModal}
              //   onCancel={() => {
              //     setEditModal(false);
              //   }}
              //   footer={false}
              //   closable={false}
              //   destroyOnClose={true}
              // >
              //   <div
              //     onClick={() => {
              //       setEditModal(false);
              //     }}
              //     style={{ cursor: "pointer", marginLeft: "450px" }}
              //   >
              //     <CloseOutlined />
              //   </div>
              //   <Form
              //     id="tst"
              //     preserve={false}
              //     layout="vertical"
              //     onFinish={handleSaveEditGroup}
              //     initialValues={{
              //       grpName: passEditValues?.chatName,
              //       description: passEditValues?.chatDescription,
              //       grpImage: passEditValues?.groupChatIcon,
              //     }}
              //   >
              //     <Row style={{ width: '100%', position: 'relative', justifyContent: 'flex-end' }}>
              //       <Col style={{ width: "300px", }}>
              //         <div>
              //           <h2 style={{ fontWeight: "bolder" }}>
              //             Edit Group Information
              //           </h2>
              //         </div>
              //         <Row>
              //           <Col span={14}>
              //             <Form.Item name="grpName" label={<div className="grp-name">GROUP NAME</div>}> <Input placeholder="name of the group" />
              //             </Form.Item >
              //             <Form.Item name="description" label={<div>
              //               <b style={{ fontSize: "16px", fontWeight: "500" }}> DESCRIPTION</ b>{"(optional)"}</div >}>
              //               <TextArea className="editGroupScrollBar" placeholder="what's the group about?" rows={2} ></TextArea>
              //             </Form.Item >
              //           </Col >
              //         </Row >
              //       </Col>
              //       <Col className={classes.editGrpImg}  >
              //         <Form.Item
              //           name="grpImage"
              //           style={{
              //             display: "flex",
              //             justifyContent: "center",
              //             alignItems: "center",
              //           }}
              //         >
              //           <Upload
              //             listType="picture-card"
              //             accept=".png, .jpg, .jpeg"
              //             beforeUpload={imgbeforeUploading}
              //             fileList={fileLists}
              //             onChange={onChange}
              //             onPreview={handlePreview}
              //           >
              //             {fileLists?.length < 1 && "Upload Image"}
              //           </Upload>
              //         </Form.Item>
              //         <Form.Item>

              //           <Modal
              //             open={previewOpen}
              //             title={previewTitle}
              //             footer={null}
              //             onCancel={handleCancel}
              //           >
              //             <img alt="example" style={{ width: "100%", }} src={previewImage}
              //             />
              //           </Modal>
              //         </Form.Item>
              //       </Col>
              //       <Form.Item >
              //         <Row style={{ display: 'flex', width: '100%', }}>
              //           <Button className={classes.Discardbtn}
              //             onClick={handleDiscard}
              //           >
              //             Discard
              //           </Button>
              //           <Button className={classes.saveBtn}
              //             htmlType="submit"
              //           >
              //             Save changes
              //           </Button>
              //         </Row>
              //       </Form.Item>
              //     </Row >
              //   </Form >
              // </Modal >

              <Modal
                open={editModal}
                onCancel={() => {
                  setEditModal(false);
                }}
                footer={false}
                closable={false}
                destroyOnClose={true}
              >
                <div
                  onClick={() => {
                    setEditModal(false);
                  }}
                  style={{ cursor: "pointer", marginLeft: "450px" }}
                >
                  <CloseOutlined />
                </div>
                <Form
                  id="tst"
                  preserve={false}
                  layout="vertical"
                  onFinish={handleSaveEditGroup}
                  initialValues={{
                    grpName: passEditValues?.chatName,
                    description: passEditValues?.chatDescription,
                    grpImage: passEditValues?.groupChatIcon,
                  }}
                >
                  <Row>
                    <Col style={{ width: "300px" }}>
                      <div>
                        <h2 style={{ fontWeight: "bolder" }}>
                          Edit Group Information
                        </h2>
                      </div>
                      <Row>

                        <Col span={14}>
                          <Form.Item name="grpName" label={<div className="grp-name">GROUP NAME</div>} >
                            <Input placeholder="name of the group" />
                          </Form.Item >
                          <Form.Item name="description"
                            label={
                              <div>
                                <b style={{ fontSize: "16px", fontWeight: "500" }} >
                                  DESCRIPTION
                                </ b>
                                {"(optional)"}
                              </div >
                            }
                          >
                            <TextArea
                              className="editGroupScrollBar"
                              placeholder="what's the group about?"
                              rows={2}
                            ></TextArea>
                          </Form.Item >
                        </Col >
                      </Row >
                    </Col>
                    <Col
                      style={{
                        width: "165px",
                        width: "128px",
                        height: "128px",
                        margin: "auto",
                      }}
                    >


                      <Form.Item
                        name="grpImage"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Upload
                          listType="picture-card"
                          accept=".png, .jpg, .jpeg"
                          beforeUpload={imgbeforeUploading}
                          fileList={fileLists}
                          onChange={onChange}
                          onPreview={handlePreview}
                        >
                          {fileLists?.length < 1 && "Upload Image"}
                        </Upload>
                      </Form.Item>

                      <Form.Item>
                        <Modal
                          open={previewOpen}
                          title={previewTitle}
                          footer={null}
                          onCancel={handleCancel}
                        >
                          <img
                            alt="example"
                            style={{
                              width: "100%",
                            }}
                            src={previewImage}
                          />
                        </Modal>
                      </Form.Item>
                    </Col>
                    {/* <Form.Item style={{ marginLeft: "255px" }}>
                      <Button
                        style={{
                          marginTop: "15px",
                          color: "black",
                          background: "#EFEFEF",
                        }}
                        onClick={handleDiscard}
                      >
                        Discard
                      </Button>
                      <Button
                        style={{
                          marginTop: "15px",
                          marginLeft: "15px",
                          background: " #6FC78F",
                          color: "white",
                        }}
                        htmlType="submit"
                      >
                        Save changes
                      </Button>
                    </Form.Item> */}
                    <Form.Item style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                      {/* <Row style={{ display: 'flex', justifyContent: 'flex-end' }}> */}
                      <Button className={classes.Discardbtn} onClick={handleDiscard} >
                        Discard
                      </Button>
                      <Button className={classes.saveBtn} htmlType="submit" >
                        Save changes
                      </Button>
                      {/* </Row> */}
                    </Form.Item>
                  </Row >
                </Form >
              </Modal >
            }

            {width < 700 && <Modal bodyStyle={{ height: '75vh', }}
              open={editModal}
              onCancel={() => {
                setEditModal(false);
              }}
              footer={false}
              closable={true}
              destroyOnClose={true}
            >
              <div style={{ fontWeight: "bolder", fontSize: '20px', fontFamily: 'poppins', paddingBottom: '5px' }}> Edit Group Information </div>
              <Form style={{ height: '62vh', overflowY: 'scroll' }}
                id="tst"
                preserve={false}
                layout="vertical"
                onFinish={handleSaveEditGroup}
                initialValues={{
                  grpName: passEditValues?.chatName,
                  description: passEditValues?.chatDescription,
                  grpImage: passEditValues?.groupChatIcon,
                }}
              >
                <Row>
                  <Col style={{ width: "100%" }}>
                    {/* <div>
                      <h2 style={{ fontWeight: "bolder" }}>
                        Edit Group Information
                      </h2>
                    </div> */}
                    <Row>

                      <Col span={14}>
                        <Form.Item
                          name="grpName"
                          label={<div className="grp-name">GROUP NAME</div>}
                        >
                          <Input placeholder="name of the group" />
                        </Form.Item >
                        <Form.Item style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                          name="description"
                          label={
                            <div>
                              <b style={{ fontSize: "16px", fontWeight: "500" }} >
                                DESCRIPTION
                              </ b>
                              {"(optional)"}
                            </div >
                          }
                        >
                          <TextArea
                            className="editGroupScrollBar"
                            placeholder="what's the group about?"
                            rows={2}
                          ></TextArea>
                        </Form.Item >
                      </Col >
                    </Row >
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: 'flex',
                      // width: "128px",
                      height: "128px",
                      margin: "auto",
                    }}
                  >
                    <Form.Item
                      name="grpImage"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Upload
                        listType="picture-card"
                        accept=".png, .jpg, .jpeg"
                        beforeUpload={imgbeforeUploading}
                        fileList={fileLists}
                        onChange={onChange}
                        onPreview={handlePreview}
                      >
                        {fileLists?.length < 1 && "Upload Image"}
                      </Upload>
                    </Form.Item>
                    <Form.Item>

                      <Modal
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="example"
                          style={{
                            width: "100%",
                          }}
                          src={previewImage}
                        />
                      </Modal>
                    </Form.Item>
                  </Col>
                  <Form.Item style={{}}>
                    <Button
                      style={{
                        marginTop: "15px",
                        color: "black",
                        background: "#EFEFEF",
                      }}
                      onClick={handleDiscard}
                    >
                      Discard
                    </Button>
                    <Button
                      style={{
                        marginTop: "15px",
                        marginLeft: "15px",
                        background: " #6FC78F",
                        color: "white",
                      }}
                      htmlType="submit"
                    >
                      Save changes
                    </Button>
                  </Form.Item>
                </Row >
              </Form >
            </Modal >}


            <Modal
              width={400}
              // title='Exit "GroupName" Group'
              centered
              closable={false}
              open={leaveGroupCnfirmModal}
              header={false}
              footer={null}
            // onOk= {confirm}
            // onCancel={() => SetLeaveGroupCnfirmModal(false)}
            >
              <div className={classes.ExitGrpdiv} >
                Exit {openChatWindow?.chatName} Group ?
              </div>
              <div className={classes.closediv}    >
                <div >
                  <Button className={classes.cancelBtn} onClick={onCancelLeaveGroupModal} >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button className={classes.ExitBtn} onClick={confirm}>
                    Exit
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        </>
      )}
    </>
  );
}
export default GrpChatConversation;