import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, LabelList } from 'recharts';
import '../DashboardModules/Participation.css'
import NormalisedHorizontalStackedBar from '../NormalisedHorizontalStackedBar';
import CustomNormalisedHorizontalStackedBar from '../CustomNormalisedHorizontalStackedBar';


const Participation = ({data,colors,eventdata,total,extraParticipationData}) => {

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, overallMaleParticipants, overallFemaleParticipants,overallMalePercentage,overallFemalePercentage ,value} = payload[0].payload;
      console.log(payload,"PART");
      console.log(extraParticipationData,"participation")
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#fff',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px'
          }}
        >
          <div style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '2px',
                    backgroundColor: name==="overallMalePercentage" ?colors[2]: name==="overallFemalePercentage"? colors[3]: "#F2F2F2",
                  }}></div>
          <div style={{display: 'flex', flexDirection:'row', gap: '2px', marginRight: '10px', fontFamily: 'Roboto'}}>
              <div style={{ margin: 0, fontFamily: 'Roboto'}}>{name==="overallMalePercentage"?"Male":name==="overallFemalePercentage"?"Female":"Remaining"}</div>
              <div style={{color:'#616161', fontSize: '14px'}}>{name==="overallMalePercentage"? extraParticipationData[0]?.value:name==="overallFemalePercentage"?extraParticipationData[1]?.value:total-extraParticipationData[0]?.value-extraParticipationData[1]?.value}</div>
          </div>
          <div style={{ margin: 0 , fontWeight: 700}}>{Math.round(value)}%</div>
        </div>
      );
    }
  
    return null;
  };

  const normalizedData = eventdata?.map(event => {
    const totalParticipants = event?.maleParticipantsCount + event?.femaleParticipantsCount;
    console.log(totalParticipants,"VI")
    return {
        name: event.title,
        Male: event.maleParticipantsCount,
        Female: event.femaleParticipantsCount,
        RemainingTotal: total-totalParticipants,
        MalePercentage: event.maleParticipantsCount/total*100,
        FemalePercentage: event.femaleParticipantsCount/total*100
    };
});

const gender = ['Male', 'Female', 'RemainingTotal']

  
    console.log(data,"CHECK")
    // if(data.length!==0){
    //   console.log(data,"CHECK1")
      //const remainingValue = 100 - data[0].value - data[1].value;

// Add the "Remaining" object to the array
    data?.push({ name: 'Remaining', value: 100 - data[0]?.value - data[1]?.value });
    // }
    const newData = data?.filter((item,index,self)=> index === self.findIndex((t)=> t.name === item.name));
    console.log(newData,"CHECK1")
    const COLORS = [colors[2], colors[3], '#F2F2F2'];
    // const pieData = data.map(entry => [
    //   { name: 'Male', value: entry.Male },
    //   { name: 'Female', value: entry.Female },
    //   { name: 'Remaining', value: entry.RemainingTotal }
    // ]);


  //   const participationData = Object.entries(data?.participation ?? {})
  // .filter(([key, value]) => key === 'overallMaleParticipants' || key === 'overallFemaleParticipants')
  // .map(([key, value]) => ({
  //   name: key,
  //   value: value
  // }));

return (
  <div className='ParticipationRate-container'>
    <h2>Participation Rate</h2>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
      <div >
      <PieChart width={250} height={250} style={{width: 'auto'}}>
        <Pie
          data={newData}
          cx="50%"
          cy="50%"
          innerRadius={65}
          outerRadius={105}
          fill="#000000"
          paddingAngle={0}
          dataKey="value"
        
        >
          {data.map((entry, index) => (
            
            <Cell key={`cell-${index}`} fill={COLORS[(index) % COLORS.length]} />
          ))}

        <LabelList dataKey="value" position="center" content={() => <text x="50%" y="47%" fill="#333" textAnchor="middle" dominantBaseline="middle" fontSize="28" className='LabelListClass'>{Math.round(Number(data[0]?.value)+Number(data[1]?.value))}%</text>} className='LabelListClass'/>
        <LabelList dataKey="value" position="center" content={() => <text x="50%" y="57%" fill="#333" textAnchor="middle" dominantBaseline="middle" fontSize="16" className='LabelListClass' >Overall</text>} />

        </Pie>
        <Tooltip content={CustomTooltip}/>
      </PieChart>
      </div>
      <div style={{width:'75%'}}>
        <h3 style={{marginLeft:'60px', color:'#616161', fontFamily:'Roboto'}}>Recent Events</h3>
      {normalizedData?.slice(0,3)?.map((entry, index)=>(
            <div >
              <div style={{marginLeft:'60px'}}>
                <div style={{ display:'flex',flexDirection:'row',justifyContent:'space-between', alignItems:'center'}}>
                <div style={{fontFamily:'Roboto'}}>{entry.name}</div>
                <div style={{fontWeight:'600', fontFamily:'Roboto', marginRight:'30px'}}>{Math.round(((entry.Male+entry.Female)/(entry.Male+entry.Female+entry.RemainingTotal))*100)}%</div>
                </div>
              </div>
            <CustomNormalisedHorizontalStackedBar
            normalizedData={[entry]}
            label={gender}
            color={COLORS}
            />
            </div>
      ))}
     
      </div>
    
    </div>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', fontSize: '14px', marginTop: '-10px'}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px'}}>
          <div style={{width: '12px', height: '12px', borderRadius: '2px', backgroundColor: colors[2]}}></div>
          <p style={{fontSize: '14px', marginBottom: '0px', fontFamily:'Roboto'}}>Male</p>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px'}}>
          <div style={{width: '12px', height: '12px', borderRadius: '2px', backgroundColor: colors[3]}}></div>
          <p style={{fontSize: '14px', marginBottom: '0px', fontFamily:'Roboto'}}>Female</p>
        </div>
      </div>
  </div>
  );
};

export default Participation;