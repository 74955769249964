import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  LabelList,
} from "recharts";
import '../modules/NormalisedBar.css'

// const data = [
//   { name: "Prithvi", male: 400, female: 538, color: "#00C49F" },
//   { name: "Agni", male: 532, female: 500, color: "#FFBB28" },
//   { name: "Vayu", male: 345, female: 500, color: "#FF8042" },
//   { name: "Jal", male: 450, female: 442, color: "#0088FE" },
//   { name: "Akash", male: 500, female: 486, color: "#A28AD5" },
// ];

// const normalizedData = data.map((d) => {
//   const total = d.male + d.female;
//   return {
//     name: d.name,
//     male: (d.male / total) * 100,
//     female: (d.female / total) * 100,
//     color: d.color,
//   };
// });

const NormalisedHorizontalStackedBar = ({normalizedData,label, color, CustomTooltip}) => {
  
  // const CustomTooltip = ({ active, payload, label }) => {
  //   if (active && payload && payload.length) {
  //     const { name, maleCount, femaleCount } = payload[0]?.payload;
  
  //     return (
  //       <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
  //         <p className="label" style={{ fontWeight: 'bold' }}>{`${name}`}</p> 
  //         <p>{`Male: ${maleCount}`}</p>
  //         <p>{`Female: ${femaleCount}`}</p>
  //       </div>
  //     );
  //   }
  
  //   return null;
  // };
  

  return (
   
  <ResponsiveContainer width="100%" height={200}>
    <BarChart
      layout="vertical"
      data={normalizedData}
      barSize={16}
      margin={{
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <XAxis type="number" axisLine={false} tickLine={false} tick={false} />
      <YAxis
        type="category"
        dataKey="name"
        axisLine={false}
        tickLine={false}
        tick={false}
      />
      <Tooltip content={CustomTooltip}/>
      {label?.map((item, index) => (
            <Bar dataKey={label?.[index]} stackId="a" fill={color[index]}>
            {normalizedData?.map((entry, indexes) => (
              <Cell key={`cell-${indexes}`} fill={color[(0+index) % color.length]} />
              
            ))}
            
          </Bar>

      ))}
  
      {/* <Bar dataKey="male" stackId="a" fill="#30ADFF">
        {normalizedData?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill="#30ADFF" />
        ))}
      </Bar>
      <Bar dataKey="female" stackId="a" fill="#008AE6">
        {normalizedData?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill="#008AE6" />
        ))}
      </Bar> */}
    </BarChart>
    
  </ResponsiveContainer>
 
  );
  
};

export default NormalisedHorizontalStackedBar;
