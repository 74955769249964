import classes from "./overviewModal.module.css";
import { useEffect, useState } from "react";
import Anahita from "../../../images/Anahita.jpg";
import hearticon from "../../../images/heart icon.svg";
import redhearticon from "../../../images/redhearticon.svg";
import { Carousel, Button, Popover } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import Api from "services/Api";
import { authActions } from "../store";
import { overViewActions } from "./store";
import SliderImage from "component/SliderImage";
import { bottom } from "@popperjs/core";



const ModalImage = () => {
  const [imageData, setImageData] = useState([]);
  const dispatch = useDispatch();
  const { images, likes } = useSelector(state => state.overView)


  useEffect(() => {
    function init() {
      let temp = [];
      for (let i = 0; i < images?.length; i++) {
        let innertemp = {
          postMedia: images[i].postMedia,
          id: images[i].id,
          isLiked: images[i].liked,
          postLikesCount: images[i].postLikesCount,

          title: images[i].title,
          description: images[i].description,
          privacy: images[i].Public,
          postType: images[i].Event,
          postSubType: images[i].Organiser,
          postLikesCount: images[i].postLikesCount,
          postCommentCount: images[i].postCommentCount,
          fileName: images[i].fileName,
          status: images[i].status,
          videoLink: images[i].videoLink,
          createdAt: images[i].createdAt,
          updatedAt: images[i].updatedAt,
          postLikes: images[i].postLikes,
          createdBy: images[i].createdBy
        }
        temp.push(innertemp)
      }
      setImageData(temp);
    }
    init()
  }, [])

  const updateLikesHandler = (id) => {


  };

  const [reload, setReload] = useState(false);
  const updateLike = (index) => {
    let tmp = imageData;
    if (tmp[index].isLiked) {
      tmp[index].isLiked = false;
      tmp[index].postLikesCount = parseInt(tmp[index].postLikesCount) > 0 ? parseInt(tmp[index].postLikesCount) - 1 : 0
    } else {
      tmp[index].isLiked = true;
      tmp[index].postLikesCount = parseInt(tmp[index].postLikesCount) + 1
    }


    setImageData(tmp);

    // console.log("tmp", tmp[index].liked);


    setReload(!reload)

  }
  useEffect(() => {

  }, [reload]);

  // const = (
  //   <div>
  //     <p>Names</p>
  //     <p>Names</p>
  //   </div>
  // );
  // const content = (
  //   <div>
  //     <p>222222</p>
  //     <p>333333</p>
  //   </div>



  return (
    <>
      {imageData?.map((img, index) => {
        return (
          <div className={classes.imageControlImages}
            key={img.id}>
            {img?.postMedia?.length > 1 && <Carousel dots={true} autoplay={true} style={{ height: "140px", width: "13vw", borderRadius: "6px", }}>

              {img?.postMedia.map(el => {
                return (
                  <img
                    src={el}
                    alt="image"
                    height="140px"
                    width="13vw"
                    style={{ borderRadius: "6px",}}
                  />
                )
              })
              }
            </Carousel>}

            {img?.postMedia?.length === 1 && img?.postMedia.map(el => {
              return (
                <img className={classes.imgsrcsingle}
                  src={el}
                  alt="image"
                // height="140px"
                width="13vw"
                // style={{ borderRadius: "6px", }}
                />
              )
            })
            }
            {/* {img.postMedia.map(el => {
                
            })} */}

            <div className={classes.heartIcon}>
              <img style={{ cursor: 'pointer'}} src={img.isLiked ? redhearticon : hearticon}
                onClick={() => {
                  dispatch(overViewActions.likesList(!likes))
                  updateLikesHandler(img.id);
                  updateLike(index);
                  return new Promise((resolve, reject) => {
                    Api.post("/ibelong-posts/like").params({ postId: img?.id }).send((response, error) => {
                      console.log("minus", response)
                      // if (callBack) {
                      //   callBack();
                      // }
                      resolve();
                      if (response) {
                        // dispatch(response());
                        // dispatch(overViewActions.imageList(!imageData))
                        // overViewActions.imageList(imageData)
                      }
                    });
                  });

                }}
                width="22px"
                height="24px"
                alt="hearticon"
              ></img>
              <Popover >
                <h3>
                  Liked by  <span style={{ marginLeft: "5px" }}>
                    {img?.postLikesCount > 0 ? img?.postLikesCount : 0}
                  </span>

                </h3>
              </Popover>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ModalImage;
