import classes from "../Pages/users.module.css";
import React, { useState } from "react";
import { Switch, Modal, Space } from "antd";
import "antd/dist/antd.css";
import { Table, Button, Spin, Row } from "antd";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import backLogo from "../../images/backLogo.png";
import UserProfileDetails from "./userProfileDetails/userProfileDetails";
import { useLocation } from "react-router-dom";
import Api from "services/Api";
import { Typography } from "antd";
import PdfLogo from "../../images/pdf-logo.svg";
import PlayVideo from "./events/playVideo";
import { DownloadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useWindowDimensions } from "../windowDimention/windowDimension";
import { RightOutlined, DownOutlined } from "@ant-design/icons";

const EventFile = ({ record, setChecked, setPlay, checked }) => {
  if (record?.event?.fileType === null || record?.event?.fileType === "none") {
    return <p>N/A</p>;
  }
  let disabled = false;

  if (record?.postMedia?.[0] && record?.event?.fileType === "applicationkk") {
    return (
      <>
        {record?.postMedia?.map((element) => {
          return (
            <img
              onClick={() => window.open(element)}
              src={PdfLogo}
              // className="pdf-logo"
              style={{ height: "30px", width: "50px", cursor: 'pointer' }}
            />
          );
        })}
      </>
    );
  } else if (record?.postMedia?.[0] && record?.event?.fileType === "image") {
    return (
      <>
        {record.postMedia?.map((element) => {
          return (
            <img
              onClick={() => window.open(element)}
              src={element}
              // className="pdf-logo"
              style={{ height: "30px", width: "50px", cursor: 'pointer' }}
            />
          );
        })}
      </>
    );
  } else if (record?.postMedia?.[0] && record?.event?.fileType === "video") {
    return (
      <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: "1rem" }}>
        {record?.postMedia.map((element) => {
          return (
            <Space>
              <Button
                onClick={() => {
                  // setPlay(record?.media)
                  setChecked(!checked);
                  setPlay(element);
                }}
              >
                Play
              </Button>
              <Button onClick={() => window.open(element)}>
                <DownloadOutlined />
              </Button>
            </Space>
          );
        })}
      </div>
    );
  }

  return <p>N/A</p>;
};

export const EventDetailsMob = ({ item, setChecked, setPlay, checked }) => {
  const [showDetails, setShowDetails] = useState(false);
  console.log("ITEMMMM", item);
  return (
    <>
      {item?.event?.title && <div>
        <div
          className={classes.eachEventMob}
          onClick={() => {
            setShowDetails((prev) => !prev);
          }}
        >
          <span>{item?.event?.title}</span>
          {!showDetails && (
            <RightOutlined
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                color: "grey",
              }}
            />
          )}
          {showDetails && (
            <DownOutlined
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                color: "grey",
              }}
            />
          )}
        </div>

        {showDetails && (
          <div className={classes.restEventMob}>
            <div>
              <span>Date</span>
              <span>{item.event.actualDate}</span>
            </div>

            <div>
              <span>Events Description</span>
              <Typography.Paragraph ellipsis={{ tooltip: true, rows: 10 }}>
                <span>{item.event.description}</span>
              </Typography.Paragraph>
            </div>

            <div>
              <span>Event Stage Reached</span>
              <span>{item?.stage ? item?.stage : "NA"}</span>
            </div>

            <div>
              <span>Files</span>
              <EventFile
                setChecked={setChecked}
                setPlay={setPlay}
                record={item}
                checked={checked}
              />
            </div>
          </div>
        )}
      </div>}
    </>
  );
};

const UserProfile = () => {
  const [details, setDetails] = useState("");
  const [event, setEvent] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [active, setActive] = useState(true);
  const [ellipsis, setEllipsis] = useState(true);
  const { Paragraph, Text } = Typography;
  const [playVideomedia, setPlay] = useState("");
  const [checked, setChecked] = useState(false);
  const history = useHistory();
  const { year } = useSelector((state) => state.auth);
  const { height, width } = useWindowDimensions();

  const columns = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "event",
      render: (text, record) => {
        return (
          <NavLink
            to={{
              pathname: "/event-details",
              state: { data: record },
            }}
          >
            <p className={classes.colItem}>{record?.event?.title}</p>
          </NavLink>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => {
        return (
          <p className={classes.colItem}>
            {record?.event?.actualDate
              ? record?.event?.actualDate
              : record?.event?.startDate}
          </p>
        );
      },
    },
    {
      title: "Events Description",
      dataIndex: "description",
      key: "description",
      width: 400,
      render: (text, record) => {
        return (
          // <p className={classes.colItem}>
          <Paragraph
            className="scroll"
            style={{
              marginTop: "auto",
              minHeight: "40px",
              maxHeight: "70px",
              overflowY: "scroll",
            }}
            ellipsis={
              ellipsis
                ? {
                  rows: 2,
                  expandable: true,
                  symbol: "more",
                  fontSize: 16,
                }
                : false
            }
          >
            {/* {"Paragraphs can contain many different kinds of information. A paragraph could contain a series of brief examples or a single long illustration of a general point. It might describe a place, character, or process; narrate a series of events; compare or contrast two or more things; classify items into categories; or describe causes and effects. Regardless of the kind of information they contain, all paragraphs share certain characteristics. One of the most important of these is a topic sentence."} */}
            {record?.event?.description}
          </Paragraph>
        );
      },
    },

    {
      title: "Event Stage Reached",
      key: "stage",
      dataIndex: "stage",
      render: (text) => {
        return <p className={classes.colItem}>{text}</p>;
      },
    },
    {
      title: "Files",
      dataIndex: "files",
      key: "files",
      render: (text, record) => {
        return (
          <EventFile
            setChecked={setChecked}
            setPlay={setPlay}
            record={record}
            checked={checked}
          />
        );
      },
    },
  ];

  let location = useLocation();
  // console.log(location)
  const [filterEvent, setFilterEvent] = useState([]);

  let values;
  if (location.state !== null) {
    values = location?.state?.data;
  }

  useEffect(() => {
    Api.get(`/ibelong-user/userMasterView?userId=${values?.id}`)
      .params({ queryYear: year })
      .send((res) => {
        // console.log("recieved",res)
        setEvent(res?.userParicipatedEvents?.items);
        setDetails(res?.user);

        const eventfiltered = res?.userParicipatedEvents?.items.filter(
          (element) => element.stage !== "NotInterested"
        );
        setFilterEvent(eventfiltered);
      });
  }, []);

  // setTimeout(() =>{
  //   console.log(event)
  // },[3000])

  // const checkHandler=()=>{
  //   setChecked(false);
  //   setModalOpen(true);
  // }

  // let switchStyle;
  // if (active)
  // {
  //   switchStyle = { backgroundColor: '#6fc78ec9' };

  // }
  // else
  // {
  //   switchStyle = { backgroundColor:'#f3f6fa',border:'1.5px solid #222222'};

  // }

  return (
    <>
      {width > 700 && (
        <div>
          {details === "" ? (
            <Row justify="center" style={{ marginTop: "100px" }}>
              <Spin size="large" style={{ alignSelf: "center", height: "10vh" }} />
            </Row>
          ) : (
            <div className={classes.dashboard} style={{ height: "100%" }}>
              <div className={classes["profile-header"]} style={{ margin: "0.5rem 0" }}>
                <div onClick={() => { history.goBack(); }} style={{ cursor: "pointer" }} >
                  <img src={backLogo} alt="back" />
                </div>
                <h1>Associate Details</h1>
              </div>

              <UserProfileDetails record={filterEvent} detailed={details} />
              <main className={classes.main} style={{ minHeight: "40vh" }}>
                <div className={classes.tableHeader}>
                  <h1 style={{ marginTop: "1rem" }}>Participated Events</h1>
                </div>
                {/* <Table columns={columns} dataSource={filterEvent}  /> */}
                <Table
                  columns={columns}
                  dataSource={filterEvent}
                  rowClassName={classes.tableStyle}
                  style={{ fontFamily: "poppins", fontSize: "16px" }}
                  pagination={{
                    total: filterEvent?.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} events`,
                  }}
                />
              </main>
            </div>
          )}
          <PlayVideo details={playVideomedia} checked={checked} />
        </div>
      )}
      {width < 700 && (
        <div>
          {details === "" ? (
            <Row justify='center' style={{ width: '100vw', height: '100vh', }}><Spin size='large' style={{ display: "flex", justifyContent: "center", alignItems: 'center' }} /></Row>
          ) : (
            <div className={classes.userProfileMob}>
              <div className={classes.mobProfTitle}>
                <div
                  onClick={() => {
                    history.goBack();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img src={backLogo} alt="back" />
                </div>
                <h1>Associate Details</h1>
              </div>

              <UserProfileDetails record={filterEvent} detailed={details} />
              <h3
                style={{
                  margin: "1.5rem 0 0.5rem 0",
                  fontSize: "1rem",
                  fontWeight: "700",
                  fontFamily: "poppins",
                }}
              >
                Participated Events
              </h3>
              <div className={classes.eventDetailsMob}>
                {filterEvent?.map((item, index) => {
                  return (
                    <EventDetailsMob
                      item={item}
                      key={index}
                      checked={checked}
                      setChecked={setChecked}
                      setPlay={setPlay}
                    />
                  );
                })}
                <PlayVideo details={playVideomedia} checked={checked} setchecked={setChecked} />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default UserProfile;
