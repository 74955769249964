import { Col, Row, Typography } from "antd";
import LocationDropdown from "component/LocationDropdown";
import YearDropdown from "component/YearDropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./description.css";
import Slider from "react-slick";
import { useWindowDimensions } from "../../../windowDimention/windowDimension";
import Api from "services/Api";
import { authActions } from "../../store";

//const {membersParticipated,totalWinners} =useSelector(state => state.house);
const Cards = ({ type, title, count, props }) => {
  return (
    <div className="house-details-container">
      <div
        className="house-details"
        style={{
          backgroundImage: `url(${props.url})`,
          backgroundColor: props.data.color,
        }}
      >
        <div className="house-details-content">
          <div className="house-details-content-heading">{title}</div>
          <div className="house-team-count">{count}</div>
        </div>
      </div>
    </div>
  );
};

const Cardcomp = (props) => {
  const { height, width } = useWindowDimensions();
  const locationArr = ["All", "Bangalore", "Coimbatore"];
  const [showHouseCard, setShowHouseCard] = useState("member_participated");
  const { year } = useSelector(state => state.auth);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [captains,setCaptains]=useState(false);
  const { membersParticipated, totalWinners, housePoint } = useSelector(
    (state) => state.house
  );
  const {house} = useSelector(state =>state.auth)
  const {user}=useSelector(state=>state.auth);

  let houseCardStyle = { marginBottom: "30px",width:"100%"};
  if (width < 900) {
    houseCardStyle = {
      ...houseCardStyle,
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: width > 900 ? "1.5rem" : "0",
    };
  }

  console.log("showHouseCard", house);
  let allCards = [
    <Cards
      title={"MEMBERS PARTICIPATED"}
      count={membersParticipated}
      props={props}
    />
    ,
    <Cards title={"TOTAL POINTS"} count={housePoint} props={props} />,
    <Cards title={"TOTAL WINNERS"} count={totalWinners} props={props} />
  ]


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,


  };

  return (
    <div style={houseCardStyle} >
      {width > 900 && (
        <Row style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <YearDropdown></YearDropdown>
          <LocationDropdown locationArr={locationArr} />
        </Row>
      )}
      <Row
        style={{
          width: "100%",
          display: "flex",
          justifyContent: width > 900 ? "space-between" : "center",
          marginBottom: 30,
          alignItems: "center",
        }}
      >

        {width < 700 &&
          <div className="sliderHouse">
            <Slider {...settings} autoplay={true} autoplaySpeed={3000} style={{ width: "90%", margin: "1rem" }}>
              {allCards.map((item, index) => {
                return (
                  <div  >
                    {item}
                  </div>
                )
              })}

            </Slider>
          </div>
        }


        {width > 700 && (
          <Cards
            title={"MEMBERS PARTICIPATED"}
            count={membersParticipated}
            props={props}
          />
        )}
        {width > 700 && (
          <Cards title={"TOTAL POINTS"} count={housePoint} props={props} />
        )}
        {width > 700 && (
          <Cards title={"TOTAL WINNERS"} count={totalWinners} props={props} />
        )}

        <Col
          span={width > 1000 ? 6 : 22}
          style={{
            height: "159px",
            backgroundColor: props.data.color,
            borderRadius: "7px",
            boxShadow: "0px 3px 6px #00000014",
          }}
        >
          <div
            style={{
              width: "97%",
              height: "100%",
              background: `url(${props.url})`,
              backgroundRepeat: "repeat",
              backgroundSize: "100%",
              textAlign: "left",
              padding: "10px",
              position: "absolute",
              margin: "0px 0px 12px 12px",
            }}
          >
            {/* <div> */}
              {/* <h1
                style={{
                  textTransform: "lowercase",
                  //color:hcolor,
                  //color:'#CAE0D2',
                }}
              > */}
                <b style={{fontSize:"1.5rem"}}>{props?.data?.name} </b>
              {/* </h1> */}
            {/* </div> */}
            <div>
              <h4>
                {" "}
                {"  "} <b> Captain :</b>
                <Typography.Text
                  ellipsis={{ tooltip: true }}
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "poppins",
                    width: 120,
                  }}
                >
                  {" "}
                  {props.data.captain}
                </Typography.Text>
              </h4>
              <h4 style={{}}>
                {"  "}
                <b> Vice Captain :</b>{" "}
                <Typography.Text
                  ellipsis={{ tooltip: true }}
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "poppins",
                    width: 120,
                  }}
                >
                  {props.data.viceCaptain}
                </Typography.Text>
              </h4>
              <h4 style={{}}>
                {"  "}
                <b> Members :</b> {props?.data?.team}
              </h4>
            </div>
          </div>
        </Col>
      </Row>
      {/* {width < 900 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "0 auto",
            width: "100%",
            padding: "0.5rem 0",
          }}
        >
          {showHouseCard === "member_participated" && (
            <Cards
              title={"MEMBERS PARTICIPATED"}
              count={membersParticipated}
              props={props}
            />
          )}
          {showHouseCard === "total_points" && (
            <Cards title={"TOTAL POINTS"} count={housePoint} props={props} />
          )}
          {showHouseCard === "total_winners" && (
            <Cards title={"TOTAL WINNERS"} count={totalWinners} props={props} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.8rem",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <button
              style={{
                width: "14px",
                height: "14px",
                border: 0,
                borderRadius: "50%",
                backgroundColor:
                  showHouseCard === "member_participated"
                    ? "#000000"
                    : props.data.color,
                cursor: "pointer",
              }}
              onClick={() => setShowHouseCard("member_participated")}
            ></button>
            <button
              style={{
                width: "14px",
                height: "14px",
                border: 0,
                borderRadius: "50%",
                backgroundColor:
                  showHouseCard === "total_points"
                    ? "#000000"
                    : props.data.color,
                cursor: "pointer",
              }}
              onClick={() => setShowHouseCard("total_points")}
            ></button>

            <button
              style={{
                width: "14px",
                height: "14px",
                border: 0,
                borderRadius: "50%",
                backgroundColor:
                  showHouseCard === "total_winners"
                    ? "#000000"
                    : props.data.color,
                cursor: "pointer",
              }}
              onClick={() => setShowHouseCard("total_winners")}
            ></button>
          </div>
        </div>
      )} */}
      {width < 900 && (
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "right",
            marginTop: "1rem",
            padding: "0 1rem",
          }}
        >
          <YearDropdown></YearDropdown>
          <LocationDropdown locationArr={locationArr} />
        </Row>
      )}
      <hr style={{ backgroundColor: "1px solid #F9F9F9", opacity: 0.3 }}></hr>
    </div>
  );
};

export default Cardcomp;
