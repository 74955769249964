// import React from 'react';
// import '../DashboardModules/Gender.css';

// const Gender = ({ data , colors}) => {
//   const total = data.reduce((acc, item) => acc + item.value, 0);

//   return (
//     <div className="gender-chart">
//       <h2>Gender</h2>
//       <div className="chart-container">
//         <svg viewBox="0 0 36 36" className="donut-chart">
//           {data.map((item, index) => {
//             const { name, value } = item;
//             const cumulativeValue = data.slice(0, index).reduce((acc, curr) => acc + curr.value, 0);
//             const offset = (cumulativeValue / total) * 100;
//             const percentage = (value / total) * 100;
//             const color = name === 'Male' ? colors[2] : colors[3];

//             return (
//               <circle
//                 key={index}
//                 className="donut-segment"
//                 cx="18"
//                 cy="18"
//                 r="15.91549431"
//                 fill="transparent"
//                 stroke={color}
//                 strokeWidth="5"
//                 strokeDasharray={`${percentage} ${100 - percentage}`}
//                 strokeDashoffset={-offset}
//               />
//             );
//           })}
//         </svg>
//         <div className="center-text">
//           <span className="total">{total}</span>
//           <span className="description">Total active members</span>
//         </div>
//       </div>
//       <div className="legend">
//         {data.map((item, index) => (
//           <div key={index} className="legend-item">
//             <span
//               className="legend-color"
//               style={{ backgroundColor: item.name === 'Male' ? colors[2] : colors[3] }}
//             ></span>
//             <span className="legend-label">{item.name}</span>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Gender;
import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend,   LabelList
} from 'recharts';
import '../DashboardModules/Gender.css'

const COLORS = ['#4ADA7B', '#25B655', '#197B3A', '#29854D', '#195F2B'];

const Gender = ({data,colors, totalActiveMembers, totalGenderCount}) => {
  
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0].payload;
  
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#fff',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px'
          }}
        >
          <div style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '2px',
                    backgroundColor: name==="Male" ?colors[2]: colors[3],
                  }}></div>
          <div style={{display: 'flex', flexDirection:'row', gap: '2px', marginRight: '10px', fontFamily: 'Roboto'}}>
              <div style={{ margin: 0, fontFamily: 'Roboto'}}>{name}</div>
              <div style={{color:'#616161', fontSize: '14px'}}>{Math.round(value/totalGenderCount*100)}%</div>
          </div>
          <div style={{ margin: 0 , fontWeight: 700}}>{value}</div>
        </div>
      );
    }
  
    return null;
  };
  return(

  <div className='GenderChart-container'>
    <h2>Gender</h2>
  <div>
  <PieChart width={260} height={200}>
    <Pie
      data={data}
      cx="50%"
      cy="50%"
      innerRadius={55}
      outerRadius={95}
      fill="#000000"
      paddingAngle={0}
      dataKey="value"
      className='GenderPie'
    >
      {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={colors[(index+2) % colors.length]} />
      ))}
    <LabelList dataKey="value" position="center" content={() => <text x="50%" y="47%" fill="#333" textAnchor="middle" dominantBaseline="middle" fontSize="24" className='LabelListClass'>{totalActiveMembers} </text>} />
    <LabelList dataKey="value" position="center" content={() => <text x="50%" y="55%" fill="#333" textAnchor="middle" dominantBaseline="middle" fontSize="10" className='LabelListClass' >Total Active</text>} />
    <LabelList dataKey="value" position="center" content={() => <text x="50%" y="60%" fill="#333" textAnchor="middle" dominantBaseline="middle" fontSize="10" className='LabelListClass' > members</text>} />



    </Pie>
    <Tooltip content={CustomTooltip}/>
    {/* <Legend
      formatter={(value, entry, index) => <span className="text-color-class">{value}</span>}
    /> */}
  </PieChart>
  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', fontSize: '14px', marginTop:'30px'}}>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px'}}>
      <div style={{width: '12px', height: '12px', borderRadius: '2px', backgroundColor: colors[2]}}></div>
      <p style={{fontSize: '14px', marginBottom: '0px', fontFamily:'Roboto'}}>Male</p>
    </div>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px'}}>
      <div style={{width: '12px', height: '12px', borderRadius: '2px', backgroundColor: colors[3]}}></div>
      <p style={{fontSize: '14px', marginBottom: '0px', fontFamily: 'Roboto'}}>Female</p>
    </div>
  </div>
  </div>
  </div>
);
}

export default Gender;
