import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import authReducer from "../src/component/Pages/store";
import overViewreducer from "./component/Pages/OverView/store";
import chatReducer from "./component/Pages/chats/chatStore";
import { saveToLocalStorage, loadFromLocalStorage } from "services/Storage";
import housereducer from "./component/Pages/houses/store";
// add all reducers here
// import counterReducer from "./pages/counter/redux";
const preloadedState = loadFromLocalStorage();
const store = configureStore({
  reducer: {


    overView:overViewreducer,
    auth:authReducer,
    house:housereducer,
    chat:chatReducer

    // counter: counterReducer,

  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),preloadedState,
});
store.subscribe(() => saveToLocalStorage(
  { auth: store.getState().auth  }
));
window.store = store;
export default store;
