import classes from './viewGroupModal.module.css';
import Api from 'services/Api';
import React,{useEffect} from 'react';
import { authActions } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import profileIcon from "../../../images/profileIcon.jpg"


const ViewGroupModal=(props)=>{

    const dispatch=useDispatch();
    const {viewGroupInfo}=useSelector(state=>state.auth);
    // console.log("GROUP INFO",viewGroupInfo,props);
    useEffect(() =>{
      Api.get(`/ibelong-events/getGroupUsers/?groupId=${props.id}`)
      .send(res =>{
        if(!res)
        {
            console.log("SOME ERROR OCCURED");
        }
        else
        {
                           dispatch(authActions.updateViewGroupInfo(res));
            // console.log("THIS IS RESPONSE:",res);
        }

      })
    },[props.id])

    // console.log(props?.groupName)
    return(
    <div style={{height:"540px",width:"90%"}} > 
        <h1 style={{fontSize:"24px", fontFamily:"poppins",color:"#161616", fontWeight:"bolder"}}>{props?.groupName}</h1>
        <h3 style={{fontFamily:"poppins", fontSize:"16px", color:"#000000"}}>Team Member Name/Employee ID</h3 >

        <div style={{height:"80%",width:"500px",overflowY:"scroll",paddingLeft:"0.7rem", marginTop:"1rem"}} className={classes.scroll}>
           {viewGroupInfo?.map((info)=>(
                <div className={classes.groupInfo} style={{display:'flex',flexDirection:"row"}}>
                    <img src={info.profileImage===null?profileIcon:info.profileImage} alt="" style={{width:"50px",height:"50px",borderRadius:"50%"}}></img>
                    <div>
                        <div style={{fontWeight:"bold", minWidth:"20vw", }}>{info.name}</div>
                        <p style={{fontSize:"12px"}}>{info.uid}</p>
                    </div>

                </div>
            ))}

        </div>

    </div>
    )
}

export default ViewGroupModal;

