import { Button, Col, Form, Input, Modal, Row, Select, Upload, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useState, useEffect } from "react";
import Api from "services/Api";
import Notification from "services/Notification";
import "./ChatCreateGrp.css";
import { chatActions } from "./chatStore";
import { useDispatch, useSelector } from "react-redux";
import uploadImage from "../../../images/uploadImage.svg";
import { UploadOutlined } from '@ant-design/icons';
import x from '../../../images/x.svg'
const { Option } = Select;
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}
function ChatCreategrp({ grpModal, setgrpModal }) {
    const [grpCreate, setGrpCreate] = useState({});
    const { user } = useSelector(state => state.auth)
    const { allUser, grpChatPerson, grpSelected, allChatToggle } = useSelector(state => state.chat)
    const [grpAddmemberModal, setgrpAddmemberModal] = useState(false);
    const [page, setPage] = useState(0);
    const dispatch = useDispatch();
    const fileList = [];
    const { width } = useWindowDimensions();

    const imgbeforeUploading = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
            return isJpgOrPng || Upload.LIST_IGNORE
        }
    }
    const createGrpHandler = (val) => {
        if (val?.grpMember?.length === 0 || val?.grpMember === undefined) {
            Notification.error("please select participants", "error")
            return;
        }
        let data = {}
        if (grpCreate?.groupName) {
            data = { ...data, chatName: grpCreate?.groupName }
        }
        if (grpCreate?.description) {
            data = { ...data, chatDescription: grpCreate?.description }
        }
        if (val?.grpMember) {
            data = { ...data, participants: val?.grpMember }

        }
        if (grpCreate?.grpImage) {
            data = { ...data, groupChatIcon: grpCreate?.grpImage?.fileList[0]?.originFileObj }
        }
        Api.post("/ibelong-chat/createGroupChat").upload(data, (per, res) => {
            if (res !== undefined) {
                dispatch(chatActions.updateGrpSelected(true))
                dispatch(chatActions.updateAllChatToggle(!allChatToggle))
                dispatch(chatActions.updateChatWindow(false));
                dispatch(chatActions.updateOpenChatWindow(null));
            }
        })
        setgrpModal(false);
    }
    const onFinishForm = (val) => {
        setGrpCreate(val); setPage((page) => page + 1)
    }

    return (
        <div>
            {width > 700 && <>
                <Modal open={grpModal} footer={false} onCancel={() => { setgrpModal(false) }}>
                    {page === 0 ?
                        <div key={Math.random()} className="createGrp-containerMob">
                            <div className="creategrp-heading">Create Group</div>
                            <div style={{ color: "#a5a5a5" }}>Group are where your team communicates. They’re best when organized around a group name — developers team, for example.</div>
                            <Form layout='vertical' onFinish={onFinishForm} >
                                <Row>
                                    <Col span={14}>
                                        <Form.Item
                                            label="Group Name"
                                            name="groupName"
                                            className={"text-left"}
                                            rules={[{ required: true },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (getFieldValue('groupName').trim().length === 0) {
                                                        return Promise.reject('Enter group name!');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                            ]}
                                        >
                                            <Input id="test" placeholder="Enter Group Name" maxLength={90} />
                                        </Form.Item>
                                        <Form.Item name="description" label={<div><b className="grp-name">Description</b> {"(optional)"}</div>} >
                                            <TextArea placeholder="what's the group about?" rows={2}></TextArea>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} offset={2} style={{ height: "26vh", width: "20vw", }}>
                                        <Form.Item name="grpImage" style={{ display: 'flex', justifyContent: "center", alignItems: 'center', height: "150px" }}>
                                            <Upload
                                                maxCount={1}
                                                listType="picture"
                                                defaultFileList={[...fileList]}
                                                className="upload-list-inline"
                                                beforeUpload={imgbeforeUploading}
                                                accept=".jpg,.png,.jpeg"
                                                onPreview={() => false}
                                            >
                                                <Button icon={<UploadOutlined />}>Upload</Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button type="submit" className="creategrpBtn">CREATE</button>
                                </Form.Item>
                            </Form>
                        </div> : ""}
                    {page === 1 ?
                        <div key={Math.random()} className="createGrp-container" >
                            <div className="creategrp-heading" >{grpCreate?.groupName}</div>
                            <Form layout="vertical" onFinish={(val) => { createGrpHandler(val) }}>
                                <Form.Item name="grpMember" label={<div style={{ font: "normal normal normal 16px/25px Poppins" }}>Add people to the group</div>} >
                                    <Select className="selectUser" mode="multiple" listHeight={90} showSearch allowClear optionFilterProp="children" placeholder="please select" style={{ width: '30vw' }}>
                                        {
                                            allUser?.map((val, id) => {
                                                if (user?.id !== val?.userId) {
                                                    return (
                                                        <Option value={val?.userId} key={id}  >
                                                            {val?.userName}
                                                        </Option>
                                                    )
                                                }
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item style={{ display: "flex", justifyContent: 'flex-end' }}>
                                    <button type="submit" className="creategrpBtn">ADD</button>
                                </Form.Item>
                            </Form>
                        </div> : ""
                    }
                </Modal>
            </>}
            {width < 700 && (
                <div className="custom-modal-container" style={{ padding: '0px 18px 0px 18px', width: '95%', height: page === 1 ? '35vh' : '60vh', zIndex: '1000', position: 'fixed', top: '53%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: 'white', display: 'flex', boxShadow: page === 1 ? 'rgba(22, 23, 23, 0.77) 0px 0px 15px 272px' : 'rgba(22, 23, 23, 0.77) 0px 0px 15px 203px', borderRadius: '4px', overflow: 'auto' }} >
                    {page === 0 && (<div className="createGrp-containerone">
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="creategrp-heading">Create Group</div>
                                <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={() => setgrpModal(false)} />
                            </div>
                            <div style={{ color: "#a5a5a5" }}>
                                Groups are where your team communicates. They’re best when organized around a group name — developers team, for example.
                            </div>
                        </div>
                        <Form style={{ overflow: 'scroll', height: '100vh', display: 'flex', flexDirection: 'column', }} layout='vertical' onFinish={onFinishForm}>
                            <div style={{ width: '100%', display: 'flex', height: '100vh', justifyContent: 'space-between', padding: '0px 30px 0px 0px' }}>
                                <div style={{ width: '60%', }}>
                                    <Form.Item
                                        label="Group Name"
                                        name="groupName"
                                        className="text-left"
                                        rules={[
                                            { required: true },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (getFieldValue('groupName').trim().length === 0) {
                                                        return Promise.reject('Enter group name!');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input id="test" placeholder="Enter Group Name" maxLength={90} />
                                    </Form.Item>
                                    <Form.Item name="description" label={<div><b className="grp-name">Description</b> (optional)</div>}>
                                        <TextArea placeholder="What's the group about?" rows={2} />
                                    </Form.Item>
                                </div>
                                <div style={{ width: '30%', }}>
                                    <Form.Item name="grpImage" style={{ display: 'flex', justifyContent: "center", alignItems: 'center', height: "150px" }}>
                                        <Upload
                                            maxCount={1}
                                            listType="picture"
                                            defaultFileList={[...fileList]}
                                            className="upload-list-inline"
                                            beforeUpload={imgbeforeUploading}
                                            accept=".jpg,.png,.jpeg"
                                            onPreview={() => false}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Form.Item>
                                </div>
                            </div>
                            <Form.Item style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', }}>
                                <button type="submit" className="creategrpBtn">CREATE</button>
                            </Form.Item>
                        </Form>
                    </div>
                    )}
                    {page === 1 && (<div className="createGrp-containerMob" >
                        <div style={{ marginTop: '-10px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="creategrp-heading" >{grpCreate?.groupName}</div>
                            <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={() => setgrpModal(false)} />
                        </div>
                        <Form layout="vertical" onFinish={val => createGrpHandler(val)}>
                            <Form.Item
                                name="grpMember"
                                label={<div style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'poppins' }}>Add people to the group</div>}
                            >
                                <Select
                                    className="selectUser"
                                    mode="multiple"
                                    listHeight={90}
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    placeholder="Please select"
                                    style={{ width: '45vw' }}
                                >
                                    {allUser?.map((val, id) => {
                                        if (user?.id !== val?.userId) {
                                            return (
                                                <Option value={val?.userId} key={id}>
                                                    {val?.userName}
                                                </Option>
                                            );
                                        }
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item style={{ display: "flex", justifyContent: 'flex-end' }}>
                                <button type="submit" className="creategrpBtn">ADD</button>
                            </Form.Item>
                        </Form>
                    </div>
                    )}
                </div>
            )}

        </div>
    )
}

export default ChatCreategrp;