import React from 'react';
import { Progress } from 'antd';
import 'antd/dist/antd.css'; // Import Ant Design styles
import '../modules/ProgressBar.css'; // Import custom styles
import HouseName from './HouseNames';

const ProgressBar = ({numerator, denominator, percentage, color}) => {
  return (
    <div style={styles.container}>
    
        <div style={styles.right}>
      <div style={styles.numeratorDenominator}>
        <p style={{fontFamily: 'Roboto'}}>{numerator}/{denominator}</p>
        <p style={{fontFamily: 'Roboto'}}>{percentage}%</p>
      </div>

      <div style={styles.progressBarContainer}>
        <Progress
          percent={percentage}
          showInfo={false}
          strokeColor={color}
          trailColor="#e0e0e0"
          className="custom-progress"
        />
      </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#FFF',
    borderRadius: '4px',
    /*boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',*/
    width: '100%',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  square: {
    width: '12px',
    height: '12px',
    marginRight: '10px',
    borderRadius: '2px',
  },
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#424242',
  },
  numeratorDenominator: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#757575',
    marginRight: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    height: '16px'
  },
  progressBarContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  percentage: {
    position: 'absolute',
    right: '0',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#757575',
    marginLeft: '10px',
  },
  right: {
    width: '100%',

  }
  
};

export default ProgressBar;
