import { Col, Row, Divider, Typography, Pagination } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import Api from "services/Api";
import "./eventCaptian.css";
import { useHistory } from "react-router-dom";
import { authActions } from "../store";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Spin } from "antd";
import userEvent from "@testing-library/user-event";
import { useSelector } from "react-redux";
import { overViewActions } from "../OverView/store";
import { useWindowDimensions } from "../../windowDimention/windowDimension";
const { Paragraph, Text } = Typography;

export const EventDataMob = ({
  ellipsis,
  setEllipsis,
  data,
  getDate,
  history,
  user,
}) => {
  const date = getDate(data?.createdAt);
  let statusColor;
  if (data?.status === "Active") statusColor = "#13e694";
  else statusColor = "#f68452";

  if (data?.status === "Active") {
    return (
      <div
        className="eventCard" 
        onClick={() => {
          if (data?.status === "Active") {
            history.push({ pathname: "/event-details", state: { data } });
          } else {
            if (
              user?.role === "SuperAdmin" ||
              user?.privilege === "Admin" ||
              user?.privilege === "EventOrganisers"
            ) {
              history.push({ pathname: "/event-details", state: { data } });
            }
          }
        }}
      >
        <div>
          {data?.media.length === 0 ? (
            <div></div>
          ) : (
            <img
              src={data?.media[0]}
              alt="img"
              style={{
                width: "100%",
                height: "180px",
                border: "3px solid white",
                borderRadius: "4px",
              }}
            />
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column", height: "auto",cursor:"pointer" }}>
          <div>
            <div className="title-div">
              <Typography.Text
                style={{ fontSize: "18px", fontWeight: "650", width: "10rem" }}
                ellipsis={{ tooltip: true }}
              >
                {data?.title}
              </Typography.Text>

              <div>
                <span>{date} </span>
                <span style={{ color: statusColor }}>{data?.status}</span>
              </div>
            </div>
            <Paragraph
              className="eventDescription"
              ellipsis={
                ellipsis
                  ? { rows: 2, expandable: true, symbol: "more", fontSize: 16 }
                  : false
              }
            >
              {data?.description}
            </Paragraph>
          </div>
        </div>
      </div>
    );
  }

  else if (
    data?.status === "InActive" &&
    (user?.role === "SuperAdmin" ||
      user?.privilege === "Admin" ||
      user?.privilege === "EventOrganisers")
  ) {


    return (
      <div className="eventCard"
        onClick={() => {
          if (data?.status === "Active") {
            history.push({ pathname: "/event-details", state: { data } });
          } else {
            if (
              user?.role === "SuperAdmin" ||
              user?.privilege === "Admin" ||
              user?.privilege === "EventOrganisers"
            ) {
              history.push({ pathname: "/event-details", state: { data } });
            }
          }
        }}
      >
        <div>
          <div>
            {data?.media.length === 0 ? (
              <div></div>
            ) : (
              <img
                src={data?.media[0]}
                alt="img"
                style={{
                  width: "100%",
                  height: "180px",
                  border: "3px solid white",
                  borderRadius: "4px",
                }}
              />
            )}
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", height: "auto" }} >
          <div>
            <div className="title-div">
              <Typography.Text
                style={{ fontSize: "18px", fontWeight: "650", width: "10rem" }}
                ellipsis={{ tooltip: true }}
              >
                {data?.title}
              </Typography.Text>

              <div>
                <span>{date} </span>
                <span style={{ color: statusColor }}>{data?.status}</span>
              </div>
            </div>
            <Paragraph
              className="eventDescription"
              ellipsis={
                ellipsis
                  ? { rows: 2, expandable: true, symbol: "more", fontSize: 16 }
                  : false
              }
            >
              {data?.description}
            </Paragraph>
          </div>
        </div>
      </div>
    );
  }

  return <></>

};

function EventCaptain(props) {
  const { user, year } = useSelector((state) => state.auth);
  const { location } = useSelector((state) => state.overView);
  const [loadValue, setLoadValue] = useState("");
  const [ellipsis, setEllipsis] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState([]);
  const { width } = useWindowDimensions();
  const getDate = (str) => {
    const date = new Date(str);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return (
      date.getUTCDate() +
      " " +
      months[date.getUTCMonth()] +
      " " +
      date.getFullYear()
    );
  };
  useEffect(() => {
    dispatch(overViewActions.updateLocation("both"))
  }, [])

  useEffect(() => {
    setLoadValue(true);
    Api.get(`/ibelong-events/All`)
      .params({ search: props.search, queryYear: year, location: location })
      .send((response) => {
        let value = [];
        let list = [];
        if (response !== undefined) {
          list = response?.events?.map((element) => {
            value.push(element);
            return element;
          });
          if (props?.eventAdded) {
            dispatch(authActions.emailModal(true));
            props?.setEventValChanged(false);
          }
          setLoadValue(false);
          dispatch(authActions.eventList(response.events));
          setEventData(list);
        }
      });
  }, [props.eventAdded, props.search, year, location]);


  return (
    <div >

      {loadValue ? (
        < Row justify="center" style={{ marginTop: "10vh" }}>
          {width < 700 && <Row justify='center' style={{ width: '100vw', height: '100%', }}><Spin size='large' style={{ display: "flex", justifyContent: "center", alignItems: 'center' }} /></Row>
          }
          {width > 700 && <Row justify="center" style={{ marginTop: "100px" }}>
            <Spin size="large" style={{ alignSelf: "center", height: "10vh" }} />
          </Row>}
        </Row >
      ) : (
        <div>
          {eventData?.length === 0 &&
            <div style={{ width: '100vw', height: '100vh', display: 'flex', fontFamily: 'poppins', alignItems: 'center', justifyContent: 'center', fontSize: '16px', fontWeight: '600', color: 'grey' }}>No Data Available</div>
          }
          {width > 700 && (
            <div className="scroll eventCaptainDiv">
              <Row style={{ display: "flex", width: "100%" }}>
                {props?.viewType == "list" ? (
                  <div >
                    <ul className="flex-container">
                      {eventData?.map((data, index) => {
                        const date = getDate(data?.createdAt);
                        let statusColor;
                        if (data?.status === "Active") statusColor = "#13e694";
                        else statusColor = "#f68452";
                        if (data?.status === "Active") {
                          return (
                            <div className="flex-item112" >
                              <li className="flex-item"
                                onClick={() => {
                                  if (data?.status === "Active") {
                                    history.push({
                                      pathname: "/event-details",
                                      state: { data },
                                    });
                                  } else {
                                    if (
                                      user?.role === "SuperAdmin" ||
                                      user?.privilege === "Admin" ||
                                      user?.privilege === "EventOrganisers"
                                    ) {
                                      history.push({
                                        pathname: "/event-details",
                                        state: { data },
                                      });
                                    }
                                  }
                                }}
                              >
                                <div>
                                  <h4 className='headTxt' style={{ fontWeight: "bold", maxWidth: "40vh" }}>
                                    {data?.title}
                                  </h4>
                                  <h6>
                                    {date}
                                    <span
                                      style={{
                                        color: statusColor,
                                        marginLeft: "1rem",
                                        fontSize: "1vw",
                                      }}
                                    >
                                      {data?.status}
                                    </span>
                                  </h6>
                                  {/* <Typography.Text ellipsis={{ tooltip: true }} style={{maxWidth:"20vw",height:"10vh"}}> */}
                                  <Typography.Paragraph ellipsis={{ tooltip: true, rows: 4 }}>{data?.description}</Typography.Paragraph>
                                  {/* </Typography.Text>  */}
                                </div>

                                <div style={{ width: "40%", textAlign: "end", alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                  {data?.media.length === 0 ? (
                                    <div></div>
                                  ) : (
                                    <img
                                      src={data?.media[0]}
                                      alt="img"
                                      style={{ width: "150px", height: "120px", border: "2px solid white ", borderRadius: "10px", boxShadow: "3px 3px 3px" }}
                                    />
                                  )}
                                </div>
                              </li>
                            </div>
                          );
                        } else if (
                          data?.status === "InActive" &&
                          (user?.role === "SuperAdmin" ||
                            user?.privilege === "Admin" ||
                            user?.privilege === "EventOrganisers")
                        ) {

                          return (
                            <div className="flex-item112" style={{cursor:"pointer"}}>
                              <li className="flex-item" onClick={() => {
                                if (data?.status === "Active") {
                                  history.push({
                                    pathname: "/event-details",
                                    state: { data },
                                  });
                                } else {
                                  if (
                                    user?.role === "SuperAdmin" ||
                                    user?.privilege === "Admin" ||
                                    user?.privilege === "EventOrganisers"
                                  ) {
                                    history.push({
                                      pathname: "/event-details",
                                      state: { data },
                                    });
                                  }
                                }
                              }}
                              >
                                <div>
                                  <h4 style={{ fontWeight: "bold" }}>
                                    {data?.title}
                                  </h4>
                                  <h6>
                                    {date}
                                    <span
                                      style={{
                                        color: statusColor,
                                        marginLeft: "1rem",
                                        fontSize: "1vw",
                                      }}
                                    >
                                      {data?.status}
                                    </span>
                                  </h6>
                                  <p className='txtOverFLow'>{data?.description}</p>
                                </div>

                                <div style={{ width: "40%", textAlign: "end" }}>
                                  {data?.media.length === 0 ? (
                                    <div></div>
                                  ) : (
                                    <img
                                      src={data?.media[0]}
                                      alt="img"
                                      style={{ width: "150px", height: "120px" }}
                                    />
                                  )}
                                </div>
                              </li>
                            </div>
                          );
                        }
                      })}
                    </ul>
                  </div>
                ) : (
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Row>
                      {eventData?.map((data, index) => {
                        const date = getDate(data?.createdAt);
                        let statusColor;
                        if (data?.status === "Active") statusColor = "#13e694";
                        else statusColor = "#f68452";
                        if (data?.status === "Active") {
                          return (
                            <div className="EventList2" >
                              < li className='listViewCard' style={{cursor:"pointer"}} onClick={() => {
                                if (data?.status === "Active") {
                                  history.push({
                                    pathname: "/event-details",
                                    state: { data },
                                  });
                                } else {
                                  if (
                                    user?.role === "SuperAdmin" ||
                                    user?.privilege === "Admin" ||
                                    user?.privilege === "EventOrganisers"
                                  ) {
                                    history.push({
                                      pathname: "/event-details",
                                      state: { data },
                                    });
                                  }
                                }
                              }}
                              >
                                <div style={{ width: '100%', height: '100%',cursor:"pointer"}}>
                                  <h4 style={{ fontWeight: "bold" }}>
                                    {data?.title}
                                  </h4>
                                  <h6>
                                    {date}
                                    <span
                                      style={{
                                        color: statusColor,
                                        marginLeft: "1rem",
                                        fontSize: "1vw",
                                      }}
                                    >
                                      {data?.status}
                                    </span>
                                  </h6>
                                  {/* <p className='txtOverFLow'>{data?.description}</p> */}
                                  <Paragraph className="scroll" style={{ lineHeight: "1.5rem", height: "auto", width: "100%", overflowY: 'scroll', textAlign: "justify", fontSize: '15px', }}
                                    ellipsis={ellipsis ? { rows: 3, expandable: true, symbol: 'more', } : false}>{data?.description}
                                  </Paragraph>
                                </div>

                                <div style={{ width: "30%", textAlign: "end" }}>
                                  {data?.media.length === 0 ? (
                                    <div></div>
                                  ) : (
                                    <img
                                      src={data?.media[0]}
                                      alt="img"
                                      style={{ width: "150px", height: "120px" }}
                                    />
                                  )}
                                </div>
                              </li>
                              <hr />
                            </div>
                          );
                        } else if (
                          data?.status === "InActive" &&
                          (user?.role === "SuperAdmin" ||
                            user?.privilege === "Admin" ||
                            user?.privilege === "EventOrganisers")
                        ) {
                          return (
                            <div className="EventList" >
                              <li className='listViewCard' style={{cursor:"pointer"}}
                                onClick={() => {
                                  if (data?.status === "Active") {
                                    history.push({
                                      pathname: "/event-details",
                                      state: { data },
                                    });
                                  } else {
                                    if (
                                      user?.role === "SuperAdmin" ||
                                      user?.privilege === "Admin" ||
                                      user?.privilege === "EventOrganisers"
                                    ) {
                                      history.push({
                                        pathname: "/event-details",
                                        state: { data },
                                      });
                                    }
                                  }
                                }}
                              >
                                <div>
                                  <h4 style={{ fontWeight: "bold",cursor:"pointer" }}>
                                    {data?.title}
                                  </h4>
                                  <h6>
                                    {date}
                                    <span
                                      style={{
                                        color: statusColor,
                                        marginLeft: "1rem",
                                        fontSize: "1vw",
                                      }}
                                    >
                                      {data?.status}
                                    </span>
                                  </h6>
                                  <p>{data?.description}</p>
                                </div>

                                <div style={{ width: "40%", textAlign: "end" }}>
                                  {data?.media.length === 0 ? (
                                    <div></div>
                                  ) : (
                                    <img
                                      src={data?.media[0]}
                                      alt="img"
                                      style={{ width: "150px", height: "120px" }}
                                    />
                                  )}
                                </div>
                              </li>
                              <hr />
                            </div>
                          );
                        }
                      })}
                    </Row>
                  </div>
                )}
              </Row>
            </div >
          )
          }

          {
            width < 700 && (
              <div style={{ height: '60vh', overflow: 'auto' }}>
                {eventData?.map((item, index) => {
                  // console.log("eee", eventData)
                  return (
                    <EventDataMob
                      key={index}
                      data={item}
                      ellipsis={ellipsis}
                      setEllipsis={setEllipsis}
                      getDate={getDate}
                      history={history}
                      user={user}
                    />
                  );
                })}
              </div>
            )
          }
        </div >
      )
      }
    </div >
  );
}
export default EventCaptain;
