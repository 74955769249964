import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

import { clearFromLocalStorage } from "services/Storage";

const initialState = {
 images:[],
 videos:[],
 likes: false,
 whatHappen:[],
 NominationLoader:false,
 overViewLike:null,
 overViewLikeCount:null,
 location:"",
};

const overViewSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {

  imageList(state,action){
    state.images=[...action.payload]
  },
  videoList(state,action){
    state.videos=[...action.payload]
  },
  likesList(state,action){
    state.likes=action.payload;
  },
  whatHappenList(state,action){
    state.whatHappen=[...action.payload];
  },
  setNominationLoader(state,action){
    state.NominationLoader=action.payload
  },
  updateOverviewLike(state,action){
    state.overViewLike=action.payload
  },updateOverviewLikeCount(state,action){
    state.overViewLikeCount=action.payload
  },  
  updateLocation(state,action){
    state.location=action.payload;
  }

  },
 

});

export default overViewSlice.reducer;

export const overViewActions = overViewSlice.actions;