import React from "react";
import HouseName from "./HouseNames";
import { Legend } from "recharts";
import '../modules/LocationAnalysis.css'
import CustomFooter from "./CustomFooter";
import NormalisedHorizontalStackedBar from './NormalisedHorizontalStackedBar'

const Location = ({allHouses,colors, userCountByHouse})=>{
    const normalizedData = allHouses?.map((item, index)=>{
        const total = Number(userCountByHouse?.[item.name]?.locations?.["bangalore"]) 
              +Number(userCountByHouse?.[item.name]?.locations?.["coimbatore"])
              +Number(userCountByHouse?.[item.name]?.locations?.["Assam"]);
        

        return {
          name: item.name,
          Banglore: Math.round(Number(userCountByHouse?.[item.name]?.locations?.["bangalore"]/total)*100),
          Coimbatore: Math.round(Number(userCountByHouse?.[item.name]?.locations?.["coimbatore"]/total)*100),
          Assam: Math.round(Number(userCountByHouse?.[item.name]?.locations?.["Assam"]/total)*100),
          color: "#000000",
          BangloreCount: userCountByHouse?.[item.name]?.locations?.["bangalore"],
          CoimbatoreCount: userCountByHouse?.[item.name]?.locations?.["coimbatore"],
          AssamCount: userCountByHouse?.[item.name]?.locations?.["Assam"]
        };
      });
      const gender=["Banglore", "Coimbatore", "Assam"]
      const color = ["#FFAF7A", "#FF8A3B", "#EB5E00"]

      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          const { name,Banglore,Coimbatore, Assam, BangloreCount, CoimbatoreCount, AssamCount } = payload[0]?.payload;
      
          return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', width:'180px' }}>
            <p className="label" style={{ fontWeight: 'bold' }}>{`${name}`}</p> 
            
            <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'4px'}}>
                <div
                    style={{
                      width: '12px',
                      height: '12px',
                      borderRadius: '2px',
                      backgroundColor: "#FFAF7A",
                    }}
                  ></div>
                  <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{fontFamily: 'Roboto'}}>Banglore</div>
                    <div style={{ color: '#616161', marginLeft: '4px', fontFamily: 'Roboto'}}>{Banglore}%</div>
                  </div>
                </div>
                <div style={{fontFamily: 'Roboto'}}>{BangloreCount}</div>
               
              </div>
            <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'4px'}}>
                <div
                    style={{
                      width: '12px',
                      height: '12px',
                      borderRadius: '2px',
                      backgroundColor: "#FF8A3B",
                    }}
                  ></div>
                  <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{fontFamily: 'Roboto'}}>Coimbatore</div>
                    <div style={{ color: '#616161', marginLeft: '4px', fontFamily: 'Roboto'}}>{Coimbatore}%</div>
                  </div>
                </div>
                <div style={{fontFamily: 'Roboto'}}>{CoimbatoreCount}</div>
               
              </div>
            <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'4px'}}>
                <div
                    style={{
                      width: '12px',
                      height: '12px',
                      borderRadius: '2px',
                      backgroundColor: "#EB5E00",
                    }}
                  ></div>
                  <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{fontFamily: 'Roboto'}}>Assam</div>
                    <div style={{ color: '#616161', marginLeft: '4px', fontFamily: 'Roboto'}}>{Assam}%</div>
                  </div>
                </div>
                <div style={{fontFamily: 'Roboto'}}>{AssamCount}</div>
               
            </div>
          </div>
        );
        }
      
        return null;
      };
    return (
        <div className="LocationContainer">
            <div className="LocationHeader">
                <h2>Location Analysis</h2>
                <p>Insights Into Regional Distribution Patterns</p>
            </div>
            <div style={{display:'flex', alignItems: 'center'}}>
                <div>
                    {allHouses?.map((item, index) => (
                        <div style={{marginBottom: '10px', marginTop: '12px',alignItems: 'center'}} ><HouseName key={index} label={item.name} color={colors[index]}/></div>
                    ))}
                </div>
                <NormalisedHorizontalStackedBar
                normalizedData={normalizedData}
                label={gender}
                color={color}
                CustomTooltip={CustomTooltip}
                />
            </div>
            <CustomFooter data={gender} colors={color}/>
        </div>
    );
}


export default Location