import classes from './Chats.module.css';
import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import SmileIcon from "../../../images/SmileIcon.png";
import { useDispatch, useSelector } from "react-redux";
import UserProfile from "../../../images/userProfile.png";
import Api from "services/Api";
import SingleChatConversation from "./SingleChatConversation";
import moment from "moment";

import { chatActions } from "./chatStore";
import profileIcon from "../../../images/profileIcon.jpg";
import { border } from "@mui/system";
function GrpChatMenu({ setGrpChatLoader, userUserClickUpdate }) {
  const { grpChatPerson, openChatWindow, unseenCount, chatData } = useSelector(
    (state) => state.chat
  );
  const dispatch = useDispatch();
  const { Paragraph, Text } = Typography;
  const [filteredgrpChatPerson, setFilteredGrpChatPerson] = useState([]);
  const [gid, setGId] = useState();

  useEffect(() => {
    console.log("inside use effect")
    grpChatPerson?.forEach((element) => {
      setGrpChatLoader(true);
      setTimeout(() => {
        setGrpChatLoader(false);
        console.log("inside for each")
      }, 300);
      if (
        gid === undefined
          ? element?.chatId === openChatWindow?.chatId
          : element?.chatId === gid
      ) {
        console.log("openchatwindow", element)
        dispatch(chatActions.updateOpenChatWindow(element));
        // dispatch(chatActions.updateNewMessage({chatData:element?.chatId,type:"DELETE"}));
      }

    });
  }, [gid, grpChatPerson]);
  let now = moment();
  let mentionRecevier;
  return (
    <div
      className={classes.scroll}
    // style={{
    //   width: "100%",
    //   height: "53vh",
    //   overflowY: "scroll",
    //   marginTop: "16px",
    //   fontSize: "10px",
    //   backgroundColor: "white",
    // }}
    >

      {grpChatPerson?.map((ele) => {

        let lastActive;
        let n = chatData[ele?.chatId]?.length;
        let Msg = chatData[ele?.chatId];
        let lastMsg;
        let lastTime;
        let replacedText;
        if (n !== undefined && Msg !== undefined) {
          lastMsg = Msg[n - 1]?.message;
          lastTime = Msg[n - 1]?.createdAt;
        }
        let time = moment(lastTime !== undefined ? lastTime : ele?.lastActive);
        if (now.diff(ele?.lastActive, "days") > 7) {
          lastActive = time.format("DD/MM/YYYY");
        } else if (
          now.diff(ele?.lastActive, "days") > 1 &&
          now.diff(ele?.lastActive, "days") < 7
        ) {
          lastActive = time.format("dddd");
        } else {
          lastActive = time.format("hh:mma");
        }
        return (
          <div className="User" onClick={() => { console.log("clicked"); setGId(ele?.chatId); dispatch(chatActions.updateChatWindow(true)); userUserClickUpdate(); }} >
            <div className="User_individual" key={ele?.userId} style={{ height: "70px", backgroundColor: gid === ele.chatId ? " #EBE9E9" : "white", }} >
              <div>
                <img src={ele?.groupChatIcon ? ele?.groupChatIcon : profileIcon} alt="image" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
              </div>
              <div style={{ width: "200px" }}>
                <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 12px/21px Poppins", maxWidth: "150px", }} >
                  {ele?.userName ? ele?.userName : ele?.chatName}
                </Typography.Text>
                <Paragraph style={{ font: "normal normal normal 10px/12px Poppins", maxWidth: "120px", }} ellipsis={true ? { rows: 2, expandable: false, symbol: "." } : false} >
                  {lastMsg !== undefined ? lastMsg?.replace(/\([^)]*\)/g, "").split(/(\[.*?\])/).map((word, index) => {
                    if (word.startsWith("[") && word.endsWith("]")) {
                      return (
                        <span key={index} style={{ color: "blue" }}>{word.slice(1, -1)} </span>
                      );
                    } else {
                      const subWords = word.split();
                      return subWords.map((subWord, subIndex) => (
                        <span key={`${index}-${subIndex}`}> {subWord} </span>
                      ));
                    }
                  })
                    .flat()
                    .map((word) =>
                      typeof word === "string"
                        ? word?.replace(/\[|\]/g, "")
                        : word
                    )
                    : ele?.lastMessage === "none"
                      ? ""
                      : ele?.lastMessage != null ? ele.lastMessage?.replace(/\([^)]*\)/g, "")
                        .split(/(\[.*?\])/)
                        .map((word, index) => {
                          if (word.startsWith("[") && word.endsWith("]")) {
                            return (
                              <span key={index} style={{ color: "blue" }}>

                                {word.slice(1, -1)}
                              </span>
                            );
                          } else {
                            const subWords = word.split(" ");
                            return subWords.map((subWord, subIndex) => (
                              <span key={`${index}-${subIndex}`}>
                                {subWord}
                              </span>
                            ));
                          }
                        })
                        .flat()
                        .map((word) =>
                          typeof word === "string"
                            ? word?.replace(/\[|\]/g, "")
                            : word
                        ) : (<div>''</div>)}
                </Paragraph>
              </div>
              <div>

                {ele?.notifications ? (
                  (
                    unseenCount[ele?.chatId] === undefined
                      ? ele?.unseenMessages > 0
                      : unseenCount[ele?.chatId] > 0
                  ) ? (
                    <div className={classes.unseenMsgGrpChatMenu} >
                      {parseInt(ele?.unseenMessages)}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div style={{ width: "90px" }}>{lastActive}</div>
            </div>
            <div style={{ borderTop: "0.5px solid #bfbfbf", margin: "0px 5%" }} ></div>
          </div>
        );
      })}
    </div>
  );
}

export default GrpChatMenu;