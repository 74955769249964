import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import '../DashboardModules/GenderAgeGroupChart.css'

// const data = [
//   { name: '18-25', Male: 75, Female: 80 },
//   { name: '26-35', Male: 70, Female: 75 },
//   { name: '36-45', Male: 70, Female: 80 },
//   { name: '46-55', Male: 65, Female: 70 },
//   { name: '56-65', Male: 70, Female: 75 }
// ];

const GenderAgeGroupChart = ({data,colors}) => {


    const yAxisTickFormatter = (value) => {
        return `${value}%`;
      };

      const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
          const { name, Male, Female, maleCount, femaleCount } = payload[0].payload;
      
          return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', width:'180px' }}>
              <p className="label" style={{ fontWeight: 'bold' }}>{`${name}`}</p> 
              

              <div style={{display: 'flex', flexDirection: 'row' ,justifyContent: 'space-between',alignItems:'center'}}>
              <div style={{display:'flex', alignItems:'center', gap:'4px'}} >
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '2px',
                    backgroundColor: colors[2],
                  }}
                ></div>
                
                  
                    <div style={{fontFamily: 'Roboto'}}>Male</div>
                    <div style={{ color: '#616161', fontFamily: 'Roboto'}}>{Math.round(Male)}%</div>
                  </div>
                  <div style={{fontFamily: 'Roboto'}}>{maleCount}</div>
                </div>
             
              <div style={{display: 'flex', flexDirection: 'row' ,justifyContent: 'space-between',alignItems:'center'}}>
              <div style={{display:'flex', alignItems:'center', gap:'4px'}} >
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '2px',
                    backgroundColor: colors[3],
                  }}
                ></div>
                
                  
                    <div style={{fontFamily: 'Roboto'}}>Female</div>
                    <div style={{ color: '#616161', fontFamily: 'Roboto'}}>{Math.round(Female)}%</div>
                  </div>
                  <div style={{fontFamily: 'Roboto'}}>{femaleCount}</div>
                </div>
              
            </div>
          );
        }
      
        return null;
      }; 
      
  return (
    <div className="chart-container">
      <h2>Age Group By Gender</h2>
    <ResponsiveContainer width="100%" height={230} >
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20, right: 10, left: -20, bottom: 5,
        }}
        barCategoryGap={0}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false}/>
        <XAxis dataKey="name" axisLine={false} tickLine={false} style={{fontFamily: 'Roboto', fontSize: '12px'}} />
        <YAxis  axisLine={false} tickLine={false} tickFormatter={yAxisTickFormatter} style={{fontFamily: 'Roboto', fontSize: '12px'}}/>
        <Tooltip content={CustomTooltip}/>
        
        <Bar dataKey="Male" fill={colors[2]} barSize={12}/>
        <Bar dataKey="Female" fill={colors[3]} barSize={12}/>
      </BarChart>
    </ResponsiveContainer>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', fontSize: '14px', marginTop: '12px'}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px'}}>
        <div style={{width: '12px', height: '12px', borderRadius: '2px', backgroundColor: colors[2]}}></div>
        <p style={{fontSize: '14px', marginBottom: '0px', fontFamily: 'Roboto'}}>Male</p>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px'}}>
        <div style={{width: '12px', height: '12px', borderRadius: '2px', backgroundColor: colors[3]}}></div>
        <p style={{fontSize: '14px', marginBottom: '0px' , fontFamily:'Roboto'}}>Female</p>
        </div>
    </div>
    </div>
  );
};

export default GenderAgeGroupChart;
