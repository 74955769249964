import React, { useEffect, useState } from 'react';
import { Switch, Modal, Select, Radio, Typography, Input, message, Form, Button } from "antd";
import Api from "services/Api";
import Notification from 'services/Notification';
import { GroupRole } from './eventdetails';
function EditGroupParticipants(props) {
  const { editGrpData, removedGrpParticipants, setGroupActionModal, getApi, getGroups, setRemovedGrpParticipants, viewGroupInfo, participantArray } = props;
  console.log("participantArrayedit :", participantArray, 'groupinfo :', viewGroupInfo)
  const [newParticipant, setNewParticipant] = useState([])
  const sendNewParticipantsData = (val) => {
    console.log("111", val)
    Api.patch('/ibelong-events/updateGroup')
      .params({
        addedParticipants: val?.actionGroupParticipants,
        grroupId: editGrpData?.id,
        groupName: val?.groupName,
        eventId: editGrpData?.event?.id,
        deletedParticipants: removedGrpParticipants,
      })
      .send((res) => {
        console.log("11122", res)
        setGroupActionModal(false);
        getApi();
        setTimeout(() => {
          console.log("1112233", getGroups)
          getGroups();
        }, 10);

      })
    setRemovedGrpParticipants([]);
  }

  useEffect(() => {
    if (participantArray) {
      setNewParticipant(participantArray.filter((item) => viewGroupInfo[0].houseName === item.houseName))
    }
  }, [])

  console.log("sssListt", newParticipant, participantArray, viewGroupInfo[0])

  const handleDiscardData = () => {
    document.getElementById("clearFormData").reset("");

  }
  return (
    <div>
      <div>
        <Form
          layout="vertical"
          initialValues={{ groupName: editGrpData?.groupName }}
          onFinish={(val) => {
            sendNewParticipantsData(val);
          }}
          id="clearFormData"
        >
          {console.log("juucre", editGrpData)}
          <Form.Item
            style={{ paddingTop: "10px", fontWeight: "bold" }}
            label="Group Name"
            name="groupName"
          >
            <Input type="text" placeholder="Group Name" />
          </Form.Item>
          <Form.Item
            style={{ fontWeight: "bold" }}
            label="Group Participant">
            {viewGroupInfo?.map((val, id) => {
              console.log('parttttinfo', val)
              return (
                <div id={`user${id}`}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    key={id}
                  >
                    <div>
                      <Typography.Text
                        ellipsis={{ tooltip: true }}
                        style={{
                          font: "normal normal  13px/22px Poppins",
                          maxWidth: "20vw",
                        }}
                      >
                        {console.log('partttt', val?.houseName, val?.name)}
                        {val?.name}
                      </Typography.Text>
                    </div>
                    <div
                      onClick={() => {
                        { console.log("jjcc", editGrpData?.createdBy?.id, 'jj', val?.id) }
                        if (editGrpData?.createdBy?.id === val?.id
                          || props?.groupRole === 'Group Leader'
                        ) {
                          Notification.error("Can't remove the event coordinator or the group leader or the Super Admin")
                        }
                        else {
                          setRemovedGrpParticipants(arr => [...arr, val?.id]);
                          document.getElementById(`user${id}`).style.display = "none";
                        }
                      }}
                      style={{ cursor: "pointer", color: "red", display: val?.id === null ? 'none' : '' }}
                    >
                      remove
                    </div>

                  </div>
                </div>
              );
            })}
          </Form.Item>
          <Form.Item style={{ fontWeight: "bold" }} label="New participants" name="actionGroupParticipants">
            <Select
              mode="multiple"
              showSearch
              allowClear
              optionFilterProp="children"
              placeholder="please select"
            >
              {newParticipant?.map((val, id) => {
                return (
                  <Select.Option value={val?.userId} key={id}>
                    {val?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item style={{ float: "right", width: "32%", marginTop: "60px" }}>
            <div style={{ display: "flex", justifyContent: "spacebetween" }}>
              <div>
                <Button onClick={
                  handleDiscardData
                }
                  style={{ marginRight: "-10px", textTransform: "uppercase", color: "black" }}
                >
                  Reset
                </Button>
              </div>
              <div style={{ marginLeft: "2rem" }}>
                <Button style={{ backgroundColor: "#6fc78f", color: "black" }} htmlType="submit">ADD</Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default EditGroupParticipants