import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import SmileIcon from "../../../images/SmileIcon.png";
import { useDispatch, useSelector } from "react-redux";
import UserProfile from "../../../images/userProfile.png";
import profileIcon from "../../../images/profileIcon.jpg";
import Api from "services/Api";
import SingleChatConversation from "./SingleChatConversation";
import moment from "moment";
import { chatActions } from "./chatStore";
import classes from '../chats/Chats.module.css'
function ChatMenu({ setChatLoader }) {
  const { singleChatPerson, openChatWindow, newMessage, allUser, chatData, unseenCount } = useSelector(state => state.chat)
  const dispatch = useDispatch();
  const { Paragraph, Text } = Typography;
  const [filteredSingleChatPerson, setFilteredSingleChatPerson] = useState([]);
  // useEffect(() => {
  //   console.log("chat G P T",search)
  //   setTimeout(()=>{
  //     let  temp=singleChatPerson?.filter((el)=>{
  //       return el.userName?.toLowerCase()?.includes(search?.toLowerCase())}
  //       )
  //     dispatch(chatActions.updateSingleChatPerson(temp))
  //   },300)\\

  // },[search,allUser])
  const [id, setId] = useState();
  // useEffect(()=>{

  // },[openChatWindow,id])
  useEffect(() => {
    // console.log("ffffffffffffffffffff",id,openChatWindow?.chatId)
    // location.reload();
    setChatLoader(true)
    setTimeout(() => {
      setChatLoader(false)
    }, 200)
    singleChatPerson?.forEach(element => {
      if (id === undefined ? element?.chatId === openChatWindow?.chatId : element?.chatId === id) {
        // console.log("ffffffffffffffffffff",element?.chatId,element?.chatId===id,)
        dispatch(chatActions.updateOpenChatWindow(element));

        // dispatch(chatActions.updateNewMessage({chatData:element?.chatId,type:"DELETE"}));
      }
      // else if(element?.chatId===openChatWindow?.chatId){
      //   console.log("jjjjjjjjjjjjjjjjjj",element?.chatId,openChatWindow?.chatId,element?.chatId===openChatWindow?.chatId,)
      //   dispatch(chatActions.updateOpenChatWindow(element));
      //   dispatch(chatActions.updateChatWindow(true));
      //   dispatch(chatActions.updateNewMessage({chatData:element?.chatId,type:"DELETE"}));
      //   setChatLoader(false)
      // }
    });

  }, [id, singleChatPerson])
  let now = moment()
  const manageSingleChat = (ele) => {
    console.log('Demo..', ele?.unseenMessages, unseenCount[ele?.chatId])
    setId(ele?.chatId);
    dispatch(chatActions.updateChatWindow(true));
    // ele.unseenMessages=0;
  }

  return (
    <div className={classes.scroll} >
      {singleChatPerson?.map((ele) => {
        let lastActive;
        let n = chatData[ele?.chatId]?.length;
        let Msg = chatData[ele?.chatId]
        let lastMsg
        let lastTime
        if (n !== undefined && Msg !== undefined) {
          lastMsg = Msg[n - 1]?.message
          lastTime = Msg[n - 1]?.createdAt;
        }
        let time = moment(lastTime !== undefined ? lastTime : ele?.lastActive)
        if (now.diff(ele?.lastActive, "days") > 7) {
          lastActive = time.format("DD/MM/YYYY")
        }
        else if (now.diff(ele?.lastActive, "days") > 1 && now.diff(ele?.lastActive, "days") < 7) {
          lastActive = time.format("dddd")
        }
        else {
          lastActive = time.format("hh:mma")
        }
        return (
          <div className="User" onClick={() => { manageSingleChat(ele) }}  >
            <div className="User_individual" key={ele?.userId} style={{ height: '70px', backgroundColor: id === ele.chatId ? "#EBE9E9" : "white", }}  >
              <div>
                <img src={ele?.userProfileImage ? ele?.userProfileImage : profileIcon} alt="image" style={{ width: '40px', height: '40px', borderRadius: "50%" }} />
              </div>
              <div style={{ width: '200px' }}>
                <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 14px/21px Poppins", maxWidth: "150px", }}>{ele?.userName ? ele?.userName : ele?.chatName}</Typography.Text>
                <Paragraph style={{ font: "normal normal normal 10px/12px Poppins", maxWidth: '200px' }} ellipsis={true ? { rows: 2, expandable: false, symbol: ".", } : false}>{lastMsg !== undefined ? (lastMsg) : (ele?.lastMessage === "none" ? "" : ele?.lastMessage)}</Paragraph>
              </div>
              <div>
                {
                  ele?.notification ? ((unseenCount[ele?.chatId] === undefined ? ele?.unseenMessages > 0 : unseenCount[ele?.chatId] > 0)) ?
                    <div style={{ backgroundColor: "#A661FF", color: "white", padding: '4px', height: '24px', width: '20px', borderRadius: '5px', textAlign: 'center' }}>{parseInt(ele?.unseenMessages)}</div> : "" : ""
                }
              </div>
              <div style={{ width: "90px" }}>
                {lastActive}
              </div>
            </div>
            <div style={{ borderTop: "0.5px solid #bfbfbf", margin: '0px 5%' }}></div>

          </div>
        );
      })}
    </div>

  );
}

export default ChatMenu;