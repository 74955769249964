import classes from "./users.module.css";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import profileIcon from "../../images/profileIcon.jpg";
import "antd/dist/antd.css";
import { Col, Input, Table, Select, Button, Row, Spin, Pagination, Popover, Checkbox, Radio, Typography } from "antd";
import { useEffect } from "react";
import userImage from "../../images/userImage.png";
import Api from "services/Api";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import { SearchOutlined, FilterFilled, DownOutlined, RightOutlined, DownloadOutlined } from "@ant-design/icons";
import SearchCompo from "component/searchCompo";
import YearDropdown from "component/YearDropdown";
import { useSelector } from "react-redux";
import './Users.css';
import { useWindowDimensions } from "../windowDimention/windowDimension";
import assignIcon from '../../images/AssignHouseIcon.svg';
import ButtonCompo from "design/ButonCompo";
import AssignModal from "./AssignModal";
import ViewMemberModal from "./ViewMemberModal";
const Option = Select;

export const UsersMob = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  console.log("showDetails", showDetails);
  return (
    <div>
      <div
        className={classes.UserData}
        onClick={() => setShowDetails((prev) => !prev)}
      >
        <div>
          <img
            src={
              props?.item?.profileImage
                ? props?.item?.profileImage
                : profileIcon
            }
          />
        </div>

        <span>{props?.item?.name}</span>
        <div>
          {!showDetails && (
            <RightOutlined
              style={{ fontWeight: "bold", fontSize: "18px", color: "grey" }}
            />
          )}
          {showDetails && (
            <DownOutlined
              style={{ fontWeight: "bold", fontSize: "18px", color: "grey" }}
            />
          )}
        </div>
      </div>
      {showDetails && (
        <div className={classes.restDetails}>
          <div>
            <span>Employee Id</span>
            <span>{props?.item?.uid}</span>
          </div>
          <div >
            <span >Contact Details</span>
            <div className={classes.contacts}>
              <span>{props?.item?.phone}</span>
              <span>
                <Typography.Text
                  ellipsis={{ tooltip: true }}
                  style={{ width: window.innerWidth > 380 ? "9rem" : '7rem', color: "gray", fontSize: "13px" }}
                >
                  {props?.item?.email}
                </Typography.Text>
              </span>
            </div>
          </div>
          <div>
            <span>Department</span>
            <span>{props?.item?.department ? props?.item?.department : "NA"}</span>
          </div>
          <div>
            <span>Events Participents</span>
            <span>{props?.item?.eventParticipatedCount}</span>
          </div>

          <div>
            <span>Location</span>
            <span>{props?.item?.location}</span>
          </div>
          <div>
            <span>Role</span>
            <span>{props?.item?.role}</span>
          </div>
          <div>
            <span>House</span>
            <span>{props?.item?.house?.name}</span>
          </div>

          <div>
            <span>Privileges</span>
            <Select className={classes.SelectOrg} style={{ width: "130px", }} dropdownStyle={{ zIndex: '0' }} value={props?.item?.privilege} onChange={(value) => {
              Api.patch("/ibelong-user/updateRole")
                .params({
                  userId: props?.item?.id,
                  userPrivilege: value
                })
                .send(res => {
                  console.log("res", res)
                  props?.getApi()
                })
            }}>
              <Option value="Admin">Admin</Option>
              <Option value="EventOrganisers">Event Organiser</Option>
              <Option value="EventCoordinators">Event Coordinator</Option>
              <Option value="None">None</Option>
            </Select>
          </div>

          <div>
            <NavLink
              to={{
                pathname: "/userProfile",
                state: { data: props?.item },
              }}
            >
              <a>View more</a>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};


const Users = () => {
  const [privilege, setPrivilege] = useState("");
  const locationOpt = ["all", "bangalore", "coimbatore"]
  const [location, setLocation] = useState("all")
  const [house, setHouse] = useState("ALL");
  const [houseData, setHouseData] = useState("ALL");
  const [houseCheck, setHouseCheck] = useState(new Array(10).fill(false));
  const [locationCheck, setLocationCheck] = useState(new Array(10).fill(false));
  const [locationDatas, setLocationDatas] = useState("");
  const [visibleLocation, setVisibleLocation] = useState(false);
  const [visibleHouse, setVisibleHouse] = useState(false);
  const { width } = useWindowDimensions();
  const [status, setStatus] = useState("Active")
  const [assignModal,setAssignModal]=useState()
  const [pageSize,setPageSize]=useState(25);
  const [currentPage,setCurrentPage]=useState(1);
  const [viewMemberModal,setViewMemberModal]=useState(false);
  const [gender, setGender] = useState("");
  const [genderCheck, setGenderCheck] = useState(new Array(10).fill(false));
  const [genderDatas, setGenderDatas] = useState("");
  const [visibleGender, setVisibleGender] = useState(false);




  const changeHandler = (val) => {
    console.log("sssValue",val)

    if (val?.houseName === null) {
      setHouse("ALL")
    }
    else if (val?.houseName !== null) {
      setHouse(val?.houseName[0])
    }
    if (val?.location === null) {
      setLocation("all")
    }
    else if (val?.location !== null) {
      setLocation(val?.location[0])
    }
    if(val?.gender === null){
      setGender("")
    }
    else if(val?.gender !== null){
      setGender(val?.gender[0])
    }
  }


  const locationData = ["bangalore", "coimbatore"];
  const handleLocationData = (icon) => {
    
    const content = (
      <div style={{ width: "8rem", zIndex: '0', }}>
        {
          locationData?.map((item, index) => {
            return (<div >
              <input type="radio" checked={locationCheck[index]} onChange={(e) => {
                // setLocationDatas(e.target.value);
                setLocationCheck(locationCheck?.map((item2, index2) => {
                  if (index === index2) {
                    if (e.target.checked) {
                      return true;
                    }
                    return false;
                  }
                  return false;
                }))
                if (!e.target.checked) {
                  setLocationDatas(locationData.filter((a) => { }))
                  return
                }
                else {
                  setLocationDatas(item)
                }

              }}
              />
              <span style={{ paddingLeft: "5px" }}>{item}</span>

              {/* </input > */}

            </div>)

          })

        }



        <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between", }}>
          <Button className={classes.locbtn} style={{ backgroundColor: "rgb(193, 226, 244)", }}
            onClick={() => {
              setLocation("all")
              setLocationCheck(new Array(10).fill(false))
              setLocationDatas("")
              let temp = visibleLocation
              if (temp === false) {
                temp = true;
                setVisibleLocation(true)
              }
              else {
                setVisibleLocation(false)
                temp = false;
              }
              setCurrentPage(1);
            }}>Reset</Button>

          <Button className={classes.locbtn} style={{ backgroundColor: "rgb(193, 226, 244)", }} onClick={() => {
            setLocation(locationDatas)

            let temp = visibleLocation
            if (temp === false) {
              temp = true;
              setVisibleLocation(true)
            }
            else {
              setVisibleLocation(false)
              temp = false;
            }
            setCurrentPage(1);
          }}>Ok</Button></div>
      </div>

    )

    return (<Popover content={content} placement="bottom" trigger="click" visible={visibleLocation} onVisibleChange={
      () => {
        let temp = visibleLocation
        if (temp === false) {
          temp = true;
          setVisibleLocation(true)
        }
        else {
          setVisibleLocation(false)
          temp = false;
        }
      }
    } overlayClassName="custom-popover"
    >

      {icon}
    </Popover>
    )
  }


  const houseDatas = ["VAYU", "PRITHVI", "JAL", "AGNI", "AKASH"];

  const handleHouseData = (symbol) => {
    const contents = (
      <div style={{ width: "8rem", zIndex: '0' }}>
        {
          houseDatas?.map((item, index) => {
            return (<div>
              <input type="radio" checked={houseCheck[index]} onChange={(e) => {

                // setHouseData(e.target.value);
                setHouseCheck(houseCheck?.map((item2, index2) => {
                  if (index === index2) {
                    if (e.target.checked) {
                      return true;
                    }
                    return false;
                  }
                  return false;
                }))
                if (!e.target.checked) {
                  setHouseData(houseDatas.filter((a) => { }))
                  return
                }
                else {
                  setHouseData(item)
                }

              }}
              />
              <span style={{ paddingLeft: "5px" }}>{item}</span>
            </div>)

          })


        }



        <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
          <Button className={classes.locbtn} style={{ backgroundColor: "rgb(193, 226, 244)", }} onClick={() => {
            setHouse("ALL")
            setHouseCheck(new Array(10).fill(false));
            setHouseData("ALL")
            let temp = visibleHouse
            if (temp === false) {
              temp = true;
              setVisibleHouse(true)
            }
            else {
              setVisibleHouse(false)
              temp = false;
            }
            setCurrentPage(1);
          }}>Reset</Button>

          <Button className={classes.locbtn} style={{ backgroundColor: "rgb(193, 226, 244)", }} onClick={() => {
            setHouse(houseData);
            // if(house === "ALL"){
            //   setHouse("ALL");
            // }
            let temp = visibleHouse

            if (temp === false) {
              temp = true;
              setVisibleHouse(true)
            }
            else {
              setVisibleHouse(false)
              temp = false;
            }
            console.log("SSStemp", house, houseData)
            setCurrentPage(1);
          }}>Ok</Button></div>

      </div>

    )





    return (<Popover content={contents} placement="bottom" visible={visibleHouse} trigger="click" onVisibleChange={() => {
      let temp = visibleHouse
      if (temp === false) {
        temp = true;
        setVisibleHouse(true)
      }
      else {
        setVisibleHouse(false)
        temp = false;
      }
    }} overlayClassName="custom-popover">
      {symbol}
    </Popover>
    )
  }

  const genderData = ["Male", "Female"];
  const handleGenderData = (icon) =>{
    const content = (
      <div style={{ width: "8rem", zIndex: '0', }}>
        {
          genderData?.map((item, index) => {
            return (<div >
              <input type="radio" checked={genderCheck[index]} onChange={(e) => {
                // setLocationDatas(e.target.value);
                setGenderCheck(genderCheck?.map((item2, index2) => {
                  if (index === index2) {
                    if (e.target.checked) {
                      return true;
                    }
                    return false;
                  }
                  return false;
                }))
                if (!e.target.checked) {
                  setGenderDatas(genderData.filter((a) => { }))
                  return
                }
                else {
                  setGenderDatas(item)
                }

              }}
              />
              <span style={{ paddingLeft: "5px" }}>{item}</span>

              {/* </input > */}

            </div>)

          })

        }



        <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between", }}>
          <Button className={classes.locbtn} style={{ backgroundColor: "rgb(193, 226, 244)", }}
            onClick={() => {
              setGender("")
              setGenderCheck(new Array(10).fill(false))
              setGenderDatas("")
              let temp = visibleGender
              if (temp === false) {
                temp = true;
                setVisibleGender(true)
              }
              else {
                setVisibleGender(false)
                temp = false;
              }
              setCurrentPage(1);
            }}>Reset</Button>

          <Button className={classes.locbtn} style={{ backgroundColor: "rgb(193, 226, 244)", }} onClick={() => {
            setGender(genderDatas)

            let temp = visibleGender
            if (temp === false) {
              temp = true;
              setVisibleGender(true)
            }
            else {
              setVisibleGender(false)
              temp = false;
            }
            setCurrentPage(1)
          }}>Ok</Button></div>
      </div>

    )

    return (<Popover content={content} placement="bottom" trigger="click" visible={visibleGender} onVisibleChange={
      () => {
        let temp = visibleGender
        if (temp === false) {
          temp = true;
          setVisibleGender(true)
        }
        else {
          setVisibleGender(false)
          temp = false;
        }
      }
    } overlayClassName="custom-popover"
    >

      {icon}
    </Popover>
    )
  }

const columnData=()=>{
  let column
  if(status!=='unAssigned'){
  column=[
  {
    title: "Associate",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {

      const profileImage = record.profileImage;
      return (
        <NavLink
          to={{ pathname: "/userProfile", state: { data: record } }}
          className={classes.userProfile}
        >
          <div className={classes.evenCol}>
            <div className={classes.col} style={{ display: 'flex', flexDirection: "column", gap: 10, }}  >
              <img src={profileImage !== null ? profileImage : profileIcon} alt="userimage" />
            </div>
            <div className={classes.col}>
              <h2 style={{ minWidth: '15px' }}>{record?.name}</h2>
              <p className={classes.colItem}>{record.uid}</p>
            </div>
            <div className={classes.col}>
            </div>
          </div>
        </NavLink> 
      );
    },
  },
  {
    title: "Contact Details",
    dataIndex: "contact",
    key: "contact",
    render: (text, record) => {
      return (
        <>
          <p className={classes.colItem}>{record?.email}</p>
          <p>{record.phone}</p>
        </>
      );
    },
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "deptartment",
    render: (text, record) => {
      return (
        <>
          <p className={classes.colItem}>
            {record?.department ? record?.department :"NA"}
            {/* {record.department} */}
          </p>
        </>
      );
    },
  },
  {
    title: "Events Participated",
    dataIndex: "eventParticipatedCount",
    key: "eventParticipated",
    render: (text, record) => {
      return (
        <p className={classes.colItem}>{record.eventParticipatedCount}</p>
      );
    },
  },
  {
    title: <div style={{ display: "flex", justifyContent: "center", gap: "10px", }}><span>Location</span>{handleLocationData(<FilterFilled style={{color:locationDatas ? "#0086FF" : "black"}} />)} </div>,
    key: "location",
    dataIndex: "location",

    filterMultiple: false,

    render: (text, record) => {
      return (
        <div>

          <p className={classes.colItem}>
            {/* {text} */}
            {record.location}
          </p>
        </div>

      );
    },
  },
  
  {
    title: "Role",
    key: "role",
    dataIndex: "role",

    render: (text, record) => {
      return (
        <div>{record?.role}</div>
      )
    }
  },
  {
    title: "Privileges",
    key: "privilege",
    dataIndex: "privilege",
    render: (text, record) => {
      return (
        <Select disabled={status==='InActive'} className={classes.SelectOrg} style={{ width: "130px", }} dropdownStyle={{ zIndex: '0' }} value={text} onChange={(value) => {
          Api.patch("/ibelong-user/updateRole")
            .params({
              userId: record?.id,
              userPrivilege: value
            })
            .send(res => {
              // console.log("res",res)
              getApi()
            })
        }}>
          <Option value="Admin">Admin</Option>
          <Option value="EventOrganisers">Event Organiser</Option>
          <Option value="EventCoordinators">Event Coordinator</Option>
          <Option value="None">None</Option>
        </Select>
      )
    }
  },
  {
    // title: "House",
    title: <div key={status} style={{ display: "flex", justifyContent: "center", gap: "10px" }}> <span>House</span>
      {status!=='unAssigned'?handleHouseData(< FilterFilled style={{color:houseData !== "ALL" ? "#0086FF" : "black"}} />):''}  
      </div>,
    key: "houseName",
    dataIndex: "houseName",
    filterMultiple: false,
    render: (text, record) => {
      return <p className={classes.colItem}>{record.houseName}</p>;
    }
  },
  {
    // title: "Gender",
    title: <div key={status} style={{ display: "flex", justifyContent: "center", gap: "10px" }}> <span>Gender</span>
     {status!=='unAssigned'?handleGenderData(< FilterFilled style={{color: gender !== "" ? "#0086FF" : "black"}} />):''} 
      </div>,
    key: "gender",
    dataIndex: "gender",
    filterMultiple: false,
    render: (text, record) => {
      return <p className={classes.colItem}>{record.gender}</p>;
    }
  },
  ]
}
  else{
   column=[
  {
    title: "Associate",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {

      const profileImage = record.profileImage;
      return (
        // <NavLink
        //   to={{ pathname: "/userProfile", state: { data: record } }}
        //   className={classes.userProfile}
        // >
          <div className={classes.evenCol}>
            <div className={classes.col} style={{ display: 'flex', flexDirection: "column", gap: 10, }}  >
              <img src={profileImage !== null ? profileImage : profileIcon} alt="userimage" />
            </div>
            <div className={classes.col}>
              <h2 style={{ minWidth: '15px' }}>{record?.name}</h2>
              <p className={classes.colItem}>{record.uid}</p>
            </div>
            <div className={classes.col}>
            </div>
          </div>
        // </NavLink>
      );
    },
  },
  {
    title: "Contact Details",
    dataIndex: "contact",
    key: "contact",
    render: (text, record) => {
      return (
        <>
          <p className={classes.colItem}>{record?.email}</p>
          <p>{record.phone}</p>
        </>
      );
    },
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "deptartment",
    render: (text, record) => {
      return (
        <>
          <p className={classes.colItem}>
            {record?.department ? record?.department : "NA"}
            {/* {record.department} */}
          </p>
        </>
      );
    },
  },
  {
    title: <div style={{ display: "flex", justifyContent: "center", gap: "10px", }}><span>Location</span>{handleLocationData(<FilterFilled />)} </div>,
    key: "location",
    dataIndex: "location",

    filterMultiple: false,

    render: (text, record) => {
      return (
        <div>

          <p className={classes.colItem}>
            {/* {text} */}
            {record.location}
          </p>
        </div>

      );
    },
  },

  {
    // title: "House",
    title: <div key={status} style={{ display: "flex", justifyContent: "center", gap: "10px" }}> <span>House</span>
      </div>,
    key: "houseName",
    dataIndex: "houseName",
    filterMultiple: false,
    render: (text, record) => {
      return (<div>
                <ButtonCompo title={'Assign A House'} onClick={()=>{setAssignModal(record)}} prefixIcon={assignIcon} style={{backgroundColor:'#6FC78F',color:'#fff'}} />
              </div>
      );
    }
  },
  {
    // title: "Gender",
    title: <div key={status} style={{ display: "flex", justifyContent: "center", gap: "10px" }}> <span>Gender</span>
     {handleGenderData(< FilterFilled style={{color: "black"}} />)} 
      </div>,
    key: "gender",
    dataIndex: "gender",
    filterMultiple: false,
    render: (text, record) => {
      return <p className={classes.colItem}>{record.gender}</p>;
    }
  },
  ]
  }
  return column;
}

  const [data, setData] = useState([
  ]);
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("")
  const [responseData, setResponseData] = useState(null)
  const [tableLoader, settableLoader] = useState(false)
  const { year } = useSelector(state => state.auth);
  const getApi = () => {
    settableLoader(true)
    Api.get(`/ibelong-user/All`).params({ status: status==='unAssigned'?'Draft':status, search: search, queryYear: year, limit:search ? 25 :pageSize,page: currentPage, location: location, house: house , gender: gender}).send((response) => {
      let value = []
      let list = []
      if (response !== undefined) {
        //  console.log("response",response)
        let isMounted = true;
        list = response?.user?.items.map((element) => {
          value.push(element)
          return element
        });
        //  console.log(list)
        setData(list)
        setResponseData(response?.user)
        settableLoader(false)
      }
    })
  }
  useEffect(() => {
    setValue(true)
    settableLoader(true)
    Api.get(`/ibelong-user/All`).params({ status: status==='unAssigned'?'Draft':status, search: search, queryYear: year, limit:search ? 25 :pageSize,page: currentPage, location: location, house: house, gender: gender }).send((response) => {
      let value = [];
      let list = [];
      if (response !== undefined) {
        // console.log("response", response);
        let isMounted = true;
        list = response?.user?.items.map((element) => {
          value.push(element);
          return element;
        });
        setData(list);
        setResponseData(response?.user)
        setValue(false)
        settableLoader(false)
      }
    });
  }, [search, status, year, location, house,pageSize,currentPage,gender,window.location.pathname]);

  const activeBorder = status === "Active" ? "3px solid #6FC78F" : "0";
  const inactiveBorder = status === "InActive" ? "3px solid #6FC78F" : "0";
  const unassignedBorder=status === "unAssigned" ? "3px solid #6FC78F" : "0";
  
  const handleSearch = (value) => {
    setSearch(value);
    setCurrentPage(1); // Reset the current page to 1 when the search changes
  };
  
  
  return (
    <>
      {width > 700 && <div>
        <Row className={classes.dashboard} >
          <main className={classes.main} >
            <Col style={{ display: 'flex', alignItems: "center", gap: '10px' }}>
              <YearDropdown></YearDropdown>
            </Col>
            <Col className={classes.tableHeader} >
              <h1>All Users</h1>
              <SearchCompo value={search} setSearch={handleSearch} maxWidth="360px" />
            </Col>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "1rem",
              }}
            >
              <Button
                type="text"
                style={{
                  marginRight: "1.5rem",
                  backgroundColor: "#F3F6FA",
                  fontSize: "14px",
                  color: "#000000",
                  fontFamily: "poppins",
                  fontWeight: "bold",
                  padding: "0",
                  borderBottom: activeBorder,
                }}
                onClick={() => {
                  setStatus("Active");
                  setPageSize(25)
                  setCurrentPage(1)
                  return (
                    <div>
                      style={{ textDecoration: "underline solid green 2px" }}
                    </div>
                  );
                }}
              >
                Active Associates
              </Button>
              <Button
                type="text"
                style={{
                  marginRight: "1.5rem",
                  backgroundColor: "#F3F6FA",
                  // textDecorationStyle: "solid",
                  textDecorationThickness: "auto",
                  fontSize: "14px",
                  color: "#000000",
                  fontFamily: "poppins",
                  fontWeight: "bold",
                  padding: "0",
                  borderBottom: inactiveBorder,
                }}
                onClick={() => {setStatus("InActive")
                  setPageSize(25)
                  setCurrentPage(1)
                }}
              >
                Inactive Associates
              </Button>
              <Button
                type="text"
                style={{
                  marginRight: "1.5rem",
                  backgroundColor: "#F3F6FA",
                  // textDecorationStyle: "solid",
                  textDecorationThickness: "auto",
                  fontSize: "14px",
                  color: "#000000",
                  fontFamily: "poppins",
                  fontWeight: "bold",
                  padding: "0",
                  borderBottom: unassignedBorder,
                }}
                onClick={() => {setStatus("unAssigned")
                  setPageSize(25)
                  setCurrentPage(1)
                }}
              >
               Unassigned Associates
              </Button>
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
           {status==='unAssigned' ?<Button size="large" style={{ background: "none",color:'#6FC78F', fontFamily: "poppins", fontWeight: "bold", fontSize: "16px", border: "0", }} onClick={(e) => {
                 setViewMemberModal(true)
                 setPageSize(25)
                 setCurrentPage(1)
                }}>
                  View Member Count 
                </Button>:
                <Button size="large" style={{ background: "none", fontFamily: "poppins", fontWeight: "bold", fontSize: "16px", border: "0", }} onClick={(e) => {
                  Api.get(`/ibelong-user/All`).params({ status: status==='unAssigned'?'Draft':status, search: search, queryYear: year,download:true, location: location, house: house, gender: gender }).send((response) => {
                    console.log("response for download", response)
                    if (typeof response != "undefined") {
                      window.open(response, "_blank")?.focus();
                    }
                  })
                }}>
                  Download User Data <DownloadOutlined style={{ marginLeft: "0.5rem" }} />
                </Button>}
              </div>
            </div>
            <Table
              // onChange={(e, value) => { changeHandler(value) }}
              className="scroll"
              loading={tableLoader}
              columns={columnData()}
              dataSource={data}
              
              rowClassName={classes.tableStyle}
              style={{ fontFamily: "poppins", fontSize: "16px", }}
              pagination={{
                size:"small",
                showSizeChanger:true,
                pageSize:pageSize,
                current:currentPage,
                pageSizeOptions:[25, 30, 50, 100],
                onChange:(page, pageSize) => {            
                  setCurrentPage(page)
                  setPageSize(pageSize)      
                },
                total: responseData?.meta?.totalItems,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} users`
              }}
            />

          </main>
        </Row>
      </div>}

      {width <= 700 &&
        <div className={classes.userMobWrapper}>
          <YearDropdown></YearDropdown>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '96vw', alignItems: 'center' }}>
            <div style={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: 'bold' }}>All Users</div>
            <Button size="large" style={{ background: "none", fontFamily: "poppins", fontWeight: "bold", fontSize: "14px", border: "0", }} onClick={(e) => {
                  Api.get(`/ibelong-user/All`).params({ status: status==='unAssigned'?'Draft':status, search: search, queryYear: year, location: location, house: house }).send((response) => {
                  console.log("response for download", response)
                  if (typeof response != "undefined") {
                    window.open(response, "_blank")?.focus();
                  }
              })
            }}>
              Download User Data <DownloadOutlined style={{ marginLeft: "0.5rem" }} />
            </Button>
          </div>
          <div className={classes.userActiveness}>
            <span onClick={() => setStatus("Active")} style={{ color: status === "Active" ? "#000000" : "grey", borderBottom: status === "Active" ? "4px solid #6FC78E" : 0 }}>Active Associates</span>
            <span onClick={() => setStatus("InActive")} style={{ color: status === "InActive" ? "#000000" : "grey", borderBottom: status === "InActive" ? "4px solid #6FC78E" : 0 }}>Inactive Associates</span>
          </div>

          <div className={classes.mobSearchUsers}>
            <SearchCompo value={search} setSearch={handleSearch} />
          </div>
          {console.log("UserData", data)}
          {data.map((item, index) => {
            return (
              <UsersMob key={index} item={item} getApi={getApi} />
            )
          })}

        </div>
      }

      {assignModal!==undefined && <AssignModal setModal={setAssignModal} modal={assignModal} refresh={getApi.bind(this)} />}
      {viewMemberModal && <ViewMemberModal modal={viewMemberModal} setModal={setViewMemberModal} /> }
    </>
  );
};
export default Users;