import React from "react";
import Tablecomp from "./table";
import Cardcomp from "./Components/description";
import Scrollcard from "./Components/scrollcard";
import { useState, useEffect } from "react";
import classes from "./Components/captainComment.module.css";
import "./houses.css";
import "./prithvistyle.css";
import { useDispatch, useSelector } from "react-redux";
import CaptainComment from "./Components/captainComment";
import agni from "./img/agni.png"
import Api from "services/Api";
import { authActions } from "../store";
const data = {
  houseName: "AGNI",
  captainName: "ABC",
  viceCaptainName: "EFG",
  color: "#EC782A",
};

const Agni = () => {
  const [captains, setCaptains] = useState(false);
  const { house } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const data = [];
  let [value, setvalue] = useState({});
  const { year } = useSelector(state => state.auth);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  console.log("sssHousee",house)

  useEffect(() => {
    house.map((element) => {
      //console.log(element);
      if (element.name === "AGNI") {
        data.push(element);
      }
    });
    let value = {
      ...data[0],
      color: "#ECB189",
    };
    //if role is caption or vice-captain set setCaptains(true) otherwise set setCaptains(false)
    if(user?.privilege==="Admin" && user?.house==="AGNI"){
      setCaptains(user?.role==='Captain'||user?.role==='ViceCaptain')
  
     }
     else if(user?.privilege!=="Admin")
     {
      
    setCaptains(user?.role==='Captain'||user?.role==='ViceCaptain');
  }
    setvalue(value);
  }, [house]);

  useEffect(() => {
    if (token) {
      Api.get("/ibelong-houses/overview").params({ queryYear: year }).send((response) => {
        if (response !== undefined) {
          dispatch(authActions.updateHouses(response.houses));
        }
      });
    }
  }, [year]);

  return (
    <div className="house-container" >
      <Cardcomp   url={agni} data={value} />
      <div className={classes.scrollbar }>
        <Scrollcard  captains={captains} />

        {captains && <CaptainComment />}
      </div>

      <Tablecomp data={value} />
    </div>
  );
};

export default Agni;
