import React, { useState, useEffect } from "react";
import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  TreeSelect,
  Popover,
  Menu,
  Switch,
  Popconfirm,
  Button,
  message,
  Dropdown,
  Space,
  Typography,
  Spin,
} from "antd";
import profileIcon from "../../../images/profileIcon.jpg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { chatActions } from "./chatStore";
// import "./ChatConversation.css";
import "./ChatBox.css";
import Api from "services/Api";

function ChatDropDown({ setChatLoader, setSearch }) {
  const dispatch = useDispatch();
  const {
    singleChatPerson,
    openChatWindow,
    newMessage,
    allUser,
    chatData,
    unseenCount,
  } = useSelector((state) => state.chat);
  const { Paragraph, Text } = Typography;
  const [id, setId] = useState();
  const [startNewChat, setStartNewChat] = useState(allUser);
  const { user, allChatToggle } = useSelector((state) => state.auth);
  let now = moment();

  useEffect(() => {
    let res = allUser.filter(function (objFromA) {
      return !singleChatPerson.find(function (objFromB) {
        return objFromA.userId === objFromB.userId;
      });
    });

    setStartNewChat(res);
  }, [allUser, singleChatPerson]);

  useEffect(() => {
    console.log(id, singleChatPerson);
    singleChatPerson?.forEach((element) => {
      if (element?.chatId === id) {
        console.log(element);
        document.getElementById("searchUser").value = "aa";
        setSearch("");
        dispatch(chatActions.updateOpenChatWindow(element));
      }
    });
  }, [id, singleChatPerson]);

  const handleStartChat = async (val) => {
    console.log("okay", val.userId);
    Api.post("/ibelong-chat/createChat")
      .params({ participants: [val?.userId, user?.id] })
      .send((res) => {
        console.log("out side api");
        if (res !== undefined) {
          console.log("in side api", res);
          dispatch(chatActions.updateChatWindow(true));
          dispatch(chatActions.updateAllChatToggle(!allChatToggle));
          setId(res?.chatId);
        }
      });
  };

  return (
    <div className="chatDropDownContainer">
      <Col>
        <div style={{ paddingLeft: "10px", fontWeight: "bold", }}>
          Recent Chats
        </div>
        <div className="chatDropDownRecentChat">
          {singleChatPerson.length !== 0 ? (
            singleChatPerson?.map((ele) => {
              let lastActive;
              let n = chatData[ele?.chatId]?.length;
              let Msg = chatData[ele?.chatId];
              let lastMsg;
              let lastTime;
              if (n !== undefined && Msg !== undefined) {
                lastMsg = Msg[n - 1]?.message;
                lastTime = Msg[n - 1]?.createdAt;
              }
              let time = moment(
                lastTime !== undefined ? lastTime : ele?.lastActive
              );
              if (now.diff(ele?.lastActive, "days") > 7) {
                lastActive = time.format("DD/MM/YYYY");
              } else if (
                now.diff(ele?.lastActive, "days") > 1 &&
                now.diff(ele?.lastActive, "days") < 7
              ) {
                lastActive = time.format("dddd");
              } else {
                lastActive = time.format("hh:mma");
              }

              return (
                <div className="User" onClick={() => { setId(ele?.chatId); dispatch(chatActions.updateChatWindow(true)); }} >
                  <div className="User_individual" key={ele?.userId} style={{ height: "70px", backgroundColor: id === ele.chatId ? "#EBE9E9" : "white"}} >
                    <div style={{display:"flex",flexDirection:"row",gap:"rem",padding:0,margin:0,alignItems:"center",}}>
                      <img src={ele?.userProfileImage ? ele?.userProfileImage : profileIcon} alt="image" style={{ width: "40px", height: "40px", borderRadius: "50%",}} />
                    <div style={{ width: "100px",display:"flex"}}>
                      <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 14px/17px Poppins", maxWidth: "140px",}}> {ele?.userName ? ele?.userName : ele?.chatName}</Typography.Text>
                      <Paragraph style={{ font: "normal normal normal 10px/12px Poppins", maxWidth: "110px"}} ellipsis={true ? { rows: 2, expandable: false, symbol: "." } : false}>{lastMsg !== undefined ? lastMsg : ele?.lastMessage === "none" ? "" : ele?.lastMessage}   
                      </Paragraph> 
                      </div>
                    <div>{ele?.notification ? ((unseenCount[ele?.chatId] === undefined ? ele?.unseenMessages > 0 : unseenCount[ele?.chatId] > 0) ? 
                    (<div style={{ backgroundColor: "#A661FF", color: "white", padding: "4px", height: "24px", width: "20px", borderRadius: "5px", textAlign: "center", }}>        
                    {parseInt(ele?.unseenMessages)}</div>) : ("")) : ("")} </div>
                    </div>
                    <div style={{ width: ""   }}>{lastActive}</div>
                  </div>

                  <div style={{ borderTop: "0.5px solid #bfbfbf", margin: "0px 5%", }}
                  ></div>
                </div>
              );
            })
          ) : (
            <div style={{ paddingLeft: "10px", }}>No user found!!!</div>
          )}
        </div>
      </Col>

      <Col>
        <div style={{ paddingLeft: "10px", fontWeight: "bold" }}> Start a new Chat</div>

        <div className="ChatDrpDownStartNewChat">
          {startNewChat.length !== 0 ? (
            startNewChat?.map((ele) => {
              let lastActive;
              let n = chatData[ele?.chatId]?.length;
              let Msg = chatData[ele?.chatId];
              let lastMsg;
              let lastTime;

              if (n !== undefined && Msg !== undefined) {
                lastMsg = Msg[n - 1]?.message;
                lastTime = Msg[n - 1]?.createdAt;
              }
              let time = moment(
                lastTime !== undefined ? lastTime : ele?.lastActive
              );
              if (now.diff(ele?.lastActive, "days") > 7) {
                lastActive = time.format("DD/MM/YYYY");
              } else if (
                now.diff(ele?.lastActive, "days") > 1 &&
                now.diff(ele?.lastActive, "days") < 7
              ) {
                lastActive = time.format("dddd");
              } else {
                lastActive = time.format("hh:mma");
              }
              return (
                <div className="User" onClick={() => handleStartChat({ userId: ele?.userId })}>
                  {console.log("hii", ele)}
                  <div className="User_individual" key={ele?.userId} style={{ height: "70px", backgroundColor: id === ele.chatId ? "white" : "#EBE9E9", }}>
                    <div>
                      <img src={ele?.profileImage ? ele?.profileImage : profileIcon} alt="image" style={{ width: "40px", height: "40px", borderRadius: "50%", }} />
                    </div>
                    <div style={{ width: "200px" }}>
                      <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 14px/21px Poppins", maxWidth: "150px", }} >  {ele?.userName ? ele?.userName : ele?.chatName} </Typography.Text>
                      <Paragraph style={{ font: "normal normal normal 10px/12px Poppins", maxWidth: "200px", }} ellipsis={true ? { rows: 2, expandable: false, symbol: "." } : false} >  {lastMsg !== undefined ? lastMsg : ele?.lastMessage === "none" ? "" : ele?.lastMessage}   </Paragraph>
                    </div>
                    <div>
                      {ele?.notification ? ((unseenCount[ele?.chatId] === undefined ? ele?.unseenMessages > 0 : unseenCount[ele?.chatId] > 0) ? (
                        <div style={{ backgroundColor: "#A661FF", color: "white", padding: "4px", height: "24px", width: "20px", borderRadius: "5px", textAlign: "center", }}> {parseInt(ele?.unseenMessages)}</div>) : ("")) : ("")}
                    </div>
                    <div style={{ width: "90px" }}>{lastActive}</div>
                  </div>
                  <div style={{ borderTop: "0.5px solid #bfbfbf", margin: "0px 5%", }}></div>
                </div>
              );
            })
          ) : (
            <div style={{ paddingLeft: "10px", }}>No user found!!!</div>
          )}
        </div>
      </Col>
    </div>
  );
}

export default ChatDropDown;