import React from "react";
import { Modal ,Row,Button} from "antd";
import ReactPlayer from "react-player";
import PdfLogo from "../../../images/pdf-logo.svg"
const GroupFiles = (props) =>{
    //console.log("sdfghjk",props)
return(
    <Modal open={props.groupUpload} onCancel={()=>props.setgroupUpload(false)} footer={false} closable={true}>
        {props?.groupsMedia?.fileType==="application" ? <Row>{props?.groupsMedia?.record?.posts?.map((element) =>{
            for(let ele of element.postMedia){
            return(
                <div >
                  <img onClick={() => window.open(ele)} src={PdfLogo} style={{height:"200px",width:"200px"}} />
                </div>
            )}
        })}</Row>:props?.groupsMedia?.fileType==="image"?
            <Row>{props?.groupsMedia?.record?.posts?.map((element) =>{
              for(let ele of element.postMedia){
              return(
                <div >
                  <img onClick={() => window.open(ele)} src={ele} style={{height:"200px",width:"200px",marginLeft:"20px"}} />
                </div>
            )}
        })}</Row>:props?.groupsMedia?.fileType==="video"?
            <Row>{props?.groupsMedia?.record?.posts?.map((element) =>{
            for(let ele of element.postMedia){
            return(<>
                <Row >
                  <ReactPlayer style={{ marginTop: 30 }} height={"80%"} width={"300px"} config={{ file: { attributes: { controlsList: 'nodownload' } } }} onContextMenu={e => e.preventDefault()} url={ele}  controls={true} />
                </Row>
                <Row style={{marginTop:"40px",marginLeft:"20px"}}>
                  <Button onClick={() =>{window.open(ele) }}>Download</Button>
                </Row>
            </>)}
        })}</Row>:"" }
    </Modal>
)
}
export default GroupFiles