import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import '../DashboardModules/LocationChart.css'

const COLORS = ['#4ADA7B', '#25B655', '#197B3A', '#29854D', '#195F2B'];

const LocationChart = ({data,colors, totalLocationCount}) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0].payload;
  
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#fff',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px'
          }}
        >
          <div style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '2px',
                    backgroundColor: name==="coimbatore" ?colors[3]: name==="assam"? colors[4]: colors[2],
                  }}></div>
          <div style={{display: 'flex', flexDirection:'row', gap: '2px', marginRight: '10px', fontFamily: 'Roboto'}}>
              <div style={{ margin: 0, fontFamily: 'Roboto'}}>{name}</div>
              <div style={{color:'#616161', fontSize: '14px'}}>{Math.round(value/totalLocationCount*100)}%</div>
          </div>
          <div style={{ margin: 0 , fontWeight: 700}}>{value}</div>
        </div>
      );
    }
  
    return null;
  };
  
  return(
  <div className='LocationChart-container'>
    <h2>Location</h2>
  <PieChart width={250} height={250}>
    <Pie
      data={data}
      cx="50%"
      cy="50%"
      innerRadius={55}
      outerRadius={95}
      fill="#000000"
      paddingAngle={0}
      dataKey="value"
      className='LocationPie'
    >
      {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={colors[(index+2) % colors.length]} />
      ))}
    </Pie>
    <Tooltip content={CustomTooltip}/>
{/*     
     <Legend
  formatter={(value, entry, index) => <span className="text-color-class">{value}</span>}
/>  */}
  </PieChart>

    <div style={{display:'flex',flexDirection: 'row', justifyContent: 'space-between', fontSize: '14px'}}>
            {data.map((location, index) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                  <div style={{ width: '12px', height: '12px', borderRadius: '2px', backgroundColor: colors[(index+2) % colors.length] }}></div>
                  <p style={{ fontSize: '14px', marginBottom: '0px', fontFamily: 'Roboto' }}>{location.name}</p>
                </div>
              ))}
    </div>
  </div>
  );
};

export default LocationChart;
