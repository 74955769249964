



import React,{useState,useEffect} from 'react';
import Tablecomp from './table'

import Cardcomp from './Components/description'
import Slider from './slider';
import './prithvistyle.css'
import AkashImg from './EarthLogo.svg'
import Scrollcard from './Components/scrollcard';
import CaptainComment from './Components/captainComment';
import classes from './Components/captainComment.module.css';
import "./houses.css";
import Api from "services/Api";
import { authActions } from "../store";
import akash from "./img/akash.png"
import { useDispatch, useSelector } from 'react-redux';
import {Row,Col,Input, Button} from "antd"
import captainImg from './userImage.png';
const data={
houseName:'AKASH',
captainName:'ABC',
viceCaptainName:'EFG',
color:'#AA8FB5'
}
const { TextArea } = Input;
const Akash = () => {
  const [captains,setCaptains]=useState(false);
  const {house} = useSelector(state =>state.auth)
  const {user}=useSelector(state=>state.auth);
  const data=[]
  let [value,setvalue] = useState({})
  const { year } = useSelector(state => state.auth);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() =>{
   house.map((element) =>{
  
    if(element.name==="AKASH")
    {
       data.push(element)
    }
   })
   let value={
    ...data[0],
    color:'#AA8FB5'
   }
   setvalue(value)
   if(user?.privilege==="Admin" && user?.house==="AKASH"){
      setCaptains(user?.role==='Captain'||user?.role==='ViceCaptain')
  
     }
     else if(user?.privilege!=="Admin")
     {
   setCaptains(user?.role==='Captain'||user?.role==='ViceCaptain');
}
  },[house])

  useEffect(() => {
   if (token) {
     Api.get("/ibelong-houses/overview").params({ queryYear: year }).send((response) => {
       if (response !== undefined) {
         dispatch(authActions.updateHouses(response.houses));
       }
     });
   }
 }, [year]);

  return(
                     <div className='house-container'>
                     <Cardcomp url={akash} data={value}/>
                     {/* <Row >
                        <Col span={16}>
                        <div>
                           <Scrollcard />
                        </div></Col><Col span={7}>
                     <div style={{border: "1px solid #E6E6E6",height:"233px",width:"100%"}}>  <Row> <Col span={6}><img alt="example" src={captainImg} style={{marginTop:"80px",marginLeft:"30px",wdith:"100%",height:"30%"}} /> </Col><Col span={16}>
                        <div style={{backgroundColor:"white",height:"200px"}}>
                        <TextArea bordered={false} style={{width:"100%",marginTop:"50px"}} placeholder='Hey Captain Whats in your mind?' rows={4} /><Row justify='end'><Button style={{alignSelf:"end"}} type="text">Post</Button></Row></div> </Col></Row>
                        
                        </div> </Col> 
                        </Row> */}
                     <div className={classes.scrollbar}>
                     <Scrollcard  captains={captains}/>

                  {captains&&<CaptainComment />}
                        </div>

                     <Tablecomp data={value}/>
                  </div>
);
}
export default Akash;