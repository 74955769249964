import React, { useState, useEffect } from "react"

import { Modal, Upload, Row, Button, message, Form, Image } from "antd";
import uploadIcon from "../../../images/upload icon.svg"
import deleteIcon from "../../../images/delete icon.svg"
import Api from "services/Api";
import { useSelector, useDispatch } from "react-redux";
import { houseActions } from "../houses/store";
import Notification from "services/Notification";
import "./uploadFiles.css"

const UploadbyUserImg = (props) => {
  console.log("junoUploadbyUserImg", props)
  // const [uploaderId, setUploaderId] = useState()
  const { user } = useSelector(state => state.auth)
  const [fileList, setFileList] = useState("")
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState(false)
  const [imageUrl, setImageUrl] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const { videoModal, groupDetails } = useSelector(state => state.house)
  const dispatch = useDispatch()
  useEffect(() => {
    // console.log("resss",videoModal)
    if (videoModal === true) {
      props.setVideoModal(true)
    }
  }, [videoModal])



  // const uploadButton = (
  //   <div style={{color:"#6FC78F"}}>
  //     {loading ? <LoadingOutlined /> : <PlusOutlined />}
  //       Upload
  //   </div>
  // );


  const imgbeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return isJpgOrPng || Upload.LIST_IGNORE

    }
    else if (!isLt10M) {
      message.error("Image must smaller than 10MB!");
      return isLt10M || Upload.LIST_IGNORE
    }
    else {
      getBase64(file, (url) => {
        setLoading(false);
        setImageUrl(url);
        setImgFile(file)
      });
    }
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const [form] = Form.useForm()

  const showUploadList = {
    showPreviewIcon: false
  }

  // const handlePreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setPreviewImage(file.url || file.preview);
  //   setPreviewOpen(true);
  //   // setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  // };

  const handleRemoveImage = (file) => {
    const newList = imageList.filter((item) => item !== file);
    setImageList(newList);
  };


  let data = {}
  const handleUploadFiles = (values) => {
    // console.log("working",values)
    let files = []
    values?.files?.fileList?.forEach((val, index) => {
      files.push(val?.originFileObj)
    })
    data = { ...data, 'files': files }
    // console.log("let me see", files)

    if (videoModal === true) {
      Api.post(`/ibelong-user/uploadUserFile?eventId=${props?.details?.id}&groupId=${groupDetails?.id}`)
        .upload(data, (response, percent) => {
          console.log("junia", groupDetails, response)
          props.setVideoModal(false)
          dispatch(houseActions.openVideoModal(false))
          document.getElementById("formX").reset();
          setImageUrl("")
          setImgFile("")
          props?.refreshed();
        })
      return
    }
    Api.post(`/ibelong-user/uploadUserFile?eventId=${props?.details?.id}`)
      .upload(data, (response, percent) => {
        console.log("juniadata", data, response)
        props.setVideoModal(false)
        dispatch(houseActions.openVideoModal(false))
        setImageUrl("")
        setImgFile("")
        props?.refresh()
      })
  }

  return (
    <Modal id='abcd' open={props.videoModal}
      onCancel={() => {
        props?.setVideoModal(false);
        dispatch(houseActions.openVideoModal(false));
        setImageUrl("");
        setImgFile("");
        props?.setAddMedia();
        console.log("Taabbb", props?.Tab)
        if (props?.Tab === "Groups") {
          props?.refreshed();
        }
        else {

          props?.refresh();
        }
        console.log("UserId", user?.id, "uploaderId", props?.uploaderId)
      }
      } footer={false} destroyOnClose={true}>
      <div style={{ fontFamily: 'poppins', fontSize: '20px', fontWeight: 'bold', padding: '0 0 20px 0' }}>Upload files</div>
      <Form id="formX" form={form} onFinish={handleUploadFiles}>
        {console.log("rrr", props?.groupLeaderUpload)}
        {(props?.details?.eventType === "Individual" || props?.Tab === "Individual") ? (
          user?.id === props?.uploaderId || user?.role === "SuperAdmin") && (<Form.Item name="files">
            <Upload.Dragger
              name="file"
              accept=".png , .jpg , .svg"
              id="abcd"
              beforeUpload={imgbeforeUpload}
              listType='picture-card'
              style={{ backgroundColor: "#6FC78F1A", margin: '0 0 20px 0' }}
              // onRemove={() => {setImageUrl("");setImgFile("")}}  
              multiple={true}
              maxCount={5}
              key={imageList.length}
              // onPreview={()=> false}
              // showPreviewIcon = {false}
              showUploadList={showUploadList}
            >
              <img src={uploadIcon} />
              <p style={{ fontFamily: 'poppins' }}>Drag to this area or Click to Upload</p>
            </Upload.Dragger>
          </Form.Item>) :
          props?.Tab === "Groups" || props?.details?.eventType === "Group" ? (
            (user?.id === props?.uploaderId ||
              user?.privilege === "EventCoordinators" ||
              user?.role === "SuperAdmin" ||
              (props?.groupLeaderUpload === true && props?.event?.createdBy?.id == user?.id) ||
              (props?.groupRole === 'Group Leader' && props?.event?.createdBy?.id == user?.id)) && (<Form.Item name="files">
                <Upload.Dragger
                  name="file"
                  accept=".png , .jpg , .svg"
                  id="abcd"
                  beforeUpload={imgbeforeUpload}
                  listType='picture-card'
                  style={{ backgroundColor: "#6FC78F1A", margin: '0 0 20px 0' }}
                  // onRemove={() => {setImageUrl("");setImgFile("")}}  
                  multiple={true}
                  maxCount={5}
                  key={imageList.length}
                  // onPreview={()=> false}
                  // showPreviewIcon = {false}
                  showUploadList={showUploadList}
                >
                  <img src={uploadIcon} />
                  <p style={{ fontFamily: 'poppins' }}>Drag to this area or Click to Upload</p>
                </Upload.Dragger>
              </Form.Item>)
          )
            : null}

        <Form.Item>
          <div style={{ display: 'flex', flexWrap: 'wrap', width: '500px' }} id="image" >
            {props?.media?.map((val, id) => {
              return (
                <div className="uploadedImageContainer" key={"image" + id} id={"image" + id} style={{ width: "110px", height: "110px", margin: '0 10px 20px 0' }}>
                  <div className="image">
                    <img src={val?.postLink !== undefined ? val?.postLink : val?.postMedia[0]?.[0]} style={{ width: '110px', height: '110px', borderRadius: '6px' }}></img>
                    {(props?.details?.eventType === "Individual" || props?.Tab === "Individual") ? (user?.id === props?.uploaderId || user?.role === "SuperAdmin") &&
                      (<div className="icon"
                        onClick={() => {
                          {
                            Api.delete("/ibelong-events/deletePost").params({ postId: val?.postId !== undefined ? val?.postId : val?.id }).send((res) => {
                              if (res?.show?.type === 'error') {
                                console.log("error", res?.show?.type)
                              } else {
                                let deleteImage = document.getElementById(`image${id}`)
                                deleteImage.style.display = "none";
                                props?.refreshed();
                                val?.postLink !== undefined ? props?.refresh() : props?.refreshed();

                              }
                            })
                          }
                        }}
                      >
                        <img className="icon-img" src={deleteIcon}></img><p>Remove file</p></div>) :
                      props?.Tab === "Groups" || props?.details?.eventType === "Group" ? (
                        (user?.id === props?.uploaderId ||
                          user?.privilege === "EventCoordinators" ||
                          user?.role === "SuperAdmin" ||
                          (props?.groupLeaderUpload === true && props?.event?.createdBy?.id == user?.id) ||
                          (props?.groupRole === 'Group Leader' && props?.event?.createdBy?.id == user?.id)) &&
                        (<div className="icon"
                          onClick={() => {
                            {
                              // Api.delete("/events/deletePost").params({ postId: val?.postId !== undefined ? val?.postId : val?.id }).send((percent, res) => {
                              //   console.log("ressss", res)
                              //   let deleteImage = document.getElementById(`image${id}`)
                              //   deleteImage.style.display = "none";
                              //   val?.postLink !== undefined ? props?.refresh() : props?.refreshed();
                              // })
                              Api.delete("/ibelong-events/deletePost")
                                .params({ postId: val?.postId !== undefined ? val?.postId : val?.id })
                                .send((res) => {
                                  if (res?.show?.type === 'error') {
                                    console.error("cannot delete")
                                  }
                                  else {
                                    let deleteImage = document.getElementById(`image${id}`);
                                    deleteImage.style.display = "none";
                                    val?.postLink !== undefined ? props?.refresh() : props?.refreshed();

                                  }
                                })
                              // ?.then(response => {
                              //   console.log("Response:", response);
                              //   let deleteImage = document.getElementById(`image${id}`);
                              //   deleteImage.style.display = "none";
                              //   val?.postLink !== undefined ? props?.refresh() : props?.refreshed();
                              // })
                              // .catch(error => {
                              //   console.error("Error:", error);
                              // });

                            }
                          }}
                        >
                          <img className="icon-img" src={deleteIcon}></img><p>Remove file</p></div>)
                      ) : null
                    }
                  </div>
                </div>
              )
            })}</div>
        </Form.Item>


        {(props?.details?.eventType === "Individual" || props?.Tab === "Individual") ? (user?.id === props?.uploaderId || user?.role === "SuperAdmin") && (
          <Form.Item>
            <Row justify="center"><Button type="primary" htmlType="submit" disabled={!imgFile}>Submit</Button></Row>
          </Form.Item>) : props?.Tab === "Groups" || props?.details?.eventType === "Group" ? (
            (user?.id === props?.uploaderId ||
              user?.privilege === "EventCoordinators" ||
              user?.role === "SuperAdmin" ||
              (props?.groupLeaderUpload === true && props?.event?.createdBy?.id == user?.id) ||
              (props?.groupRole === 'Group Leader' && props?.event?.createdBy?.id == user?.id))
            && (
              <Form.Item>
                <Row justify="center"><Button type="primary" htmlType="submit" disabled={!imgFile}>Submit</Button></Row>
              </Form.Item>)
          ) : null
        }
      </Form>
    </Modal>
  )
}
export default UploadbyUserImg