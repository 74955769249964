import React, { useEffect, useState } from "react";
import Api from "services/Api";
import ChatMenu from "./ChatMenu";
import './ChatBox.css'
import { Col, Form, Input, Modal, Row, Select, TreeSelect, Popover, Menu, Switch, Popconfirm, Button, message, Dropdown, Space, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import profileIcon from "../../../images/profileIcon.jpg";
import SingleChatConversation from "./SingleChatConversation";
import creategrpIcon from "../../../images/creategrpIcon.svg";
import filterIcon from "../../../images/filterIcon.svg";
import ChatCreategrp from "./ChatCreateGrp";
import { chatActions } from "./chatStore";
import { useDispatch, useSelector } from "react-redux";
import credentials from "../../../credentials";
import socketIO from "socket.io-client";
import GrpChatConversation from "./GrpChatConversation";
import { MailOutlined, SettingOutlined } from '@ant-design/icons';
import GrpChatMenu from "./GrpChatMenu";
import { async } from "q";
import store from "store";
import Search from "antd/lib/input/Search";
import ChatCreateConversation from "./ChatCreateConversation";
import SearchCompo from "component/searchCompo";
import ChatDropDown from "./ChatDropDown";
import backLogo from "../../../images/backLogo.png";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}

function Chat(props) {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const Unread = (value) => {
  }

  const items = [
    {
      key: '1',
      type: 'group',
      label: 'Filter Chats by',
      children: [
        {
          key: '1-1',
          label: (<a onClick={Unread}>Unread</a>),
        },
      ],
    },
  ]

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [chatLoader, setChatLoader] = useState(false);
  const [grpChatLoader, setGrpChatLoader] = useState(false)
  const { user, chatToken } = useSelector(state => state.auth)
  const { allUser, singleChatPerson, grpChatPerson, chatWindow, grpSelected, openChatWindow, allChatToggle } = useSelector(state => state.chat)
  const socket = socketIO(credentials?.socketUrl);
  const [search, setSearch] = useState("");
  const [userClick, setUserClick] = useState(false)
  const handleBackClick = () => {
    if (chatWindow && window.innerWidth < 768) {
      dispatch(chatActions.updateChatWindow(false));
    } else {
      console.log("return1111")

    }
  };

  useEffect(() => {
    setSearchLoader(true)
    Api.get("/ibelong-chat/houseUsers").params({ search: search }).send(async (res) => {
      if (res !== undefined) {
        await dispatch(chatActions.updateAllUser(res));
      }
      setSearchLoader(false);
    })
  }, [search])
  const userUserClickUpdate = () => { setUserClick(true) }
  useEffect(() => {
    console.log("connecting to server")
    socket.on("connection", (res) => {

    })
    socket.on(chatToken, async (res) => {
      console.log("received", res)
      await dispatch(chatActions.updateChatData({ chatData: res, type: "RECEIVE" }))
      setTimeout(async () => {
        let temp = await store.getState().chat.openChatWindow;
        // let temp1=await store.getState().chat.singleChatPerson;
        // console.log("chatGPT in receive",temp,)
        if (res?.chat?.id !== temp?.chatId) {
          // let flag=temp1.find((val)=>{
          //   return val?.chatId===res?.chat?.id
          // })
          // if(flag===undefined)
          // {
          dispatch(chatActions.updateAllChatToggle(!allChatToggle));
        }

      }, 10)
    })
  }, [])

  const [filterAllUser, setFilterAllUser] = useState([])
  const [grpModal, setgrpModal] = useState(false);
  const [conversationModal, setConversationModal] = useState(false);
  const [conversationType, setConversationType] = useState('Non Group');
  const [searchLoader, setSearchLoader] = useState(false);
  const { height, width } = useWindowDimensions();

  const getAllChatApi = () => {
    setLoader(false)
    Api.get("/ibelong-chat/allChats").params({ search: search, searchType: grpSelected ? "Group" : "Non Group" }).send(async (res) => {
      if (res !== undefined) {
        await dispatch(chatActions.updateSingleChatPerson(res?.nonGroup));
        await dispatch(chatActions.updateGrpChatPerson(res?.group));

        res?.group?.forEach((val) => {
          socket.on(val?.chatReceiveToken, async (res) => {
            console.log("group received", res)
            await dispatch(chatActions.updateChatData({ chatData: res, type: "RECEIVE" }))
            setTimeout(async () => {
              let temp = await store.getState().chat.openChatWindow;
              if (res?.chat?.id !== temp?.chatId) {
                dispatch(chatActions.updateAllChatToggle(!allChatToggle));
              }
            }, 100)

          })
        })
        setLoader(false)
      }
    });
  }
  useEffect(() => {
    getAllChatApi();
  }, [allChatToggle, search])

  return (
    <div style={{ width: '100vw', backgroundColor: '#f3f6fa', position: 'fixed', padding: '5px', marginTop: '5px', }}>
      <Row className="HeadingRow">
        {chatWindow ? (
          <Row className="backIcon" onClick={handleBackClick} style={{ display: window.innerWidth > 700 ? 'none' : 'block' }}>
            <img style={{ width: '100%', height: '100%', cursor: 'pointer' }} src={backLogo} alt="Back" />
          </Row>
        ) : null}
        {window.innerWidth < 700 ? (<div className="MessagesMob">Messages</div>
        ) : (
          <h1>Messages</h1>
        )}
      </Row>
      <Row className="ChatsRow">
        <Col className={`ChatPersons ${chatWindow ? 'hide' : ''}`}>
          <div className="searchdivCont" >
            <SearchCompo setSearch={setSearch} value={search}></SearchCompo>{search.length > 0 && conversationType === 'Non Group' ? (
              <Col className="ChatDropDownCol">
                <ChatDropDown setSearch={setSearch} />
                </Col>
            ) : (
              <div></div>
            )}
            {grpSelected ? (<div className="creategrpIconDiv"><img src={creategrpIcon} onClick={() => { setgrpModal(true); }} style={{ cursor: 'pointer', width: '100%', height: '100%' }} /></div>
            ) : (
              ''
            )}
            {grpModal && (<ChatCreategrp grpModal={grpModal} setgrpModal={setgrpModal}></ChatCreategrp>)}
            {conversationModal && (<ChatCreateConversation setConversationModal={setConversationModal} conversationModal={conversationModal} ></ChatCreateConversation>)}
          </div>
          <Row className="group_container"> <button className="convo" onClick={() => {
            dispatch(chatActions.updateGrpSelected(false));
            dispatch(chatActions.updateChatWindow(false));
            dispatch(chatActions.updateOpenChatWindow(null));
            setConversationType('Non Group');
          }}
            style={{ backgroundColor: grpSelected ? 'white' : '#C0E0FE', }} >
            Conversations
          </button>
            <button className="grp" onClick={() => {
              dispatch(chatActions.updateGrpSelected(true));
              dispatch(chatActions.updateChatWindow(false));
              dispatch(chatActions.updateOpenChatWindow(null));
              setConversationType('Group');
            }}
              style={{ backgroundColor: grpSelected ? '#C0E0FE' : 'white', }} >
              Groups
            </button>
          </Row>
          {loader ? (<Row justify="center" style={{ marginTop: '10vh', }}> <Spin size="large" style={{ alignSelf: 'center', height: '60vh' }} /> </Row>
          ) : (
            <div className="chat_main">{grpSelected ? (grpChatPerson?.length !== 0 ? (<GrpChatMenu userUserClickUpdate={userUserClickUpdate} setGrpChatLoader={setGrpChatLoader} search={search} ></GrpChatMenu>
            ) : (
              'No previous Chat! please Select A person to chat'
            )
            ) : (
              <ChatMenu setChatLoader={setChatLoader} search={search}></ChatMenu>
            )}
            </div>
          )}
        </Col>
        {width > 700 && (
          <Col className="chatWindow">{grpSelected ? (chatWindow ? (<GrpChatConversation socket={socket} userClick={userClick} grpChatLoader={grpChatLoader} ></GrpChatConversation>
          ) : (
            <div className="chatBox"><div className="message">Click on any message to appear </div> </div>
          )
          ) : chatWindow ? (<SingleChatConversation socket={socket} chatLoader={chatLoader} />
          ) : (
            <div className="chatBox"><div className="message"> Click on any message to appear </div> </div>
          )}
          </Col>
        )}
        {width < 700 && (
          <Col className="chatWindowMobile">
            {grpSelected ? (
              chatWindow ? (
                <GrpChatConversation socket={socket} userClick={userClick} grpChatLoader={grpChatLoader} ></GrpChatConversation>
              ) : (
                null
              )
            ) : chatWindow ? (
              <SingleChatConversation socket={socket} chatLoader={chatLoader} />
            ) : (
              null
            )}
          </Col>
        )}
      </Row>
    </div>
  )
}
export default Chat;