import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Row } from "antd";
import ErrorGift from '../../images/ErrorGift.gif'
import credentials from "credentials";
function NotFound() {
    console.log("notfound")
    const history = useHistory();
    const Goback = () => {
        window.location.href = credentials?.backendUrl

    };
    return (
        <Row style={{ height: '100vh', width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', zIndex: '9999' }}>
            <Row style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'sans-serif', fontWeight: '800', justifyContent: 'center' }}>
                <img style={{ width: '25%', height: '25%' }} src={ErrorGift}></img>
            </Row>
            <Row style={{ height: 'fit-content', width: '100%', justifyContent: 'center', fontWeight: '500', fontSize: '14px', display: 'flex', gap: '7px', marginTop: '8px', cursor: 'pointer', marginTop: '12px' }} ><button style={{ backgroundColor: '#0086FF', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer', padding: '4px' }} onClick={Goback}>Back to HomePage</button></Row>
        </Row>
    )
}
export default NotFound;