import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  LabelList,
} from "recharts";
import '../modules/NormalisedBar.css'

// const data = [
//   { name: "Prithvi", male: 400, female: 538, color: "#00C49F" },
//   { name: "Agni", male: 532, female: 500, color: "#FFBB28" },
//   { name: "Vayu", male: 345, female: 500, color: "#FF8042" },
//   { name: "Jal", male: 450, female: 442, color: "#0088FE" },
//   { name: "Akash", male: 500, female: 486, color: "#A28AD5" },
// ];

// const normalizedData = data.map((d) => {
//   const total = d.male + d.female;
//   return {
//     name: d.name,
//     male: (d.male / total) * 100,
//     female: (d.female / total) * 100,
//     color: d.color,
//   };
// });

const CustomNormalisedHorizontalStackedBar = ({normalizedData,label, color}) => {
  
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      // const {x,y} = coordinate;
      const { name, Male,Female, MalePercentage, FemalePercentage } = payload[0].payload;
      
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#fff',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '0px',
            height:'140px',
            pointerEvents: 'none',
            transform: 'translate(-100%, -100%)', // Move tooltip top-left relative to the data point
          }}
        >
        <div style={{display:'flex',flexDirection:'column'}}>
          <h4>{name}</h4>
          <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
            <div style={{fontFamily:'Roboto'}}>Overall  {Male+Female}</div>
            <div >{Math.round(MalePercentage+FemalePercentage)}%</div>
          </div>
         

          <div style={{display:'flex', justifyContent:'space-between'}}>
              <div style={{display: 'flex', flexDirection:'row', alignItems:'center', gap:'4px'}}>
                      <div style={{
                        width: '12px',
                        height: '12px',
                        borderRadius: '2px',
                        backgroundColor:  color[0],
                      }}></div>
                    
                      <div style={{ margin: 0, fontFamily: 'Roboto'}}>Male</div>
                      <div style={{color:'#616161', fontSize: '14px'}}>{Male}</div>
                </div>
            <div style={{fontWeight: 600}}>{Math.round(MalePercentage)}%</div>
          </div>


          <div style={{display:'flex', justifyContent:'space-between'}}>
              <div style={{display: 'flex', flexDirection:'row', alignItems:'center', gap:'4px'}}>
                      <div style={{
                        width: '12px',
                        height: '12px',
                        borderRadius: '2px',
                        backgroundColor:  color[1],
                      }}></div>
                    
                      <div style={{ margin: 0, fontFamily: 'Roboto'}}>Female</div>
                      <div style={{color:'#616161', fontSize: '14px'}}>{Female}</div>
                </div>
            <div style={{fontWeight: 600, marginLeft:'20px'}}>{Math.round(FemalePercentage)}%</div>
          </div>
          </div>
        </div>
      );
    }
  
    return null;
  };
  

  return (
   
  <ResponsiveContainer width="100%" height={45}>
    <BarChart
      layout="vertical"
      data={normalizedData}
      barSize={40}
      margin={{
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <XAxis type="number" axisLine={false} tickLine={false} tick={false} />
      <YAxis
        type="category"
        dataKey="name"
        axisLine={false}
        tickLine={false}
        tick={false}
      />
      <Tooltip content={CustomTooltip}/>
      {label?.map((item, index) => (
            <Bar dataKey={label?.[index]} stackId="a" fill={color[index]}>
            {normalizedData?.map((entry, indexes) => (
              <Cell key={`cell-${indexes}`} fill={color[(0+index) % color.length]} />
              
            ))}
            
          </Bar>

      ))}
      
  
      {/* <Bar dataKey="male" stackId="a" fill="#30ADFF">
        {normalizedData?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill="#30ADFF" />
        ))}
      </Bar>
      <Bar dataKey="female" stackId="a" fill="#008AE6">
        {normalizedData?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill="#008AE6" />
        ))}
      </Bar> */}
    </BarChart>
    
  </ResponsiveContainer>
 
  );
  
};

export default CustomNormalisedHorizontalStackedBar;
