import React, { useEffect, useRef, useState } from "react";
import { Modal, Upload, Row, Button } from "antd";
import ReactPlayer from "react-player";
import classes from "./playVideo.module.css";
const PlayVideo = (props) => {
  const [video, setVideo] = useState(false);
  const videoRef = useRef(null);


  useEffect(() => {
    if (props.details !== "") {
      setVideo(true);
    }
  }, [props?.details, props?.checked]);

  // useEffect(() => {
  //   if (video) {
  //     if (videoRef.current) {
  //       videoRef.current.getInternalPlayer().pause();
  //     }
  //   }
  // }, [video]);

  return (
    <>
      <Modal
        open={video}
        onCancel={() => {
          setVideo(false);
          if (videoRef.current) {
            videoRef.current.getInternalPlayer().pause();
          }
          // props?.setPlay("");
        }}
        footer={false}
        closable={true}
      >
        <div className={classes.videoPlayer}>
          <ReactPlayer
            height={"40vh"}
            ref={videoRef}
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
            onContextMenu={(e) => e.preventDefault()}
            url={props?.details}
            controls={true}
          />
        </div>
      </Modal>
    </>
  );
};
export default PlayVideo;
