import React, { useEffect,useState } from "react";
import "antd/dist/antd.css";
import { BrowserRouter as Router, Switch, Route,  } from "react-router-dom";
import Layout from "component/Layout/Layout";
import Main from "component/Main/Main";
import Api from "services/Api";
import { authActions } from "component/Pages/store";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import credentials  from "credentials";
// var sessionstorage=require("sessionstorage");

function App() {
  const dispatch=useDispatch()
  const {token, year} =useSelector((state) =>state.auth)

   let hash_value

  
  useEffect(() =>{
    let params = window.location.href;
    const hashIndex = params.indexOf('hash=');
    let hash_value = null;
    if (hashIndex !== -1) {
      hash_value = params.substring(hashIndex + 5); 
    }
    console.log("sssToken",token,hash_value)
    const today=parseInt(moment().format("YYYY"));
    dispatch(authActions.updateYear(today))
    // if(token){
    //   return
    // }
    Api.post(`/ibelong-auth/returnData?loginEncryption=${hash_value}`)
    .params({})
    .send((response,error) =>{

      console.log("sssresponse123",response,"error123",error)
      if(response!==undefined)
      {
        let platform=credentials?.backendUrl;
        if(response?.show?.type === "error"){
          setTimeout(()=>{
            window.open("https://newpmt.brigosha.com", "_self");
          },600)
          return;
        }
        else{
          dispatch(authActions.updateToken({token:response?.accessToken}))
          dispatch(authActions.updateUser({user:response?.user, chatToken:response?.chatToken}))
          dispatch(authActions.updateUserImage({userImage:response?.userImage}))
          dispatch(authActions.updateChatToken({chatToken:response?.chatToken}))
      }}
    })
  },[]
  )
  useEffect(() => {
    if (token) {
      Api.header(token);
    }
  }, [token]);




  return (
    



    <Router>
      <Switch>
        <Route path="/">

          <Layout>
            <Main/>
          </Layout>


        </Route>
      </Switch>
    </Router>
  );
}

export default App;



