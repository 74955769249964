import classes from './Chats.module.css';
import React from "react";
// import Modal from "react-modal";
import { Modal } from "antd";
import "./ChatConversation.css";
import Chat from "./Chat";
import UserProfile from "../../../images/userProfile.png";
import profileIcon from "../../../images/profileIcon.jpg";
import SingleMessage from "./SingleMessage";
import sendIcon from "../../../images/sendIcon.svg";
import fileIcon from "../../../images/fileIcon.svg";
import removeIcon from "../../../images/removeIcon.svg";
import downloadIcon from "../../../images/downloadIcon.svg";
import shareIcon from "../../../images/shareIcon.svg";
import fileText from "../../../images/fileText.svg";
import moreVertical from "../../../images/moreVertical.svg";
import moreHorizontal from "../../../images/moreHorizontal.svg";
import { CloseOutlined } from "@ant-design/icons";
import SearchCompo from "component/searchCompo";
import emailIcon from "../../../images/emailIcon.png";
import FileShareModal from "./FileShareModal";
import {
  Button,
  Form,
  Input,
  Row,
  Dropdown,
  Space,
  Col,
  Typography,
  Spin,
  Image,
  Upload,
  message,
  List,
  Select,
  Tabs,
} from "antd";
import Api from "services/Api";
import { useState, useEffect, useContext, useRef } from "react";
import moment from "moment";
import { chatActions } from "./chatStore";
import { useDispatch, useSelector } from "react-redux";
import InputEmoji from "react-input-emoji";
import { IconContext } from "react-icons";
import { width } from "@mui/system";
import ChatMenu from "./ChatMenu";
import TextArea from "antd/lib/input/TextArea";
import { Progress } from "antd";
import ChatCreateConversation from "./ChatCreateConversation";
import smileEmojiIcon from "../../../images/smile icon.svg";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import { Mention, MentionsInput } from "react-mentions";
import mentionsInputStyle from "./mentionsInputStyle";
import mentionStyle from "./mentionStyle";
import darkSendIcon from "../../../images/darkSendIcon.svg"; // import socketIO from "socket.io-client";

const props = {
  headers: {
    authorization: "authorization-text",
  },
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  name: "file",
};
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
function SingleChatConversation({ socket, chatLoader, getChatPerson }) {
  const {
    chatData,
    openChatWindow,
    allChatToggle,
    allUser,
    grpChatPerson,
    singleChatPerson,
  } = useSelector((state) => state.chat);
  const { user, userImage, chatToken } = useSelector((state) => state.auth);
  const [getMsgToggle, setMsgToggle] = useState(false);
  const dispatch = useDispatch();
  const [dateDay, setDateDay] = useState("");
  const currDate = useRef("");
  const msgId = useRef("");
  const [file, setFile] = useState("");
  const [value, setValue] = useState("");
  const [fileList, setFileList] = useState(new Map());
  const [update, setUpdate] = useState(false);
  const [OpenShareModal, SetOpenShareModal] = useState(false);
  const [filesVisible, setFilesVisible] = useState(false);
  const [fileListVisible, setFileListVisible] = useState(false);
  const [fileName, setFileName] = useState([]);
  const [loader, setLoader] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [singleChatModal, setSingleChatModal] = useState(false);
  const [modalAboutShareOpen, setmodalAboutShareOpen] = useState(false);
  const [fileLists, setFileLists] = useState([]);
  const [search, setSearch] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [mediaChat, setmediaChat] = useState([]);
  const [filteredMedia, setFilteredMedia] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [FileShareModalOpen, setFileShareModalOpen] = useState(false);
  const { Paragraph, Text } = Typography;
  const [allMentionedUsers, setAllMentionedUsers] = useState([]);
  const [visible, setVisible] = useState(false);

  const myOnEmojiClick = (emojiData: EmojiClickData, event: MouseEvent) => {
    setValue((prevState) => prevState + emojiData.emoji);
    setVisible(false);
    document.getElementById("onFoucsInput").focus();
  };

  const emojiVisibleHandler = () => {
    setVisible(!visible);
  };
  const handleShareIconClick = (mediavalue) => {
    setFileShareModalOpen(true);
    setSelectedMedia(mediavalue);
  };
  // useEffect(() => {
  //   console.log("jjj333")
  //   setSearchLoader(true);
  //   setTimeout(() => {
  //     Api.get("/chat/getMedia").params({ search: search, chatId: openChatWindow?.chatId, userId: user?.id, })
  //       .send(async (res) => {
  //         await dispatch(chatActions.updateChatData(res));
  //         console.log("111Response", res)
  //         setmediaChat(res);
  //         if (Array.isArray(res)) {
  //           const mediaFiles = res.map((item) => item.media);
  //           setFilteredMedia(mediaFiles);
  //         } else {
  //           // console.log('mediaChat is not an array');
  //         }
  //         setSearchLoader(false);
  //       });
  //   }, 2000);
  // }, [search, openChatWindow]);


  // useEffect(() => {
  //   setSearchLoader(true);
  //   setTimeout(() => {
  //     Api.get("/chat/getMedia")
  //       .params({ search: search, chatId: openChatWindow?.chatId, userId: user?.id })
  //       .send(async (res) => {
  //         await dispatch(chatActions.updateChatData(res));
  //         console.log("111Response", res);
  //         const uniqueResponses = new Set();
  //         res.forEach((response) => {
  //           if (!uniqueResponses.has(response.id)) {
  //             uniqueResponses.add(response.id);
  //             console.log("111Responseafterfilter", response);
  //             setmediaChat(response);
  //           }
  //         });

  //         if (Array.isArray(res)) {
  //           const mediaFiles = res.map((item) => item.media);
  //           setFilteredMedia(mediaFiles);
  //         } else {
  //           // console.log('mediaChat is not an array');
  //         }
  //         setSearchLoader(false);
  //       });
  //   }, 2000);
  // }, [search, openChatWindow]);


  // const numericSearch = parseInt(search); 
  // const numericChatId = parseInt(openChatWindow?.chatId);
  // const numericUserId = parseInt(user?.id); 

  // Api.post(`/your/api/endpoint`)
  //   .params({ search: search, chatId: numericChatId, userId: numericUserId })
  //   .send((response) => {
  //     console.log(response);
  //   });
  const getMediaFunc = (() => {
    console.log("mediaaa")
    Api.get("/ibelong-chat/getMedia")
      .params({ search: search, chatId: openChatWindow?.chatId, userId: user?.id })
      .send(async (res) => {
        await dispatch(chatActions.updateChatData(res));
        console.log("mediaaaResponse", res, 'jooochatids', openChatWindow?.chatId, 'jooouser', typeof (user?.id));
        const uniqueResponses = new Set();
        const filteredMediaFiles = [];
        res.forEach((response) => {
          if (!uniqueResponses.has(response.id)) {
            uniqueResponses.add(response.id);
            console.log("111Responseafterfilter", response);
            filteredMediaFiles.push(response.media);
          }
        });

        setFilteredMedia(filteredMediaFiles);
        setSearchLoader(false);
      });
  })

  useEffect(() => {
    setSearchLoader(true);
    setTimeout(() => {
      getMediaFunc();

    }, 2000);
  }, [search, openChatWindow]);


  const { Search } = Input;

  const handleChange = (info) => {
    console.log("Handlechangejjjj", info)
    const { status, response, fileList: newFileList } = info;
    const userId = openChatWindow?.chatId;
    console.log("jjj000", userId)
    fileList?.get(userId)?.map((ele) => {
      console.log("jjj111", ele);
    });
    fileList.set(userId, newFileList);
    // console.log("jjj222", fileList, "jjj333", newFileList, "jjj444", userId);
    setUpdate(!update);
    setFileList(fileList);
    console.log("jjj222", fileList, "jjj333", newFileList, "jjj444", userId);

  };

  const handleRemove = (file) => {
    const userId = openChatWindow?.chatId;
    const index = fileList?.get(userId)?.indexOf(file);
    const list = fileList?.get(userId);
    list.splice(index, 1);
    setUpdate(!update);
    fileList?.set(userId, list);
    setFileList(fileList);
  };

  const ShareModal = () => {
    SetOpenShareModal(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendMessageHandler();
    }
  };


  const myTst = (e) => {
    setValue(e.target.value);
    setVisible(false);
  };
  const MuteFunc = () => {
    Api.patch("/ibelong-chat/chatActions")
      .params({
        chatId: openChatWindow?.chatId,
        mute: true,
        archive: false,
        clearChat: false,
      })
      .send((res) => {
        dispatch(chatActions.updateAllChatToggle(!allChatToggle));
      });
  };
  const DeleteFunc = () => {
    Api.patch("/ibelong-chat/chatActions")
      .params({
        chatId: openChatWindow?.chatId,
        mute: false,
        archive: false,
        clearChat: true,
      })
      .send((res) => {
        setMsgToggle(!getMsgToggle);

        dispatch(
          chatActions.updateChatData({
            chatId: openChatWindow?.chatId,
            type: "DELETE",
          })
        );
        dispatch(chatActions.updateAllChatToggle(!allChatToggle));
      });
  };
  const itemspop = [
    {
      key: "1",
      label: (
        <a onClick={MuteFunc}>
          {openChatWindow?.notification ? "Mute" : "Unmute"}
        </a>
      ),
    },
    {
      key: "2",
      label: <a onClick={DeleteFunc}> Clear Chat</a>,
    },
  ];

  useEffect(() => {
    socket.on(chatToken, async (res) => {
      await dispatch(
        chatActions.updateMessageData({ message: res, api: false })
      );
    });
  }, []);
  const dateHandler = (dateToday) => {
    if (dateDay !== dateToday) {
      setDateDay(dateToday);
      return true;
    } else return false;
  };

  const filterMedia = (e) => {
    if (Array.isArray(mediaChat)) {
      const mediaFiles = mediaChat?.map((item) => item.media);
      setFilteredMedia(mediaFiles);

      if (e.target.value === "") {
        setFilteredMedia(mediaFiles);
      } else {
        const filteredMedia = mediaFiles?.filter((media) => {
          const fileName = media[0]?.split("/").pop();
          return fileName.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setFilteredMedia(filteredMedia);
      }
    } else {
    }
  };

  const items = [
    {
      key: "3",
      label: `Files`,
      children: (
        <div>
          <div>
            <div>
              {/* <Search
                setSearch={setSearch}
                placeholder="search "
                onChange={filterMedia}
                allowClear
                style={{ width: "100%" }}
              /> */}
            </div>
            {/* <Row style={{ border: "2px solid green" }} className={classes.SharedFileList}>
              {filteredMedia?.map((media, key) => (
                <Row className={classes.filteredMedia} >
                  {media?.map((mediavalue, index) => {
                    console.log("111allmedia", mediavalue)
                    const fileName = mediavalue?.split("/").pop();
                    const downloadUrl = mediavalue;
                    const handleDownload = (fileUrl, filename) => {
                      fetch(fileUrl, {
                        headers: {
                          "Content-Type": "application/octet-stream",
                        },
                        method: "GET",
                        mode: "cors",
                      })
                        .then((response) => response.blob())
                        .then((blob) => {
                          const url = window.URL.createObjectURL(blob);
                          const link = document.createElement("a");
                          link.href = url;
                          link.setAttribute(
                            "download",
                            fileUrl?.split("/").pop()
                          );
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                          window.URL.revokeObjectURL(url);
                        })
                        .catch((error) => console.log(error));
                      window.open(fileUrl, "_blank")?.focus();
                    };
                    const fileType = mediavalue?.split(".")?.pop()?.toLowerCase();
                    const isImage = ["jpg", "jpeg", "gif", "bmp", "png",].includes(fileType);
                    return (
                      <Col className={classes.Files} >
                        <Col className={classes.filePreview} >
                          {isImage ? (<img src={mediavalue} alt={null} width="100%" height="100%" />
                          ) : (
                            <Col className={classes.fileText}>
                              <img src={fileText} alt={null} width="100%" height="100%" />
                            </Col>
                          )}
                        </Col>
                        <Col className={classes.fileNameFit}>
                          <Typography.Text ellipsis={{ tooltip: true }}>
                            {fileName}
                          </Typography.Text>
                        </Col>
                        <Col className={classes.shareIconcol} >
                          <img style={{ marginRight: "0.5rem", width: '100%', height: '100%' }} src={shareIcon} onClick={() => handleShareIconClick(key + '-' + index)} />
                          {FileShareModalOpen && <FileShareModal isOpen={key + '-' + index == selectedMedia ? FileShareModalOpen : false} onClose={() => setFileShareModalOpen(false)} selectedMedia={mediavalue} socket={socket} />}
                        </Col>
                        <Col className={classes.downloadIconcol}>
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={downloadIcon}
                            onClick={() => handleDownload(mediavalue)}
                          ></img>
                        </Col>
                      </Col>
                    );
                  })}
                </Row>
              ))}
            </Row> */}

            <Row className={classes.SharedFileList}>
              {filteredMedia?.map((media, key) => (
                <Row className={classes.filteredMedia} >
                  {media?.map((mediavalue, index) => {
                    console.log("111allmedia", mediavalue)
                    const fileName = mediavalue?.split("/").pop();
                    const downloadUrl = mediavalue;
                    const handleDownload = (fileUrl, filename) => {
                      fetch(fileUrl, {
                        headers: {
                          "Content-Type": "application/octet-stream",
                        },
                        method: "GET",
                        mode: "cors",
                      })
                        .then((response) => response.blob())
                        .then((blob) => {
                          const url = window.URL.createObjectURL(blob);
                          const link = document.createElement("a");
                          link.href = url;
                          link.setAttribute(
                            "download",
                            fileUrl?.split("/").pop()
                          );
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                          window.URL.revokeObjectURL(url);
                        })
                        .catch((error) => console.log(error));
                      window.open(fileUrl, "_blank")?.focus();
                    };
                    const fileType = mediavalue?.split(".")?.pop()?.toLowerCase();
                    const isImage = ["jpg", "jpeg", "gif", "bmp", "png",].includes(fileType);
                    return (
                      <Col className={classes.Files} >
                        <Col className={classes.filePreview} >
                          {isImage ? (<img src={mediavalue} alt={null} width="100%" height="100%" />
                          ) : (
                            <Col className={classes.fileText}>
                              <img src={fileText} alt={null} width="100%" height="100%" />
                            </Col>
                          )}
                        </Col>
                        <Col className={classes.fileNameFit}>
                          <Typography.Text ellipsis={{ tooltip: true }}> {fileName} </Typography.Text>
                        </Col>
                        <Col className={classes.shareIconcol} >
                          <img style={{ marginRight: "0.5rem", width: '100%', height: '100%' }} src={shareIcon} onClick={() => handleShareIconClick(key + '-' + index)} />
                          {FileShareModalOpen && <FileShareModal isOpen={key + '-' + index == selectedMedia ? FileShareModalOpen : false} onClose={() => setFileShareModalOpen(false)} selectedMedia={mediavalue} socket={socket} />}
                        </Col>
                        <Col className={classes.downloadIconcol}>
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={downloadIcon}
                            onClick={() => handleDownload(mediavalue)}
                          ></img>
                        </Col>
                      </Col>
                    );
                  })}
                </Row>
              ))}
            </Row>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    Api.get("/ibelong-chat/conversation")
      .params({ chatId: openChatWindow?.chatId, page: 1, limit: 300 })
      .send((res) => {
        if (res?.messages?.length > 0) {
          dispatch(
            chatActions.updateChatData({ chatData: res?.messages, type: "API" })
          );
          dispatch(
            chatActions.updateUnseenCount({
              unseenCount: res?.unseen,
              chatId: openChatWindow?.chatId,
            })
          );
        }
      });
  }, [openChatWindow, getMsgToggle]);

  const sendMessageHandler = (val, file) => {
    let data = {
      to: openChatWindow?.userId,
      from: user?.id,
      // message: val?.message,
      message: value,
      chatId: openChatWindow?.chatId,
      chatType: "nonGroup",
    };
    let data1 = {
      to: openChatWindow?.userId,
      from: user?.id,
      // message: val?.message,
      message: value,
      chatId: openChatWindow?.chatId,
      chatType: "nonGroup",
      createdAt: moment().format(),
      fromName: user?.name,
      media: null,
    };

    if (fileList?.get(openChatWindow?.chatId)?.length > 0) {
      console.log("p o s t A P I");
      let file = [];
      fileList?.get(openChatWindow?.chatId)?.forEach((ele) => {
        file.push(ele?.originFileObj);
      });
      data = { ...data, files: file };

      Api.post("/ibelong-chat/mediaMessage").upload(
        data,
        (percentCompleted, response) => {
          if (response !== undefined && percentCompleted === 100) {
            dispatch(
              chatActions.updateChatData({
                chatData: response?.data?.messageResponseObject,
                type: "RECEIVE",
              })
            );
            document.getElementById("message").reset();
            setUpdate(!update);
            setFileListVisible(false);
            setFileList(new Map());
          }
        }
      );
      setValue("");
      getMediaFunc();
    } else if (fileList?.get(openChatWindow?.chatId)?.length === 0 || fileList?.get(openChatWindow?.chatId) === undefined) {
      console.log("s o c k e t A P I");
      if (value?.trim().length === 0) {
        return;
      }

      socket.emit("sendMessage", data, (res) => {
        if (res === true) {
          dispatch(
            chatActions.updateChatData({ chatData: data1, type: "SEND" })
          );
          document.getElementById("message").reset();
          getMediaFunc();
        }
      });
      setValue("");
      getMediaFunc();
    }
  };

  const scrollref = useRef();
  useEffect(() => {
    scrollref.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatData]);

  return (
    <>
      {chatLoader ? (
        <Row justify="center" style={{ marginTop: "10vh" }}>
          <Spin size="large" style={{ alignSelf: "center", height: "60vh" }} />
        </Row>
      ) : (
        <div className={classes.GrpChatBox} >
          <div className={classes.singleChatheader} >
            <div className={classes.ProfImg} >
              <img src={
                openChatWindow?.userProfileImage
                  ? openChatWindow?.userProfileImage
                  : profileIcon
              }
                alt="image"
                style={{
                  borderRadius: '5px',
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <div className={classes.ProfImgMobile} >
              <img src={openChatWindow?.userProfileImage ? openChatWindow?.userProfileImage : profileIcon} alt="image" style={{ borderRadius: '50%', width: "100%", height: "100%", }} />
            </div>
            <Typography.Text ellipsis={{ tooltip: true }} style={{ font: "normal normal bold 16px/22px Poppins", cursor: "pointer", }} onClick={() => { setSingleChatModal(true); }} >
              {openChatWindow?.userName}
            </Typography.Text>
            <Space>
              <Dropdown
                menu={{
                  items: itemspop,
                }}
                placement="bottomRight"
                profileImage
              >
                {window.innerWidth > 700 ? (
                  <div className={classes.more}>
                    <img src={moreHorizontal} style={{ width: '100%', height: '100%', cursor: 'pointer' }} alt="More Horizontal" />
                  </div>
                ) : (
                  <div className={classes.more}>
                    <img src={moreVertical} style={{ width: '100%', height: '100%', cursor: 'pointer' }} alt="More Vertical" />
                  </div>
                )}
              </Dropdown>
            </Space>
          </div>
          <div className="MessageArea scroll">
            {chatData[openChatWindow?.chatId]?.map((val, id, key) => {
              let profileImage =
                user?.id == val?.from
                  ? userImage
                  : openChatWindow?.userProfileImage;
              let time = moment(val?.createdAt).format("hh:mma");
              const dateToday = moment(val?.createdAt).format("MMMM D");

              let flag = false;

              if (key == 0) {
                currDate.current = moment(val?.createdAt).format("MMMM D");
                flag = true;
              } else if (currDate.current != dateToday) {
                currDate.current = dateToday;
                flag = true;
              }

              if (key === 0) {
                msgId.current = val?.id;
                return (
                  <div key={id} ref={scrollref}>
                    {flag && (
                      <div
                        style={{ justifyContent: "center", display: "flex", }} >
                        <div
                          style={{
                            borderBottom: "0.2px solid #E3E3E3",
                            height: "0",
                            width: "40%",
                            marginTop: "16px",
                          }}
                        ></div>
                        <div className={classes.Datediv} >
                          {currDate.current}
                        </div>
                        <div
                          style={{
                            borderBottom: "0.2px solid #E3E3E3",
                            height: "0",
                            width: "40%",
                            marginTop: "16px",
                          }}
                        ></div>
                      </div>
                    )}

                    <SingleMessage
                      text={val?.message}
                      userName={val?.fromName}
                      time={time}
                      profileImage={profileImage}
                    />
                  </div>
                );
              }
              return (
                <div key={id} ref={scrollref}>
                  {flag && (
                    <div className={classes.Datediv} >
                      <div className={classes.dateBorder}></div>
                      <div className={classes.DateBox}>
                        {currDate.current}
                      </div>
                      <div className={classes.dateBorder}></div>
                    </div>
                  )}
                  <SingleMessage
                    FileShareModalOpen={FileShareModalOpen}
                    setFileShareModalOpen={setFileShareModalOpen}
                    text={val?.message}
                    userName={val?.fromName}
                    time={time}
                    profileImage={profileImage}
                    SendchatData={val}
                  />
                </div>
              );
            })}
          </div>
          <Row className="SendFile" style={{
            height: "auto", borderTop: "1px solid #B6B6B6",
            display:
              fileList?.get(openChatWindow?.chatId) == undefined ||
                fileList?.get(openChatWindow?.chatId).length == 0
                ? "none"
                : "block",
            width: "100%",
            padding: "7px",
          }}
          >
            <Row className={classes.SendFileText} >
              Send the attached files
            </Row>
            <Row className={classes.uploadFilesRow}>
              <Row style={{ overflowX: "auto" }}>
                {fileList?.get(openChatWindow?.chatId)?.map((file) => (
                  <Row className={classes.SingleFile} key={file.uid}>
                    <Col className={classes.uploadedImg}>
                      {file.type.startsWith("image/") ? (
                        <img
                          src={URL.createObjectURL(file?.originFileObj)}
                          alt={file?.name}
                          style={{ width: "100%", height: "100%" }}
                        />
                      ) : (
                        <img
                          src={fileText}
                          alt={file?.name}
                          style={{ width: "100%", height: "100%" }}
                        />
                      )}
                    </Col>
                    <Col className={classes.FileNameCol} >
                      {file?.name}
                    </Col>
                    <Col className={classes.RemoveIcon} >
                      <img
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "auto",
                        }}
                        onClick={() => handleRemove(file)}
                        src={removeIcon}
                      ></img>
                    </Col>
                  </Row>
                ))}
              </Row>
            </Row>
          </Row>
          <Row className={classes.ChatMesage}>
            <Form className={classes.messageform} id="message" onFinish={(val) => { sendMessageHandler(val); }} onKeyDown={handleKeyDown} >
              <Col style={{ width: window.innerWidth > 700 ? "83%" : '79%' }}>
                <Form.Item name="message" style={{ height: "auto" }} className="input-form-item" >
                  {visible ? (
                    <div className="Emojipick">
                      <Picker className="emojiStyle" onEmojiClick={(emojiObject) =>
                        myOnEmojiClick(emojiObject)
                      }
                      />
                    </div>
                  ) : null}
                  <div className="mentionInputContainer">
                    <MentionsInput
                      placeholder={"Type a message.."}
                      style={mentionsInputStyle}
                      value={value}
                      onChange={myTst}
                      id="onFoucsInput"
                    >
                      <Mention
                        style={mentionStyle}
                        data={allMentionedUsers}
                        trigger="@"
                      />
                    </MentionsInput>
                  </div>
                </Form.Item>
              </Col>
              <Col className={classes.messageBox}>
                <div className={classes.UploadCol} >
                  <Form.Item name="File" >
                    <Upload
                      fileList={fileList?.get(openChatWindow?.chatId)}
                      className="uploadImageList"
                      listType="picture"
                      showUploadList={false}
                      onChange={handleChange}
                      onRemove={handleRemove}
                      maxCount={5}
                      beforeUpload={(file) => {
                        const isLt20MB = file.size / 1024 / 1024 < 20;
                        if (!isLt20MB) {
                          message.error("File must be smaller than 20MB!");
                          return Upload.LIST_IGNORE;
                        }

                        const combinedList = [
                          ...(fileList?.get(openChatWindow?.chatId) ?? []),
                          file,
                        ];
                        if (combinedList.length > 5) {
                          message.error("You can only upload up to 5 files!");
                          return Upload.LIST_IGNORE;
                        }
                        return isLt20MB;
                      }}
                    >
                      <img
                        style={{
                          cursor: "pointer",
                          border: "none",
                        }}
                        src={fileIcon}
                      ></img>
                    </Upload>
                  </Form.Item>
                </div>
                <div className={classes.Emojidiv} >
                  <Form.Item className="input-form-item" >
                    <Button className={classes.BtnEmo}>
                      <img
                        style={{
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "#F2F2F2",
                        }}
                        src={smileEmojiIcon}
                        onClick={emojiVisibleHandler}
                      ></img>
                    </Button>
                  </Form.Item>
                </div>
                <div className={classes.SendDiv}>
                  <Form.Item >
                    <Button className={classes.SendBtn}
                      htmlType="submit"
                    >
                      <img src={value?.trim()?.length > 0 || fileList?.get(openChatWindow?.chatId)?.length > 0 ? darkSendIcon : sendIcon}></img>
                    </Button>
                  </Form.Item>
                </div>
              </Col>

            </Form>
          </Row>
          <Modal
            bodyStyle={{ height: 520 }}
            open={singleChatModal}
            onCancel={() => {
              setSingleChatModal(false);
            }}
            footer={false}
            closable={false}
          >
            <div className={classes.FileShareModaldiv}>
              <div className={classes.FileModalheader}>
                <div className={classes.ModalProfImgdiv} >
                  <Image
                    src={
                      openChatWindow?.userProfileImage
                        ? openChatWindow?.userProfileImage
                        : profileIcon
                    }
                    alt="image"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  />
                  <div>
                    <Typography.Text
                      ellipsis={{ tooltip: true }}
                      style={{
                        font: "normal normal bold 12px/21px Poppins",
                        maxWidth: "200px",
                      }}
                    >
                      {openChatWindow?.userName}
                    </Typography.Text>
                  </div>
                </div>

                <div
                  onClick={() => {
                    setSingleChatModal(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <CloseOutlined />
                </div>
              </div>
              {/* <div>
                  <Modal
                    bodyStyle={{
                      height: 350,
                      marginTop: "100px",
                      border: '1px solid green'
                    }}
                    open={modalAboutShareOpen}
                    onCancel={() => setmodalAboutShareOpen(false)}
                    closable={true}
                    header={null}
                    footer={null}
                  >

                    <div>
                      <div className={classes.searchdiv}
                      // style={{
                      //   display: "flex",
                      //   flexDirection: "column",
                      //   padding: "10px",
                      //   height: "10vh",
                      // }}
                      >
                        <Search
                          placeholder="Search for groups or people"
                          allowClear
                          style={{
                            width: 7,
                          }}
                        />
                      </div>
                      <div style={{ marginTop: "10px", }}>
                        <Row
                          className="aboutEditss"
                          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        >
                          <Col
                            className="gutter-row"
                            span={3}
                            style={{ padding: "5px", marginLeft: "20px" }}
                          >
                            <div>
                              <img
                                src={profileIcon}
                                alt="image"
                                style={{ width: "40px", height: "42px" }}
                              />
                            </div>
                          </Col>
                          <Col span={14}>
                            <div onClick={ShareModal}>
                              <Typography.Text
                                ellipsis={{ tooltip: true }}
                                style={{
                                  font: "normal normal bold 12px/21px Poppins",
                                  maxWidth: "150px",
                                  marginTop: "13px",
                                }}
                              >
                                userName
                              </Typography.Text>
                            </div>
                          </Col>
                          <Col span={5}> </Col>
                        </Row>
                      </div>
                    </div>

                  </Modal>
                </div> */}
              <div>
                <Tabs defaultActiveKey="1" items={items} />
              </div>
            </div>
          </Modal>
          {/* </div> */}
        </div>
      )}
    </>
  );
}
export default SingleChatConversation;