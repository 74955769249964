
import React,{useState,useEffect}from 'react';
import Tablecomp from './table'
import Cardcomp from './Components/description'
import jal from "./img/jal.png"
import './prithvistyle.css'
import { useDispatch, useSelector } from 'react-redux';
import Scrollcard from './Components/scrollcard';
import classes from './Components/captainComment.module.css';
import "./houses.css";
import CaptainComment from './Components/captainComment';
import Api from "services/Api";
import { authActions } from "../store";
import {Row,Col,Input, Button} from "antd"
import captainImg from './userImage.png';
const { TextArea } = Input;
    const data={
        houseName:'JAL',
        captainName:'ABC',
        viceCaptainName:'EFG',
        color:"#A3D6F0"
      }

const Jal = () => {
  const [captains,setCaptains]=useState(false);
  const {house} = useSelector(state =>state.auth)
  const {user}=useSelector(state=>state.auth);
  const data=[]
  const { year } = useSelector(state => state.auth);
  const { token } = useSelector((state) => state.auth);
  let [value,setvalue] = useState({})
  const dispatch = useDispatch();

  useEffect(() =>{
   house.map((element) =>{
    if(element.name==="JAL")
    {
       data.push(element)
    }
   })
   let value={
    ...data[0],
    color:'#A3D6F0'
   }
   if(user?.privilege==="Admin" && user?.house==="JAL"){
    setCaptains(user?.role==='Captain'||user?.role==='ViceCaptain');

   }
   else if(user?.privilege!=="Admin")
   {
   setCaptains(user?.role==='Captain'||user?.role==='ViceCaptain');
  }
   setvalue(value)
  },[house])

  useEffect(() => {
    if (token) {
      Api.get("/ibelong-houses/overview").params({ queryYear: year }).send((response) => {
        if (response !== undefined) {
          dispatch(authActions.updateHouses(response.houses));
        }
      });
    }
  }, [year]);

  return(
  <div className='house-container'>
   <Cardcomp  url={jal} data={value}/>
   
   <div className={classes.scrollbar}>
    <Scrollcard  captains={captains}/>

{captains&&<CaptainComment />}
      </div>
     <Tablecomp data={value}/>
  </div>
);
}

export default Jal;