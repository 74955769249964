import classes from "./userProfileDetails.module.css";
import profileIcon from "../../../images/profileIcon.jpg";
import earthLogo from "../../../images/modalCardImage.png";
import refresh from "../../../images/refresh.png";
import windlogo from "../../../images/vayuLogo.svg";
import earthlogo from "../../../images/prithviLogo.svg";
import firelogo from "../../../images/agniLogo.svg";
import spacelogo from "../../../images/akashLogo.svg";
import waterlogo from "../../../images/jalLogo.svg";
import { HomeOutlined, LoadingOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import React, { useState } from "react";
import { useWindowDimensions } from "../../windowDimention/windowDimension";

import { useSelector } from "react-redux";
import {
  Form,
  Modal,
  Upload,
  Button,
  message,
  Row,
  Col,
  Spin,
  Typography,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import Api from "services/Api";
import { render } from "@testing-library/react";
import { authActions } from "../store";
import Paragraph from "antd/lib/skeleton/Paragraph";

// import EventDetails from "../events/eventdetails";

const UserProfileDetails = (props) => {
  // const onChange = ({ fileList: newFileList }) => {

  //   console.log("newFileList",fileList)
  //   Api.patch(`/user/profile/${user.id}`).uploadAndPatch({profileImage:fileList.originFileObj},(response,percent)=>{
  //     setDpModal(false)
  //     props.refresh()
  //     console.log(response)
  //   })

  // };

  const { user } = useSelector((state) => state.auth);
  const { width } = useWindowDimensions();
  const detailed = props?.detailed;
  const employeeId = detailed?.id;

  const [ellipsis, setEllipsis] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const profileImage = detailed?.profileImage;

  //console.log(profileImage)
  // console.log(props.detaild.house.name)

  //   const uid=detailed?.uid;
  //   if(user!==undefined)
  //   {

  //   const eventCount=user.map((item)=>{
  //     if(item.uid===uid)
  //     {
  //       return item.eventParticipatedCount;
  //     }
  //     return 0;
  //   })
  //   console.log(eventCount);
  // }

  let color, logo, hcolor;
  if (detailed?.house?.name === "AGNI") {
    color = "#ECB189";
    logo = firelogo;
    hcolor = "#CC5200";
  } else if (detailed?.house?.name === "JAL") {
    color = "#A3D6F0";
    logo = waterlogo;
    hcolor = "#348FBD";
  } else if (detailed?.house?.name === "VAYU") {
    color = "#F8EECB";
    logo = windlogo;
    hcolor = "#F0BC00";
  } else if (detailed?.house?.name === "AKASH") {
    color = "#AA8FB5";
    logo = spacelogo;
    hcolor = "#5D3382";
  } else if (detailed?.house?.name === "PRITHVI") {
    color = "#CAE0D2";
    logo = earthlogo;
    hcolor = "#447A57";
  }
  let count = 0;
  return (
    <>
      <Modal
        centered
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={null}
        closable={false}
        maskClosable={true}
        width="800px"
        // height="513px"
      >
        <img
          src={logo}
          alt="modalCardImage"
          className={classes.modalCardImage}
        />

        <img
          src={profileImage !== null ? profileImage : profileIcon}
          alt="userProfileLogo"
          className={classes.userProfileLogo}
        ></img>
        <div className={classes.modal} style={{ backgroundColor: color }}>
          <div>
            <p>Name :-</p>
            <h1 style={{ color: hcolor }}>{detailed?.name}</h1>
            <p>Employee Id :-</p>
            <h2>{detailed?.uid}</h2>
            <p>Email :-</p>
            <span>{detailed?.email}</span>
            <p>Phone Number :-</p>
            <span>{detailed?.phone}</span>
            <p>
              Events Participated :- <span>{props?.record?.length}</span>
            </p>
          </div>
        </div>
      </Modal>
      {width > 700 && (
        <div className={classes["profile-details"]}>
          <div className={classes["profile-div"]}>
            <div className={classes.empInfo}>
              <img
                src={profileImage !== null ? profileImage : profileIcon}
                style={{ borderRadius: "50%" }}
                alt=""
              ></img>

              {employeeId === user?.id ? (
                <div style={{ marginLeft: "1%" }}>
                  <Upload
                    accept=".jpg, .jpeg, .png"
                    showUploadList={false}
                    onChange={(e) => {
                      count = count + 1;
                      if (count === 1) {
                        Api.patch(`/ibelong-user/profile/${user.id}`).uploadAndPatch(
                          { profileImage: e.file.originFileObj },
                          (response, percent) => {
                            props.refresh();
                          }
                        );
                      }
                    }}
                  >
                    <div style={{ display: "flex", cursor: "pointer" }}>
                      <div
                        style={{
                          fontFamily: "poppins",
                          color: "black",
                          fontWeight: "500",
                          fontSize: "13px",
                        }}
                      >
                        Change Image
                      </div>{" "}
                      <img
                        src={refresh}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "6px",
                          marginTop: "1%",
                        }}
                        alt=""
                      ></img>
                    </div>
                  </Upload>
                </div>
              ) : null}
            </div>

            <div className={classes.empInfo}>
              <Typography.Text
                style={{
                  fontSize: 20,
                  fontFamily: "poppins",
                  fontWeight: "500",
                  maxWidth: "220px",
                }}
                ellipsis={{ tooltip: true }}
              >
                {detailed?.name}
              </Typography.Text>
              {/* <h2>{detailed?.name+"raju raju raju raju rasu"}</h2> */}
              <p>{detailed?.uid}</p>
              <h4>{detailed?.designation}</h4>
            </div>
            <div className={classes.empInfo}>
              <h3>Department </h3>
              <h3>Location</h3>
              <h3>Email Id</h3>
              <h3>Mobile No</h3>
            </div>
            <div className={classes.empInfo}>
              <h5>{detailed?.department ? detailed?.department : "_ _ _ _"}</h5>
              <h5>{detailed?.location ? detailed?.location : "_ _ _ _"}</h5>

              <h5>
                <Typography.Text
                  style={{
                    fontSize: 13,
                    fontFamily: "poppins",
                    fontWeight: "500",
                    maxWidth: "15vw",
                  }}
                  ellipsis={{ tooltip: true }}
                >
                  {detailed?.email ? detailed?.email : "____"}
                </Typography.Text>
              </h5>
              {/* <h3 style={{maxWidth:'200px'}} >{detailed?.email?("jaksjdask"+detailed?.email+"fgdhsjakjs jgaksdask fgieufweiu wediuwehiuwe wieu weiuweiu sgdfgdhsja"):'____'}</h3> */}
              <h5>{detailed?.phone ? detailed?.phone : "___"}</h5>
            </div>
          </div>

          <div className={classes.houseCard}>
            <img src={logo} alt="" className={classes.houseCardImage} />
            <div className={classes.div2} style={{ backgroundColor: color }}>
              <div className={classes.prithvi}>
                <h1 style={{ color: hcolor }}>{detailed?.house?.name}</h1>
              </div>
            </div>
            <h2 onClick={() => setModalOpen(true)} style={{ color: hcolor }}>
              {" "}
              View House Card
            </h2>
          </div>
        </div>
      )}

      {width < 700 && (
        <>
          <div className={classes.mobEmpProfile}>
            <div>
              <img src={profileImage !== null ? profileImage : profileIcon} />
            </div>

            <div>
              <div>
                <Typography.Text
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    maxWidth: "220px",
                  }}
                  ellipsis={{ tooltip: true }}
                >
                  {detailed?.name}
                </Typography.Text>
                <span>{detailed?.designation}</span>
              </div>
              <p>{detailed?.uid}</p>
              <div>
                <a style={{ color: hcolor }} onClick={() => setModalOpen(true)}>
                  View House Card
                </a>
                <div
                  style={{
                    width: "45%",
                    height: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: color,
                    flexDirection: "row",
                    borderRadius: 8,
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={logo}
                    alt=""
                    style={{ width: "1.5rem", height: "1.5rem" }}
                  />
                  <span
                    style={{
                      color: hcolor,
                      fontSize: "0.9rem",
                      fontWeight: "600",
                    }}
                  >
                    {detailed?.house?.name}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.additonalDetails}>
            <div>
              <span>Designation</span>
              <span>
                {detailed?.department ? detailed?.department : "_ _ _ _"}
              </span>
            </div>
            <div>
              <span>Contact Details</span>
              <div>
                <h5>
                  <Typography.Text
                    style={{
                      fontSize: "13px",
                      fontFamily: "poppins",
                      fontWeight: "500",
                      maxWidth: "8rem",
                      color: "grey",
                    }}
                    ellipsis={{ tooltip: true }}
                  >
                    {detailed?.email ? detailed?.email : "____"}
                  </Typography.Text>
                </h5>

                <h5>{detailed?.phone ? detailed?.phone : "___"}</h5>
              </div>
            </div>
            <div>
              <span>Location</span>
              <span>{detailed?.location ? detailed?.location : "_ _ _ _"}</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default UserProfileDetails;
