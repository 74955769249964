
import { NavLink } from 'react-router-dom';
import prithviLogo from '../../images/prithviLogo.svg';
import akashLogo from '../../images/akashLogo.svg';
import agniLogo from '../../images/agniLogo.svg';
import vayuLogo from '../../images/vayuLogo.svg';
import jalLogo from '../../images/jalLogo.svg';
import { useEffect } from 'react';
import Api from 'services/Api';
import { useState } from 'react';
import "./houseNav.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}
const HouseNav = (props) => {
    const { height, width } = useWindowDimensions();
    const sideBarStyle = { color: '#000000', fontFamily: 'poppins', fontSize: '14px', fontWeight: '500' }
    let page = props?.page;
    //console.log('houseNavigation', props)
    let style
    let str
    if (props?.role === "SuperAdmin") {
        style = true
        str = "";
    }
    else if (props?.privilege === "Admin") {
        style = true
        str = "";
    }

    else {
        style = false
        str = "house";
    }

    return (
        <>
            {width > 700 && <ul>
                <NavLink to='/vayu' >
                    <li id="vayu" onClick={() => { props.sideBarHandler(1) }} style={{ backgroundColor: page === 1 ? "#E8F4FF" : "" }} className={style === true ? "style1" : "style2"}>
                        {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'VAYU') ? <div name="VAYU" >
                            <img src={vayuLogo} alt="vayu" style={{ height: '18px', width: '18px' }}></img>
                            <div style={sideBarStyle}>Vayu {str}</div>
                        </div> : null}
                    </li>
                </NavLink>
                <NavLink to='/prithvi'>
                    <li id="prithvi" onClick={() => { props.sideBarHandler(2) }} style={{ backgroundColor: page === 2 ? "#E8F4FF" : "" }} className={style === true ? "style1" : "style2"}>
                        {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'PRITHVI') ? <div name="PRITHVI" >
                            <img src={prithviLogo} alt="prithvi" style={{ height: '18px', width: '18px' }}></img>
                            <div style={sideBarStyle}>Prithvi {str}</div>
                        </div> : null}
                    </li>
                </NavLink>
                <NavLink to='/jal'>
                    <li id="jal" onClick={() => { props.sideBarHandler(3) }} style={{ backgroundColor: page === 3 ? "#E8F4FF" : "" }} className={style === true ? "style1" : "style2"}>
                        {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'JAL') ?
                            <div name="JAL" >
                                <img src={jalLogo} alt="jal" style={{ height: '18px', width: '18px' }}></img>
                                <div style={sideBarStyle}>Jal {str}</div>
                            </div> : null}
                    </li>
                </NavLink>
                <NavLink to='/agni'>
                    <li id="agni" onClick={() => { props.sideBarHandler(4) }} style={{ backgroundColor: page === 4 ? "#E8F4FF" : "" }} className={style === true ? "style1" : "style2"}>
                        {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'AGNI') ?
                            <div name="AGNI" >
                                <img src={agniLogo} alt="agni" style={{ height: '18px', width: '18px' }} />
                                <div style={sideBarStyle}>Agni {str} </div>
                            </div> : null}

                    </li>
                </NavLink>

                <NavLink to='/akash'>
                    <li id="akash" onClick={() => { props.sideBarHandler(5) }} style={{ backgroundColor: page === 5 ? "#E8F4FF" : "" }} className={style === true ? "style1" : "style2"}>
                        {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'AKASH') ?
                            <div name="AKASH" style={{ margin: '' }}>
                                <img src={akashLogo} alt="akash" style={{ height: '18px', width: '18px' }}></img>
                                <div style={sideBarStyle}>Akash {str}</div>
                            </div> : null}
                    </li>
                </NavLink>

            </ul>}
            {width < 700 &&
                <ul>
                    <NavLink to='/vayu' >
                        {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'VAYU') ?
                            <li id="vayu" onClick={() => { props.sideBarHandler(1) }} style={{ backgroundColor: page === 1 ? "#E8F4FF" : "", margin: '0px 2px 2px 0px', }} className={style === true ? "style1" : "style2"}>
                                {/* {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'VAYU') ? */}
                                <div name="VAYU" >
                                    <img src={vayuLogo} alt="vayu" style={{ height: '18px', width: '18px' }}></img>
                                    <div style={sideBarStyle}>Vayu {str}</div>
                                </div>
                                {/* : null} */}
                            </li>
                            : null}
                    </NavLink>
                    <NavLink to='/prithvi'>
                        {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'PRITHVI') ?
                            <li id="prithvi" onClick={() => { props.sideBarHandler(2) }} style={{ backgroundColor: page === 2 ? "#E8F4FF" : "", margin: '0px 2px 2px 0px', }} className={style === true ? "style1" : "style2"}>
                                {/* {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'PRITHVI') ? */}
                                <div name="PRITHVI" >
                                    <img src={prithviLogo} alt="prithvi" style={{ height: '18px', width: '18px' }}></img>
                                    <div style={sideBarStyle}>Prithvi {str}</div>
                                </div>
                                {/* : null} */}
                            </li>
                            : null}
                    </NavLink>
                    <NavLink to='/jal'>
                        {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'JAL') ?

                            <li id="jal" onClick={() => { props.sideBarHandler(3) }} style={{ backgroundColor: page === 3 ? "#E8F4FF" : "", margin: '0px 2px 2px 0px', }} className={style === true ? "style1" : "style2"}>
                                {/* {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'JAL') ? */}
                                <div name="JAL" >
                                    <img src={jalLogo} alt="jal" style={{ height: '18px', width: '18px' }}></img>
                                    <div style={sideBarStyle}>Jal {str}</div>
                                </div>
                                {/* : null} */}
                            </li>
                            : null}
                    </NavLink>
                    <NavLink to='/agni'>
                        {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'AGNI') ?
                            <li id="agni" onClick={() => { props.sideBarHandler(4) }} style={{ backgroundColor: page === 4 ? "#E8F4FF" : "", margin: '0px 2px 2px 0px', }} className={style === true ? "style1" : "style2"}>
                                {/* {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'AGNI') ? */}
                                <div name="AGNI">
                                    <img src={agniLogo} alt="agni" style={{ height: '18px', width: '18px', }}></img>
                                    <div style={sideBarStyle}>Agni {str} </div>
                                </div>
                                {/* : null} */}
                            </li>
                            : null}
                    </NavLink>
                    <NavLink to='/akash'>
                        {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'AKASH') ?

                            <li id="akash" onClick={() => { props.sideBarHandler(5) }} style={{ backgroundColor: page === 5 ? "#E8F4FF" : "", margin: '0px 2px 2px 0px', }} className={style === true ? "style1" : "style2"}>
                                {/* {(props.role === "SuperAdmin" || props?.privilege === "Admin" || props.house === 'AKASH') ? */}
                                <div name="AKASH" >
                                    <img src={akashLogo} alt="akash" style={{ height: '18px', width: '18px' }}></img>
                                    <div style={sideBarStyle}>Akash {str}</div>
                                </div>
                                {/* : null} */}
                            </li>
                            : null}
                    </NavLink>

                </ul>}
        </>

    )
}
export default HouseNav;