import React, { useEffect } from "react";
import "antd/dist/antd.css";

import { Button, Form, Input, message, Select } from "antd";
import Api from "services/Api";
import { useState } from "react";
import Notification from "services/Notification";

const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const SelectWinnerModal = (props) => {
  const [form] = Form.useForm();
  const [finalists, setFinalists] = useState([]);
  const [disfirst, setdisFirst] = useState(null);
  const [dissecond, setdisSecond] = useState(null);
  const [disThird, setDisThird] = useState(null);
  const [uid1, setUid1] = useState({});
  const [uid2, setUid2] = useState({});
  const [uid3, setUid3] = useState({});

  const onFinish = (values) => {
    form.resetFields();
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFill = () => {
    form.setFieldsValue({
      note: "Hello world!",
      gender: "male",
    });
  };

  useEffect(() => {
    Api.get("/ibelong-events/eventFinalists")
      .params({ eventId: props?.eventId, pType: props?.eventType })
      .send((res) => {
        let value = [];
        let list = [];

        if (res !== undefined) {
          // if (res?.length < 3) {
          //   Notification.error("error", "Please make atleast three Finalists");
          //   props?.setSelectWinner(false);
          //   return;
          // }
          list = res?.map((element) => {
            value.push(element);
            return element;
          });
          setFinalists(list);
        }
      });
  }, []);
  const winnerHandler = () => {
    // if (disfirst === null || dissecond === null || disThird === null) {
    //   message.error("Select three winners");
    //   return;
    // }
    console.log("ggggggggggg", {
      eventId: props?.eventId,
      uId1: uid1,
      uId2: uid2,
      uId3: uid3,
    });
    Api.post("/ibelong-events/selectWinners")
      .params({ eventId: props?.eventId, uId1: uid1, uId2: uid2, uId3: uid3 })
      .send((res) => { });
    document.getElementById("winner_form").reset();
    props.setSelectWinner(false);
    props.setSubmitWinner(true);
  };

  return (
    <div>
      <h1 style={{ fontFamily: "poppins", fontWeight: "bold", fontSize: "24px" }} >
        Select Winners
      </h1>
      <Form
        form={form}
        name="control-hooks"
        onFinish={winnerHandler}
        layout="vertical"
        style={{}}
        id="winner_form"
      >
        <Form.Item
          label="1st Winner"
          name="firstWinner"
          rules={[{ required: true, message: "Please Input File Type" }]}
        >
          <Select
            placeholder="Select First Winner"
            onChange={(e, val) => {
              console.log("iddd", "...", val);
              setUid1({ id: val?.key, pType: val?.id, pos: "1" });
              setdisFirst(val?.value);
            }}
          >
            {finalists?.map((val, index) => {
              if (
                (val?.groupName !== null ? val?.groupName : val?.name) !==
                disfirst &&
                (val?.groupName !== null ? val?.groupName : val?.name) !==
                dissecond &&
                (val?.groupName !== null ? val?.groupName : val?.name) !==
                disThird
              ) {
                if (val?.groupName !== null)
                  return (
                    <Option value={val?.groupName} key={val?.id} id="Group">
                      {val?.groupName}{" "}
                    </Option>
                  );
                if (val?.groupName === null)
                  return (
                    <Option value={val?.name} key={val?.id} id="Individual">
                      {val?.name}
                    </Option>
                  );
              }
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="2nd Winner "
          name="secondWinner"
          rules={[{ required: true, message: "Please Input File Type" }]}
        >
          <Select
            placeholder="Select Second Winner"
            disabled={Object.keys(uid1).length===0}
            onChange={(e, val) => {
              setdisSecond(val?.value);
              setUid2({ id: val?.key, pType: val?.id, pos: "2" });
            }}
          >
            {finalists?.map((val, index) => {
              if (
                (val?.groupName !== null ? val?.groupName : val?.name) !==
                disfirst &&
                (val?.groupName !== null ? val?.groupName : val?.name) !==
                dissecond &&
                (val?.groupName !== null ? val?.groupName : val?.name) !==
                disThird
              ) {
                if (val?.groupName !== null) {
                  return (
                    <Option value={val?.groupName} key={val?.id} id="Group">
                      {val?.groupName}
                    </Option>
                  );
                }
                if (val?.groupName === null) {
                  return (
                    <Option value={val?.name} key={val?.id} id="Individual">
                      {val?.name}
                    </Option>
                  );
                }
              }
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="3rd Winner "
          name="thirdWinner"
          rules={[{ required: true, message: "Please Input File Type" }]}
        >
          <Select
            placeholder="Select Third winner"
            disabled={Object.keys(uid2).length===0}
            onChange={(e, val) => {
              setDisThird(val?.value);
              setUid3({ id: val?.key, pType: val?.id, pos: "3" });
            }}
          >
            {finalists?.map((val, index) => {
              if (
                (val?.groupName !== null ? val?.groupName : val?.name) !==
                disfirst &&
                (val?.groupName !== null ? val?.groupName : val?.name) !==
                dissecond &&
                (val?.groupName !== null ? val?.groupName : val?.name) !==
                disThird
              ) {
                if (val?.groupName !== null)
                  return (
                    <Option value={val?.groupName} key={val?.id} id="Group">
                      {val?.groupName}
                    </Option>
                  );
                if (val?.groupName === null)
                  return (
                    <Option value={val?.name} key={val?.id} id="Individual">
                      {val?.name}
                    </Option>
                  );
              }
            })}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button onClick={winnerHandler}>Submit</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SelectWinnerModal;
