import React from "react";

const CustomFooter = ({ data, colors }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          fontSize: '14px',
        }}
      >
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '12px',
                height: '12px',
                borderRadius: '2px',
                backgroundColor: colors[index],
              }}
            ></div>
            <p
              style={{
                fontSize: '14px',
                marginBottom: '0px',
                fontFamily: 'Roboto',
              }}
            >
              {item}
            </p>
          </div>
        ))}
      </div>
    );
  };

export default CustomFooter;