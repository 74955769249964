import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { clearFromLocalStorage } from "services/Storage";


const t=parseInt(moment().format("YYYY"))
const initialState = {
    warnings: 5,
    token: null,
    user: {}, 
    userImage:"", 
    events:[],
    addevent:{},
    sendmodal:false,
    house:[],
    viewGroupInfo:[],
    year:null,
    chatToken:null,
    houseMembers:[]
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateToken(state, action) {
      state.token = action.payload.token;
    },
    updateUser(state, action) {
      state.user = {...action.payload.user };
     
    },
    updateUserImage(state,action)
    {
      state.userImage=action.payload.userImage;
    },
    updateChatToken(state,action){
    state.chatToken=action.payload.chatToken;
    },
    addEvent(state,action){
        state.addevent = {...action.payload };
    },
  eventList(state,action){
    state.events=[...action.payload]
  },
  emailModal(state,action){
    state.sendmodal=action.payload
  },
  updateHouses(state,action){
    state.house=[...action.payload]
  },
  removeUser(state) {
    state.user = {};
    state.token = null;
    state.warnings = 5;
    clearFromLocalStorage();
  },

  updateViewGroupInfo(state,action){
    state.viewGroupInfo=[...action.payload]
  },
  updateYear(state,action){
    state.year=action.payload;
  }
  ,
  updateHouseMembers(state,action){
    
    state.houseMembers=action.payload
  },
 },
});

export default authSlice.reducer;

export const authActions = authSlice.actions;