import { createSlice } from "@reduxjs/toolkit";

import { clearFromLocalStorage } from "services/Storage";

const initialState = {
  toggle:false,
  cardDetails: [],
  totalWinners:0,
  membersParticipated:0,
  housePoint:0,
  videoModal: false,
  details: {},
 captainComment:'',
 post:false,
};

const houseSlice = createSlice({
  name: "house",
  initialState,
  reducers: {
    updaterenderPost(state, action) {
      state.toggle=action.payload;
    },
    updateMembersParticipated(state, action) {
     
      state.membersParticipated=action.payload;
      
    },
    updateTotalWinners(state, action) {
     
      state.totalWinners=action.payload;
    },
    updateMembersParticipated(state, action) {
     
      state.membersParticipated=action.payload;
      
    },
    updateTotalWinners(state, action) {
     
      state.totalWinners=action.payload;
    },
    updateCarddetails(state, action) {
      state.cardDetails = { ...action.payload };
    },
    openVideoModal(state, action) {
      state.videoModal = action.payload;
    },
    groupDetails(state, action) {
      state.groupDetails = { ...action.payload };
    },
   
    updatePost(state,action){
      state.post=action.payload;
     } ,
  updateHousePoint(state,action){
    state.housePoint=action.payload;
  },

    
  },
});

export default houseSlice.reducer;
export const houseActions = houseSlice.actions;
