

import React,{useState,useEffect} from 'react';
import Tablecomp from './table'

import Cardcomp from './Components/description'
import { useDispatch, useSelector } from 'react-redux';
import './prithvistyle.css'
import Earth from './img/prithvi.png'
import Scrollcard from './Components/scrollcard';
import classes from './Components/captainComment.module.css';
import "./houses.css";
import CaptainComment from './Components/captainComment';
import Api from "services/Api";
import { authActions } from "../store";
import {Row,Col,Input, Button} from "antd"
import captainImg from './userImage.png';
const { TextArea } = Input;
const data={
  houseName:'Prithvi',
  captainName:'ABC',
  viceCaptainName:'EFG',
  color:"#CAE0D2"
  
}
const Prithvi = () => {
  const [captains,setCaptains]=useState(false);
  const {house} = useSelector(state =>state.auth)
  const {user}=useSelector(state=>state.auth);
  const data=[]
  let [value,setvalue] = useState({})
  const { year } = useSelector(state => state.auth);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() =>{
   house.map((element) =>{
    if(element.name==="PRITHVI")
    {
       data.push(element)
    }
   })
   let value={
    ...data[0],
    color:'#CAE0D2'
   }
   if(user?.privilege==="Admin" && user?.house==="PRITHVI"){
    setCaptains(user?.role==='Captain'||user?.role==='ViceCaptain')

   }
   else if(user?.privilege!=="Admin"){
   setCaptains(user?.role==='Captain'||user?.role==='ViceCaptain');}
   setvalue(value)
  },[house])

  useEffect(() => {
    if (token) {
      Api.get("/ibelong-houses/overview").params({ queryYear: year }).send((response) => {
        if (response !== undefined) {
          dispatch(authActions.updateHouses(response.houses));
        }
      });
    }
  }, [year]);

  return(
  <div className='house-container'>
    <div className='prithvicardname'>
      <Cardcomp url={Earth} data={value}/>
      </div>
      {/* <Row >
        <Col span={16}>
       <div>
           <Scrollcard />
        </div></Col><Col span={7}>
     <div style={{border: "1px solid #E6E6E6",height:"233px",width:"100%"}}>  <Row> <Col span={6}><img alt="example" src={captainImg} style={{marginTop:"80px",marginLeft:"30px",wdith:"100%",height:"30%"}} /> </Col><Col span={16}>
      <div style={{backgroundColor:"white",height:"200px"}}>
      <TextArea bordered={false} style={{width:"100%",marginTop:"50px"}} placeholder='Hey Captain Whats in your mind?' rows={4} /><Row justify='end'><Button style={{alignSelf:"end"}} type="text">Post</Button></Row></div> </Col></Row>
      
      </div> </Col> 
        </Row> */}
         <div className={classes.scrollbar}>
    <Scrollcard  captains={captains}/>

{captains&&<CaptainComment />}
      </div>
        <Tablecomp data={value}/>
      </div>
);
}
export default Prithvi;