import classes from "./overviewModal.module.css";
import { Row, Col, Modal } from "antd";
import ModalImage from "./modalImage";
import ModalNominatedFiles from "./modalNominatedFiles";
import ModalDescription from "./modalDescription";
import { useState, useEffect } from "react";
import OverviewModalPost from "./overviewModalPost";
import { overViewActions } from "./store";
import nominatedVideo from "../../../images/overviewModalVideoImage.jpg";
import redHeartIcon from "../../../images/redhearticon.svg";
import heartIcon from "../../../images/heart icon.svg";
import uploadLogo from "../../../images/uploadLogo.png";
import FileImageOutlined from "@ant-design/icons"
import profileIcon from "../../../images/profileIcon.jpg"
import { Button, message, Space, Upload } from "antd";
import React from "react";
import "antd/dist/antd.css";
import Api from "services/Api";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store";
import { time } from "../houses/timeUtility";
import moment from "moment";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
const OverviewModal = (props) => {
  const { height, width } = useWindowDimensions();
  const [descrition, setDescription] = useState(true);
  const [image, setImage] = useState(false);
  const [file, setFile] = useState(false);

  let postvalue = props?.data;
  const { videos, likes, overViewLike, overViewLikeCount } = useSelector(state => state.overView)
  const [btnLoader, setBtnLoader] = useState(false);
  const [comment, setComment] = useState("");
  // const [isForm, setIsForm] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [postUlInfo, setPostUrl] = useState([]);
  const createrIamge = props.data.createrImage;
  const { userImage, NominationLoader, year } = useSelector(state => state.auth);
  // const [postLikes, setPostLikes] = useState({});
  const [postLikes, setPostLikes] = useState({ id: 6356, likes: 0, liked: false });
  const updateLikesHandler = () => {
    setPostLikes((prevPostLikes) => ({
      ...prevPostLikes,
      likes: prevPostLikes.liked ? prevPostLikes.likes - 1 : prevPostLikes.likes + 1,
      liked: !prevPostLikes.liked
    }));
  };
  // let index4=s.indexOf(',');
  const hour = 'am'
  //   let hours=s.substring(index4+3)
  //   let h = s.getHours();
  // let m = s.getMinutes();

  useEffect(() => {
    setPostLikes({
      id: props?.data?.eventPostId,
      likes: props?.data?.postLikeCount,
      liked: props?.data?.liked,
    })
  }, [JSON.stringify(props)])

  const dispatch = useDispatch()
  useEffect(() => {
    if (props?.data?.eventId) {
      dispatch(overViewActions.setNominationLoader(true));
      Api.get(`/ibelong-houses/getEventData?eventId=${props.data.eventId}&queryYear=${year}`).send(
        (res) => {
          console.log("response1", res);
          setPostUrl(res?.eventPostComments);
          dispatch(overViewActions.imageList(res?.images));
          dispatch(overViewActions.videoList(res?.nominatedFiles));
          dispatch(overViewActions.setNominationLoader(false));
          dispatch(overViewActions.updateOverviewLike(props.data.liked));
          dispatch(overViewActions.updateOverviewLikeCount(props.data.postLikeCount));
        }
      );
    }
  }, [props?.data, videos.length, likes]);


  // useEffect(() => {
  //   dispatch(overViewActions.setNominationLoader(true))
  //   Api.get(`/houses/getEventData?eventId=${props?.data?.eventId}&queryYear=${year}`).send(
  //     (res) => {
  //       console.log("response1", res);
  //       setPostUrl(res?.eventPostComments);
  //       dispatch(overViewActions.imageList(res?.images))
  //       dispatch(overViewActions.videoList(res?.nominatedFiles))
  //       dispatch(overViewActions.setNominationLoader(false))
  //       dispatch(overViewActions.updateOverviewLike(props?.data?.liked))
  //       dispatch(overViewActions.updateOverviewLikeCount(props?.data?.postLikeCount))
  //     }
  //   );
  // }, [props?.data, videos.length, likes]);
  const submitFormHandler = (event) => {
    setBtnLoader(true);
    event.preventDefault();

    const postData = {
      eventId: props?.data?.eventId,
      file: image,
      comment: comment,
    };

    Api.post("/ibelong-posts/comment").upload(
      postData,
      (response, percentCompleted) => {
        setBtnLoader(false);
        setImage("");
        setImageUrl("");
        setComment("");
        Api.get(`/ibelong-houses/getEventData?eventId=${props?.data?.eventId}&queryYear=${year}`).send(
          (res) => {
            console.log("response11")
            setPostUrl(res?.eventPostComments);
          }
        );
      }
    );
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const imgbeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 3072 / 3072 < 10;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    } else if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    } else {
      getBase64(file, (url) => {
        // setLoading(false);
        setImageUrl(url);
        setImage(file);
      });
    }
  };


  let count = props?.data?.postLikeCount != null ? props?.data?.postLikeCount : 0;
  // dispatch(overViewActions.setNominationLoader(true));
  return (
    <div >
      {width < 700 &&
        <Modal
          bodyStyle={{ padding: '12px',height:"90vh" }}
          // style={{border:"2px solid"}}
          className={classes.ModalOverviewMobile}
          open={props.open}
          onOk={() => props.setOpen(false)}
          onCancel={() => {
            props.setOpen(false); setFile(false); setImage(false); setImageUrl(""); setComment("");
          }}
          closable={false}
          header={false}
          footer={false}
        >
          <div style={{ position: 'relative', height: '70vh', display: 'flex', flexDirection: 'column' }}>
            <div className={classes.imageDiv} style={{ position: 'sticky', top: '0', background: 'white', zIndex: '1', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '5px' }}>
              <div style={{ height: '57px', cursor: 'pointer', width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '-17px', padding: '3px' }} onClick={() => { props.setOpen(false); setFile(false); setImage(false); }}   > &#x2716;  </div>
              <div style={{ width: '97%', display: 'flex', justifyContent: 'space-between', position: 'absolute', marginTop: '9px', paddingBottom: '12px', gap: '5px', marginBottom: '5px', background: 'white' }}>
                <span onClick={() => { setDescription(true); setImage(false); setFile(false); }}
                  className={descrition ? classes.active : ""}>Description
                </span>
                <span onClick={() => { setDescription(false); setImage(true); setFile(false); }}
                  className={image ? classes.active : ""}>Images
                </span>
                <span onClick={() => { setDescription(false); setImage(false); setFile(true); }}
                  className={file ? classes.active : ""}>Nomination Files
                </span>
              </div>
            </div>
            <div style={{ overflowY: 'auto', height: 'calc(100% - 40px)', justifyContent: 'space-between', boxShadow: '2px 2px 10px 2px #00000012', padding: '5px' }}>
              <Col className={classes.NomCol} style={{ boxShadow: '2px 2px 10px 2px #00000012' }}>
                <Col className={`${classes.scroll} ${classes.imageRow}`} >
                  {!descrition && !image && file && <ModalNominatedFiles data={props?.data} style={{}} />}
                  {!file && !descrition && image && <ModalImage data={props?.data} />}
                  {!image && !file && <ModalDescription style={{ marginTop: "auto", minHeight: '40px', maxHeight: "70px", overflowY: 'scroll', }} data={props?.data} />}
                </Col>
              </Col>

              <Col style={{ boxShadow: '2px 2px 10px 2px #00000012' }} className={classes.PostCol} >
                {/* <OverviewModalPost data={postvalue} /> */}
                <div className={`${classes.container} ${classes.scroll}`} style={{ padding: '7px', overflowX: "scroll", width: "100%", height: window.innerWidth < 700 ? "fit-content" : "440px", }}>
                  {postUlInfo?.map((info, key, index) => {
                    console.log("informatin", info, key)
                    return (
                      <div key={key}>
                        {info?.commentMedia === null ? (
                          <div className={classes.profile1}>
                            <div className={classes.profile}>
                              {width > 700 &&
                                <div style={{ width: '20%' }}>
                                  <img src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} style={{ width: '45px', height: '45px', borderRadius: '50%', }} alt="userImage" />
                                </div>
                              }
                              {width < 700 &&
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', }} className={classes.imagePart}>
                                  <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                                    <div className={classes.employee}>
                                      <img style={{ width: '30px', height: '30px', borderRadius: '50%' }} src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} alt="userImage" />
                                      <div style={{ fontFamily: 'poppins', fontWeight: '500', fontSize: '14px' }}>{info?.user?.name}</div>
                                      <div style={{ fontFamily: 'poppins', fontWeight: '400', fontSize: '13px' }}>{moment(info?.createdAt).format('lll')} </div>
                                    </div>
                                  </div>
                                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'baseline', marginLeft: '55px' }}>
                                    <div style={{ width: '100%', display: 'flex', }}>{info.comment}</div>
                                    {/* <img style={{ width: '100px', height: '100px' }} src={info?.commentMedia} alt={nominatedVideo} /> */}
                                  </div>
                                </div>}
                              {width > 700 &&
                                <>
                                  <div className={classes.employee} style={{ minWidth: '18vw' }}>
                                    <h2>{info?.user?.name}</h2>
                                    <p>{moment(info?.createdAt).format('lll')}
                                    </p>
                                  </div>
                                  <span>{info?.comment}</span>
                                </>
                              }

                            </div>

                          </div>
                        ) : (
                          <>
                            {width > 700 && <div style={{ width: '100%', display: 'flex', }} className={classes.imagePart}>
                              <img style={{ width: '27%', }} src={info?.commentMedia} alt={nominatedVideo} />
                              <div style={{ width: '68%' }} className={classes.profile2}>
                                <div className={classes.imageProf}>
                                  <img style={{ width: '45px', height: '45px', }} src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} alt="userImage" />
                                  <div className={classes.employee} style={{ minWidth: '18vw' }} >
                                    <h2>{info?.user?.name}</h2>
                                    <p>{moment(info?.createdAt).format('lll')}
                                    </p>
                                  </div>
                                </div>
                                <span >{info.comment}</span>
                              </div>
                            </div>}
                            {width < 700 &&
                              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', }} className={classes.imagePart}>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                                  <div className={classes.employee}>
                                    <img style={{ width: '30px', height: '30px', borderRadius: '50%' }} src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} alt="userImage" />
                                    <div style={{ fontFamily: 'poppins', fontWeight: '500', fontSize: '14px' }}>{info?.user?.name}</div>
                                    <div style={{ fontFamily: 'poppins', fontWeight: '400', fontSize: '13px' }}>{moment(info?.createdAt).format('lll')} </div>
                                  </div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'baseline', marginLeft: '65px' }}>
                                  <div style={{ width: '100%', display: 'flex', }}>{info.comment}</div>
                                  <img style={{ width: '100px', height: '100px' }} src={info?.commentMedia} alt={nominatedVideo} />
                                </div>
                              </div>}
                          </>


                        )}
                      </div>
                    );
                  })}
                </div>
                <div className={classes.likeDiv}>
                  {console.log("111postLikes", postLikes)}
                  <img style={{ cursor: 'pointer' }}
                    src={postLikes.liked ? redHeartIcon : heartIcon}
                    alt="redHeartIcon"
                    onClick={() => {
                      updateLikesHandler();
                      dispatch(overViewActions.likesList(!likes));
                      return new Promise((resolve, reject) => {
                        console.log("111this is posteventID", postLikes);
                        Api.post("/ibelong-posts/like").params({ postId: postLikes?.id }).send((response, error) => {
                          resolve();
                          if (response) { }
                        });
                      });
                    }}
                  />
                  <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <div style={{ marginRight: '3px' }}>Liked by</div>
                    {postLikes.liked && <div style={{ marginLeft: '5px' }}>you and</div>}
                    {/* <div style={{ marginLeft: '5px' }}>
            {overViewLike ? overViewLikeCount - 1 : overViewLikeCount} others
          </div> */}
                    <div style={{ marginLeft: '3px' }}>
                      {overViewLikeCount > 0 && postLikes.liked ? overViewLikeCount - 1 : overViewLikeCount} others
                    </div>
                  </div>
                </div>
                <form style={{ height: '9vh', }} className={classes.formControl} >
                  <textarea value={comment} style={{ font: 'normal normal 600 14px/15px Poppins', color: 'black', padding: '10px', }} placeholder="Add comments " onChange={(event) => { setComment(event.target.value); }} />
                  <Upload name="avatar" className="avatar-uploader" showUploadList={false} beforeUpload={imgbeforeUpload}  >
                    {imageUrl ? (<img src={imageUrl} alt="" style={{ width: "40px", height: "40px", padding: '2px', }} />
                    ) : (
                      <img src={uploadLogo} alt="uploadIcon" style={{ width: "25px", height: "25px", background: "none", }} />
                    )
                    }
                  </Upload>
                  <div style={{ height: '34px' }}>
                    <Button
                      style={{
                        height: '100%',
                      }}
                      disabled={comment === ''}
                      type="text"
                      loading={btnLoader}
                      onClick={submitFormHandler}
                    >
                      Post
                    </Button>
                  </div>
                </form>

              </Col>
            </div>
          </div>
        </Modal>

      }


      {
        width > 700 && <Modal bodyStyle={{ height: '90vh' }} centered className={classes.ModalOverview} width="937px" open={props.open} onOk={() => props.setOpen(false)} onCancel={() => { props.setOpen(false); setFile(false); setImage(false); setComment(''); setImageUrl("") }} closable={true} header={false} footer={false} >
          <Row className={classes.PostModalRow} style={{height:"100%"}}>
            <Col className={classes.NomCol}  >
              <Row >
                <div className={classes.imageDiv}>
                  <span onClick={() => { setDescription(true); setImage(false); setFile(false); }}
                    className={descrition ? classes.active : ""}>Description
                  </span>
                  <span onClick={() => { setDescription(false); setImage(true); setFile(false); }}
                    className={image ? classes.active : ""}>Images
                  </span>
                  <span onClick={() => { setDescription(false); setImage(false); setFile(true); }}
                    className={file ? classes.active : ""}>Nomination Files
                  </span>
                </div>
              </Row>
              <Col className={`${classes.scroll} ${classes.imageRow}`}          >
                {!descrition && !image && file && <ModalNominatedFiles data={props?.data} />}
                {!file && !descrition && image && <ModalImage data={props?.data} />}
                {!image && !file && <ModalDescription style={{ marginTop: "auto", minHeight: '40px', height: "80vh", overflowY: 'scroll', }} data={props?.data} />}
              </Col>
            </Col>

            <Col className={classes.PostCol} >
              {/* <OverviewModalPost data={postvalue} /> */}
              <div className={`${classes.container} ${classes.scroll}`} style={{ padding: '7px', overflowX: "scroll", width: "100%", height: window.innerWidth < 700 ? "fit-content" : "80vh", }}>
                {postUlInfo?.map((info, key, index) => {
                  console.log("informatin", info, key)
                  return (
                    <div key={key}>
                      {info?.commentMedia === null ? (
                        <div className={classes.profile1}>
                          <div className={classes.profile} style={{width:"100%",display:"flex",flexDirection:"row",gap:"0.5rem"}}>
                            {width > 700 &&
                              <div style={{ width:"1rem"}}>
                                <img src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} style={{ width: '45px', height: '45px', borderRadius: '50%', }} alt="userImage" />
                              </div>
                            }
                            {width < 700 &&
                              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', }} className={classes.imagePart}>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                                  <div className={classes.employee}>
                                    <img style={{ width: '30px', height: '30px', borderRadius: '50%' }} src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} alt="userImage" />
                                    <div style={{ fontFamily: 'poppins', fontWeight: '500', fontSize: '14px' }}>{info?.user?.name}</div>
                                    <div style={{ fontFamily: 'poppins', fontWeight: '400', fontSize: '13px' }}>{moment(info?.createdAt).format('lll')} </div>
                                  </div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'baseline', marginLeft: '55px' }}>
                                  <div style={{ width: '100%', display: 'flex', }}>{info.comment}</div>
                                  {/* <img style={{ width: '100px', height: '100px' }} src={info?.commentMedia} alt={nominatedVideo} /> */}
                                </div>
                              </div>}
                            {width > 700 &&
                              <div style={{display:"flex",flexDirection:"column",width:"90%",marginLeft:"-9.5rem"}}>
                                <div className={classes.employee} style={{ width: '100%',}}>
                                  <h2>{info?.user?.name}</h2>
                                  <p>{moment(info?.createdAt).format('lll')}
                                  </p>
                                </div>
                                <span>{info?.comment}</span>
                              </div>
                            }

                          </div>

                        </div>
                      ) : (
                        <>
                          {width > 700 && <div style={{ width: '100%', display: 'flex', }} className={classes.imagePart}>
                            <img style={{ width: '27%', }} src={info?.commentMedia} alt={nominatedVideo} />
                            <div style={{ width: '68%' }} className={classes.profile2}>
                              <div className={classes.imageProf}>
                                <img style={{ width: '45px', height: '45px', }} src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} alt="userImage" />
                                <div className={classes.employee} style={{ minWidth: '18vw' }} >
                                  <h2>{info?.user?.name}</h2>
                                  <p>{moment(info?.createdAt).format('lll')}
                                  </p>
                                </div>
                              </div>
                              <span >{info.comment}</span>
                            </div>
                          </div>}
                          {width < 700 &&
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', }} className={classes.imagePart}>
                              <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                                <div className={classes.employee}>
                                  <img style={{ width: '30px', height: '30px', borderRadius: '50%' }} src={info?.user?.profileImage !== null ? info?.user?.profileImage : profileIcon} alt="userImage" />
                                  <div style={{ fontFamily: 'poppins', fontWeight: '500', fontSize: '14px' }}>{info?.user?.name}</div>
                                  <div style={{ fontFamily: 'poppins', fontWeight: '400', fontSize: '13px' }}>{moment(info?.createdAt).format('lll')} </div>
                                </div>
                              </div>
                              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'baseline', marginLeft: '65px' }}>
                                <div style={{ width: '100%', display: 'flex', }}>{info.comment}</div>
                                <img style={{ width: '100px', height: '100px' }} src={info?.commentMedia} alt={nominatedVideo} />
                              </div>
                            </div>}
                        </>


                      )}
                    </div>
                  );
                })}
              </div>
              <div className={classes.likeDiv}>
                {console.log("111postLikes", postLikes)}
                <img style={{ cursor: 'pointer' }}
                  src={postLikes.liked ? redHeartIcon : heartIcon}
                  alt="redHeartIcon"
                  onClick={() => {
                    updateLikesHandler();
                    dispatch(overViewActions.likesList(!likes));
                    return new Promise((resolve, reject) => {
                      console.log("111this is posteventID", postLikes);
                      Api.post("/ibelong-posts/like").params({ postId: postLikes?.id }).send((response, error) => {
                        resolve();
                        if (response) { }
                      });
                    });
                  }}
                />
                <div style={{ display: 'flex', marginBottom: '20px' }}>{console.log("sssRes",overViewLikeCount)}
                  <div style={{ marginRight: '3px' }}>Liked by</div>
                  {postLikes.liked && <div style={{ marginLeft: '5px' }}>you and</div>}
                  {/* <div style={{ marginLeft: '5px' }}>
            {overViewLike ? overViewLikeCount - 1 : overViewLikeCount} others
          </div> */}
                  <div style={{ marginLeft: '3px' }}>
                    {overViewLikeCount > 0 && postLikes.liked ? overViewLikeCount - 1 : overViewLikeCount} others
                  </div>

                </div>
              </div>
              <form className={classes.formControl} >
                <textarea
                  value={comment}
                  style={{ font: 'normal normal 600 14px/15px Poppins', color: 'black', padding: '10px', }}
                  placeholder="Add comments "

                  onChange={(event) => {
                    setComment(event.target.value);
                  }}
                />


                <Upload style={{ width: '20%', cursor: 'pointer', }}
                  name="avatar"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={imgbeforeUpload}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt=""
                      style={{
                        width: "40px",
                        height: "40px",
                        cursor: 'pointer',
                        // borderRadius: "50%",
                      }}
                    />
                  ) : (

                    // <Button>
                    <img
                      src={uploadLogo}
                      alt="uploadIcon"
                      style={{
                        width: "30px", height: "30px", background: "none", marginLeft: "20px", cursor: 'pointer'
                      }}
                    />

                    // </Button>
                  )
                  }
                </Upload>
                <div style={{ height: '34px' }}>
                  <Button
                    style={{
                      height: '100%',
                    }}
                    disabled={comment === ""}
                    type="text"
                    loading={btnLoader}
                    onClick={submitFormHandler}
                  >
                    Post
                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </Modal>
      }
    </div >
  );
};

export default OverviewModal;
