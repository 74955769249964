
import React from "react";
import profileIcon from "../../../images/profileIcon.jpg";
import "./Messages.css";
import { Modal } from 'antd';
import { Image, Row, Col, Select, Form, Button, Typography } from "antd";
import downloadChatIcon from "../../../images/downloadChatIcon.svg";
import shareIcon from "../../../images/shareIcon.svg";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import fileText from "../../../images/fileText.svg";
import FileShareModal from "./FileShareModal";
import playCircle from '../../../images/playCircle.svg'
function SingleMessage({ text, time, userName, profileImage, SendchatData }) {
    let words;
    let myString;
    let mentionRecevier;
    let wordsInsideBrackets;


    const { allUser, grpChatPerson, singleChatPerson } = useSelector(
        (state) => state.chat
    );
    const { user } = useSelector((state) => state.auth);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewFile, setPreviewFile] = useState(null);
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [FileShareModalOpen, setFileShareModalOpen] = useState(false);
    const { Paragraph, Text } = Typography;

    const handleShareIconClick = (mediavalue) => {
        console.log("sharesingle", mediavalue)
        setFileShareModalOpen(true);
        setSelectedMedia(mediavalue);
    };

    const handlePreview = (image) => {
        setPreviewImage(image);
        setPreviewVisible(true);
    }

    const handleFilePreview = (file) => {
        setPreviewFile(file);
        setPreviewVisible(true);
    }
    const handleDownload = (fileUrl, filename) => {
        console.log("111Download", fileUrl, "and", filename)
        fetch(fileUrl, {
            headers: { 'Content-Type': 'application/octet-stream' },
            method: 'GET',
            mode: 'no-cors'
        })?.then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileUrl?.split('/').pop());
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.log(error));
        // window.open(fileUrl, "_blank")?.focus(); //to view downloaded file
    };
    if (text != null) {
        const cleanedString = text.replace(/\([^)]*\)/g, " ");
        const regex = /\[([^\]]+)\]/g;
        const matches = text.match(regex);
        if (matches != null) {
            wordsInsideBrackets = matches.map((match) => match.slice(1, -1));
        }
        let myString1 = cleanedString.replace(/[\[\]]/g, " ");

        // words = myString1.split(" ");
        mentionRecevier = user?.name;

        // rrr
        // const sentence = "This is a sentence with some words that should not be split, like 'New York City', but other words should be split apart.";
        // const nonSplitWords = ["New York City"];

        words = myString1
            .replace(new RegExp(wordsInsideBrackets?.join('|').replace(/\s/g, '\\s'), 'g'), match => match.replace(/\s/g, '§'))
            .split(/\s+/)
            .map(word => word.replace(/§/g, ' '))

    }

    return (
        <div className="SingleChat">
            <img className="ChatImageProfile" bodyStyle={{ height: "5vh" }} src={profileImage ? profileImage : profileIcon} />
            <div className="chatData">
                <div className="ChatUserAndTime">
                    <div className="ChatUserName">{userName}</div>
                    <div className="ChatTime">{time}</div>
                </div>
                <div>  {words != undefined ? (
                    <div>
                        {words.map((word) => {
                            if (word === mentionRecevier) {
                                return (
                                    <span style={{ color: "red", backgroundColor: "#f2efed" }}>
                                        {word}{" "}
                                    </span>
                                );
                            } else {
                                const mentionSender = wordsInsideBrackets?.includes(word);
                                const customStyle = mentionSender
                                    ? { color: "blue", backgroundColor: "#f2efed" }
                                    : {};
                                return <span style={customStyle}>{word} </span>;
                            }
                        })}
                    </div>
                ) : (
                    <div>{''}</div>
                )}</div>
                <div style={{ width: '100%' }}>
                    {Array.isArray(SendchatData?.media) && SendchatData?.media.length > 0 ? (
                        <div style={{ display: "flex", alignItems: "center", gap: '3px' }}>
                            {SendchatData?.media.map((file, index) => {
                                const fileType = file?.split(".")?.pop()?.toLowerCase();
                                const isImage = ["jpg", "jpeg", "gif", "bmp", "png"].includes(fileType);
                                const fileName = file?.split('/').pop();
                                console.log("filename", fileName)
                                const isVideo = ["mp4", "avi", "mov", "wmv"].includes(fileType);

                                return (
                                    <Row key={index} style={{ gap: '4px', borderRadius: "4px", border: '1px solid #E0DFDF', display: "flex", alignItems: "center", columnGap: "8px", padding: '3px', }}>
                                        {/* <Col style={{ height: "30px", width: "30px", cursor: 'pointer' }}>
                                            {isImage ? (
                                                <img src={file} alt={null} width="100%" height="100%" onClick={() => handlePreview(file)} />
                                            ) : (
                                                <Col style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", cursor: 'pointer' }}>
                                                    <img src={fileText} alt={null} width="100%" height="100%" onClick={() => handleFilePreview(file)} />
                                                </Col>
                                            )}
                                        </Col> */}
                                        <Col style={{ height: "30px", width: "30px", cursor: 'pointer' }}>
                                            {isImage ? (
                                                <img src={file} alt={null} width="100%" height="100%" onClick={() => handlePreview(file)} />
                                            ) : (
                                                isVideo ? (
                                                    <Col style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", cursor: 'pointer' }}>
                                                        <img src={playCircle} alt={null} width="100%" height="100%" onClick={() => handleFilePreview(file)} />
                                                    </Col>
                                                ) : (
                                                    <Col style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", cursor: 'pointer' }}>
                                                        <img src={fileText} alt={null} width="100%" height="100%" onClick={() => handleDownload(file, fileName)} />
                                                    </Col>
                                                )
                                            )}
                                        </Col>

                                        <Col style={{ height: "24px", width: "60px", cursor: 'pointer', overflow: 'hidden' }}>{fileName}</Col>
                                        <Col className="hover-div" style={{ width: '20px', height: '20px', display: "flex", alignItems: 'center', }}>
                                            <img style={{ objectFit: "cover", width: "100%", height: "100%", marginLeft: '5px', cursor: 'pointer' }} src={downloadChatIcon} onClick={() => handleDownload(file, fileName)} />
                                        </Col>
                                        <Col className="hover-div" style={{ width: '20px', height: '20px', display: "flex", alignItems: 'center' }}>
                                            <img style={{ objectFit: "cover", width: "100%", height: "100%", marginLeft: '5px', cursor: 'pointer', }} src={shareIcon} onClick={() => handleShareIconClick(file)} />
                                            {FileShareModalOpen && <FileShareModal isOpen={FileShareModalOpen} onClose={() => setFileShareModalOpen(false)} selectedMedia={selectedMedia} />}
                                        </Col>
                                    </Row>
                                );

                            })}
                        </div>
                    ) : null}
                </div>
            </div>
            {previewVisible && (
                <Modal open={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                    {previewImage && <img alt="Preview" style={{ width: '98%', }} src={previewImage} />}
                    {previewFile && <iframe title="Preview" style={{ width: '98%', height: '80vh' }} src={previewFile} />}
                </Modal>
            )}
        </div >
    );
}



export default SingleMessage;