import classes from "./tableDataMob.module.css";
import profileIcon from "../../../images/profileIcon.jpg";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Typography } from "antd";
import { NavLink } from "react-router-dom";

export const UserData = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  console.log("showDetails", showDetails);
  return (
    <div>
      <div
        className={classes.UserData}
        onClick={() => setShowDetails((prev) => !prev)}
      >
        <div>
          <img
            src={
              props?.item?.user?.profileImage
                ? props?.item?.user?.profileImage
                : profileIcon
            }
          />
        </div>

        <span>{props?.item?.user?.name}</span>
        <div>
          {!showDetails && (
            <RightOutlined
              style={{ fontWeight: "bold", fontSize: "18px", color: "grey" }}
            />
          )}
          {showDetails && (
            <DownOutlined
              style={{ fontWeight: "bold", fontSize: "18px", color: "grey" }}
            />
          )}
        </div>
      </div>
      {showDetails && (
        <div className={classes.restDetails}>
          <div>
            <span>Employee Id</span>
            <span>{props?.item?.user?.uid}</span>
          </div>
          <div>
            <span>Contact Details</span>
            <div className={classes.contacts}>
              <span>{props?.item?.user?.phone}</span>
              <span>
                <Typography.Text
                  ellipsis={{ tooltip: true }}
                  style={{ width: "5rem", color: "gray", fontSize: "13px" }}
                >
                  {props?.item?.user?.email}
                </Typography.Text>
              </span>
            </div>
          </div>
          <div>
            <span>Events Participants</span>
            <span>{props?.item?.eventParticipateCount}</span>
          </div>
          <div>
            <span>Location</span>
            <span>{props?.item?.user?.location}</span>
          </div>
          <div>
            {/* <span>Department</span>
            <span>{props?.item?.user?.department}</span> */}
            <NavLink
              to={{
                pathname: "/userProfile",
                state: { data: props?.item?.user },
              }}
            >
              <a>View more</a>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

const TableDataMob = (props) => {
  const { data } = props;
  console.log("props", props);

  return (
    <div className={classes.container}>
      {data.map((item, index) => (
        <UserData key={index} item={item} />
      ))}
    </div>
  );
};
export default TableDataMob;
