import React, { useState } from "react"
import { Button, Col, Form, Input, Modal, Row, Select, Upload, message } from "antd";
import Api from "services/Api";
import Notification from "services/Notification";
import "./groupModal.css";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const GroupModal = (props) => {
  console.log("tabss", props)
  const [selectedArray, setSelectedArray] = useState([])
  const [selectedData, setSelecteddata] = useState([])
  const [inputValue, setInputValue] = useState("")
  const [dis, setDis] = useState(false)
  const { user } = useSelector(state => state.auth)
  const { allUser, grpChatPerson, grpSelected, allChatToggle } = useSelector(state => state.chat)
  const dispatch = useDispatch();

  const [selectedHouse, setSelectedHouse] = useState(null);

  const handleHouseChange = (value) => {
    setSelectedHouse(value);
    // setSelectedHouse(value[0]);
    setSelectedArray([]);
  };

  const createGroupFun = (val) => {
    console.log("jjj1", val)
    if (val?.grpMember?.length === 0 || val?.grpMember === undefined) {
      Notification.error("please select participants", "error")
      return;
    }

    let data = {}
    if (val?.groupName) {
      data = { ...data, chatName: val?.groupName }
    }
    if (val?.grpMember) {
      data = { ...data, participants: val?.grpMember }
    }
    setDis(true)

    Api.post("/ibelong-events/createGroup").params({ eventId: props?.details?.id, groupName: val?.groupName, userIds: selectedArray, groupLeaderId: props?.groupLeaderId }).send(res => {
      console.log("ppppp", props)
      props.setGrpModal(false)
      setInputValue("");
      setSelectedArray([]);
      setSelecteddata([]);
      setDis(false)
      document.getElementById("createForm").reset();
      setTimeout(() => {
        props.refresh();
        if (props?.Tab === "Groups" || props?.details?.eventType === "Groups") {
          props?.refreshed && props.refreshed();
        }
      }, 200)
    }
    )

  }

  return (
    <Modal bodyStyle={{
      height: props?.username === 'SuperAdmin' ? "50vh" : '40vh'
    }} open={props.groupModal} destroyOnClose={true} footer={false} onCancel={() => {
      props?.setGrpModal(false); setInputValue(""); setSelectedArray([]); setSelecteddata([]);
      console.log("Taabbb", props.Tab)
      if (props?.Tab === "Groups") {
        props?.refreshed();
      } else {
        props?.refresh();
      }
    }}>
      <h2 style={{ font: "normal bold  16px/25px Poppins", }}>Create Group</h2>
      {
        props?.username === 'SuperAdmin' ?
          <Form layout='vertical' id="createForm" onFinish={(val) => { createGroupFun(val) }} >
            <Form.Item
              name="groupName"
              className={"text-left"}
              rules={[{ required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('groupName').trim().length === 0) {
                    return Promise.reject('Enter group name!');
                  }
                  return Promise.resolve();
                },
              }),
              ]}
            >
              <Input style={{ width: "100%", boxShadow: "4px 4px 8px #00000014" }} id="test" placeholder="Name of the group" maxLength={90} />
            </Form.Item>
            <Form.Item name='houseName' rules={[{ required: true, message: "Please select a house" }]} >
              <Select
                className="selectHouse"
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="Search house"
                value={selectedHouse}
                style={{ width: '100%', boxShadow: "4px 4px 8px #00000014" }}
                onChange={handleHouseChange}
              >
                {Array.from(new Set(props?.participantArray?.map((element) => element.houseName))).map((houseName) => (
                  <Select.Option key={houseName}>{houseName}{console.log("house", houseName)}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="grpMember">
              <Select
                className="selectUser"
                mode="multiple"
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="Search participants"
                value={selectedArray}
                style={{ width: '100%', boxShadow: "4px 4px 8px #00000014" }}
                onChange={(value) => setSelectedArray(value)}
              >

                {props?.participantArray
                  ?.filter((element) => element.houseName === selectedHouse)
                  .map((element) => (
                    <Select.Option key={element.userId} value={element.userId}>
                      {element.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button type="submit" className="creategroupBtn">CREATE</button>
              </div>
            </Form.Item>
          </Form> : <Form layout='vertical' id="createForm" onFinish={(val) => { createGroupFun(val) }} >
            <Form.Item
              name="groupName"
              className={"text-left"}
              rules={[{ required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('groupName').trim().length === 0) {
                    return Promise.reject('Enter group name!');
                  }
                  return Promise.resolve();
                },
              }),
              ]}
            >
              <Input style={{ width: "100%", boxShadow: "4px 4px 8px #00000014" }} id="test" placeholder="Name of the group" maxLength={90} />
            </Form.Item>
            <Form.Item name="grpMember" >
              <Select className="selectUser" mode="multiple" showSearch allowClear optionFilterProp="children" placeholder="search participants" value={selectedData.length === 0 ? "" : selectedArray[0]} style={{ width: '100%', boxShadow: "4px 4px 8px #00000014", }}
                onChange={(value) => {
                  if (!value) {
                    return
                  }

                  for (let element of selectedArray) {
                    if (element === value) {

                      return
                    }

                  }
                  setSelectedArray(value);
                  let valued = props?.participantArray.find((element => element?.userId === value))
                  setSelecteddata((oldArray) => [...oldArray, valued])

                }
                }>
                {props?.participantArray?.map((element) => {
                  return (
                    <Select.Option value={element.userId}>{element.name}</Select.Option>
                  )
                })}

              </Select>
            </Form.Item>

            <Form.Item >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button type="submit" className="creategroupBtn">CREATE</button>
              </div>
            </Form.Item>
          </Form>
      }

    </Modal >
  )

}
export default GroupModal