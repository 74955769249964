import React from "react";
import { useState, useEffect } from 'react';
import { Modal, Row } from "antd";
import iBelongLogo from '../../../images/iBelongLogo.svg';
import moment from "moment";
const months = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}
const ImageModal = (props) => {
    const { height, width } = useWindowDimensions();

    const date = moment(props?.details?.postDate).format("DD MMM")
    return (
        <>
            <Modal bodyStyle={{ height: window.innerWidth > 700 ? '70vh' : '60vh', overflowY: 'scroll' }} open={props?.addImageModal} width="500px" footer={false} closable={true} onCancel={() => props?.setImageModal(false)}>
                <div style={{ padding: "12px" }}>
                    <img src={props?.details?.img} style={{ width: "95%", marginBottom: "10px" }} />
                    <Row style={{ color: "#787878", fontSize: "14px", fontFamily: "poppins", fontWeight: 400 }}>
                        <img src={iBelongLogo} style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
                        <div style={{ marginTop: "10px", marginLeft: "15px" }}>
                            Posted By {props.details?.createBy?.name},{" "}{date}
                        </div>
                    </Row>
                </div>
            </Modal>
        </>
    )
}
export default ImageModal