import React from "react";
import HouseName from "./HouseNames";
import { Tooltip } from "recharts";
import '../modules/GenderDistribution.css'
import CustomFooter from "./CustomFooter";
import NormalisedHorizontalStackedBar from "./NormalisedHorizontalStackedBar";

const GenderDistribution = ({allHouses,colors,userCountByHouse})=>{
    const normalizedData = allHouses?.map((item, index)=>{
        const total = Number(userCountByHouse?.[item.name]?.gender?.maleCount) 
              +Number(userCountByHouse?.[item.name]?.gender?.femaleCount);
        return {
          name: item.name,
          male: Math.round(Number(userCountByHouse?.[item.name]?.gender?.maleCount/total)*100),
          female: Math.round(Number(userCountByHouse?.[item.name]?.gender?.femaleCount/total)*100),
          color: "#000000",
          maleCount: userCountByHouse?.[item.name]?.gender?.maleCount,
          femaleCount: userCountByHouse?.[item.name]?.gender?.femaleCount
        };
      });
      const gender=["male", "female"]
      const color = ["#30ADFF", "#008AE6"]

      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          const { name, male, female, maleCount, femaleCount } = payload[0]?.payload;
      
          return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', width:'180px' }}>
              <p className="label" style={{ fontWeight: 'bold' }}>{`${name}`}</p> 
              
            
              <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'4px'}}>
                <div
                    style={{
                      width: '12px',
                      height: '12px',
                      borderRadius: '2px',
                      backgroundColor: "#30ADFF",
                    }}
                  ></div>
                  <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{fontFamily: 'Roboto'}}>Male</div>
                    <div style={{ color: '#616161', marginLeft: '4px', fontFamily: 'Roboto'}}>{male}%</div>
                  </div>
                </div>
                <div style={{fontFamily: 'Roboto'}}>{maleCount}</div>
               
              </div>
              <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'4px'}}>
                <div
                    style={{
                      width: '12px',
                      height: '12px',
                      borderRadius: '2px',
                      backgroundColor: "#008AE6",
                    }}
                  ></div>
                  <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{fontFamily: 'Roboto'}}>Female</div>
                    <div style={{ color: '#616161', marginLeft: '4px', fontFamily: 'Roboto'}}>{female}%</div>
                  </div>
                </div>
                <div style={{fontFamily: 'Roboto'}}>{femaleCount}</div>
               
              </div>
            </div>
          );
        }
      
        return null;
      };
    return (
        <div className="GenderDistributionContainer">
            <div className="GenderDistributionHeader">
                <h2>Gender Distribution</h2>
                <p>Graphical depiction of gender ratios</p>
            </div>
            <div style={{display:'flex', alignItems: 'center'}}>
                <div>
                    {allHouses?.map((item, index) => (
                        <div style={{marginBottom: '10px', marginTop: '10px',alignItems: 'center'}} ><HouseName key={index} label={item.name} color={colors[index]}/></div>
                    ))}
                </div>
                <NormalisedHorizontalStackedBar
                    normalizedData={normalizedData}
                    label={gender}
                    color={color}
                    CustomTooltip={CustomTooltip}
                />
                
            </div>
            
            <CustomFooter data={gender} colors={color}/>
        </div>
    );
}


export default GenderDistribution;