import { Height } from '@mui/icons-material';
import React from 'react';

const EventCard = ({ date, day, eventName, time, publisher }) => {
  return (
    <div style={styles.card}>
      <div style={styles.dateContainer}>
        <div style={styles.date}>{date}</div>
        <div style={styles.day}>{day}</div>
      </div>
      <div style={styles.detailsContainer}>
        <div style={styles.eventName}>{eventName || '[Event name]'}</div>
        <div style={styles.timePublisher}>
          <span>{time || '[Start time - End time]'}</span>
          <span style={styles.dot}>•</span>
          <span>{publisher || '[Publisher]'}</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  card: {
    display: 'flex',
    marginBottom: '20px',
    width: '512px',
    height: '52px',
    
  },
  dateContainer: {
    backgroundColor: '#F0F9FF',
    padding: '10px',
    textAlign: 'center',
    borderRadius: '4px 0 0 4px',
  },
  date: {
    fontSize: '24px',
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  day: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#757575',
  },
  detailsContainer: {
    backgroundColor: '#FFFFFF',
    padding: '10px',
    borderRadius: '0 4px 4px 0',
    flex: 1,
  },
  eventName: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#424242',
    marginBottom: '5px',
    fontFamily: 'Roboto'
  },
  timePublisher: {
    fontSize: '14px',
    color: '#757575',
  },
  dot: {
    margin: '0 5px',
  },
};

export default EventCard;
