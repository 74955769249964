import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import '../modules/TeamMemberAllocation.css'
import HouseName from './HouseNames';
import Ellipse from '../../../../images/Ellipse 2445.svg'

// const TeamMemberAllocation = () => {
  // Sample data
  const data = [
    { type: 'Prithvi', value: 938 },
    { type: 'Agni', value: 1032 },
    { type: 'Vayu', value: 845 },
    { type: 'Jal', value: 892 },
    { type: 'Akash', value: 986 },
  ];

  const totalValue = data.reduce((sum, item) => sum + item.value, 0);

//   const config = {
//     appendPadding: 10,
//     data,
//     angleField: 'value',
//     colorField: 'type',
//     radius: 1,
//     innerRadius: 0.6,
//     label: {
//       type: 'inner',
//       offset: '-50%',
//       content: '{value}',
//       style: {
//         textAlign: 'center',
//         fontSize: 14,
//       },
//     },
//     interactions: [{ type: 'element-active' }],
//     color: ['#4CAF50', '#FF9800', '#FFEB3B', '#2196F3', '#9C27B0'],
//   };

//   return (
//     <div className="card">
//       <h2>Team Member Allocation</h2>
//       <Pie {...config} />
//     </div>
//   );
// };

// export default TeamMemberAllocation;




// import '../DashboardModules/LocationChart.css'

const colors = ['#4ADA7B',  '#FF8A3B', '#F0DF44','#30ADFF', '#8C33E5'];

const TeamMemberAllocation = ({allHouses,userCountByHouse,userCountsByStatus}) => {
  const updatedData = data?.map(item => ({
    type: item?.type,
    value: Number(userCountByHouse?.[item?.type]?.teamAllocationPercentage),
  }));

  console.log(updatedData,"KV")
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { type, value } = payload[0]?.payload;
  
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
          <p className="label" style={{ fontWeight: 'bold' }}>{`${type} : ${value}%`}</p> 
        </div>
      );
    }
  
    return null;
  };
  return (
  <div className='TeamMemberAllocation-container'>
    <div className='TeamMemberHeading'>
      <h2 style={{fontFamily: " Roboto"}}>Team Member Allocation</h2>
      <p >A holistic View of the member distribution</p>
    </div>
    <div className='TeamMemberPie'>
      <PieChart width={212} height={212} >
        <Pie
          data={updatedData}
          cx="50%"
          cy="50%"
          innerRadius={50}
          outerRadius={100}
          fill="#000000"
          paddingAngle={-20}
          dataKey="value"
          
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />}/>   
      </PieChart>
      <div className='TeamMemberPie1'>
        <div >
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap:'4px'}}>
          <img src={Ellipse} style={{width: '12px', height: '12px'}}/>
          <h2 style={{margin: '0px', fontFamily: 'Roboto', fontSize: '24px'}}>{userCountsByStatus?.Active}</h2>
          </div>
          <p style={{gap: '24px', fontFamily: 'Roboto'}}>Total Active Members</p>
        </div>
          {allHouses?.map((item, index) => (
            <>
          <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent: 'space-between'}}>
            <div style={{display:'flex', flexDirection:'row', alignItems: 'center'}}>
              <div ><HouseName key={index} label={item.name} color={colors[index]}/></div>
              <div style={{marginLeft:'8px', color:'#616161'}}>{(Number(userCountByHouse?.[item?.name]?.teamAllocationPercentage))}%</div>
            </div>
              <div>{userCountByHouse?.[item?.name]?.count}</div>
          </div>
            {index!=4?<hr style={{ borderTop: '1px dashed #E8E8E8', marginTop: '12px'}}/>:null}
            </>
          ))}
      </div>
    </div>
  </div>
  );
};

export default TeamMemberAllocation;

