import MainHeader from "./MainHeader";
import classes from "./Layout.module.css";
import { useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { Row, Col } from "antd";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
const Layout = (props) => {
  const { height, width } = useWindowDimensions();
  const { user } = useSelector((state) => state.auth);
  // console.log(user.image)

  return (
    <>
      {width > 700 &&
        <main className={classes.layout}>
          <MainHeader user={user.name} />
          <main>{props.children}</main>
        </main>}
      {width < 700 &&
        <main className={classes.layout}>
          <MainHeader />
          <main>{props.children}</main>
        </main>
      }
    </>

  );
};

export default Layout;
