import React, { useEffect, useState } from "react";
import { Modal, Upload, Row, Button, Form, message, Typography } from "antd";
import pdfLogo from "../../../images/pdf-logo.svg";
import deleteIcon from "../../../images/delete icon.svg";
import "./uploadFiles.css";
import { CloseOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import uploadIcon from "../../../images/upload icon.svg";
import Api from "services/Api";
import { useSelector, useDispatch } from "react-redux";
import { produceWithPatches } from "immer";
import { houseActions } from "../houses/store";
import Notification from "services/Notification";
const UploadAnyFile = (props) => {
    console.log("junoUploadAnyFile", props?.uploadPostmedia);
    const { user } = useSelector((state) => state.auth);
    const [pdfUrl, setPdfUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const { videoModal, groupDetails } = useSelector((state) => state.house);
    const dispatch = useDispatch();
    useEffect(() => {
        if (videoModal === true) {
            props.setVideoModal(true);
        }
    }, [videoModal]);
    const [jdFile, setJdFile] = useState([]);
    const [fileList, setFiles] = useState([]);
    const handleChange = ({ fileList }) => {
        setFiles(fileList);
        const selectedFiles = fileList.map(file => file.originFileObj);
        setJdFile(selectedFiles);
    };

    const pdfbeforeUpload = (file) => {
        console.log("ppp", file)
        const isPdf = ["application", "pdf", "doc", "docx", "XML"].includes(fileType);
        const fileType = file?.split(".")?.pop()?.toLowerCase();
        const isImage = ["jpg", "jpeg", "gif", "bmp", "png"].includes(fileType);
        const isVideo = ["mp4", "avi", "mov", "wmv"].includes(fileType);

        const isLt10M = file.size / 1024 / 1024 < 10;
        // if (!isPdf) {
        //     message.error("You can only upload PDF files");
        //     return isPdf || Upload.LIST_IGNORE;
        // } else
        if ((isImage || isVideo || isPdf) && !isLt10M) {
            message.error("file must smaller than 10MB!");
            return isPdf || Upload.LIST_IGNORE;
        } else {
            getBase64(file, (url) => {
                setLoading(false);
                setPdfUrl(url);
                setJdFile([file]);
            });
        }
    };
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const [form] = Form.useForm();

    const showUploadList = {
        showPreviewIcon: false,
    };



    let data = {};
    const handleUploadpdf = (values) => {
        let files = [];
        jdFile?.forEach((val, index) => {
            files.push(val);
        });
        data = { ...data, files: files };
        const groupIdToSend = props?.Tab !== "Individual"?(props?.groupId || groupDetails?.id||''):'';

        if (user?.role === 'SuperAdmin') {
            Api.post(`/ibelong-user/uploadUserFileBySuperAdmin?eventId=${props?.details?.id}&userId=${props?.uploaderId}&groupId=${groupIdToSend}`).upload(data,
                (response,) => {
                    console.log("juno1superadmin", user?.role)
                    props.setVideoModal(false);
                    props.setGroupId('');
                    dispatch(houseActions.openVideoModal(false));
                    setJdFile([]);
                    setPdfUrl("");
                    // props.refresh();
                    console.log("juno1superadmin11", props?.Tab, props?.details?.eventType)
                    if (response === 100) {
                        console.log("juno1user2res", props, response)
                        if (props?.Tab === "Groups" || props?.details?.eventType === "Group") {
                            props?.refreshed && props.refreshed();
                        } else {
                            props?.refresh && props?.refresh();
                        }
                    }

                }
            );
        } 
        else if (user?.id === props?.uploaderId) {
            Api.post(`/ibelong-user/uploadUserFile?eventId=${props?.details?.id}&userId=${user?.id}&groupId=${groupIdToSend}`).upload(data,
                (response,) => {
                    console.log("juno1user2", props?.details?.eventType)
                    props.setVideoModal(false);
                    props.setGroupId('');
                    dispatch(houseActions.openVideoModal(false));
                    // setFiles([])
                    setJdFile([]);
                    setPdfUrl("");
                    console.log("tabsss", props?.details?.eventType, props?.Tab)
                    if (response === 100) {
                        console.log("juno1user2res", props, response)
                        // props?.refresh && props?.refresh();
                        if (props?.Tab === "Groups" || props?.details?.eventType === "Group") {
                            console.log("refreshed")
                            props?.refreshed && props.refreshed();
                        } else {
                            console.log("refresh")
                            props?.refresh && props?.refresh();
                        }
                    }
                }
            );
        }

        else if (videoModal === true) {
            Api.post(
                `/ibelong-user/uploadUserFile?eventId=${props?.details?.id}&groupId=${groupIdToSend}`
            ).upload(data, (response, percent) => {
                props.setVideoModal(false);
                props.setGroupId('');
                setJdFile([]);
                setPdfUrl("");
                dispatch(houseActions.openVideoModal(false));
                // props?.refresh && props?.refresh();
                if (response === 100) {
                    console.log("juno1user2res", props, response)
                    // props?.refresh && props?.refresh();
                    if (props?.Tab === "Groups" || props?.details?.eventType === "Group") {
                        props?.refreshed && props.refreshed();
                    } else {
                        props?.refresh && props?.refresh();
                    }
                }
            });
            return;
        }
    };
    const getImageSizeKb = (value) => {
        return Math.round(value / 1024);
    };


    return (
        <Modal
            open={props.videoModal}
            onCancel={() => {
                props?.setVideoModal(false);
                props?.setGroupId && props?.setGroupId('');
                setJdFile([]);
                setPdfUrl("");
                props?.setUploadDetails && props?.setUploadDetails(null);
                props?.setUploadPostmedia && props?.setUploadPostmedia([]);
                props?.setAddMedia();
                dispatch(houseActions.openVideoModal(false));
                if (props?.Tab === "Groups") {
                    props?.refreshed();
                    console.log("jjjuserId", user?.id, "props", props);
                }
                else if (props.refreshTst == undefined) {
                    return null
                }
                else {
                    props?.refresh();
                }
            }}
            footer={false}
            destroyOnClose={true}
        >
            <div
                style={{
                    fontFamily: "poppins",
                    fontSize: "20px",
                    fontWeight: "bold",
                    padding: "0 0 20px 0",
                }}
            >
                Upload files
            </div>
            <Form form={form} onFinish={handleUploadpdf}>
                {console.log("props?.Tab", props)}
                {console.log(props?.details?.eventType, "tab", props?.Tab, 'junoUIDind1', user?.id, 'uploaderId', props?.uploaderId, 'user?.privilege', user?.privilege, 'props?.groupLeaderUpload', props?.groupLeaderUpload, 'props?.groupRole', props?.groupRole)}
                {(props?.Tab === "Individual" || props?.details?.eventType === "Individual" || props?.details?.eventType === "Both") ?
                    (user?.id === props?.uploaderId || user?.role === 'SuperAdmin') &&
                    (
                        <Form.Item name="files">
                            {console.log('junoUIDind', user?.id, 'uploaderId', props?.uploaderId, 'user?.privilege', user?.privilege, 'props?.groupLeaderUpload', props?.groupLeaderUpload, 'props?.groupRole', props?.groupRole)}
                            <Upload.Dragger name="file" id="abcd" beforeUpload={pdfbeforeUpload} accept=".pdf,.jpg,.jpeg,.png,.gif,.mp4,.doc,.docx,.XML" style={{ backgroundColor: "#6FC78F1A", margin: "0 0 20px 0" }} onRemove={() => { setJdFile([]); setPdfUrl(""); }} maxCount={5} onChange={handleChange} listType="picture-card" showUploadList={showUploadList} > <img src={uploadIcon} /> <p style={{ fontFamily: "poppins" }}> Drag to this area or Click to Upload </p>
                            </Upload.Dragger>
                        </Form.Item>
                    ) :
                    props?.Tab === "Groups" || props?.details?.eventType === "Group" ?
                        (user?.id === props?.uploaderId ||
                            user?.privilege === "EventCoordinators" ||
                            user?.role === "SuperAdmin" ||
                            (props?.groupLeaderUpload === true && props?.event?.createdBy?.id == user?.id) ||
                            (props?.groupRole === 'Group Leader' && props?.event?.createdBy?.id == user?.id)) && (
                            <Form.Item name="files">
                                <Upload.Dragger name="file" id="abcd" beforeUpload={pdfbeforeUpload} accept=".pdf,.jpg,.jpeg,.png,.gif,.mp4,.doc,.docx,.XML" style={{ backgroundColor: "#6FC78F1A", margin: "0 0 20px 0" }} onRemove={() => { setJdFile([]); setPdfUrl(""); }} maxCount={5} onChange={handleChange} listType="picture-card" showUploadList={showUploadList} > <img src={uploadIcon} /> <p style={{ fontFamily: "poppins" }}>     Drag to this area or Click to Upload </p> </Upload.Dragger>
                            </Form.Item>
                        )
                        : null}
                <Form.Item>
                    <div style={{ display: "flex", flexWrap: "wrap", width: "500px" }}>
                        {console.log("sssJdfileju1", props?.media)}
                        {console.log("sssJdfileju2", props?.postMedia ? props?.postMedia[0]?.[0] : "")}
                        {console.log("tabsssini1", props?.Tab, props?.details?.eventType)}

                        {props?.uploadPostmedia === undefined ?
                            <>
                                {props?.media?.map((val, id) => {
                                    console.log('llll', val?.postLink, val?.postMedia)
                                    const postLink = val?.postLink;
                                    const grppostLink = val?.postMedia ? val?.postMedia[0]?.[0] : ""
                                    const fileExtension = postLink?.split(".").pop().toLowerCase();
                                    const fileExtensiongrp = grppostLink?.split(".").pop().toLowerCase();
                                    const isImage = ["jpg", "jpeg", "gif", "bmp", "png"].includes(fileExtension);
                                    const isPDF = ["pdf", "docx", 'doc', 'XML'].includes(fileExtension);
                                    const isVideo = ["mp4", "avi", "mov", "wmv"].includes(fileExtension);
                                    const isImageGrp = ["jpg", "jpeg", "gif", "bmp", "png"].includes(fileExtensiongrp);
                                    const isPDFGrp = ["pdf", "docx", 'doc', 'XML'].includes(fileExtensiongrp);
                                    const isVideoGrp = ["mp4", "avi", "mov", "wmv"].includes(fileExtensiongrp);

                                    let IndPdfName;
                                    let grpPdfName;
                                    console.log("grppostLink", grppostLink)
                                    if (val?.postLink !== undefined) {
                                        IndPdfName = val?.postLink?.split("/")[val?.postLink?.split("/")?.length - 1];
                                    } else {
                                        grpPdfName = val?.postMedia[0]?.[0]?.split("/")[val?.postMedia[0]?.[0]?.split("/")?.length - 1];
                                    }
                                    return (
                                        <div className="uploadedImageContainer" key={"image" + id} id={"image" + id} style={{ width: "110px", height: "110px", margin: "0 10px 50px 0", }} >
                                            <div className="image" style={{ height: '100%', width: '100%' }}>
                                                {console.log("jrgrrp", props?.details?.eventType, props?.Tab)}
                                                {(props?.details?.eventType === "Individual" || props?.Tab === "Individual") ?
                                                    <>
                                                        {console.log("tabsss1", props?.details?.eventType)}
                                                        {isImage && <img src={postLink} alt={val.fileName} style={{ width: '100%', height: "100%" }}>
                                                            {console.log("tabsss122", props?.details?.eventType)}
                                                        </img>
                                                        }
                                                        {isPDF && <img src={pdfLogo} alt="PDF" style={{ width: '100%', height: "100%" }}
                                                        />}
                                                        {isVideo && (
                                                            <video style={{ width: '100%', height: "100%" }}
                                                                controls>
                                                                {console.log("tabsss122", props?.details?.eventType, postLink, isVideo)}
                                                                <source src={postLink} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        )}
                                                    </> :
                                                    <>
                                                        {console.log("tabsss11", props?.details?.eventType)}
                                                        {isImageGrp &&
                                                            <img src={grppostLink} alt="file" style={{ width: '100%', height: "100%" }}>
                                                                {console.log("tabsss12", props?.details?.eventType)}
                                                            </img>
                                                        }
                                                        {isPDFGrp && <img src={pdfLogo} alt="PDF" style={{ width: '100%', height: "100%" }}>
                                                            {console.log("tabsss12", props?.details?.eventType)}
                                                        </img>
                                                        }
                                                        {isVideoGrp && (
                                                            <video style={{ width: '100%', height: "100%" }}
                                                                controls>
                                                                {console.log("tabsss12", props?.details?.eventType)}
                                                                <source src={grppostLink} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        )}
                                                    </>

                                                }
                                                <Typography.Text style={{ textOverflow: "ellipsis", overflow: "hidden", paddingLeft: "8px", }} ellipsis={{ tooltip: true }} >
                                                    {IndPdfName}  {grpPdfName}
                                                </Typography.Text>
                                                {(props?.details?.eventType === "Individual" || props?.Tab === "Individual") ? (
                                                    user?.id === props?.uploaderId || user?.role === 'SuperAdmin') && (
                                                        <div className="icon" onClick={() => {
                                                            Api.delete("/ibelong-events/deletePost").params({ postId: val?.postId === undefined ? val?.id : val?.postId }).send((res) => {
                                                                console.log("resss1delete11", props?.uploaderId, user?.id, res)
                                                                if (res?.show?.type === 'error') {
                                                                    console.error("cannot delete")
                                                                }
                                                                else {
                                                                    let deletePdf = document.getElementById(`image${id}`);
                                                                    deletePdf.style.display = "none";
                                                                    val?.postLink !== undefined ? props?.refresh() : props?.refreshed();

                                                                }
                                                            });
                                                        }} >
                                                            <img className="icon-img" src={deleteIcon}></img>
                                                            <p>Remove file</p>
                                                        </div>) :
                                                    props?.Tab === "Groups" || props?.details?.eventType === "Group" ?
                                                        (user?.id === props?.uploaderId ||
                                                            user?.privilege === "EventCoordinators" ||
                                                            user?.role === "SuperAdmin" ||
                                                            (props?.groupLeaderUpload === true && props?.event?.createdBy?.id == user?.id) ||
                                                            (props?.groupRole === 'Group Leader' && props?.event?.createdBy?.id == user?.id)) && (
                                                            <div className="icon" onClick={() => {
                                                                Api.delete("/ibelong-events/deletePost").params({ postId: val?.postId !== undefined ? val?.postId : val?.id, }).send((res) => {
                                                                    console.log("resss1delete1111", props?.uploaderId, user?.id, res)
                                                                    if (res?.show?.type === 'error') {
                                                                        console.log("error", res?.show?.type)
                                                                    }
                                                                    else {
                                                                        let deletePdf = document.getElementById(`image${id}`);
                                                                        deletePdf.style.display = "none";
                                                                        val?.postLink !== undefined ? props?.refresh() : props?.refreshed();

                                                                    }
                                                                })
                                                            }} >
                                                                <img className="icon-img" src={deleteIcon}></img>
                                                                <p>Remove file</p>
                                                            </div>) : null}
                                            </div>
                                        </div>
                                    );
                                })}
                            </> :
                            <>
                                {props?.uploadPostmedia?.map((val, id) => {
                                    console.log('sssllll2', val)
                                    const postLink = val?.postMedia[0];
                                    const grppostLink = val ? val?.postMedia[0] : ""
                                    const fileExtension = postLink?.split(".").pop().toLowerCase();
                                    const fileExtensiongrp = grppostLink?.split(".").pop().toLowerCase();
                                    const isImage = ["jpg", "jpeg", "gif", "bmp", "png"].includes(fileExtension);
                                    const isPDF = ["pdf", "docx", 'doc', 'XML'].includes(fileExtension);
                                    const isVideo = ["mp4", "avi", "mov", "wmv"].includes(fileExtension);
                                    const isImageGrp = ["jpg", "jpeg", "gif", "bmp", "png"].includes(fileExtensiongrp);
                                    const isPDFGrp = ["pdf", "docx", 'doc', 'XML'].includes(fileExtensiongrp);
                                    const isVideoGrp = ["mp4", "avi", "mov", "wmv"].includes(fileExtensiongrp);
                                    let IndPdfName;
                                    let grpPdfName;
                                    console.log("grppostLink", val)
                                    if (val?.postLink !== undefined) {
                                        // IndPdfName = val?.split("/")[val?.split("/")?.length - 1];
                                        IndPdfName = val?.postMedia[0].split("/").pop()
                                    } else {
                                        // grpPdfName = val[0]?.split("/")[val[0]?.split("/")?.length - 1];
                                        grpPdfName = val?.postMedia[0].split("/").pop()
                                    }
                                    return (
                                        <div className="uploadedImageContainer" key={"image" + id} id={"image" + id} style={{ width: "110px", height: "110px", margin: "0 10px 50px 0", display: 'flex', }} >
                                            <div className="image" style={{ width: '100%', height: '100%' }}>
                                                {console.log("jrgrrp1", props?.details?.eventType, props?.Tab)}
                                                {(props?.details?.eventType === "Individual" || props?.Tab === "Individual") ?
                                                    <>
                                                        {isImage && <img src={postLink} alt={val.fileName}
                                                            style={{ width: '100%', height: "100%" }}
                                                        />}
                                                        {isPDF && <img src={pdfLogo} alt="PDF"
                                                            style={{ width: '100%', height: "100%" }}
                                                        />}
                                                        {isVideo && (
                                                            <video
                                                                style={{ width: '100%', height: "100%" }}
                                                                controls>
                                                                <source src={postLink} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        )}

                                                    </> :
                                                    <>
                                                        {console.log("jrgrrp1", props?.details?.eventType)}
                                                        {isImageGrp &&
                                                            <img src={grppostLink} alt="file"
                                                                style={{ width: '100%', height: "100%" }}
                                                            />
                                                        }
                                                        {isPDFGrp && <img src={pdfLogo} alt="PDF"
                                                            style={{ width: '100%', height: "100%" }}
                                                        />}
                                                        {isVideoGrp && (
                                                            <video
                                                                style={{ width: '100%', height: "100%" }}
                                                                controls>
                                                                <source src={grppostLink} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        )}
                                                    </>
                                                }
                                                <Typography.Text style={{ textOverflow: "ellipsis", overflow: "hidden", paddingLeft: "8px", }} ellipsis={{ tooltip: true }} >
                                                    {IndPdfName}  {grpPdfName}
                                                </Typography.Text>
                                                {(props?.details?.eventType === "Individual" || props?.Tab === "Individual" || props?.details?.eventType === "Both") ? (
                                                    user?.id === props?.uploaderId || user?.role === 'SuperAdmin') && (
                                                        <div className="icon" onClick={() => {
                                                            Api.delete("/ibelong-events/deletePost").params({ postId: val?.postId === undefined ? val?.id : val?.postId }).send((res) => {
                                                                console.log("resss1delete111", props?.uploaderId, user?.id, res, 'val :', props?.details?.posts)
                                                                if (res?.show?.type === 'error') {
                                                                    console.error("cannot delete")
                                                                }
                                                                else {
                                                                    let deletePdf = document.getElementById(`image${id}`);
                                                                    deletePdf.style.display = "none";
                                                                    val?.postLink !== undefined ? props?.refresh() : props?.refreshed();

                                                                }
                                                            });
                                                        }} >
                                                            {console.log("delte")}
                                                            <img className="icon-img" src={deleteIcon}></img>
                                                            <p>Remove file</p>
                                                        </div>) :
                                                    props?.Tab === "Groups" || props?.details?.eventType === "Group" ?
                                                        (user?.id === props?.uploaderId ||
                                                            user?.privilege === "EventCoordinators" ||
                                                            user?.role === "SuperAdmin" ||
                                                            (props?.groupLeaderUpload === true && props?.event?.createdBy?.id == user?.id) ||
                                                            (props?.groupRole === 'Group Leader' && props?.event?.createdBy?.id == user?.id)) && (
                                                            <div className="icon" onClick={() => {

                                                                Api.delete("/ibelong-events/deletePost").params({ postId: val?.postId !== undefined ? val?.postId : val?.id, }).send((res) => {
                                                                    console.log("resss1delete1", props?.uploaderId, user?.id, res)
                                                                    if (res?.show?.type === 'error') {
                                                                        console.log("error", res?.show?.type)
                                                                    }
                                                                    else {
                                                                        let deletePdf = document.getElementById(`image${id}`);
                                                                        deletePdf.style.display = "none"; val?.postLink !== undefined ? props?.refresh() : props?.refreshed();

                                                                    }
                                                                })
                                                            }} >
                                                                {console.log("delte")}
                                                                <img className="icon-img" src={deleteIcon}></img>
                                                                <p>Remove file</p>
                                                            </div>) : null}
                                            </div>
                                        </div>
                                    );
                                })}
                            </>

                        }
                    </div>
                </Form.Item>

                {props?.Tab === "Groups" || props?.details?.eventType === "Group" ?
                    (user?.id === props?.uploaderId || user?.role === "SuperAdmin" ||
                        user?.privilege === "EventCoordinators" ||
                        (props?.groupLeaderUpload === true && props?.event?.createdBy?.id == user?.id) ||
                        (props?.groupRole === 'Group Leader' && props?.event?.createdBy?.id == user?.id)) &&
                    (
                        <Form.Item>
                            {console.log('role', props?.groupRole, "juniagroupsTab", props?.Tab, 'USERID', user?.id, 'UPLOADERID', props?.uploaderId, 'PRIVILEGE', user?.privilege, 'GROUPLEADERUPLOAD', props?.groupLeaderUpload)}
                            <Row justify="center" style={{ marginTop: "10px" }}> <Button type="primary" size="large" htmlType="submit" disabled={jdFile.length > 0 ? false : true} > Submit </Button>
                            </Row>

                        </Form.Item>
                    )
                    : (props?.details?.eventType === "Individual" || props?.Tab === "Individual" || props?.details?.eventType === "Both") ? (
                        user?.id === props?.uploaderId || user?.role === 'SuperAdmin') && (
                            <Form.Item>
                                {console.log('jjjjjrole', user?.role, "juniagroupsTab", props?.Tab, 'USERID', user?.id, 'UPLOADERID', props?.uploaderId, 'PRIVILEGE', user?.privilege)}
                                <Row justify="center" style={{ marginTop: "10px" }}> <Button type="primary" size="large" htmlType="submit" disabled={jdFile.length > 0 ? false : true} > Submit </Button>
                                </Row>
                            </Form.Item>
                        ) : null}
            </Form>
        </Modal >
    );
};
export default UploadAnyFile;