import React, { useEffect, useState } from "react";
import Tablecomp from "./table";

import Cardcomp from "./Components/description";
import Slider from "./slider";
import "./prithvistyle.css";
import VayuImg from "./img/vayu.png";
import Scrollcard from "./Components/scrollcard";
import classes from "./Components/captainComment.module.css";
import "./houses.css";
import CaptainComment from "./Components/captainComment";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Input, Button } from "antd";
import captainImg from "./userImage.png";
import Api from "services/Api";
import { authActions } from "../store";
// const data = {
//   houseName: "VAYU",
//   captainName: "ABC",
//   viceCaptainName: "EFG",
//   color: "#F8CE37",
// };
const { TextArea } = Input;
const Vayu = () => {
  const {user}=useSelector((state) => state.auth);
  const { house } = useSelector((state) => state.auth);
  const [captains,setCaptains]=useState(false);
  const data = [];
  let [value, setvalue] = useState({});
  const { year } = useSelector(state => state.auth);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    //console.log("lkllklkl;lllpddpdppdpdpdpd");
    house.map((element) => {
      // console.log(element);
      if (element.name === "VAYU") {
        data.push(element);
      }
    });
   // console.log(data);
    let value = {
      ...data[0],
      color: "#F8EECB",
    };
    setvalue(value);

//random
  if(user?.privilege==="Admin" && user?.house==="VAYU"){
    setCaptains(user?.role==='Captain'||user?.role==='ViceCaptain')

  }
  else if(user?.privilege!=="Admin"){
  setCaptains(user?.role==='Captain'||user?.role==='ViceCaptain');}
  }, [house]);

  useEffect(() => {
    if (token) {
      Api.get("/ibelong-houses/overview").params({ queryYear: year }).send((response) => {
        if (response !== undefined) {
          dispatch(authActions.updateHouses(response.houses));
        }
      });
    }
  }, [year]);

  // margin-left: 12rem;
  // height: 100%;

  return (
    <div className="house-container">
      <Cardcomp url={VayuImg} data={value} />
      {/* <Row >
        <Col span={16}>
       <div>
           <Scrollcard />
        </div></Col><Col span={7}>
     <div style={{border: "1px solid #E6E6E6",height:"233px",width:"100%"}}>  <Row> <Col span={6}><img alt="example" src={captainImg} style={{marginTop:"80px",marginLeft:"30px",wdith:"100%",height:"30%"}} /> </Col><Col span={16}>
      <div style={{backgroundColor:"white",height:"200px"}}>
      <TextArea bordered={false} style={{width:"100%",marginTop:"50px"}} placeholder='Hey Captain Whats in your mind?' rows={4} /><Row justify='end'><Button style={{alignSelf:"end"}} type="text">Post</Button></Row></div> </Col></Row>
      
      </div> </Col> 
        </Row> */}
      <div className={classes.scrollbar} >
        <div style={{margin:"0 auto 1rem auto"}}>
        <Scrollcard  captains={captains}/>

        </div>
        

        {captains&&<CaptainComment />}
      </div>
      <Tablecomp data={value} />
    </div>
  );
};
export default Vayu;
