import { createSlice, current } from "@reduxjs/toolkit";
import { message } from "antd";
import { async } from "q";
import store from "store";
import { useDispatch } from "react-redux";

const initialState = {
  chatData: {},
  allUser: [],
  singleChatPerson: [],
  grpChatPerson: [],
  openChatWindow: null,
  chatWindow: false,
  grpSelected: false,
  allChatToggle: false,
  unseenCount: {},
};


const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    updateUnseenCount(state, action) {

      if (action?.payload?.chatId in state.unseenCount) {

        state.unseenCount[action?.payload?.chatId] = action.payload.unseenCount;
      } else {

        state.unseenCount[action?.payload?.chatId] = [];
        state.unseenCount[action?.payload?.chatId] = action.payload.unseenCount;
      }
    },
    updateChatData(state, action) {

      if (action.payload.type === "API") {
        if (action?.payload?.chatData?.chat?.id in state.chatData) {
          state.chatData[action?.payload?.chatData[0]?.chat?.id] =
            action.payload.chatData;
        } else {
          state.chatData[action?.payload?.chatData[0]?.chat?.id] = [];
          state.chatData[action?.payload?.chatData[0]?.chat?.id] =
            action.payload.chatData;
        }
      } else if (action?.payload?.type === "SEND") {
        if (action.payload.chatData.chatId in state.chatData) {
          state.chatData[action.payload.chatData.chatId].push(
            action.payload.chatData
          );
        } else {
          state.chatData[action.payload.chatData.chatId] = [];
          state.chatData[action.payload.chatData.chatId].push(
            action.payload.chatData
          );
        }
      } else if (action?.payload?.type === "RECEIVE") {
        let temp;
        let id = action.payload.chatData?.chat?.id;
        let val = action.payload.chatData;
        setTimeout(async () => {
          temp = await store.getState()?.chat?.openChatWindow;

          if (id !== temp?.chatId) {

          }
        }, 10);

        if (action.payload.chatData?.chat?.id in state.chatData) {
          state.chatData[action.payload.chatData?.chat?.id].push(
            action.payload.chatData
          );
        } else {
          state.chatData[action.payload.chatData?.chat?.id] = [];
          state.chatData[action.payload.chatData?.chat?.id].push(
            action.payload.chatData
          );
        }
      } else if (action?.payload?.type === "DELETE") {
        delete state.chatData[action.payload.chatId];
      }
    },
    updateAllUser(state, action) {
      state.allUser = action.payload;
    },
    updateSingleChatPerson(state, action) {
      state.singleChatPerson = action.payload;
    },
    updateGrpChatPerson(state, action) {
      state.grpChatPerson = action.payload;
    },
    updateOpenChatWindow(state, action) {
      state.openChatWindow = action.payload;
    },
    updateChatWindow(state, action) {

      state.chatWindow = action.payload;
    },
    updateGrpSelected(state, action) {

      state.grpSelected = action.payload;
    },
    updateAllChatToggle(state, action) {

      state.allChatToggle = !(state.allChatToggle);
      state.unseenCount = {};
    },



  },
});

export default chatSlice.reducer;
export const chatActions = chatSlice.actions;